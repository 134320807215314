import { Form, Input, Radio, Button, Row, Col, notification, Card, Space, Checkbox, Modal, InputNumber, DatePicker, Select, Divider } from 'antd'
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import moment from "moment"
//action
// import { getPatientList } from '../../action/getPatientListAction'
import { savePatientIntakeForm, savePatientIntakeFormReset } from '../../action/savePatientIntakeFormAction'
import { getPatientDetailForIntakeForm, getPatientDetailForIntakeFormReset } from '../../action/getPatientDetailForIntakeFormAction'
//other
import { PageContainer } from "../../component/Xcomponent"

const { TextArea } = Input

const PatientIntakeForm = props => {

    // Variables
    const book_appointment_id = props.match.params.book_appointment_id

    const {
        savePatientIntakeForm, savePatientIntakeFormReset, savePatientIntakeFormState,
        getPatientDetailForIntakeForm, getPatientDetailForIntakeFormReset, getPatientDetailForIntakeFormState
    } = props

    const [formData, setFormData] = useState({
        book_appointment_id: book_appointment_id,
        patient_id: "",
        doctor_id: "",
        consultation_type: "",
        name: "",
        phone: "",
        emergency_contact: "",
        city: "",
        referred_by: "",
        dob: null,
        age: 0,
        gender: "",
        pronouns: "",
        sexual_orientation: "",
        marital_status: "",
        employed: "",
        home_maker: "",
        previously_taken_health_services: "",
        psychiatric_medication: "",
        mental_illness_in_family: "",
        current_physical_health: "",
        current_sleeping_habits: "",
        current_eating_habits: "",
        experiencing_overwhelming_saddness_grief_or_depression: "",
        how_long_experiencing_overwhelming_saddness_grief_or_depression: "",
        experiencing_anxiet_panic_attacks: "",
        experiencing_anxiet_panic_attacks_when_started: "",
        drink_alcohol: "",
        recreational_drug_use: "",
        significant_life_changes: "",
        expectations_from_therapy: "",
        referral_reason: "",
        previous_consultation_ddone_at_lilac: "",
        previous_tests_done: "",
        first_followup: "",
        appropriate_option: ""
    })

    const [form] = Form.useForm()

    // Callbacks
    useEffect(() => {
        getPatientDetailForIntakeForm({ id: book_appointment_id })
        return (() => {
            savePatientIntakeFormReset()
            getPatientDetailForIntakeFormReset()
        })
    }, [])

    useEffect(() => {
        if (savePatientIntakeFormState.apiState === "success") {
            notification["success"]({
                message: savePatientIntakeFormState.message,
                placement: "topRight"
            })
        }
        if (savePatientIntakeFormState.apiState === "error") {
            savePatientIntakeFormReset()
            notification["error"]({
                message: savePatientIntakeFormState.message,
                placement: "topRight"
            })
        }
    }, [savePatientIntakeFormState])

    useEffect(() => {
        if (getPatientDetailForIntakeFormState.apiState === "success") {
            if (getPatientDetailForIntakeFormState.expired_status || getPatientDetailForIntakeFormState.already_exist) {

            }
            else {
                form.setFieldsValue({
                    firstName: getPatientDetailForIntakeFormState.data.patient_id.name.firstName,
                    lastName: getPatientDetailForIntakeFormState.data.patient_id.name.lastName,
                    phone: getPatientDetailForIntakeFormState.data.patient_id.phone,
                    gender: getPatientDetailForIntakeFormState.data.patient_id.gender,
                    email: getPatientDetailForIntakeFormState.data.patient_id.email,
                    dob: getPatientDetailForIntakeFormState.data.patient_id.dob ? moment(getPatientDetailForIntakeFormState.data.patient_id.dob) : null,
                    city: getPatientDetailForIntakeFormState.data.patient_id.city,
                    referred_by: "Self",
                    consultation_type: getPatientDetailForIntakeFormState.data.doctor_id.type,
                })
                setFormData({
                    ...formData,
                    ['firstName']: getPatientDetailForIntakeFormState.data.patient_id.name.firstName,
                    ['lastName']: getPatientDetailForIntakeFormState.data.patient_id.name.lastName,
                    ['phone']: getPatientDetailForIntakeFormState.data.patient_id.phone,
                    ['gender']: getPatientDetailForIntakeFormState.data.patient_id.gender,
                    ['email']: getPatientDetailForIntakeFormState.data.patient_id.email,
                    ['dob']: getPatientDetailForIntakeFormState.data.patient_id.dob,
                    ['city']: getPatientDetailForIntakeFormState.data.patient_id.city,
                    ['referred_by']: "Self",
                    ['consultation_type']: getPatientDetailForIntakeFormState.data.doctor_id.type,
                    ['patient_id']: getPatientDetailForIntakeFormState.data.patient_id._id,
                    ['doctor_id']: getPatientDetailForIntakeFormState.data.doctor_id._id,
                })
            }
        }

    }, [getPatientDetailForIntakeFormState])

    //Functions
    const onFinish = () => {
        savePatientIntakeForm(formData)
    }

    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleOnSelect = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    return (
        <>
            <PageContainer>
                <Card title="Patient Intake Form">

                    {savePatientIntakeFormState.apiState === "success" ?
                        <>
                            <h3>
                                Your form submitted successfully.
                            </h3>
                        </>
                        :

                        (getPatientDetailForIntakeFormState.apiState === "success" && getPatientDetailForIntakeFormState.expired_status) ?
                            <>
                                <h3>
                                    Your appointment time has been expired.
                                </h3>
                            </>
                            :
                            (getPatientDetailForIntakeFormState.apiState === "success" && getPatientDetailForIntakeFormState.already_exist) ?
                                <>
                                    <h3>
                                        You are alredy submitted this intake form.
                                    </h3>
                                </>
                                :
                                getPatientDetailForIntakeFormState.apiState === "success" &&
                                <>

                                    <Form form={form} layout="vertical" onFinish={onFinish}>
                                        {formData.consultation_type === "GC" &&
                                            <>
                                                <Row gutter={20}>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='firstName'
                                                            label="First Name"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="firstName" placeholder="Enter First Name" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='lastName'
                                                            label="Last Name"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="lastName" placeholder="Enter Last Name" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='age'
                                                            label="Age"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <InputNumber min={1} max={100} style={{ width: "100%" }} name="age" placeholder="In years" onChange={(v) => handleOnSelect('age', v)} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='gender'
                                                            label="Gender"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="gender" onChange={handleOnChange} value={formData.gender}>
                                                                <Space direction="horizontal">
                                                                    <Radio value="Man">Man</Radio>
                                                                    <Radio value="Woman">Woman</Radio>
                                                                    <Radio value="Intersex">Intersex</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='referred_by'
                                                            label="Referring clinician / Hospital name"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="referred_by" placeholder="Referred by" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            style={{ borderRadius: "8px" }}
                                                            name='phone'
                                                            label="Phone Number"
                                                            rules={[{
                                                                required: true, message: "Required",
                                                                type: "regexp",
                                                                pattern: "^[6-9][0-9]{9}$",
                                                                message: "Phone number is not valid",
                                                            },]}
                                                        >
                                                            <Input name="phone" placeholder="Enter phone number" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='email'
                                                            label="E-mail ID"
                                                            rules={[{ required: true, message: "Required" }, {
                                                                type: "email", message: "E-mail ID is not valid"
                                                            }]}
                                                        >
                                                            <Input name="email" placeholder="You'r E-mail ID" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='city'
                                                            label="State / City / District"
                                                            rules={[{ required: true, message: "Required" }, {
                                                                pattern: "",
                                                                message: "City is not valid",
                                                            }]}
                                                        >
                                                            <Input name="city" placeholder="Enter city" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item

                                                            name='referral_reason'
                                                            label="Referral reason"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <TextArea rows={2} name="referral_reason" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='previous_consultation_ddone_at_lilac'
                                                            label="Have you taken any previous consultation at Lilac?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="previous_consultation_ddone_at_lilac" onChange={handleOnChange} value={formData.previous_consultation_ddone_at_lilac}>
                                                                <Space direction="horizontal">
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='previous_tests_done'
                                                            label="Have you done any tests with Lilac Insights?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="previous_tests_done" onChange={handleOnChange} value={formData.previous_tests_done}>
                                                                <Space direction="horizontal">
                                                                    <Radio value="Yes">Yes</Radio>
                                                                    <Radio value="No">No</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='first_followup'
                                                            label="Is this your first consultation with lilac?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="first_followup" onChange={handleOnChange} value={formData.first_followup}>
                                                                <Space direction="horizontal">
                                                                    <Radio value="First">First</Radio>
                                                                    <Radio value="Follow-up">Follow-up</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='appropriate_option'
                                                            label="Choose an appropriate option:"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="appropriate_option" onChange={handleOnChange} value={formData.appropriate_option}>
                                                                <Space direction="vertical">
                                                                    <Radio value="Obstetric/ Prenatal">Obstetric/ Prenatal</Radio>
                                                                    <Radio value="Paediatric">Paediatric</Radio>
                                                                    <Radio value="Adult">Adult</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {formData.consultation_type === "MHC" &&
                                            <>
                                                <h3>Personal Information</h3>
                                                <Divider style={{ marginTop: 0 }} />
                                                <Row gutter={20}>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='name'
                                                            label="Full Name"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="name" placeholder="Enter full name" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            style={{ borderRadius: "8px" }}
                                                            name='phone'
                                                            label="Phone Number"
                                                            rules={[{
                                                                required: true, message: "Required",
                                                                type: "regexp",
                                                                pattern: "^[6-9][0-9]{9}$",
                                                                message: "Phone number is not valid",
                                                            },]}
                                                        >
                                                            <Input name="phone" placeholder="Enter phone number" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item

                                                            name='emergency_contact'
                                                            label="Emergency Contact"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="emergency_contact" placeholder="(Name, Relationship and Number)" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='city'
                                                            label="Current Location (City)"
                                                            rules={[{ required: true, message: "Required" }, {
                                                                pattern: "",
                                                                message: "City is not valid",
                                                            }]}
                                                        >
                                                            <Input name="city" placeholder="Enter city" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='referred_by'
                                                            label="Referred by"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="referred_by" placeholder="Referred by" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='dob'
                                                            label="Date of Birth"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <DatePicker name="dob" format="DD-MM-YYYY" style={{ width: "100%" }} placeholder="Date of Birth" onChange={(v) => handleOnSelect('dob', v)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='age'
                                                            label="Age"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <InputNumber min={1} max={100} style={{ width: "100%" }} name="age" placeholder="In years" onChange={(v) => handleOnSelect('age', v)} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={20}>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='gender'
                                                            label="Mention your sex"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="gender" onChange={handleOnChange} value={formData.gender}>
                                                                <Space direction="vertical">
                                                                    <Radio value="Man">Man</Radio>
                                                                    <Radio value="Woman">Woman</Radio>
                                                                    <Radio value="Intersex">Intersex</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='pronouns'
                                                            label="Your pronouns"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="pronouns" onChange={handleOnChange} value={formData.pronouns}>
                                                                <Space direction="vertical">
                                                                    <Radio value="She / Her">She / Her</Radio>
                                                                    <Radio value="Her /Him">Her /Him</Radio>
                                                                    <Radio value="They / Them">They / Them</Radio>
                                                                    <Radio value="Others">Others</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='sexual_orientation'
                                                            label="Sexual orientation"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Radio.Group name="sexual_orientation" onChange={handleOnChange} value={formData.sexual_orientation}>
                                                                <Space direction="vertical">
                                                                    <Radio value="Heterosexual">Heterosexual</Radio>
                                                                    <Radio value="Lesbian">Lesbian</Radio>
                                                                    <Radio value="Gay">Gay</Radio>
                                                                    <Radio value="Bisexual">Bisexual</Radio>
                                                                    <Radio value="Queer">Queer</Radio>
                                                                    <Radio value="Asexual">Bisexual</Radio>
                                                                    <Radio value="Others">Others</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='marital_status'
                                                            label="Marital Status"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="marital_status" showSearch onChange={(v) => handleOnSelect("marital_status", v)}>
                                                                <Select.Option value="Unmarried"> Unmarried  </Select.Option>
                                                                <Select.Option value="Married"> Married  </Select.Option>
                                                                <Select.Option value="Domestic Partnership"> Domestic Partnership  </Select.Option>
                                                                <Select.Option value="Divorced"> Divorced  </Select.Option>
                                                                <Select.Option value="Widowed"> Widowed  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='employed'
                                                            label="Are you currently employed?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="employed" showSearch onChange={(v) => handleOnSelect("employed", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                        <Form.Item
                                                            name='home_maker'
                                                            label="Are you a home maker?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="home_maker" showSearch onChange={(v) => handleOnSelect("home_maker", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <h3>Client History</h3>
                                                <Divider style={{ marginTop: 0 }} />
                                                <Row gutter={20}>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='previously_taken_health_services'
                                                            label="Have you previously received any type of mental health services (psychotherapy, psychiatric services, etc.)?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="previously_taken_health_services" showSearch onChange={(v) => handleOnSelect("previously_taken_health_services", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='psychiatric_medication'
                                                            label="Are you currently taking any psychiatric medication?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="psychiatric_medication" showSearch onChange={(v) => handleOnSelect("psychiatric_medication", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='mental_illness_in_family'
                                                            label="Is there a history of mental illness in the family?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="mental_illness_in_family" showSearch onChange={(v) => handleOnSelect("mental_illness_in_family", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <h3>Information on mental & physical health</h3>
                                                <Divider style={{ marginTop: 0 }} />
                                                <Row gutter={20}>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='current_physical_health'
                                                            label="How would you rate your current physical health?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="current_physical_health" showSearch onChange={(v) => handleOnSelect("current_physical_health", v)}>
                                                                <Select.Option value="Poor"> Poor  </Select.Option>
                                                                <Select.Option value="Unsatisfactory"> Unsatisfactory </Select.Option>
                                                                <Select.Option value="Satisfactory"> Satisfactory </Select.Option>
                                                                <Select.Option value="Good"> Good </Select.Option>
                                                                <Select.Option value="Very good"> Very good </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='current_sleeping_habits'
                                                            label="How would you rate your current sleeping habits?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="current_sleeping_habits" showSearch onChange={(v) => handleOnSelect("current_sleeping_habits", v)}>
                                                                <Select.Option value="Poor"> Poor  </Select.Option>
                                                                <Select.Option value="Unsatisfactory"> Unsatisfactory </Select.Option>
                                                                <Select.Option value="Satisfactory"> Satisfactory </Select.Option>
                                                                <Select.Option value="Good"> Good </Select.Option>
                                                                <Select.Option value="Very good"> Very good </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='current_eating_habits'
                                                            label="How would you rate your current eating habits?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="current_eating_habits" showSearch onChange={(v) => handleOnSelect("current_eating_habits", v)}>
                                                                <Select.Option value="Poor"> Poor  </Select.Option>
                                                                <Select.Option value="Unsatisfactory"> Unsatisfactory </Select.Option>
                                                                <Select.Option value="Satisfactory"> Satisfactory </Select.Option>
                                                                <Select.Option value="Good"> Good </Select.Option>
                                                                <Select.Option value="Very good"> Very good </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='experiencing_overwhelming_saddness_grief_or_depression'
                                                            label="Are you currently experiencing overwhelming sadness, grief or depression?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="experiencing_overwhelming_saddness_grief_or_depression" showSearch onChange={(v) => handleOnSelect("experiencing_overwhelming_saddness_grief_or_depression", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item

                                                            name='how_long_experiencing_overwhelming_saddness_grief_or_depression'
                                                            label="If yes, for approximately how long?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="how_long_experiencing_overwhelming_saddness_grief_or_depression" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='experiencing_anxiet_panic_attacks'
                                                            label="Are you currently experiencing anxiety, panics attacks or have any phobias?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="experiencing_anxiet_panic_attacks" showSearch onChange={(v) => handleOnSelect("experiencing_anxiet_panic_attacks", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item

                                                            name='experiencing_anxiet_panic_attacks_when_started'
                                                            label="If yes, when did you begin experiencing this?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Input name="experiencing_anxiet_panic_attacks_when_started" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='drink_alcohol'
                                                            label="Do you drink alcohol more than once a week?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="drink_alcohol" showSearch onChange={(v) => handleOnSelect("drink_alcohol", v)}>
                                                                <Select.Option value="Yes"> Yes  </Select.Option>
                                                                <Select.Option value="No"> No  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name='recreational_drug_use'
                                                            label="How often do you engage in recreational drug use?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <Select name="recreational_drug_use" showSearch onChange={(v) => handleOnSelect("recreational_drug_use", v)}>
                                                                <Select.Option value="Daily"> Daily  </Select.Option>
                                                                <Select.Option value="Weekly"> Weekly  </Select.Option>
                                                                <Select.Option value="Monthly"> Monthly  </Select.Option>
                                                                <Select.Option value="Infrequently"> Infrequently  </Select.Option>
                                                                <Select.Option value="Never"> Never  </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item

                                                            name='significant_life_changes'
                                                            label="What significant life changes or stressful events have you experienced recently?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <TextArea rows={2} name="significant_life_changes" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <Form.Item

                                                            name='expectations_from_therapy'
                                                            label="What are your expectations from therapy?"
                                                            rules={[{ required: true, message: "Required" }]}
                                                        >
                                                            <TextArea rows={2} name="expectations_from_therapy" onChange={handleOnChange} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Form.Item >
                                            <Button type="primary" htmlType="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>

                                    </Form>
                                </>
                    }
                </Card>
            </PageContainer>

        </>
    )

}

const mapStateToProps = (state) => ({
    savePatientIntakeFormState: state.savePatientIntakeForm,
    getPatientDetailForIntakeFormState: state.getPatientDetailForIntakeForm,
})

const mapDispatchToProps = (dispatch) => ({
    savePatientIntakeForm: (params) => dispatch(savePatientIntakeForm(params)),
    savePatientIntakeFormReset: () => dispatch(savePatientIntakeFormReset()),
    getPatientDetailForIntakeForm: (params) => dispatch(getPatientDetailForIntakeForm(params)),
    getPatientDetailForIntakeFormReset: () => dispatch(getPatientDetailForIntakeFormReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientIntakeForm)