import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const YourHelthBg = styled.div`
  width: 100%;
  position: relative;
  // padding-bottom: 50px;
`;
export const HealthBox = styled.div`
  --bs-gutter-x: 0;
  align-items: "center";
`;
export const MobileDiv = styled.div`
  // display: block;
  // @media (max-width: 768px) {
  //     display: flex;
  //     flex-direction: column;
  // }
`;

export const HelthboxTiles = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: 90%;
    margin: 15px auto;
  }
`;
export const HealthImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const HealthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
`;
export const HelthLink = styled(Link)`
  text-decoration: none;
`;
export const YourHelthImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0px;
`;
export const YourHelthImg2 = styled.img`
  position: absolute;
  top: 70%;
  left: 0;
  width: 30%;
`;
export const YourHelthImg3 = styled.img`
  width: 100%;
  position: absolute;
`;
export const XImg = styled.img`
  width: 110%;
`;
export const HelthboxHeading = styled.h4`
  margin: 0px 0px 4px 0px;
  font-family: Poppins;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #1f274a;
`;
export const HelthboxPara = styled.h4`
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  color: #857085;
`;
