import React, { useEffect, useState } from "react";
import { Typography, Input, Modal, Spin, Button, Form } from "antd";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import config from "../../config";
import { getPatientToken } from "../../utils";
import { Tabs, Radio, Space } from "antd";
import { MyReportModal } from "../../component/MyReportModal/MyReportModal";
import {
  SearchOutlined,
  FilterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./MyReport.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";
const MyReport = (props) => {
  const [form] = Form.useForm();
  const [firstTabData, setFirstTabData] = useState([]);
  const [secondTabData, setSecondTabData] = useState([]);
  const [thirdTabData, setThirdTabData] = useState([]);
  const [filterValue, setFiltervalue] = useState("");
  const [classType, setClassType] = useState("themeBtnnew");
  const [listStore, setListStore] = useState([]);
  const [linkTabData, setLinkTabData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAddTestReportBtn, setShowAddTestReportBtn] = useState(true);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [loadingTestReports, setLoadingTestReports] = useState(false);
  const [loadingDoctorReports, setLoadingDoctorReports] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const { TabPane } = Tabs;
  const { Title } = Typography;

  const getReportList = async () => {
    window.scrollTo(0, 0);
    try {
      setLoadingReports(true);
      const response = await fetch(`${config.api.base_url}report/list?page=1&records=1000`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      });
      const res = await response.json();
      setFirstTabData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReports(false);
    }
  };

  useEffect(() => {
    getReportList();
  }, []);

  function getTabKey(key) {
    if (key == 1) {
      firstTab();
    }
    if (key == 2) {
      secondTab();
    }
    if (key == 3) {
      thirdTab();
    }
  }

  async function firstTab() {
    setShowAddTestReportBtn(true);
    try {
      setLoadingReports(true);
      const response = await fetch(`${config.api.base_url}report/list?page=1&records=1000`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      });
      const resp = await response.json();
      setFirstTabData(resp.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReports(false);
    }
  }
  async function secondTab() {
    console.log('second tab')
    setShowAddTestReportBtn(false);
    try {
      setLoadingTestReports(true);
      const response = await fetch(
        `${config.api.base_url}report/get-test-reports`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }
      );
      const res = await response.json();
      setSecondTabData(res?.data);
      setLinkTabData(res?.data.result[0]?.report[0]?.location);
      setListStore(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTestReports(false);
    }
  }
  async function thirdTab() {
    console.log('thired tab')
    setShowAddTestReportBtn(false);
    try {
      setLoadingDoctorReports(true);
      const response = await fetch(
        `${config.api.base_url}report/list-by-doctor?page=1&records=1000`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }
      );
      const res = await response.json();
      setThirdTabData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDoctorReports(false);
    }
  }

  // useEffect(() => {
  //   secondTab();
  // }, []);

  // useEffect(() => {
  //   thirdTab();
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showFilter = () => {
    setIsFilterModalVisible(true);
  };
  const handleOk = () => {};
  function closeModal() {
    setIsFilterModalVisible(false);
  }
  const searchfiler = async (e) => {
    setFiltervalue(e.currentTarget.value);
    setIsFilterModalVisible(false);
    if (e.currentTarget.value == "") {
      form.setFieldsValue("");
      form.resetFields("");
    }

    fetch(
      `${config.api.base_url}report/get-test-reports?search_contain=&&status=${e.currentTarget.value}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      }
    ).then((result) => {
      result.json().then((resp) => {
        console.log("resp.data", resp.data);
        setSecondTabData(resp.data);

        setLinkTabData(resp.data.result[0]?.report[0]?.location);
      });
    });
  };

  const filters = (e) => {
    let dvalue = e.target.value;
    // console.log("secondTabData", secondTabData);
    if (e.target.value != "") {
      fetch(
        `${config.api.base_url}report/get-test-reports?search_contain=${e.target.value}&&status=`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }
      ).then((result) => {
        result.json().then((resp) => {
          console.log("resp.data", resp.data);
          setSecondTabData(resp.data);

          setLinkTabData(resp.data.result[0]?.report[0]?.location);
        });
      });
    } else {
      fetch(`${config.api.base_url}report/get-test-reports`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      }).then((result) => {
        result.json().then((resp) => {
          setSecondTabData(resp.data);

          setLinkTabData(resp.data.result[0]?.report[0]?.location);
          setListStore(resp.data);
        });
      });
    }
  };
  // firstTab();
  console.log('sohoooooo',showAddTestReportBtn)

  // console.log(firstTabData);
  return (
    <div className="container">
      {/* Start Report */}
      <div className="pageWrap">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="tabbable-panel">
                {/* <div className="addReportRightWrap">
                  <button
                    type="button"
                    class="btn addReportBtn"
                    onClick={showModal}
                  >
                    + Add Report
                  </button>
                </div> */}
                <div className="tabbable-line">
                  <ul className="nav nav-tabs">
                    <li>
                      <a
                        className="active"
                        href="#tab_default_1"
                        data-toggle="tab"
                        onClick={() => {
                          firstTab();
                        }}
                      >
                        Your Reports
                      </a>
                    </li>
                    {/* requested by lilac */}
                    {/* <li>
                      <a
                        href="#tab_default_2"
                        onClick={() => {
                          secondTab();
                        }}
                        data-toggle="tab"
                      >
                        Test Reports
                      </a>
                    </li> */}
                    {/* requested by lilac */}
                    <li>
                      <a
                        href="#tab_default_3"
                        onClick={() => {
                          thirdTab();
                        }}
                        data-toggle="tab"
                      >
                        Doctor Report
                      </a>
                    </li>
                  </ul>
                  {showAddTestReportBtn && (
                    <div
                      className="tab-pane addTabBg1 pt-0"
                      style={{ background: "#f8f9fa" }}
                    >
                      <div className="addReportRightWrap">
                        <button
                          type="button"
                          class="btn addReportBtn"
                          onClick={showModal}
                        >
                          + Add Report
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="tab-content">
                    <div
                      className="tab-pane active addTabBg1"
                      id="tab_default_1"
                    >
                      {loadingReports ? (
                        <div className="row text-center">
                          <Spin spinning={loadingReports} tip="Loading..." />
                        </div>
                      ) : firstTabData?.result?.length > 0 ? (
                        <>
                          {firstTabData.result.map((item) => (
                            <>
                              <div className="row">
                                <div class="col-12 col-sm-9">
                                  <div className="row align-items-center">
                                    <div className="col-2 col-sm-1">
                                      <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="40"
                                          height="40"
                                          fill="#F8F9FA"
                                        />
                                        <path
                                          d="M23 12H14V28H26V15H23V12ZM14 10H24L28 14V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C13.4696 30 12.9609 29.7893 12.5858 29.4142C12.2107 29.0391 12 28.5304 12 28V12C12 11.4696 12.2107 10.9609 12.5858 10.5858C12.9609 10.2107 13.4696 10 14 10ZM16 19H24V21H16V19ZM16 23H24V25H16V23Z"
                                          fill="#1F274A"
                                          fill-opacity="0.8"
                                        />
                                      </svg>
                                    </div>
                                    <div className="col-10 col-sm-11">
                                      <span className="fileNameHeader">
                                        {item.title}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-3">
                                  <span class="float-right">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={
                                        "https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/patient-report/" +
                                        item.file_path
                                      }
                                      download
                                      className="fileNamedownload"
                                    >
                                      {" "}
                                      Download
                                      <svg
                                        width="17"
                                        height="20"
                                        viewBox="0 0 17 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1.875 18.875H15.375M2.625 9.125L8.625 14.375L14.625 9.125M8.625 1.625V14.375"
                                          stroke="#a54ea5"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </div>
                                <MyReportModal
                                  visible={isModalVisible}
                                  getReportList={getReportList}
                                  onClose={() => setIsModalVisible(false)}
                                />
                                <hr className="borderDivider" />
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            <img
                              className="img-fluid"
                              src="images/no-report.png"
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 text-center">
                            <h3 className="noReportHeading">
                              You don't have any reports
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="tab-pane" id="tab_default_2">
                      <div className="addReportRightWrap">
                        <div className="reportSearch mb-2">
                          <Form layout="inline" form={form}>
                            <Form.Item name="search" className="mb-2 mr-0">
                              <Input
                                name="search"
                                className="reportSearchInput"
                                placeholder="Search"
                                onChange={filters}
                                prefix={<SearchOutlined />}
                              />
                            </Form.Item>
                            <FilterOutlined
                              className="mt-2 ml-2 mr-2"
                              onClick={showFilter}
                              style={{ cursor: "pointer" }}
                            />
                            <Button
                              shape="round"
                              type="button"
                              className="resetButton"
                              onClick={searchfiler}
                            >
                              Reset
                            </Button>
                          </Form>
                        </div>
                      </div>
                      <div className="row">
                        {loadingTestReports ? (
                          <div className="row text-center">
                            <Spin
                              spinning={loadingTestReports}
                              tip="Loading..."
                            />
                          </div>
                        ) : secondTabData?.result?.length > 0 ? (
                          <>
                            {secondTabData.result.map((item) => (
                              <div className="col-12 col-sm-6 col-md-4 mb-4">
                                <div className="testReportCard">
                                  <div className="testReportCardInner">
                                    <div className="testReportLeft">
                                      <img
                                        className="img-fluid"
                                        src="images/user-icon.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="testReportRight">
                                      <div className="testReportCardHead1">
                                        Patient Name
                                      </div>
                                      <div className="testReportCardHead2">
                                        {" "}
                                        {item.patient[0].name.firstName}{" "}
                                        {item.patient[0].name.lastName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="testReportCardInner">
                                    <div className="testReportLeft">
                                      <img
                                        className="img-fluid"
                                        src="images/document-icon.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="testReportRight">
                                      <div className="testReportCardHead1">
                                        Test Name
                                      </div>
                                      <div className="testReportCardHead2">
                                        {item.test_info.display_test_name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="testReportCardInner">
                                    <div className="testReportLeft">
                                      <img
                                        className="img-fluid"
                                        src="images/document-icon-2.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="testReportRight">
                                      <div className="testReportCardHead1">
                                        Institute Name
                                      </div>
                                      <div className="testReportCardHead2">
                                        {item.institute_name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="testReportCardInner">
                                    <div className="testReportLeft">
                                      <img
                                        className="img-fluid"
                                        src="images/document-icon-2.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="testReportRight">
                                      <div className="testReportCardHead1">
                                        Doctor Name
                                      </div>
                                      <div className="testReportCardHead2">
                                        {item.doctor_info.doctorName.label}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-4">
                                      <span className="testReportNormal">
                                        {" "}
                                        {item.status.status}
                                      </span>
                                    </div>
                                    {item.status.status[0] !== "WIP" && (
                                      <div className="col-8 text-right">
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={
                                            item.report[0]
                                              ? item.report[0].location
                                              : ""
                                          }
                                          download
                                          class="fileNamedownload"
                                        >
                                          Download
                                          <svg
                                            width="17"
                                            height="20"
                                            viewBox="0 0 17 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.875 18.875H15.375M2.625 9.125L8.625 14.375L14.625 9.125M8.625 1.625V14.375"
                                              stroke="#a54ea5"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>
                                          </svg>
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="row">
                            <div className="col-sm-12 text-center">
                              <img
                                className="img-fluid"
                                src="images/no-report.png"
                                alt=""
                              />
                            </div>
                            <div className="col-sm-12 text-center">
                              <h3 className="noReportHeading">
                                You don't have any reports
                              </h3>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* start show No report */}
                    </div>
                    <div className="tab-pane addTabBg1" id="tab_default_3">
                      {loadingDoctorReports ? (
                        <div className="row text-center">
                          <Spin
                            spinning={loadingDoctorReports}
                            tip="Loading..."
                          />
                        </div>
                      ) : thirdTabData?.result?.length > 0 ? (
                        <>
                          {thirdTabData.result.map((item) => (
                            <>
                              <div className="row">
                                <div class="col-12 col-sm-9">
                                  <div className="row align-items-center">
                                    <div className="col-2 col-sm-1">
                                      <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 40 40"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="40"
                                          height="40"
                                          fill="#F8F9FA"
                                        />
                                        <path
                                          d="M23 12H14V28H26V15H23V12ZM14 10H24L28 14V28C28 28.5304 27.7893 29.0391 27.4142 29.4142C27.0391 29.7893 26.5304 30 26 30H14C13.4696 30 12.9609 29.7893 12.5858 29.4142C12.2107 29.0391 12 28.5304 12 28V12C12 11.4696 12.2107 10.9609 12.5858 10.5858C12.9609 10.2107 13.4696 10 14 10ZM16 19H24V21H16V19ZM16 23H24V25H16V23Z"
                                          fill="#1F274A"
                                          fill-opacity="0.8"
                                        />
                                      </svg>
                                    </div>
                                    <div className="col-10 col-sm-11">
                                      <span className="fileNameHeader">
                                        {item.title}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-3">
                                  <span class="float-right">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      // href={
                                      //   "https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com//" +
                                      //   item.file_path
                                      // }
                                      href={
                                        "https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/doctor-report/" +
                                        item.file_path
                                      }
                                      download
                                      className="fileNamedownload"
                                    >
                                      {" "}
                                      Download
                                      <svg
                                        width="17"
                                        height="20"
                                        viewBox="0 0 17 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1.875 18.875H15.375M2.625 9.125L8.625 14.375L14.625 9.125M8.625 1.625V14.375"
                                          stroke="#a54ea5"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </div>
                                <hr className="borderDivider" />
                              </div>
                            </>
                          ))}
                        </>
                      ) : (
                        <div className="row">
                          <div className="col-sm-12 text-center">
                            <img
                              className="img-fluid"
                              src="images/no-report.png"
                              alt=""
                            />
                          </div>
                          <div className="col-sm-12 text-center">
                            <h3 className="noReportHeading">
                              You don't have any reports
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Report */}
      <div className="col-12 text-center mb-5">
        <Modal
          visible={isFilterModalVisible}
          onOk={handleOk}
          onCancel={closeModal}
          footer={null}
        >
          <div className="row">
            <div className="col-12">
              <Title style={{ fontSize: "20px" }}>Sort by</Title>

              <div className="col-12 text-center mt-3">
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="Low Risk"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "Low Risk" ? "selectedFilter" : ""
                  }`}
                >
                  Low Risk
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="Intermediate Risk"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "Intermediate Risk" ? "selectedFilter" : ""
                  }`}
                >
                  Intermediate Risk
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="High Risk"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "High Risk" ? "selectedFilter" : ""
                  }`}
                >
                  High Risk
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="Normal"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "Normal" ? "selectedFilter" : ""
                  }`}
                >
                  Normal
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="WIP"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "WIP" ? "selectedFilter" : ""
                  }`}
                >
                  Pending
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="Abnormal"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "Abnormal" ? "selectedFilter" : ""
                  }`}
                >
                  Abnormal
                </button>
                <button
                  size="large"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  value="Failed"
                  onClick={searchfiler}
                  className={`tas ${
                    filterValue === "Failed" ? "selectedFilter" : ""
                  }`}
                >
                  Failed
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <MyReportModal
        visible={isModalVisible}
        getReportList={getReportList}
        onClose={() => setIsModalVisible(false)}
      />
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};
export default MyReport;
