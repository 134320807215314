import styled from 'styled-components'
import { Layout, Pagination } from 'antd'



export const PageContainer = styled.div`
    text-decoration: none;
    min-height: calc(100vh - 50px);

    ${({ list }) => list && `
        background: #fff;
        padding: 0;
    `}
`

export const ViewBox = styled.div`
    height: 100%;
    border-bottom: solid 1px #eaeaec;
    padding: 10px 0;
`

export const ViewLabel = styled.p`
    margin: 0;
    font-size: 12px;
    color: #7e818c;
`

export const ViewValue = styled.p`
    margin: 0;
    font-size: 14px;
    color: #000;
`
export const BlankSpace = styled.div`
height: 20px;
`
export const LayoutContainer = styled(Layout)`
    padding: 10px 24px 24px;
    background: #fff;
`

export const Xpagination = styled(Pagination)`
    display: flex;
    justify-content: flex-end;
    margin:1rem 0;
`