import axios from 'axios'
import config from '../config'
import {getPatientToken} from '../utils'

const getUpcomingAppointmentApi = async () => {
    const response = await axios({
        url: `${config.api.base_url}patient/upcoming-appointments`,
        method: "get", 
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    })  
    return response 
}

export default getUpcomingAppointmentApi