import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getListenApi = async (params) => { 
  let url = ""
  if(params.week !== "-1"){
    url = `${config.api.base_url}article/app-list/audio?category=${params.cat}&week=${params.week}`
  }
  else{
    url = `${config.api.base_url}article/app-list/audio?category=${params.cat}`
  }
  const response = await axios({
    url: url,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  }); 
  return response;
};

export default getListenApi;
