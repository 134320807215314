export const GET_BOOKMARK_ARTICLES = "GET_BOOKMARK_ARTICLES"
export const GET_BOOKMARK_ARTICLES_FAIL = "GET_BOOKMARK_ARTICLES_FAIL"
export const GET_BOOKMARK_ARTICLES_SUCCESS = "GET_BOOKMARK_ARTICLES_SUCCESS"
export const GET_BOOKMARK_ARTICLES_RESET = "GET_BOOKMARK_ARTICLES_RESET"

export const getBookmarkArticles = (params) => {
    return { type: GET_BOOKMARK_ARTICLES, params }
}
export const getBookmarkArticlesFail = (response) => {
    return { type: GET_BOOKMARK_ARTICLES_FAIL, response }
}
export const getBookmarkArticlesSuccess = (response) => { 
    return { type: GET_BOOKMARK_ARTICLES_SUCCESS, response }
}
export const getBookmarkArticlesReset = () => {
    return { type: GET_BOOKMARK_ARTICLES_RESET }
}
