//import {React, useState} from 'react'
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Radio, Space, Row, Col, Slider } from "antd";
import {
  Xh1,
  XModal,
  FormItem,
  XDatePicker,
  DatePicker,
  XButton,
  ImgDiv,
} from "./ToolModalStyle";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastBody } from "react-bootstrap";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";

export const ToolModal = (props) => {
  /*
    const dateFormat = 'DD-MM-YYYY';
    const customFormat = value => `${value.format(dateFormat)}`;
    */
  const [day, setDay] = useState("28");
  const [dateState, setDateState] = useState(new Date());
  const [fertileBegin, setFertileBegin] = useState("");
  const [fertileEnds, setFertileEnds] = useState("");
  //const [fertileBeginPrint, setFertileBeginPrint] = useState("")
  const [toggle, setToggle] = useState(false);
  const [dateRangeSelect, setDateRangeSelect] = useState();
  const [ovulationCalcVisiable, setOvulationCalcVisiable] = useState(false);
  //const [date, setDate] = useState(new Date());

  function closeModal() {
    props.onClose();
    setToggle(false);
    setOvulationCalcVisiable(false);
    setDateState("");
  }

  const ovulationCalc = (e) => {
    setOvulationCalcVisiable(true);
  };

  const changeDate = (e) => {
    setDateState(e);
    //console.log(moment(dateState).format('DD-MM-YYYY'))
    //setDate(e);
    //console.log(e);
    //console.log(e);
    //handleOk()
  };

  //console.log(moment(dateState).format('DD-MM-YYYY'))
  const handlerSelect = (e) => {
    var slectedDays = e.target.value;
    setDay(slectedDays);
  };

  const [hilighteDate, setHilighteDate] = useState([
    {
      //startDate: moment(new Date()).format('YYYY, MM, DD'),
      startDate: new Date(2022, 2, 15),
      endDate: new Date(2022, 2, 26),
    },
  ]);

  //console.log(state.startDate)

  //return (compare(fertileBegin, fertileEnds, date))
  /*
    function compare(startDate, endDate, date) {
        var startDate = moment(startDate).format("DD/MM/YYYY");
        var endDate = moment(endDate).format("DD/MM/YYYY");
        var date = moment(date).format("DD/MM/YYYY");

        
        console.log(startDate)
        console.log(endDate)
        console.log(date)
       
    if(moment(startDate).isBefore(date) && moment(endDate).isBefore(date)){
           console.log("1..")
            return "testColor2"
       }else if(moment(startDate).isSame(date)){
        console.log("2..")
        return "testColor2"
       }else{
        console.log("3..")
           return ""
       }
    }
*/
  const handleOk = () => {
    //console.log(toggle, fertileBegin, fertileEnds)
    //alert(toggle)
    //if(fertileEnds === "" && fertileBegin === "")
    if (true) {
      //console.log("In Loop..")
      var fEnds = moment(dateState, "YYYYMMDD")
        .add(Math.floor(day/2) + 4, "days")
        .format("MMMM DD YYYY");

      setFertileEnds(fEnds);
      var fBegin = moment(dateState, "MMMM DD YYYY")
        .add(day % 2 === 0 ? Math.floor(day / 2) - 3 : Math.floor(day / 2) - 2, "days")
        .format("MMMM DD YYYY");
      setFertileBegin(fBegin);
      setToggle(true);
      //setDateRangeSelect(disabled)
      let temp = [...hilighteDate];
      temp[0].startDate = new Date(fBegin);
      temp[0].endDate = new Date(fEnds);
      setHilighteDate(temp);
      console.log(temp);
    } else {
      setToggle(true);
    }
  };
  /*
    function onExperienceDateChange(fertileBegin, event) {
        console.log('Date Selected: ' + fertileBegin);
    }
    */

  function ReCalculate() {
    setToggle(false);
  }

  // console.log(ovulationCalcVisiable)
  //console.log(toggle)
  /*
    useEffect(() => {        
        setToggle(true)
    }, [fertileBegin])
*/
  return (
    <XModal
      visible={props.visible}
      onOk={handleOk}
      onCancel={closeModal}
      footer={null}
      style={{ borderRadius: "22px!important" }}
    >
      {ovulationCalcVisiable ? (
        <div>
          <Xh1 className="mb-2">Ovulation Calculator</Xh1>
          <div className="row">
            <div className="col-12 mb-2">
              <lable className="ToolBoxLabel">
                Select the first day of your period cycle
              </lable>
            </div>
            <div className="col-12">
              {toggle ? (
                <div>
                  <DateRange className="calCalendar" ranges={hilighteDate} />
                  {console.log(fertileBegin, fertileEnds)}
                </div>
              ) : (
                <div>
                  <Calendar
                    value={dateState}
                    onChange={changeDate}
                    className="calCalendar"
                    /*
                        tileClassName={({ date, view }) => {
                            //{console.log(fertileBegin, fertileEnds)}
                           return (compare(fertileBegin, fertileEnds, date))
                        }} 
                        */
                  />
                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              <lable className="ToolBoxLabel">
                Average length of your menstrual cycle
              </lable>
            </div>
            {toggle ? (
              <div className="col-12 mt-1">
                <select disabled class="form-select customDropDown">
                  <option value={day}>{day} Days</option>
                </select>
              </div>
            ) : (
              <div className="col-12 mt-1">
                <select
                  class="form-select customDropDown"
                  onChange={handlerSelect}
                >
                  <option value="20">20 Days</option>
                  <option value="21">21 Days</option>
                  <option value="22">22 Days</option>
                  <option value="23">23 Days</option>
                  <option value="24">24 Days</option>
                  <option value="25">25 Days</option>
                  <option value="26">26 Days</option>
                  <option value="27">27 Days</option>
                  <option value="28" selected>
                    28 Days
                  </option>
                  <option value="29">29 Days</option>
                  <option value="30">30 Days</option>
                  <option value="31">31 Days</option>
                  <option value="32">32 Days</option>
                  <option value="33">33 Days</option>
                  <option value="34">34 Days</option>
                  <option value="35">35 Days</option>
                  <option value="36">36 Days</option>
                  <option value="37">37 Days</option>
                  <option value="38">38 Days</option>
                  <option value="39">39 Days</option>
                  <option value="40">40 Days</option>
                </select>
              </div>
            )}
          </div>
          {toggle ? (
            <div className="col-12 mt-3">
              <p style={{ color: "#A64FA6" }}>
                <strong>If your period starts:</strong>
                <br />
                {moment(dateState).format("MMMM DD, YYYY")}
              </p>

              <p style={{ color: "#A64FA6" }}>
                <strong>If your menstrual cycle is:</strong>
                <br />
                {day} days
              </p>

              <p style={{ color: "#A64FA6" }}>
                <strong>Your estimated fertile window is:</strong>
                <br />
                {fertileBegin} To {fertileEnds}
              </p>

              <p>
                You are most likely to get pregnant if you have sex without
                birth control a few days before your ovulation date.
              </p>
            </div>
          ) : (
            <div></div>
          )}

          <div className="row">
            <div className="col-12 mt-4 text-center">
              {toggle ? (
                <button
                  className="themeBtn"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  onClick={ReCalculate}
                >
                  Recalculate
                </button>
              ) : (
                <button
                  className="themeBtn"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  onClick={handleOk}
                  disabled={!dateState}
                >
                  Calculate
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 text-center p-sm-5 pt-5 pb-5">
            <button onClick={ovulationCalc} className="themeBtn themeBtnOvulation">
              Ovulation Calculator
            </button>
          </div>
        </div>
      )}
    </XModal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ToolModal);
