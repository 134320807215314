import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_READ,
    getReadFail,
    getReadSuccess
} from '../action/getReadAction'

// api 
import getReadApi from '../api/getReadApi'

export function* getReadSaga(action){

    try{
        const response = yield call(()=> getReadApi(action.params))
        if(response.data.status ===1){
            yield put(getReadSuccess(response, action))
        }else
        {
            yield put(getReadFail(response, action))
        }
    }
    catch(e){
        yield put(getReadFail(e.response, action))
    }
}

export default function* mySaga(){
    yield takeLatest(GET_READ, getReadSaga)
}