import * as React from "react"
const CheckSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M32 0C14.355 0 0 14.354 0 32c0 17.645 14.354 32 32 32 17.645 0 32-14.355 32-32S49.645 0 32 0Z"
      />
      <path
        fill="#FAFAFF"
        d="M48.219 25.219 30.885 42.552a2.66 2.66 0 0 1-1.885.782 2.66 2.66 0 0 1-1.885-.782l-8.667-8.667a2.663 2.663 0 0 1 0-3.77 2.663 2.663 0 0 1 3.77 0L29 36.896l15.448-15.448a2.663 2.663 0 0 1 3.77 0 2.664 2.664 0 0 1 0 3.77Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={0}
        x2={68.776}
        y1={0}
        y2={5.628}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D487D4" />
        <stop offset={1} stopColor="#A54EA5" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default CheckSvg;
