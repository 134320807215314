import styled from "styled-components";
import { Link } from 'react-router-dom';


export const Title = styled.h1`
    font-size:2rem;
    font-weight: 700;
    color: #1F274A;
    font-family: Poppins;
    @media only screen and (max-width: 800px) {
       font-size: 20px;
    }
`
export const AidoPlayerWrap = styled.div`
    position:relative;
    z-index:1;
    width:90%;
    left:5%; 
    margin-bottom : 10px;
    color:#FFF !important;
`
export const Title2 = styled.h1`
    color: #A3A9BF; 
    font-size:1.6rem; 
    font-family: Poppins;
`
export const Title3 = styled.h1`
    color: #121111; 
    font-size:1.6rem;
    line-height:2rem; 
    font-family: Poppins;
    font-weight:700;
`
export const HeadRate = styled.div`
    position:relative;
    color: #A3A9BF; 
    font-family: Poppins;
`
export const Para = styled.div`
    position:relative;
    color: #1F274A; 
    font-size:1rem;
    font-weight:700;
    font-family: Poppins;
`
export const BoxWrap = styled.div`
    position:relative;
    background:#FFF;
    color: #A3A9BF; 
    font-size:1.1rem;
    line-height:1.2rem;
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius:12px;
    padding-bottom:1rem;
    font-family: Poppins;
    font-weight:700;
    span{
        font-size:0.8rem;
        padding-left:15px;
        font-weight:normal;
    }
`

export const Ximg = styled.img`
    border-radius: 15px;
    width: 454px;
    height: 350px;
`