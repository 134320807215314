import React, { useEffect, useState } from "react";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { AudioPlayerWrap } from "./AudioArticleStyle";

import { Col, Row } from "antd";
import config from "../../config";

const AudioArticle = (props) => {
  console.log("Audio Article", props);
  return (
    <AudioPlayerWrap className="audioPlayerWrap">
      <AudioPlayer
        className="audioPlayer"
        style={{
          borderRadius: "10px",
          // boxShadow:"0px 8px 18px rgba(202, 212, 226, 0.3)"
        }}
        autoPlay={false}
        src={
          `${config.api.s3_url}article/` +
          props.ArticleData.audio_path
        }
      />
    </AudioPlayerWrap>
  );
};
export default AudioArticle;
