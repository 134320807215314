/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";

//COMPONENTS
import {
  getTribeArticleList,
  getTribeArticleListReset,
} from "../../action/getTribeArticleListAction";
import { TopContainer } from "../DoctorAppoint/DoctorAppointStyle";
import { CuratedTitle } from "./SupportTribeCompileStyle";
import config from "../../config";

const SupportTribeCompile = (props) => {
  //CONSTANTS
  const {
    getTribeArticleList,
    getTribeArticleListReset,
    getTribeArticleState,
  } = props;
  const { Title } = Typography;
  const location = useLocation();
  const [SupportTribeArticles, setSupportTribeArticles] = useState([]);
  const type = location.state.type;

  useEffect(() => {
    window.scrollTo(0, 0);
    let tribe = JSON.parse(localStorage.getItem("tribe"));
    getTribeArticleList({
      tribes: Object.keys(tribe)
        .map((k) => {
          return tribe[k];
        })
        .join(","),
      type,
    });
    return () => {
      getTribeArticleListReset();
    };
  }, []);

  useEffect(() => {
    if (getTribeArticleState) {
      if (
        type === "text" &&
        getTribeArticleState.apiState === "success" &&
        getTribeArticleState.data.text.length > 0
      ) {
        setSupportTribeArticles(getTribeArticleState.data.text);
      } else if (
        type === "audio" &&
        getTribeArticleState.apiState === "success" &&
        getTribeArticleState.data.audio.length > 0
      ) {
        setSupportTribeArticles(getTribeArticleState.data.audio);
      } else if (
        type === "video" &&
        getTribeArticleState.apiState === "success" &&
        getTribeArticleState.data.video.length > 0
      ) {
        setSupportTribeArticles(getTribeArticleState.data.video);
      }
    }
  }, [getTribeArticleState]);

  return (
    <>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <div className="container">
        <img
          alt="right-bg"
          className="doctorRightBg1"
          src="./images/svg/doctorbgRight.svg"
        />
        <img
          alt="right-bg"
          className="doctorLeftBg"
          src="./images/svg/doctorbgLeft.svg"
        />
        <div className="pageWrap">
          <CuratedTitle>
            {type === "text"
              ? "Read Articles"
              : type === "video"
              ? "Video Articles"
              : "Audio Articles"}
          </CuratedTitle>
          {SupportTribeArticles.length && (
            <div className="row">
              <div className="offset-sm-1 col-sm-10 col-12">
                <div className="row">
                  {SupportTribeArticles.map((ReadData) => {
                    return (
                      <div className="col-sm-6 col-12 mb-3">
                        <div className="drReadBox">
                          <Link
                            to={{
                              pathname: "/article/" + ReadData.slug,
                              state: { is_bookmarked: ReadData.is_bookmarked },
                            }}
                            style={{ textDecoration: "none", color: "#1F274A" }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-3">
                                <img
                                  className="img-fluid drReadImgRound"
                                  src={`${config.api.s3_url}article/${ReadData.image_path}`}
                                  alt="Card image cap"
                                />
                              </div>
                              <div className="col-sm-12 col-9">
                                <div className="row">
                                  <div className="col-sm-12 col-12 mb-2">
                                    <h4 className="drReadHeading">
                                      {ReadData.title}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12 col-12">
                                    <div className="drReadComment">
                                      <img
                                        className="img-fluid"
                                        src="./images/eye.svg"
                                        alt="no"
                                      ></img>{" "}
                                      {ReadData.views} Views &nbsp; &nbsp;
                                      &nbsp;
                                      <img
                                        className="img-fluid"
                                        src="./images/heart.svg"
                                        alt="no"
                                      ></img>{" "}
                                      {ReadData.likes} likes
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getTribeArticleState: state.getTribeArticleList,
});

const mapDispatchToProps = (dispatch) => ({
  getTribeArticleList: (params) => dispatch(getTribeArticleList(params)),
  getTribeArticleListReset: () => dispatch(getTribeArticleListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportTribeCompile);
