import { Card } from "antd";
import styled, { css } from "styled-components";

export const BlockReverse = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
export const ListSupportImg = styled.img`
  border-radius: 20px !important;
  min-height: 330px;
  max-height: 330px;
  width: 100%;
  @media (max-width: 768px) {
    min-height: 270px;
    max-height: 270px;
  }
  @media (max-width: 500px) {
    min-height: 100px;
    max-height: 100px;
    width: 100px;
  }
`;
// export const ListSupportImg = styled.img`
//   border-radius: 20px !important;
//   min-height: 330px;
//   max-height: 330px;
//   width: 100%;
//   @media (max-width: 768px) {
//     min-height: 270px;
//     max-height: 270px;
//   }
//   @media (max-width: 500px) {
//   ${(props) => {
//     switch (props.$mode) {
//       case 0:
//         return css`
//           min-height: 200px;
//           max-height: 200px;
//         `;
//       default:
//         return css`
//           min-height: 100px;
//           max-height: 100px;
//           width: 100px;
//         `;
//     }
//   }}
// }
// `;
export const BlockTitle = styled.div`
  font-family: Poppins;
  h5 {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 500px) {
    h5 {
      font-size: 12px;
    }
  }
`;
export const BlockCard = styled(Card)`
  @media (min-width: 501px) {
    box-sizing: border-box;
    padding: 12px;
    background: #fff;
    border-radius: 16px;
    .ant-card-body {
      padding: 0;
      padding-left: 0;
    }
  }
  @media (max-width: 500px) {
    border-radius: 20px;
    .ant-card-body {
      padding: 4%;
      padding-left: 4%;
      font-size: 12px;
    }
  }
`;
export const BlockIndex = styled.div`
  @media (max-width: 500px) {
    display: flex;
    gap: 8px;
  }
`;
// export const BlockIndex = styled.div`
//   @media (max-width: 500px) {
//     ${(props) => {
//       switch (props.$mode) {
//         case 0:
//           return css``;
//         default:
//           return css`
//             display: flex;
//             gap: 8px;
//           `;
//       }
//     }}
//   }
// `;
export const BlockFooter = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: space-between;
  font-size: 16px;
  color: #a3a9bf;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

export const FooterIconView = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-content: flex-start;
  justify-content: flex-start;
`;
export const ViewLikes = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 500px) {
    gap: 8px;
  }
`;

export const BlockFooterImg = styled.img`
  @media (max-width: 500px) {
    width: 18px;
  }
`;
export const TribeImg = styled.img`
  height: 40px;
  width: 40px;
  @media (min-width: 425px) and (max-width: 500px) {
    width: 26px;
  }
  @media (min-width: 375px) and (max-width: 424px) {
    width: 18px;
  }
  @media(max-width: 374px){
    width: 14px;
  }
`;
