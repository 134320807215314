export const UPDATE_PAYMENT = "UPDATE_PAYMENT"
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_RESET = "UPDATE_PAYMENT_RESET"

export const updatePayment = (params) => {
    return { type: UPDATE_PAYMENT, params }
}
export const updatePaymentFail = (response) => {
    return { type: UPDATE_PAYMENT_FAIL, response }
}
export const updatePaymentSuccess = (response) => {
    return { type: UPDATE_PAYMENT_SUCCESS, response }
}
export const updatePaymentReset = () => {
    return { type: UPDATE_PAYMENT_RESET }
}