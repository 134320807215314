import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_APPOINTMENT_HISTORY,
    getAppointmentHistoryFail,
    getAppointmentHistorySuccess
} from '../action/getAppointmentHistoryAction'

// api 
import getAppoinmentHistoryApi from '../api/getAppoinmentHistoryApi'

export function* getAppoinmentHistorySaga(action) {
    try {
        const response = yield call(() => getAppoinmentHistoryApi(action.params))
        if (response.data.status === 1) {
            yield put(getAppointmentHistorySuccess(response, action))
        } else {
            yield put(getAppointmentHistoryFail(response, action))
        }
    } catch (e) {
        yield put(getAppointmentHistoryFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_APPOINTMENT_HISTORY, getAppoinmentHistorySaga)
}