import { call, put, takeLatest } from "redux-saga/effects";

// action
import {
  GET_SCHEDULE_TIMING_LIST,
  getScheduleTimingListFail,
  getScheduleTimingListSuccess,
} from "../action/getScheduleTimeListAction";

// api
import getScheduleTimingListApi from "../api/getScheduleTimingApi";

export function* getScheduleTimingListSaga(action) {
  try {
    const response = yield call(() => getScheduleTimingListApi(action.params));
    if (response.data.status === 1) {
      yield put(getScheduleTimingListSuccess(response, action));
    } else {
      yield put(getScheduleTimingListFail(response, action));
    }
  } catch (e) {
    yield put(getScheduleTimingListFail(e.response, action));
  }
}

export default function* mySaga() {
  yield takeLatest(GET_SCHEDULE_TIMING_LIST, getScheduleTimingListSaga);
}
