export const SAVE_ARTICLE_LIKE = "SAVE_ARTICLE_LIKE"
export const SAVE_ARTICLE_LIKE_FAIL = "SAVE_ARTICLE_LIKE_FAIL"
export const SAVE_ARTICLE_LIKE_SUCCESS = "SAVE_ARTICLE_LIKE_SUCCESS" 
export const SAVE_ARTICLE_LIKE_RESET = "SAVE_ARTICLE_LIKE_RESET"

export const saveArticleLike = (params) => {
    return { type: SAVE_ARTICLE_LIKE, params }
}
export const saveArticleLikeFail = (response) => {
    return { type: SAVE_ARTICLE_LIKE_FAIL, response }
}
export const saveArticleLikeSuccess = (response) => {
    return { type: SAVE_ARTICLE_LIKE_SUCCESS, response }
}
export const saveArticleLikeReset = (response) => {
    return { type: SAVE_ARTICLE_LIKE_RESET, response }
} 