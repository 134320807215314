import { UpCircleFilled } from "@ant-design/icons";
const ScrollToTop = () => {
  return (
    <>
      <UpCircleFilled
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="scroll-top-btn"
      />
    </>
  );
};
export default ScrollToTop;
