import React from "react";
import { Link } from "react-router-dom";
import { YourHelthBg } from "../../page/Home/HomeStyle";
import { XImg, XDiv } from "./ResourceStyle";

function Resource(props) {

  return (
    <>
      <YourHelthBg>
        <div className="container">
          <h1 className="heading-home1 text-center top-space">Resource</h1>
          <XDiv className="row">
            <div className="col-4 col-sm-4 text-center">
              <div className="homeLinkBg ">
                <Link
                  to={{
                    pathname: "/ArticleList",
                    state: { type: "text", category:"At Ease", week: "" },
                  }}
                >
                  <img
                    className="resourceImg"
                    alt="right-bg"
                    src="./images/svg/atEasyIcon-1.svg"
                  />
                  <br />
                  <br />
                </Link>
              </div>
            </div>
            <div className="col-4 col-sm-4 text-center">
              <div className="homeLinkBg">
                <Link
                  to={{
                    pathname: "/ArticleList",
                    state: {
                      type: "video",
                      category: "At Ease",
                      week: "",
                    },
                  }}
                >
                  <img
                    className="resourceImg"
                    alt="right-bg"
                    src="./images/svg/atEasyIcon-2.svg"
                  />
                  <br />
                  <br />
                </Link>
              </div>
            </div>
            <div className="col-4 col-sm-4 text-center">
              <div className="homeLinkBg">
                <Link
                  to={{
                    pathname: "/ArticleList",
                    state: {
                      type: "audio",
                      category: "At Ease",
                      week: "",
                    },
                  }}
                >
                  <img
                    className="resourceImg"
                    alt="right-bg"
                    src="./images/svg/atEasyIcon-3.svg"
                  />
                  <br />
                  <br />
                </Link>
              </div>
            </div>
          </XDiv>
        </div>
      </YourHelthBg>
    </>
  );
}
export default Resource;
