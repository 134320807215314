import axios from 'axios'
import config from '../config'

const savePatientIntakeFormApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}patient-intake`,
        method: "post",
        data: params,
        headers: {
            "Content-Type": "application/json"
        }
    })

    return response

}

export default savePatientIntakeFormApi