//action
import {
    GET_PATIENT_DETAIL_FOR_INTAKE_FORM,
    GET_PATIENT_DETAIL_FOR_INTAKE_FORM_FAIL,
    GET_PATIENT_DETAIL_FOR_INTAKE_FORM_SUCCESS,
    GET_PATIENT_DETAIL_FOR_INTAKE_FORM_RESET
} from "../action/getPatientDetailForIntakeFormAction"
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: "",
    expired_status: false,
    already_exist: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PATIENT_DETAIL_FOR_INTAKE_FORM:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_PATIENT_DETAIL_FOR_INTAKE_FORM_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_PATIENT_DETAIL_FOR_INTAKE_FORM_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data.result,
                expired_status: action.response.data.data.expired_status,
                already_exist: action.response.data.data.already_exist,
            }
        case GET_PATIENT_DETAIL_FOR_INTAKE_FORM_RESET:
            return initialState
        default:
            return state
    }
}