import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getDoctorListApi = async (params) => { 
  const response = await axios({
    url: `${config.api.base_url}doctor/list?type=${params.type}&page=${params.page}&records=${params.records}`,
    method: "get",  
    params: params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });   
  return response;
};

export default getDoctorListApi;
