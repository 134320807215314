import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getUpcomingConsultation,
  getUpcomingConsultationReset,
} from "../../action/getUpcominConsultationAction";
import {
  getConsultationHistory,
  getConsultationHistoryReset,
} from "../../action/getConsultationHistoryAction";
import { timeSlotFormat, DaySlotFormat, weekDays } from "../../utils";
import { DoctorDetailBlock } from "../../component/DoctorDetailBlock/DoctorDetailBlock";
import {
  getDoctorDetail,
  getDoctorDetailReset,
} from "../../action/getDoctorDetailAction";
import moment from "moment";
import { Spin } from "antd";
import {
  BookAppointmentTitle,
  XEmpty,
} from "../../component/DoctorDetailBlock/DoctorDetailBlockStyle";

export const Bookings = (props) => {
  //constants
  const {
    getUpcomingConsultation,
    getUpcomingConsultationReset,
    getUpcomingConsultationState,
    getConsultationHistory,
    getConsultationHistoryReset,
    getConsultationHistoryState,
    getDoctorDetail,
    getDoctorDetailReset,
    getDoctorDetailState,
  } = props;
  const location = useLocation();
  const doctor = location.state.doctor;
  const isHistory = location.state.isHistory;
  const [doctorData, setDoctorData] = useState({
    id: "",
    speciality: "",
    fname: "",
    lname: "",
    gender: "",
    qualification: "",
    profile_picture: "",
    description: "",
    tier_1_fee: "",
    short_description: "",
    tier_2_fee: "",
    exprience: "",
    salutation: "",
  });
  const [historyList, setHistoryList] = useState([]);
  const [futureList, setFutureList] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingFuture, setLoadingFuture] = useState(false);
  const Params = {
    doctor: doctor,
  };

  function getDay(params) {
    let num = moment(params).isoWeekday();
    console.log(num);
    if (num === 1) {
      return "Monday";
    }
    if (num === 2) {
      return "Tuesday";
    }
    if (num === 3) {
      return "Wednesday";
    }
    if (num === 4) {
      return "Thursday";
    }
    if (num === 5) {
      return "Friday";
    }
    if (num === 6) {
      return "Saturday";
    }
    if (num === 7) {
      return "Sunday";
    }
  }
  // console.log("length", historyList.length, futureList.length);
  function History() {
    return (
      <>
        {loadingHistory ? (
          <Spin
            spinning={loadingHistory}
            tip="Loading Consultation History..."
          />
        ) : historyList.length === 0 ? (
          <XEmpty description="You don't have consultation history" />
        ) : (
          historyList.map((doctor) => (
            <div className="col-md-4 col-12">
              <div className="apptListBox">
                <h5 className="apptListHeader">
                  {new Date(doctor.date).getUTCDate() +
                    " " +
                    new Date(doctor.date).toLocaleString("default", {
                      month: "short",
                    }) +
                    " " +
                    new Date(doctor.date).getFullYear()}
                  &nbsp;
                  <span className="float-md-right">
                    {timeSlotFormat(doctor.time_slot_start)}
                  </span>
                </h5>
                <h6 style={{ color: "grey", fontFamily: "Poppins" }}>
                  {weekDays()[new Date(doctor.date).getUTCDay()]}
                </h6>
              </div>
            </div>
          ))
        )}
      </>
    );
  }

  function Future() {
    return (
      <>
        {loadingFuture ? (
          <Spin
            spinning={loadingFuture}
            tip="Loading Upcoming Consultations..."
          />
        ) : futureList.length === 0 ? (
          <XEmpty description="Please book an appointment" />
        ) : (
          futureList.map((doctor) => (
            <div className="col-md-4 col-12">
              <div className="apptListBox">
                <h5 className="apptListHeader">
                  {new Date(doctor.date).getUTCDate() +
                    " " +
                    new Date(doctor.date).toLocaleString("default", {
                      month: "short",
                    }) +
                    " " +
                    new Date(doctor.date).getFullYear()}
                  &nbsp;
                  <span className="float-md-right">
                    {timeSlotFormat(doctor.time_slot_start)}
                  </span>
                </h5>
                <h6 style={{ color: "grey", fontFamily: "Poppins" }}>
                  {weekDays()[new Date(doctor.date).getUTCDay()]}
                </h6>
              </div>
            </div>
          ))
        )}
      </>
    );
  }

  //Callbacks
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isHistory) getUpcomingConsultation(Params);
    if (isHistory) getConsultationHistory(Params);
    getDoctorDetail(Params);
    return () => {
      getUpcomingConsultationReset();
      getConsultationHistoryReset();
      getDoctorDetailReset();
    };
  }, []);

  useEffect(() => {
    if (getUpcomingConsultationState.apiState === "loading") {
      setLoadingFuture(true);
    }
    if (getUpcomingConsultationState.apiState === "success") {
      setFutureList(getUpcomingConsultationState.data.data);
      setLoadingFuture(false);
    }
  }, [
    getUpcomingConsultationState.apiState,
    getUpcomingConsultationState.data.data,
  ]);

  useEffect(() => {
    if (getConsultationHistoryState.apiState === "loading") {
      setLoadingHistory(true);
    } else if (getConsultationHistoryState.apiState === "success") {
      setHistoryList(getConsultationHistoryState.data.data);
      setLoadingHistory(false);
    }
  }, [
    getConsultationHistoryState.apiState,
    getConsultationHistoryState.data.data,
  ]);

  useEffect(() => {
    if (getDoctorDetailState.apiState === "success") {
      setDoctorData({
        ["id"]: getDoctorDetailState.data.data.data._id,
        ["speciality"]: getDoctorDetailState.data.data.data.speciality,
        ["fname"]: getDoctorDetailState.data.data.data.fname,
        ["lname"]: getDoctorDetailState.data.data.data.lname,
        ["gender"]: getDoctorDetailState.data.data.data.gender,
        ["qualification"]: getDoctorDetailState.data.data.data.qualification,
        ["profile_picture"]:
          getDoctorDetailState.data.data.data.profile_picture,
        ["description"]: getDoctorDetailState.data.data.data.description,
        ["tier_1_fee"]: getDoctorDetailState.data.data.data.tier_1_fee,
        ["tier_2_fee"]: getDoctorDetailState.data.data.data.tier_2_fee,
        ["short_description"]:
          getDoctorDetailState.data.data.data.short_description,
        ["exprience"]: getDoctorDetailState.data.data.data.exprience,
        ["salutation"]: getDoctorDetailState.data.data.data.salutation.value,
      });
    }
  }, [getDoctorDetailState]);

  return (
    <div className="container">
      <div className="pageWrap3 pb-0">
        <div className="row">
          <h1 className="dtDetailsHead">
            {isHistory ? "Consultation History" : "Upcoming Consultations"}
          </h1>
          <DoctorDetailBlock doctorData={doctorData} isExperience={true} />
        </div>
      </div>
      <div className="pageWrap pt-5">
        <div className="row">
          {/* <div className='col-sm-12 col-12'> */}
          {!isHistory ? <Future /> : ""}
          {isHistory ? <History /> : ""}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getUpcomingConsultationState: state.getUpcomingConsultation,
  getConsultationHistoryState: state.getConsultationHistory,
  getDoctorDetailState: state.getDoctorDetail,
});

const mapDispatchToProps = (dispatch) => ({
  getUpcomingConsultation: (params) =>
    dispatch(getUpcomingConsultation(params)),
  getUpcomingConsultationReset: () => dispatch(getUpcomingConsultationReset()),
  getConsultationHistory: (params) => dispatch(getConsultationHistory(params)),
  getConsultationHistoryReset: () => dispatch(getConsultationHistoryReset()),
  getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
  getDoctorDetailReset: () => dispatch(getDoctorDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
