import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getWatchApi = async (params) => { 
  let url = ""
  console.log(params.week)
  if(params.week !== "-1"){
    
    url = `${config.api.base_url}article/app-list/video?category=${params.cat}&week=${params.week}`
  }
  else{ 
    url = `${config.api.base_url}article/app-list/video?category=${params.cat}`
  }
  console.log(params, url)
  const response = await axios({
    url: url,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  }); 
  return response;
};

export default getWatchApi;
