import config from '../config'
import axios from 'axios'

export const loginApi = async (params) => {
    let url = `${config.api.base_url}patient/auth/sign-in` 
    let data = {}
    if(params.socialLogin){
        url = url + "?type="+params.socialType
        data["provider"] = params.provider
        data["id"] = params.id
        data["email"] = params.email
    }else{
    // alert("normal")

        data = {
            "username": params.username,
            "password": params.password,
        }
    }
    const response = await axios({
        method: 'post',
        url,
        data,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        }
    })

    return response
}

export default loginApi