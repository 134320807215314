import { call, put, takeLatest } from 'redux-saga/effects'

import {
    VALIDATE_OTP,
    validateOtpSuccess,
    validateOtpFail
} from '../action/validateOtpAction'

import validateOtpApi from '../api/validateOtpApi'

export function* validateOtpSaga(action) {
    try {
        const response = yield call(() => validateOtpApi(action.params))
        if (response) {
            yield put(validateOtpSuccess(response, action))
        }
    }
    catch (e) {
        yield put(validateOtpFail(e.response, action))
    }
}

export default function* MySaga() {
    yield takeLatest(VALIDATE_OTP, validateOtpSaga);
}