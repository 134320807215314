import styled from "styled-components";
import { Button } from "antd";
import Carousel from 'react-bootstrap/Carousel';

export const MoodDiv = styled.div`
    // @media (max-width: 768px) { 
        margin-top: 50px !important;
    // }
`
export const Container = styled.div`
position: absolute;
width: 27%;
height: 30%;
left: 5%;
top: 30%; 
font-family: Poppins;
font-style: bold;
font-weight: bold;
font-size: 40px;
line-height: 42px;
overflow: hidden;
color: #000000;   
@media (min-width: 700px) and  (max-width: 1080px){
    width: 30%;
    height: 30%;
    top: 20%; 
    font-size: 30px;
    line-height: 35px; 
    margin-left: 5%;
}
@media (min-width: 550px) and  (max-width: 800px){
    width: 30%;
    height: 30%;
    top: 18%; 
    font-size: 20px;
    line-height: 25px; 
    margin-left: 5%;
}
@media screen and (min-width: 300px) and  (max-width: 580px){
    width: 30%;
    height: 17%;
    font-size: 10px;
    line-height: 20px; 
    top: 10%;
    margin-left: 5%;
}
` 

export const XCButton = styled(Button)`
	position: absolute; 
    background: linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%);
	box-shadow: 1px 6px 22px #EDE8ED;
    margin-top: 95%;
    @media (min-width: 800px) and (max-width: 1024px){
        width : 80px;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        width : 60px; 
        height: 15%;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        width : 50px;
        margin-top: 70%;
        height: 20%;
    } 
`

export const XButton = styled(Button)`
	position: absolute; 
	color: #FFFFFF; 
	margin-top: 195%;
	margin-left: 20%; 
    @media (min-width: 800px) and (max-width: 1024px){
        margin-left : 8%;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-left : 4%; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        margin-left : 2%;
        padding-bottom: 15%;
    }
`

export const Ximg = styled.img`
	width: 100%;
`

export const Box1 = styled.div`
    position: absolute;
    width:40%;
    height:40%;
    top: 65.75%;
    bottom: 50.25%;
    left: 5%;
    z-index: 1;
    @media (min-width: 800px) and (max-width: 1204px){
        width:30%;
        height:20%; 
        top: 63%;
        bottom: 50.25%;
        left:5%; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        width: 25%;
        height:20%; 
        top: 30%;
        bottom: 50.25%;
        left:10%;  
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        width: 20%;
        height:20%; 
        top: 20%;
        bottom: 50.25%;
        left:10%;  
    }
`;

export const Box2 = styled.div`
    position: absolute;
    width:40%;
    height:40%;
    top: 90%;
    bottom: 50.25%;
    left: 5%;
    z-index: 1;
    .img{
        height:90%;
    }
    @media (min-width: 800px) and (max-width: 1024px){
        width:30%;
        height:30%; 
        top: 77%;
        bottom: 50.25%;
        left:5%;  
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        top: 37%;
        bottom: 50.25%;
        left:10%;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        top: 27%;
        bottom: 50.25%;
        left:10%;
    }
`;

export const Box3 = styled.div`
    position: absolute;
    width:40%;
    top:30%;
    bottom: 50.25%;
    padding-Left:25%
    z-index: 0;
    margin-left: 18.5%;
    
`;

export const Box4 = styled.div`
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0px;
    top: 711px;
    line-height: 42px;

`
export const Title = styled.h4`
    font-size:48px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    color:white;
    padding-left:10%;
    padding-top:5%;
`;

export const Title2 = styled.p`
    font-size:40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    color:white;
    padding-left:10%;
`
export const Backcard = styled.div`
    margin-top: 11%;
    margin-left: 47%;

`
export const Frontcard = styled.div`
    margin-top: 11%;
    margin-left: 57%;
    flex-direction: column;
    z-index:0;
    position: absolute;
`
export const XFCdiv = styled.div`
    margin-top: 3%; 
    width: 100%;
    line-height: 70px;
`
export const Xmomdiv = styled.div`
    margin-left: 3%;  
`

export const BackcardTitle = styled.div`

color : white;
font-size : 32px ;
margin-left : 5%;
margin-top: 7%;
font-family : Poppins;
font-weight : bold;
line-height : 40px;
font-style : normal
`

export const FrontcardTitle = styled.div`

color : black;
font-size : 32px ;
margin-left : 5%;
margin-top: 7%;
font-family : Poppins;
font-weight : bold;
line-height : 40px;
font-style : normal
`

export const Cardcontent = styled.span`

margin-left : 3%;
font-weight : normal;
font-size : 16px ; 
font-family : Poppins
`
export const Cardcontent1 = styled.span`
color : white;
width: 100%;
margin-left : 2%;
font-weight : normal;
font-size : 16px ; 
font-family : Poppins
`


export const Box5 = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgb(255, 255, 255);
	width: 50%;
    border-radius: 36px;
	left: 10%;
	right:10%;
	position: absolute;
	padding: 0px 10px;
	margin-top: 90px;
`;
export const Box6 = styled.body`  
    margin: 0 !important;
    
`
export const Content1 = styled.p`

width:35%;
position:absolute;
margin-top:-112%;
margin-left:50%;
font-size:16px;
`

export const Shortcard = styled.div`
box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
margin-top: 19%;
 position:absolute;
 margin-left:2%;
 width:383px;
 height:59px;
 border-radius:20px;
 @media (min-width: 800px) and (max-width: 1024px){
    margin-top:19%;
    width:283px;
    height:40px;
    border-radius:20px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top:19%;
    width:200px;
    height:30px;
    border-radius:20px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top:22%;
    width:130px;
    height:20px;
    border-radius:20px;
}
`
export const Shortcard1 = styled.div`
    margin-top:38%;
 position:absolute;
 margin-left:20%;
 width:312px;
 height:59px;
 border-radius:20px;
 box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
 @media (min-width: 800px) and (max-width: 1024px){
    margin-top:38%;
    width:283px;
    height:40px;
    border-radius:20px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top:35%;
    width:183px;
    height:30px;
    border-radius:20px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top:38%;
    width:100px;
    height:20px;
    border-radius:20px;
}
`
export const Shortcard2 = styled.div`
box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
margin-top:53%;
 position:absolute;
 margin-left:67%;
 width:383px;
 height:59px;
 border-radius:20px;
 @media (min-width: 800px) and (max-width: 1024px){
    margin-top:53%;
    width:283px;
    height:40px;
    border-radius:20px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top:48%;
    width:220px;
    height:30px;
    border-radius:20px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top:53%;
    margin-left:65%;
    width:130px;
    height:20px;
    border-radius:20px;
}
`
export const Shortcard3 = styled.div`
box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
margin-top:72%;
 position:absolute;
 margin-left:47%;
 width:312px;
 height:59px;
 border-radius:20px;
 @media (min-width: 800px) and (max-width: 1024px){
    margin-top:72%;
    width:283px;
    height:40px;
    border-radius:20px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top: 62%;
    width:183px;
    height:30px;
    border-radius:20px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top:70%;
    width:110px;
    height:20px;
    margin-left:47%;
    border-radius:20px;
}
`


export const Cardtext = styled.p`
font-size : 14px;
white-space: nowrap;
font-family : Poppins
font-weight : normal;
line-height : 21px;
margin-top: 15px;
color: #1F274A;
text-align : center; 
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 44px; 
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 3%;
    font-size : 10px;  
}
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : -2%;
    font-size : 6px;
}
`

export const Cardtext1 = styled.p`
font-size : 14px;
font-family : Poppins
font-weight : normal;
line-height : 21px;
color: #1F274A;
text-align : center; 
margin-top : 15px;
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 44px; 
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 3%;
    font-size : 9px;  
}
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 1%;
    font-size : 6px;
}
`

export const Viewmore1 = styled(Button)` 
    margin-top:35%;
    position:absolute;
    margin-left:50%;
    width: 230px;
    height: 50px !important;
    background-color: linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    @media (min-width: 800px) and (max-width: 1024px){
        font-size : 44px; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-top : 30%;
        font-size : 24px; 
        width: 130px;
        height: 40px !important;
        margin-left:45%; 
    }
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : 40%; 
        font-size : 14px;
        width: 80px;
        height: 25px !important;
    }
`

export const Viewmore2 = styled(Button)`
    margin-top:67%;
    position:absolute;
    margin-left:12%; 
    width: 230px;
    height: 50px !important;
    background-color: linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (min-width: 800px) and (max-width: 1024px){
        font-size : 44px; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-top : 62%;
        font-size : 24px; 
        margin-left:15%; 
        width: 130px;
        height: 40px !important;
    }
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : 70%;
        font-size : 10px;
        margin-left: 4%; 
        width: 80px;
        height: 25px !important;
    } 
`

export const BtnTxtC = styled.h5`
font-size: 20px;
margin-top: 3%;
color: white;
@media (min-width: 800px) and (max-width: 1024px){
    font-size: 20px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size: 18px; 
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size: 13px; 
    margin-top: -6%;
    margin-left: -25%;
} 
`

export const Heading = styled.h3`

position: absolute ;
padding-top : 20% ;
color : white ;
padding-left : 10%;
font-family : Poppins; 
line-height : 85x

`
export const Para = styled.p`

position: absolute;
padding-top : 25%;
color : white;
padding-left : 10%;
font-family : Poppins;
font-size : 36px;
`

export const Div1 = styled.div`

position: absolute;
padding-top : 33%;
text-align : center;
padding-left : 3%;
`;

export const Container2 = styled.div`
position : absolute;
padding-top : 33%;
text-align : center;
padding-left : 3%;
`;

export const HeadTag = styled.p`
    position : absolute; 
    color : black ;
    font-size : 54px;
    font-weight : bold;
    text-align : center;
    z-index : 1;
    margin-left : 27%;
    margin-top: -10%;
    @media (min-width: 800px) and (max-width: 1024px){
        font-size : 44px; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){ 
        font-size : 24px;  
    }
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : -10%;
        font-size : 14px;
    }
`
export const FirstCard = styled.div`

width:60%;
margin-left : 20%;
height: 60%;
margin-top: 75%;
@media (min-width: 800px) and (max-width: 1024px){
    height: 60%; 
} 
@media (min-width: 550px) and  (max-width: 800px){
    height: 60%; 
}
@media (min-width: 300px) and  (max-width: 580px){
    height: 40%;
}
 `

export const Xfooter  = styled.div`
    position : absolute;
    width: 100%; 
    margin-top: 270%;
`

export const LinksCard = styled.div` 
    position : absolute; 
    padding-left : 23%;
    padding-top: 66%;
    margin-top: 95%;
    width: 100%; 
    @media (min-width: 800px) and (max-width: 1024px){
        padding-left : 20%;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        padding-left : 23%;
        margin-top: 95%;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        padding-left : 13%;
        margin-top: 100%;
    }
`

export const Bmargin = styled.div`  
    padding-left : 30%; 
`
export const Amargin = styled.div`  
    padding-left : 70%; 
`
export const FirstContainer = styled.div`
display: flex;
  justify-content: space-around;
  height: 30%;
  padding: 2%;
`;

export const Para1 = styled.p`

font-size : 60px;
`;

export const Para2 = styled.p`
font-size : 16px;
margin-bottom : 3%;
margin-top : -20%;  
padding: 15px;    
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 14px;
    width: 40px; 
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 10px; 
    padding: 15px; 
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 8px; 
    width: 40px;
    margin-left: -50%;
    padding: 5px;
}
`;

export const Day = styled.b`

color : white;
font-size : 64px;
`;

export const ParaD = styled.p`

color : white;
font-size : 18px;
`;

export const Number = styled.span`

color : white;
font-size : 64px;
text-align : center;
white-space : nowrap;
font-weight : bold;
`;

export const Weight = styled.span`

color : white;
font-size : 18px;
text-align : center;
font-weight : normal;
`;

export const Weight1 = styled.p`

color : white;
font-size : 18px;
padding-right: 27%;
`;

export const Size = styled.p`

color : white;
font-size : 18px;
padding-left : 10%;
padding-top : 5%;
`;

export const Size1 = styled.span`

color : white;
font-size : 64px;
font-weight : bold;
`;

export const Size2 = styled.span`

color : white;
font-size : 18px;

`;

export const Size3 = styled.p`

color : white;
font-size : 18px;
padding-right: 55%;
`;

export const Title1 = styled.p`

margin-top : 15%;
font-weight : bold;
font-size : 50px;
text-align : center;
`;

export const Image = styled.img`

position: absolute;
margin-top : 13%;
margin-left : 10%;
height : 70%;
`;

export const Image1 = styled.img`

height: 32px;
margin-left : 5%;
`;

export const Image2 = styled.img`

height: 32px;
margin-left : 5%;
`;

export const Image3 = styled.img`
margin-top : 16%;
position : absolute;
margin-left : 20%;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 27%;
    margin-left : 23%;
    width: 210px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 18%;
    margin-left : 23%;
    width: 150px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 20%;
    margin-left : 28%;
    width: 80px;
}
`;

export const Image4 = styled.img`

margin-top : 27%;
position : absolute;
margin-left : 4%;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 27%;
    margin-left : 23%;
    width: 210px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 26%;
    margin-left : 12%;
    width: 150px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 30%;
    margin-left : 12%;
    width: 80px;
}
`;

export const Image5 = styled.img`

margin-top : 50%;
position : absolute;
margin-left : 48%;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 27%;
    margin-left : 23%;
    width: 210px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 45%;
    margin-left : 55%;
    width: 150px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 50%;
    margin-left : 58%;
    width: 80px;
}
`;

export const Image6 = styled.img`

margin-top : 60%;
position : absolute;
margin-left : 63%;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 27%;
    margin-left : 23%;
    width: 210px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 55%;
    margin-left : 65%;
    width: 150px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 60%;
    margin-left : 73%;
    width: 80px;
}
`;

export const VideoTitle = styled.p` 
width : 45%;
position : absolute;
margin-top : 50%;
margin-left : 12%;
font-size : 48px;
font-family : Poppins;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 50%;    
    font-size : 38px;
    margin-left : 4%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 45%;
    font-size : 28px;
    margin-left : 15%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 48%;
    font-size :18px;
    margin-left : 4%;
    width : 50%;
}
`
;

export const VideoContent = styled.p`

width : 35%;
position : absolute;
margin-top : 55%;
margin-left : 12%;
font-size : 16px;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 50%;    
    font-size : 38px;
    margin-left : 4%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : 50%;
    font-size : 10px;
    margin-left : 15%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : 55%;
    font-size : 6px;
    margin-left : 4%;
    width : 53%;
}
`;

export const Whitecurve = styled.img`

width : 100% ;
// z-index :0;
// margin-top : -8%;
`;

export const AudioTitle = styled.p`

width : 35%;
position : absolute;
margin-top : -120%;
margin-left : 50%;
font-size : 48px;
font-family : Poppins;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 50%;    
    font-size : 38px;
    margin-left : 4%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : -122%;
    font-size : 28px;
    margin-left : 45%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : -118%;
    font-size : 18px;
    margin-left : 52%;
    width : 50%;
}
`;

export const AudioContent = styled.p`

width : 35%;
position : absolute;
margin-top : -115%;
margin-left : 50%;
font-size : 16px;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : 50%;    
    font-size : 38px;
    margin-left : 4%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : -117%;
    font-size : 10px;
    margin-left : 45%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : -110%;
    font-size : 6px; 
    margin-left : 52%;
    width : 48%;
}
`;

export const Image7 = styled.img` 
// height: 200%;
width : 100%;
margin-top : -60%;
position: relative; 
overflow-y:hidden;
`;

export const Image8 = styled.img`  
width : 100%;
margin-top : -25%;
position: relative;
// z-index: 0;
@media (min-width: 800px) and (max-width: 1024px){
    margin-top : -23%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    margin-top : -20%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    margin-top : -18%; 
}
`

export const Heading1 = styled.p` 
font-size : 55px;
font-family : Poppins;
font-weight : bold;
position: relative;
margin-top : -123%;
text-align : center;
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 45px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 35px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 20px; 
}
`

export const Image9 = styled.img`
width : 70%;
margin-top : 5%;
position: relative;
margin-left:15%;
// z-index: 1;
`

export const Image10 = styled.img` 
margin-top : 75%;
margin-left : 40%;
// z-index: 1;
width: 250px;
@media (min-width: 800px) and (max-width: 1024px){
    width: 200px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    width: 150px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    width: 50px;
    margin-left : 45%;
    margin-top : 80%;
}
`

export const Cardimg = styled.img`
    height:40px !important;
    @media (min-width: 800px) and (max-width: 1024px){
        height: 30px !important;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        height: 30px !important; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        height: 20px !important;
    } 
`

 
export const BtnTxt = styled.p`
font-size: 20px;
margin-top: -10%;
@media (min-width: 800px) and (max-width: 1024px){
    font-size: 10px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size: 8px; 
    margin-top: 3%;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size: 5px;
} 
`

export const Cardp = styled.p`
white-space: nowrap;
font-size : 14px;
color: #857085;
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 10px;
    margin-top: -15px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 5px;
    margin-top: -30px;
}
`

export const Combinedshape = styled.img`
width: 100%;
position : absolute;
margin-top : 60%;
margin-left : -85%; 
`

export const Limg = styled.img`
@media (min-width: 800px) and (max-width: 1024px){
    width: 170px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    width: 80px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    width: 50px;
}
`

export const Bigcard = styled.div`

margin-top : 53%;
position: absolute;
width : 60%;
margin-left : 20%;
` 

export const XCdiv = styled.div`
    margin-top:77%;
    position:absolute; 
    width:60%;
    margin-left:38%;
`

export const XCarddiv = styled.div`
    margin-top:54%;
    position:absolute; 
    width:60%;
    margin-left:23%;
`
export const XChatCarddiv = styled.div`
    margin-top:130%;
    position:absolute; 
    width:60%;
    margin-left:30%;
`

export const Card1 = styled.p`

white-space : nowrap;
color: #857085;
font-size : 14px;
`;

export const Card2 = styled.p` 
white-space : nowrap;
font-size : 15px;
font-weight : bold;
font-family : Poppins;
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 13px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 10px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 7px; 
    margin-top: -10px;
}
`;

export const CardText = styled.p`

white-space : nowrap;
font-size : 14px;
color: #857085;
`;

export const Card3 = styled.p`

white-space : nowrap;
font-size : 15px;
font-weight : bold;
font-family : Poppins;
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 10px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 10px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 7px; 
}
`;

export const Card3Text = styled.p`

white-space: nowrap;
font-size : 14px;
color: #857085;
@media (min-width: 800px) and (max-width: 1024px){
    font-size : 13px;
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 10px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 5px;
    margin-top: -10px;
}
`;

export const Mask = styled.img`

position : absolute;
width: 100%;
margin-top : 90%;
`;

export const Whitecurve1 = styled.img`

position : absolute;
width : 100%;
margin-top : 100%;
padding-top : 65%;
`;

export const XTG1img = styled.img`
	width: 320px;  
    @media (min-width: 800px) and (max-width: 1024px){
        width: 300px; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        width: 180px; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        width: 80px; 
    }
`

export const XTGimg = styled.img`
	width: 400px; 
    @media (min-width: 800px) and (max-width: 1024px){
        width: 300px; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        width: 200px; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        width: 100px; 
    }
`

export const XTimg = styled.img`
    padding-left: 10%; 
	height: 400%; 
    @media (min-width: 800px) and (max-width: 1024px){
        height: 200%; 
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        height: 150%; 
        margin-top: -45%;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        height: 100%;
        padding-left: 30%; 
        margin-top: -95%;
    }
`

export const EmojiImg = styled.img`
height : 80%;
@media (min-width: 800px) and (max-width: 1024px){
    height : 60%;
} 
@media (min-width: 550px) and  (max-width: 800px){
    height : 50%; 
} 
@media (min-width: 300px) and  (max-width: 580px){
    height : 40%; 
}
`

export const CarouselDiv  = styled.div`
    position : absolute;
    width:80%;
    margin-left : 12%;
    // z-index: 2;
    height: 30%;
    margin-top: 230%;
    @media (min-width: 800px) and (max-width: 1024px){
        margin-left : 14%;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-left : 11%; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        margin-left : 10%;
        margin-bottom: 20px;
    }
`

export const CarouselItem  = styled(Carousel.Item)` 
    @media (min-width: 800px) and (max-width: 1024px){
        margin-top : 3%;
        font-size : 16px;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-top : 6%; 
        font-size : 7px;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : 10%;
        font-size : 5px;
        .Caption{
            margin-top : 10% !important;
        }
    }
`

export const CarouselCaption  = styled(Carousel.Caption)` 
    @media (min-width: 800px) and (max-width: 1024px){
        margin-top : 3%;
        font-size : 16px;
    } 
    @media (min-width: 550px) and  (max-width: 800px){
        margin-top : 6%; 
        font-size : 7px;
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : 10% !important;
        font-size : 5px;
    }
`