//action
import {
    GET_QUICK_SUPPORT,
    GET_QUICK_SUPPORT_FAIL,
    GET_QUICK_SUPPORT_SUCCESS,
    GET_QUICK_SUPPORT_RESET
} from "../action/getQuickSupportAction"
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    result: [],
    pagination: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_QUICK_SUPPORT:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_QUICK_SUPPORT_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_QUICK_SUPPORT_SUCCESS:
            console.log("myresponse", action.response)
            return {
                ...state,
                apiState: "success",
                result: action.response.data.data.result,
                pagination: action.response.data.data.pagination,
            }
        case GET_QUICK_SUPPORT_RESET:
            return initialState
        default:
            return state
    }
}