export const GET_CHAT_BOT = "GET_CHAT_BOT"
export const GET_CHAT_BOT_FAIL = "GET_CHAT_BOT_FAIL"
export const GET_CHAT_BOT_SUCCESS = "GET_CHAT_BOT_SUCCESS" 
export const GET_CHAT_BOT_RESET = "GET_CHAT_BOT_RESET"

export const getChatBot = (params) => {
    return { type: GET_CHAT_BOT, params }
}
export const getChatBotFail = (response) => {
    return { type: GET_CHAT_BOT_FAIL, response }
}
export const getChatBotSuccess = (response) => {
    return { type: GET_CHAT_BOT_SUCCESS, response }
}
export const getChatBotReset = (response) => {
    return { type: GET_CHAT_BOT_RESET, response }
} 