import styled from "styled-components";
import { Button, Form, Modal } from "antd";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const Content = styled.div`
  width: 100%;
`;
export const Left = styled.div``;
export const FbButton = styled(Button)``;

export const GButton = styled(Button)``;

export const Ximg = styled.img`
  @media (min-width: 300px) and (max-width: 580px) {
  }
`;

export const Right = styled.div`
  width: 50%;
  top: 0;
  margin-left: 30%;
  padding-top: 5%;
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: -10%;
    margin-top: 80%;
  }
`;

export const LoginInput = styled.div`
  .ant-input {
    border-radius: 10px;
  }
  width: 100%;
`;

export const Xlink = styled.a`
  color: #c473c4;
  font-weight: 500;
  text-decoration: none;
  :hover {
    color: #a64fa6;
  }
`;

export const XButton = styled(Button)`
  width: 200px;
  background-color: #c473c4;
  border: none;
  /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  @media (min-width: 300px) and (max-width: 580px) {
    height: 40px !important;
  }
`;
export const FormItem = styled(Form.Item)``;

export const FormItem1 = styled(Form.Item)``;

export const FormItem2 = styled(Form.Item)``;

export const FormItem3 = styled(Form.Item)``;

export const FormItem4 = styled(Form.Item)``;

export const FormItem5 = styled(Form.Item)``;

export const XModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  width: 50%;
  height: 70%;
  @media (min-width: 300px) and (max-width: 580px) {
    height: 50%;
  }
`;

export const Mh1 = styled.h1`
  color: #a64fa6;
  // margin-left: 12%;
`;
export const Mdiv = styled.div`
  text-align: center;
`;
export const NInput = styled.input`
  border-radius: 8px;
  width: 45px;
  height: 45px;
  text-align: center;
`;

export const Xlabel = styled.label`
  margin-left: -130%;
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 0%;
    padding-top: 12px;
    width: 300px;
  }
`;

export const XPlabel = styled.label`
  margin-left: -130%;
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: -0.5%;
    padding-top: 12px;
    padding-left: 15px;
    white-space: nowrap;
    display: inline-block;
  }
`;
export const Test = styled.div`
  background: #f00;
`;
