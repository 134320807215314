import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_DOCTOR_LIST,
    getDoctorListFail,
    getDoctorListSuccess
} from '../action/getDoctorListAction'

// api 
import getDoctorListApi from '../api/getDoctorListApi'

export function* getDoctorListSaga(action) {
    try {
        const response = yield call(() => getDoctorListApi(action.params))
        if (response.data.status === 1) {
            yield put(getDoctorListSuccess(response, action))
        } else {
            yield put(getDoctorListFail(response, action))
        }
    }
    catch (e) {
        yield put(getDoctorListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_DOCTOR_LIST, getDoctorListSaga)
}