export const GET_UPCOMING_CONSULTATION = "GET_UPCOMING_CONSULTATION"
export const GET_UPCOMING_CONSULTATION_FAIL = "GET_UPCOMING_CONSULTATION_FAIL"
export const GET_UPCOMING_CONSULTATION_SUCCESS = "GET_UPCOMING_CONSULTATION_SUCCESS"
export const GET_UPCOMING_CONSULTATION_RESET = "GET_UPCOMING_CONSULTATION_RESET"

export const getUpcomingConsultation = (params) => {
    return { type: GET_UPCOMING_CONSULTATION, params }
}
export const getUpcomingConsultationFail = (response) => {
    return { type: GET_UPCOMING_CONSULTATION_FAIL, response }
}
export const getUpcomingConsultationSuccess = (response) => {
    return { type: GET_UPCOMING_CONSULTATION_SUCCESS, response }
}
export const getUpcomingConsultationReset = () => {
    return { type: GET_UPCOMING_CONSULTATION_RESET }
}