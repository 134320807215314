// action
import {
    GET_DOCTOR_DETAIL,
    GET_DOCTOR_DETAIL_FAIL,
    GET_DOCTOR_DETAIL_SUCCESS,
    GET_DOCTOR_DETAIL_RESET
} from '../action/getDoctorDetailAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DOCTOR_DETAIL:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_DOCTOR_DETAIL_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_DOCTOR_DETAIL_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response,
            }
        case GET_DOCTOR_DETAIL_RESET:
            return initialState
        default:
            return {
                ...state
            }
    }
}