import React, { useEffect, useState } from "react";
//import { Form, Input, Typography, Button, Select, Space, Modal } from "antd"
//import {React, useState} from 'react'
import {
  Form,
  Typography,
  Radio,
  Space,
  Modal,
  Button,
  DatePicker,
  Divider,
} from "antd";
import { useHistory } from "react-router-dom";
import { saveRegisterData } from "../../action/saveRegisterDataAction";
import { connect } from "react-redux";
import moment from "moment";
import "./Interest.css";

//STYLE
import {
  Container,
  Content,
  Left,
  Right,
  XButton,
  Xdiv,
  XText,
  RadioButton,
  Ximg,
  XTitle,
  RadioGroup,
} from "./InterestStyle";

export const Interest = (props) => {
  const { saveRegisterData, saveRegisterDataState } = props;
  const _ = require("lodash");
  let items = "";
  //console.log(props.RegistrationData)
  const { Title } = Typography;
  const [clicked, setClicked] = useState(false);
  const [genvisble, setgenvisble] = useState(false);
  const [cli, setCli] = useState(false);
  const [value, setValue] = useState([]);
  const [Lmp_date, setLmp_date] = useState("");
  const [Conceivevalue, setConceiveValue] = useState("");
  const [ConceiveData, setConceiveData] = useState({
    "Looking to Conceived": { BgColor: "#FFFFFF", click: false },
    "Already Conceived": { BgColor: "#FFFFFF", click: false },
    "Become A Mother": { BgColor: "#FFFFFF", click: false },
  });
  const [ButtonData, setButtonData] = useState({
    ease: { BgColor: "", click: false },
    wellness: { BgColor: "", click: false },
    onco: { BgColor: "", click: false },
    prego: { BgColor: "", click: false, value: "" },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPregnancySelected, setIsPregnancySelected] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const cancelOk = () => {
    setIsModalVisible(false);
  };
  function disabledDate(current) {
    let customDate = moment();
    return current &&
      moment().diff(moment(current).format("YYYY-MM-DD"), "days") < 280 &&
      current < moment()
      ? false
      : true;
  }
  const handleOk = () => {
    if (!cli) {
      Modal.warning({
        content: "Please Select Menstrual details",
      });
    } else if (clicked && Lmp_date == "") {
      Modal.warning({
        content: "Please Select LMP Date",
      });
    } else {
      var today = moment();
      let lmp_as_of_date = today.format("YYYY-MM-DD");
      console.log(today);

      items = _.toString(value);

      console.log(items);
      const data = {
        interest: items,
        lmp: Lmp_date,
        lmp_as_of: lmp_as_of_date,
        conceive_status: Conceivevalue,
      };
      props.setPatientData({ ...props.patientData, ...data });

      console.log("Final Submit handle OK", { ...props.patientData, ...data });
      submit();
      // saveRegisterData(props.patientData);
      // // console.log("i was here");
      // // props.setSuccess(4);
      setIsModalVisible(false);
    }
  };
  console.log('value************************************',value)

  function handleChange(e) {
   
    var interest = e.target.value;
    // setValue([...value, interest]);

    // props.setPatientData({
    //   ...props.patientData,
    //   interest: _.toString([...value, interest]),
    // });
  }

  // function ConceiveChange(e) {
  //   var value =
  //     e.target.value == "I am trying to get pregnant"
  //       ? "I am trying to get pregnant"
  //       : "I am pregnant";
  //   setConceiveValue(value);
  // }

  function ConceiveChange(e) {
    setConceiveValue(e.target.value);
  }

  function LmpDateChange(date, dateString) {
    console.log(date, dateString);
    const date1 = date ? date.format("YYYY-MM-DD") : null;
    console.log(date1);
    setLmp_date(date1);
  }



  function conceiveClick(params) {
    console.log("toConceiveData", ConceiveData);
    let clicked;
    setCli(true);
    if (params === "Looking to Conceived") {
      clicked = ConceiveData["Looking to Conceived"].click;
      clicked = !clicked;
      setClicked(false);
      if (clicked === true) {
        // alert("toConceive")
        setConceiveData({
          ...ConceiveData,
          "Looking to Conceived": {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          "Already Conceived": {
            BgColor: "#FFFFFF",
            click: false,
          },
          ["Become A Mother"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      } else {
        setConceiveData({
          ...ConceiveData,
          ["Looking to Conceived"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (params === "Already Conceived") {
      console.log("ConceiveData", ConceiveData);
      clicked = ConceiveData["Already Conceived"].click;
      clicked = !clicked;
      if (clicked === true) {
        // alert("AlreadyConceive")
        setClicked(true);
        setConceiveData({
          ...ConceiveData,
          ["Already Conceived"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["Looking to Conceived"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
          ["Become A Mother"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      } else {
        setClicked(false);
        setConceiveData({
          ...ConceiveData,
          ["Already Conceived"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (params === "Become A Mother") {
      console.log("ConceiveData", ConceiveData);
      clicked = ConceiveData["Become A Mother"].click;
      clicked = !clicked;
      setClicked(false);
      if (clicked === true) {
        // alert("AlreadyConceive")
        setConceiveData({
          ...ConceiveData,
          ["Become A Mother"]: {
            BgColor: "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
            click: true,
          },
          ["Looking to Conceived"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
          ["Already Conceived"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      } else {
        setConceiveData({
          ...ConceiveData,
          ["Become A Mother"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    }
  }

  console.log(value);
  function Click(e,gender) {
    console.log('gender',e.target.value)
    
    let clicked;
    if (gender === "ease") {
      clicked = ButtonData.ease.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["ease"]: {
            BgColor: "2px solid #a54ea5",
            click: true,
          },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["ease"]: {
            BgColor: "1px solid black",
            click: false,
          },
        });
      }
    } else if (gender === "wellness") {
      clicked = ButtonData.wellness.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["wellness"]: {
            BgColor: "2px solid #a54ea5",
            click: true,
          },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["wellness"]: {
            BgColor: "1px solid black",
            click: false,
          },
        });
      }
    } else if (gender === "onco") {
      clicked = ButtonData.onco.click;
      clicked = !clicked;
      if (clicked === true) {
        setButtonData({
          ...ButtonData,
          ["onco"]: {
            BgColor: "2px solid #a54ea5",
            click: true,
          },
        });
      } else {
        setButtonData({
          ...ButtonData,
          ["onco"]: {
            BgColor: "1px solid black",
            click: false,
          },
        });
      }
    } else if (gender === "prego") {
      clicked = ButtonData.prego.click;
      clicked = !clicked;
      console.log('clickedclickedclicked',clicked)
      if (clicked === true) {
        setIsPregnancySelected(true);
        setValue([...value,e.target.value]);
        setButtonData({
          ...ButtonData,
          ["prego"]: {
            BgColor: "2px solid #a54ea5",
            click: true,
            value: "Pregnancy & Reproductive Wellness",
          },
        });
      } else {
        setIsPregnancySelected(false);
        setValue((prevValue) => prevValue.filter((v) => v !== e.target.value));
        setButtonData({
          ...ButtonData,
          ["prego"]: {
            BgColor: "1px solid black",
            click: false,
          },
        });
      }
    }
  }

  const checkStatus = (params) => {
    items = _.toString(value);
    // if (items === "") {
    //   Modal.warning({
    //     content: "Please select interest",
    //   });
    // } else 
    if (items.includes("Pregnancy & Reproductive Wellness")) {
      showModal();
      if (Lmp_date) {
        // const lmp_D = Lmp_date;
        var today = moment();
        let lmp_as_of_date = today.format("YYYY-MM-DD");

        const data = {
          interest: items,
          lmp: Lmp_date,
          lmp_of_date: lmp_as_of_date,
          // conceive_status: Conceivevalue[0],
          conceive_status: Conceivevalue == "" ? null : Conceivevalue,
        };
        console.log(Lmp_date);
        props.setPatientData({ ...props.patientData, ...data });

        // saveRegisterData(props.patientData);
        // console.log("saveRegisterData =>", saveRegisterData);
        // handleOk();
        submit();
      }
    } else {
      props.setPatientData({
        ...props.patientData,
        interest: items,
        conceive_status: Conceivevalue == "" ? null : Conceivevalue,
      });
      console.log("else submit");
      submit();
    }
  };

  const submit = () => {
    var today = moment();
    let lmp_as_of_date = today.format("YYYY-MM-DD");
    items = _.toString(value);
    const data = {
      ...props.patientData,
      interest: items,
      lmp: Lmp_date,
      lmp_of_date: lmp_as_of_date,
      conceive_status: Conceivevalue == "" ? null : Conceivevalue,
    };
    props.setSuccess(4, data);
    console.log();
    saveRegisterData(props.patientData);
  };
  // function checkStatus(params) {
  //   items = _.toString(value);
  //   if (items.includes("Pregnancy & Reproductive Wellness")) {
  //     showModal();
  //     if (Lmp_date) {
  //       // const lmp_D = Lmp_date;

  //       const data = {
  //         lmp: Lmp_date,
  //         conceive_status: Conceivevalue[0],
  //       };
  //       console.log(Lmp_date);
  //       props.setPatientData({...props.patientData, ...data})

  //       saveRegisterData(props.patientData);
  //       console.log("saveRegisterData =>", saveRegisterData);
  //       handleOk();
  //       Submit();
  //     }
  //   } else {
  //     console.log("else submit");
  //     Submit();
  //   }
  // }

  // const Submit = () => {
  //   if (items === "") {
  //     Modal.warning({
  //       content: "Please select interest",
  //     });
  //   } else {
  //     var today = moment();
  //     let lmp_as_of_date = today.format("YYYY-MM-DD");
  //     console.log(today);

  //     items = _.toString(value);

  //     console.log(items);
  //     if (saveRegisterDataState.data.socialSignin) {
  //       const data = {
  //         interest: items,
  //         lmp: Lmp_date,
  //         lmp_as_of: lmp_as_of_date,
  //         conceive_status: Conceivevalue == "" ? null : Conceivevalue,
  //       };
  //       props.setPatientData({...props.patientData, ...data})
  //       props.setSuccess(4);
  //       console.log(data);
  //       // saveRegisterData(props.patientData);
  //     } else {
  //       const data = {
  //         interest: items,
  //         lmp: Lmp_date,
  //         lmp_as_of: lmp_as_of_date,
  //         conceive_status: Conceivevalue == "" ? null : Conceivevalue,
  //       };
  //       console.log(" Final submit Submit ",{...props.patientData, ...data});
  //       props.setPatientData({...props.patientData, ...data})
  //       // saveRegisterData(props.patientData);
  //       console.log("i was here");
  //       props.setSuccess(4);
  //     }
  //   }
  // };

  useEffect(() => {
    console.log("final state ", props.patientData);
  });

  /*
    useEffect(()=>{
        if(saveRegisterDataState.data.interest.lenght > 0){
            
        }
   },[])
   */
  useEffect(() => {
    if (saveRegisterDataState.data.gender === "women") {
      setgenvisble(true);
    }
  }, "");

  return (
    <div className="container-fluid">
      <div className="row pl-lg-5">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 pl-lg-5">
          <div className="logos-interest">
            <img
              src={
                process.env.PUBLIC_URL + "/images/svg/Lilac-Insights-Logo1.svg"
              }
              alt=""
            />
          </div>
          <h1 className="regitWelcome mb-4 mt-3">Select Interest</h1>
          {/* {console.log(ButtonData)} */}
          <RadioGroup
            buttonStyle="solid"
            // defaultValue={value}
            value={value}
            onChange={handleChange}
          >
            <Space direction="vertical" className="radioInterest" size="large">
              {props.patientData.gender === "female" ? (
                <RadioButton
                  value="Pregnancy & Reproductive Wellness"
                  onClick={(e) => Click(e,"prego")}
                  style={{ border: `${ButtonData.prego.BgColor}` }}
                >
                  <Xdiv>
                    <div>
                      {ButtonData.prego.click ? (
                        <img
                          src={process.env.PUBLIC_URL + "/images/rectcheck.svg"}
                          style={{ padding: "10px" }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/rectuncheck.svg"
                          }
                          style={{ padding: "10px" }}
                          alt=""
                        />
                      )}
                    </div>
                    {ButtonData.prego.click ? (
                      <h5 style={{ marginTop: "3%", color: "#a54ea5" }}>
                        Pregnancy & Reproductive Wellness
                      </h5>
                    ) : (
                      <h5 style={{ marginTop: "3%", color: "black" }}>
                        Pregnancy & Reproductive Wellness
                      </h5>
                    )}
                  </Xdiv>
                </RadioButton>
              ) : null}
              {/* as per the requirement */}
               {/* <RadioButton
                value="At Ease"
                onClick={() => Click("ease")}
                style={{ border: `${ButtonData.ease.BgColor}` }}
              >
                <Xdiv>
                  <div>
                    {ButtonData.ease.click ? (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectcheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectuncheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    )}
                  </div>
                  {ButtonData.ease.click ? (
                    <XText style={{ color: "#a54ea5" }}>At Ease</XText>
                  ) : (
                    <XText style={{ color: "black" }}>At Ease</XText>
                  )}
                </Xdiv>
              </RadioButton>  */}
              {/* <RadioButton
                value="General Wellness"
                onClick={() => Click("wellness")}
                style={{ border: `${ButtonData.wellness.BgColor}` }}
              >
                <Xdiv>
                  <div>
                    {ButtonData.wellness.click ? (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectcheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectuncheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    )}
                  </div>
                  {ButtonData.wellness.click ? (
                    <h5 style={{ marginTop: "5%", color: "#a54ea5" }}>
                      General Wellness
                    </h5>
                  ) : (
                    <h5 style={{ marginTop: "5%", color: "black" }}>
                      General Wellness
                    </h5>
                  )}
                </Xdiv>
              </RadioButton> */}
              {/* <RadioButton
                value="Oncogenomic"
                onClick={() => Click("onco")}
                style={{ border: `${ButtonData.onco.BgColor}` }}
              >
                <Xdiv>
                  <div>
                    {ButtonData.onco.click ? (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectcheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/images/rectuncheck.svg"}
                        style={{ padding: "10px" }}
                        alt=""
                      />
                    )}
                  </div>
                  {ButtonData.onco.click ? (
                    <XText style={{ color: "#a54ea5" }}>Oncogenomic</XText>
                  ) : (
                    <XText style={{ color: "black" }}>Oncogenomic</XText>
                  )}
                </Xdiv>
              </RadioButton> */}
            </Space>
          </RadioGroup>
          <Modal
            visible={isModalVisible}
            footer={null}
            className="concieveModal"
            onOk={handleOk}
            onCancel={cancelOk}
          >
            <div className="text-center mt-3 mb-3 container">
              <h5 className="text-left">Your Stage</h5>
              <Divider />
              <RadioGroup
                onChange={ConceiveChange}
                value={Conceivevalue}
                className="mt-4"
                size="large"
              >
                <Space direction="vertical" className="radioInterest">
                  <RadioButton
                    value="Looking to Conceived"
                    onClick={() => conceiveClick("Looking to Conceived")}
                    style={{
                      background: `${ConceiveData["Looking to Conceived"].BgColor}`,
                      marginBottom: "20px",
                    }}
                  >
                    <Xdiv>
                      <div>
                        {ConceiveData["Looking to Conceived"].click ? (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/Tickedcircle.svg"
                            }
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + "/images/circle.svg"}
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        )}
                      </div>
                      {ConceiveData["Looking to Conceived"].click ? (
                        <h5 className="your-stage-option-1">
                          I am trying to get pregnant
                        </h5>
                      ) : (
                        <h5 className="your-stage-option-2">
                          I am trying to get pregnant
                        </h5>
                      )}
                      {console.log(ConceiveData["Looking to Conceived"].click)}
                    </Xdiv>
                  </RadioButton>
                  <RadioButton
                    value="Already Conceived"
                    onClick={() => conceiveClick("Already Conceived")}
                    style={{
                      background: `${ConceiveData["Already Conceived"].BgColor}`,
                      marginBottom: "20px",
                    }}
                  >
                    <Xdiv>
                      <div>
                        {ConceiveData["Already Conceived"].click ? (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/Tickedcircle.svg"
                            }
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + "/images/circle.svg"}
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        )}
                      </div>
                      {ConceiveData["Already Conceived"].click ? (
                        <h5 className="your-stage-option-1">I am pregnant</h5>
                      ) : (
                        <h5 className="your-stage-option-2">I am pregnant</h5>
                      )}
                    </Xdiv>
                  </RadioButton>
                  <RadioButton
                    value="Become A Mother"
                    onClick={() => conceiveClick("Become A Mother")}
                    style={{
                      background: `${ConceiveData["Become A Mother"].BgColor}`,
                      marginBottom: "20px",
                    }}
                  >
                    <Xdiv>
                      <div>
                        {ConceiveData["Become A Mother"].click ? (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/Tickedcircle.svg"
                            }
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + "/images/circle.svg"}
                            style={{ padding: "10px" }}
                            alt=""
                          />
                        )}
                      </div>
                      {ConceiveData["Become A Mother"].click ? (
                        <h5 className="your-stage-option-1">
                          I have delivered a baby
                        </h5>
                      ) : (
                        <h5 className="your-stage-option-2">
                          I have delivered a baby
                        </h5>
                      )}
                      {console.log(ConceiveData["Become A Mother"].click)}
                    </Xdiv>
                  </RadioButton>
                </Space>
              </RadioGroup>
              {clicked ? (
                <div className="container">
                  <h6 style={{ textAlign: "left" }}>Last Menstrual Period</h6>
                  <DatePicker
                    disabledDate={disabledDate}
                    className="form-control mt-3"
                    onChange={LmpDateChange}
                    format={"DD-MM-YYYY"}
                    size="small"
                  />
                </div>
              ) : (
                <></>
              )}
              <Button
                className="themeBtn consultBtnResp mt-4"
                onClick={handleOk}
              >
                Finish
              </Button>
            </div>
          </Modal>

          <div className="row mt-5 mb-5 mb-lg-0">
            <div className="col-12 col-sm-5 mb-2">
              <button
                className="regitBtn"
                shape="round"
                type="primary"
                onClick={() => props.previous()}
              >
                Back
              </button>
            </div>
            <div className="col-12 col-sm-5">
              <button
                className="regitBtn"
                shape="round"
                type="primary"
                htmlType="submit"
                onClick={checkStatus}
              >
                {isPregnancySelected ? "Next" : "Finish"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
          <img
            src={process.env.PUBLIC_URL + "/images/svg/loginImg.svg"}
            className="img-interest-fix"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  saveRegisterDataState: state.saveRegisterData,
});

const mapDispatchToProps = (dispatch) => ({
  saveRegisterData: (params) => dispatch(saveRegisterData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Interest);
