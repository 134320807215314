import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PATIENT_DETAIL_FOR_INTAKE_FORM,
    getPatientDetailForIntakeFormFail,
    getPatientDetailForIntakeFormSuccess
} from '../action/getPatientDetailForIntakeFormAction'

// api 
import getPatientDetailForIntakeFormApi from '../api/getPatientDetailForIntakeFormApi'

export function* getPatientDetailForIntakeFormSaga(action) {
    try {
        const response = yield call(() => getPatientDetailForIntakeFormApi(action.params))
        if (response.data.statusCode === 200) {
            yield put(getPatientDetailForIntakeFormSuccess(response, action))
        } else {
            yield put(getPatientDetailForIntakeFormFail(response, action))
        }
    }
    catch (e) {
        yield put(getPatientDetailForIntakeFormFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PATIENT_DETAIL_FOR_INTAKE_FORM, getPatientDetailForIntakeFormSaga)
}