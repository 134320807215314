import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CHAT_BOT,
    getChatBotFail,
    getChatBotSuccess
} from '../action/getChatBotAction'

// api 
import getChatBotApi from '../api/getChatBotApi'

export function* getChatBotSaga(action) {
    try {
        const response = yield call(() => getChatBotApi(action.params))
        if (response.data.status === 1) {
            yield put(getChatBotSuccess(response, action))
        } else {
            yield put(getChatBotFail(response, action))
        }
    }
    catch (e) {
        yield put(getChatBotFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CHAT_BOT, getChatBotSaga)
}