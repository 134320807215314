/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react"
import { Row, Col, Space, Button } from 'antd'
import { Link, NavLink } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Header from "../../component/Header/Header";
import AtEasy from '../../component/AtEasy/AtEasy';
//component
import Footer from "../../component/Footer/Footer";
//others
// import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
// Style
import {
Container,
Box1,
Box2,
Box3,
Box5,
Box6,
Backcard,
Frontcard,
BackcardTitle,
FrontcardTitle,
Cardcontent,
Cardcontent1,
Shortcard,
Cardtext,
Cardtext1,
Shortcard2,
Shortcard3,
Viewmore1,
Viewmore2,
Heading,
Para,
Div1,
Container2,
HeadTag,
FirstCard,
FirstContainer,
Para1,
Para2, 
Image3,
Image4,
Image5,
Image6,
Image8,
VideoTitle,
VideoContent,
Whitecurve,
AudioTitle,
AudioContent,
Image7,
Heading1,
Combinedshape,
Bigcard,
card1p,
Card2,
CardText,
Card3,
Card3Text, 
XButton,
Ximg, 
LinksCard,
CarouselDiv,
Xfooter, 
XCButton,
XTG1img,
XTGimg,
XTimg,
EmojiImg,
Image9,
BtnTxt,
Cardimg,
Image10,
Cardp,
Limg,
CarouselItem,
CarouselCaption,
Shortcard1,
BtnTxtC,
MoodDiv
} from "./NonPregHomeStyle";
import { SocialButton, GoogleStoreImg, AppleStoreImg, HomeWrap, TopContainer } from "../Home/HomeStyle";

import "./NonPregHome.css";
import { TribeModal } from "../../component/TribeModal/TribeModal"; 
import axios from "axios";
import config from "../../config"
import { getPatientToken } from "../../utils"
import CarouselBlock from "../../component/CarouselBlock/CarouselBlock";
import HealthBlock from "../../component/HealthBlock/HealthBlock";
import MoodOMeter from "../../component/MoodOMeter/MoodOMeter";
import ArticleBlock from "../../component/ArticleBlock/ArticleBlock"; 

const NonPregHome =(props)=>{

    //CONSTANTS
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [textAvailable, setTextAvailable] = useState(false);
    const [Testimonials, setTestimonials] = useState([]);
    let page_week = JSON.parse(localStorage.getItem('week'));
    let name = JSON.parse(localStorage.getItem('name'));
    const showModal = () => {
        setIsModalVisible(true);
    };
    const [articleData, setArticleData] = useState({
        "text": [],
        "audio": [],
        "video": []
    })
    useEffect(() => {  
        axios({
            url: `${config.api.base_url}testimonial/list/`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${getPatientToken()}`
            }
        })
            .then((respons) => {
                console.log(respons.data)

            })
            .catch(error => {
                console.log(error);
            });
        const data = [
            {
                "_id": "61388a82a42c695fdcf9d093",
                "patient_id": {
                    "_id": "613f50c1bab60ee2115d60c7",
                    "name": "Kshitija Duraphe"
                },
                "rating": 4,
                "comment": "Excellent consultation",
                "doctor_id": {
                    "_id": "6129d72025b7290647da9a01",
                    "fname": "Syeda",
                    "lname": "Ruksheda",
                    "profile_picture": "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg"
                },
                "status": "approved"
            },
            {
                "_id": "61389796a42c695fdcf9d099",
                "patient_id": {
                    "_id": "6138cee78b740d790ac422c4",
                    "name": "Asifa Shaikh",
                    "profile_pic": ""
                },
                "rating": 2,
                "comment": "Good experince I had with doctor consultancy",
                "doctor_id": {
                    "_id": "6129d72025b7290647da9a01",
                    "fname": "Syeda",
                    "lname": "Ruksheda",
                    "profile_picture": "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg"
                },
                "status": "rejected"
            },
            {
                "_id": "6138a542a42c695fdcf9d09c",
                "patient_id": {
                    "_id": "613071c0b57292fde5aaac55",
                    "name": "asif shaikh",
                    "profile_pic": ""
                },
                "rating": 3,
                "comment": "Best experince I had with doctor consultancy",
                "doctor_id": {
                    "_id": "6129d72025b7290647da9a01",
                    "fname": "Syeda",
                    "lname": "Ruksheda",
                    "profile_picture": "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg"
                }
            }
        ]
        setTestimonials(data)
    }, [])
    

    return(
        <>
        <HomeWrap>
            <TopContainer>
                <img className='align-center mobile-height' src="./images/lilac-insights-logo.svg"  alt = "exp" /> 
            </TopContainer>
            <img className="nonPregHomeBanner" src="./images/HomeFamily.svg" alt="no"></img>
            <div className="container nonPregHomeSpace">
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <h1 className="username">Hi, {name} </h1>
                        <SocialButton>
                            <Link to='www.google.com' target="_blank"><GoogleStoreImg className="img-fluid" src="./images/Badge.svg" alt="no" /></Link>
                            <Link to='www.google.com'><AppleStoreImg className="img-fluid mb-5" src="./images/Badge1.svg" alt="no" /></Link>
                        </SocialButton>
                    </div>
                </div>
            </div>
            <div>
            <div className="nonSmilyMargin">
                    <MoodDiv>   
                        <MoodOMeter />
                    </MoodDiv>
            </div>
                

                <div className="nonWeeklyHeWrap">
                    <img src="./images/whitecurve.svg" alt="no" class="weeklyHeWrapBg hideMobile"></img>
                    <img alt="right-bg" class="homeRightBg hideMobile" src="./images/svg/doctorbgRight.svg" />
                    <img className="yourHelthImg2 hideMobile" src="./images/svg/home-helth-bg.svg" alt="no"/>  
                    <div className="container">
                        <div className="Articlecontainer">
                            < ArticleBlock
                                align="right"
                                title="Curated for you"
                                week={page_week}
                                type="text"
                                link="read"
                            />
                            < ArticleBlock
                                align="left"
                                title="Videos for the week"
                                week={page_week}
                                type="video"
                                link="watch"
                            />
                            < ArticleBlock
                                align="right"
                                title="Audios for the week"
                                week={page_week}
                                type="audio"
                                link="listen"
                            />
                        </div>
                    </div>
                </div>
            </div> 

            <section id="health">
                <HealthBlock />
            </section>

            <section id="ease">
                <AtEasy/>
            </section>

            <div className="yourHelthBg">
                <div className="container">
                    <div className="row">
                        <div className="offset-sm-1 col-sm-10 col-12 text-center">                            
                            <button className="btn modalLinkBtn" type='link' onClick={showModal}>
                                <img className="img-fluid" src="./images/svg/homeModalLinkBg.svg" alt="no_img"/>
                            </button> 
                            < TribeModal 
                                visible = {isModalVisible}  
                                onClose= {() => setIsModalVisible(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourHelthBg">
                <CarouselBlock
                    Testimonials = {Testimonials}
                />
            </div>
        </HomeWrap>
    </>
)}
export default NonPregHome 