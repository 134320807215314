import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_APPOINTMENT_DETAILS,
    getAppointmentDetailsSuccess,
    getAppointmentDetailsFail
} from '../action/getAppointmentDetailsAction'

// api 
import getAppointmentDetailsApi from '../api/getAppointmentDetailsApi'

export function* getAppointmentDetailsSaga(action) {
    try {
        const response = yield call(() => getAppointmentDetailsApi(action.params))
        if (response.data.status === 1) {
            yield put(getAppointmentDetailsSuccess(response, action))
        } else {
            yield put(getAppointmentDetailsFail(response, action))
        }
    } catch (e) {
        yield put(getAppointmentDetailsFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_APPOINTMENT_DETAILS, getAppointmentDetailsSaga)
}