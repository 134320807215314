import {
    SAVE_PAYMENT_DATA 
} from '../action/savePaymentDataAction' 

const initialState = {
    apiState: "", // loading, success, error
    message: "",
    data: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_PAYMENT_DATA: 
            return {
                ...state,
                apiState: "Saved",
                data: action.params,
            }  
        default:
            return {
                ...state
            }
    }
}
