import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_TRIBE_ARTICLE_LIST,
    getTribeArticleListFail,
    getTribeArticleListSuccess
} from '../action/getTribeArticleListAction'

// api 
import getTribeArticleListApi from '../api/getTribeArticleListApi'

export function* getTribeArticleListSaga(action) {
    try {
        const response = yield call(() => getTribeArticleListApi(action.params))
        if (response.data.status === 1) {
            yield put(getTribeArticleListSuccess(response, action))
        } else {
            yield put(getTribeArticleListFail(response, action))
        }
    }
    catch (e) {
        yield put(getTribeArticleListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_TRIBE_ARTICLE_LIST, getTribeArticleListSaga)
}