import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PREG_HIGHLIGHT_APP_LIST,
    getPregHighlightAppListFail,
    getPregHighlightAppListSuccess,
} from '../action/getPregHighlightAppListAction'

// api 
import getPregHighlightAppListApi from '../api/getPregHighlightAppListApi'

export function* getPregHighlightAppListSaga(action) {
    try {
        const response = yield call(() => getPregHighlightAppListApi(action.params))
        if (response.data.status === 1) {
            yield put(getPregHighlightAppListSuccess(response, action))
        } else {
            yield put(getPregHighlightAppListFail(response, action))
        }
    }
    catch (e) {
        yield put(getPregHighlightAppListFail(e.response, action))
    } 
}

export default function* mySaga() {
    yield takeLatest(GET_PREG_HIGHLIGHT_APP_LIST, getPregHighlightAppListSaga)
}