import { call, put, takeLatest } from "redux-saga/effects";

//action
import{
    GET_BOOK_TEST,
    getBookTestFail,
    getBookTestSuccess
} from "../action/getBookTestAction";

//API
import getBookTestApi from "../api/getBookTestApi";

export function* getBookTestSaga(action){
    try{
        const response = yield call(() => getBookTestApi(action.params));
        if(response.data.status === 1){
            yield put(getBookTestSuccess({response: response.data, action}));
        }else {
            yield put(getBookTestFail({response, action}));
        }
    }catch(e){
        yield put(getBookTestFail({response: e.response, action}));
    }
}

export default function* mySaga(){
    yield takeLatest(GET_BOOK_TEST, getBookTestSaga);
}