// action
import {
    SAVE_MOOD_TRACK,
    SAVE_MOOD_TRACK_FAIL,
    SAVE_MOOD_TRACK_SUCCESS
} from '../action/saveMoodTrackAction'

import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: "",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch(action.type){
        case SAVE_MOOD_TRACK:
            return {
                ...state,
                apiState: "loading"
            }
        case SAVE_MOOD_TRACK_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case SAVE_MOOD_TRACK_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
            }
        default:
            return state
    }
}