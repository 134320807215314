import config from '../config'
import axios from 'axios'
import { getPatientToken } from '../utils'
export const getDoctorSearchApi = async (params) => {

    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}doctor/search`,
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }

    })
    return response
}

export default getDoctorSearchApi