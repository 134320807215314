import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import { EyeOutlined, HeartOutlined } from "@ant-design/icons";

import {
  BlockCard,
  BlockFooter,
  BlockIndex,
  BlockReverse,
  BlockTitle,
  FooterIconView,
  ListSupportImg,
  TribeImg,
  ViewLikes,
} from "./SupportBlockStyled";
import config from "../../config";

const SupportBlock = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [url, setUrl] = useState("");
  let img = [];

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  //   console.log(props);
  useEffect(() => {
    if (props.article.type === "Text Article") {
      setUrl("article");
    } else if (props.article.type === "Video Article") {
      setUrl("article");
    } else if (props.article.type === "Audio Article") {
      setUrl("article");
    }
  }, []);

  for (let i = 0; i < props.article.support_tribe.length; i++) {
    if (props.article.support_tribe[i].value === "Depression Support") {
      img.push("DcatB");
    } else if (props.article.support_tribe[i].value === "Anxiety Support") {
      img.push("anxietyB");
    } else if (props.article.support_tribe[i].value === "Grief Support") {
      img.push("griefB");
    } else if (props.article.support_tribe[i].value === "Infertility Support") {
      img.push("inferB");
    } else if (
      props.article.support_tribe[i].value === "Perinatal Loss Support"
    ) {
      img.push("perinB");
    } else if (props.article.support_tribe[i].value === "Postpartum Support") {
      img.push("postB");
    } else if (props.article.support_tribe[i].value === "Queer Support") {
      img.push("queerB");
    }
  }
  console.log(props.block_index);
  return (
    <>
      <BlockCard className="supportBlock">
        <Link
          to={{
            pathname: `/${url}/${props.article.slug}`,
            state: { is_bookmarked: props.article.is_bookmarked },
          }}
          // to={{
          // pathname: "/article/"+articleData.data.slug,
          // state: { "is_bookmarked": props.article.is_bookmarked }
          // }}
          style={{ textDecoration: "none", color: "#B45FB4" }}
        >
          <BlockIndex
          //   $mode={props.block_index}
          >
            <div>
              <ListSupportImg
                // $mode={props.block_index}
                src={
                  props.article
                    ? `${config.api.s3_url}article/${props.article.image_path}`
                    : `./images/rect3.svg`
                }
                alt="no"
              />
            </div>
            <Space
              direction="vertical"
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <BlockReverse>
                <Space>
                  {props.article.support_tribe ? (
                    <>
                      {img.map((item) => (
                        <TribeImg
                          className="mt-2"
                          src={process.env.PUBLIC_URL + `/images/${item}.svg`}
                          alt="icon-img"
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Space>
                <BlockTitle className="title mt-2">
                  <h5>
                    {props.article
                      ? props.article.title
                      : "This is temp header"}
                  </h5>
                </BlockTitle>
              </BlockReverse>

              <BlockFooter>
                <ViewLikes>
                  <FooterIconView>
                    <EyeOutlined />
                    <span>
                      {" "}
                      {props.article ? props.article.views : "0"} Views
                    </span>
                  </FooterIconView>
                  <FooterIconView>
                    <HeartOutlined />
                    <span>
                      {props.article ? props.article.likes : "17"} Likes
                    </span>
                  </FooterIconView>
                </ViewLikes>
              </BlockFooter>
            </Space>
          </BlockIndex>
        </Link>
      </BlockCard>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SupportBlock);
