export const GET_READ = "GET_READ"
export const GET_READ_FAIL = "GET_READ_FAIL"
export const GET_READ_SUCCESS = "GET_READ_SUCCESS"
export const GET_READ_RESET = "GET_READ_RESET"

export const getRead = (params) => {
    return { type: GET_READ, params }
}
export const getReadFail = (response) => {
    return { type: GET_READ_FAIL, response }
}
export const getReadSuccess = (response) => {
    return { type: GET_READ_SUCCESS, response }
}
export const getReadReset = () => {
    return { type: GET_READ_RESET }
}