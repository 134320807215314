import axios from "axios";
import config from "../config"
import { getPatientToken } from '../utils'

const getQuickSupportApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}quick-support/question-answer/Patient`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        },
    });

    return response;
};

export default getQuickSupportApi;
