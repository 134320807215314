import styled from "styled-components";
import { Button, Card, Radio, Typography } from "antd";
import { Link } from 'react-router-dom'

export const Container = styled.div`
    
`
export const Content = styled.div`
    
`
export const Left = styled.div` 
    
`
export const Right = styled.div` 
      
`

export const Ximg = styled.img` 
    
`

export const XTitle = styled(Typography.Title)`
   
`

export const Xdiv = styled.div` 
    display:flex; 
`

export const XText = styled.h5` 
    padding-top: 8.5%;  
    display:flex; 
`

export const RadioButton = styled(Radio.Button)` 
    width: 100%;  
    display: flex; 
    height: 30% !important;
    border-radius: 10px !important;
    padding: 1%;
    color: black;
    font-size: 14px;
    box-shadow: 1px 6px 22px #EDE8ED;
`

export const RadioGroup = styled(Radio.Group)` 

`

export const XButton = styled(Button)` 
    width: 200px;
    border: none;
 
    @media (min-width: 300px) and  (max-width: 580px){
        height: 40px !important;
        width: 280px;
        margin-top: -5%;
        margin-bottom: 12%;
        margin-left: 20%;
    } 
`