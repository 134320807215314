import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getBookmarkArticlesApi = async () => { 
  const response = await axios({
    url: `${config.api.base_url}article/bookmarked`,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  }); 
  return response;
};

export default getBookmarkArticlesApi;
