import { React, useState } from "react";
import { Rate } from "antd";
import {
  CarouselDiv,
  CarouselItem,
  DrtestmonialHead,
  DrQuoteLeft,
  DrQuoteRight,
} from "./CarouselBlockStyle";
import Carousel from "react-bootstrap/Carousel";

function CarouselBlock(props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-12">
        {props.Testimonials.length > 0 ? (
          <CarouselDiv className="carouselDiv" id="carouselWrap">
            <Carousel onSelect={handleSelect} className="carousel">
              <CarouselItem>
                <div className="customslides">
                  <div className="row" alt="First slide">
                    <div className="col-sm-12">
                      <DrtestmonialHead>
                        {props.Testimonials[1].patient_id.name}
                      </DrtestmonialHead>
                    </div>
                    <div className="col-sm-12 mt-1 mb-4 text-center">
                      <Rate
                        disabled
                        defaultValue={props.Testimonials[1].rating}
                      />
                    </div>
                    <div className="col-sm-12 text-center">
                      <p className="mb-0">
                        <DrQuoteLeft>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-left.svg"
                            }
                            alt=""
                          />
                        </DrQuoteLeft>{" "}
                        {props.Testimonials[1].comment}
                        <DrQuoteRight>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-right.svg"
                            }
                            alt=""
                          />
                        </DrQuoteRight>
                      </p>
                    </div>
                  </div>
                </div>
              </CarouselItem>
              <CarouselItem>
                <div className="customslides">
                  <div className="row" alt="First slide">
                    <div className="col-sm-12">
                      <DrtestmonialHead>
                        {props.Testimonials[2].patient_id.name}
                      </DrtestmonialHead>
                    </div>
                    <div className="col-sm-12 mt-1 mb-4 text-center">
                      <Rate
                        disabled
                        defaultValue={props.Testimonials[2].rating}
                      />
                    </div>
                    <div className="col-sm-12 text-center">
                      <p className="mb-0">
                        <DrQuoteLeft>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-left.svg"
                            }
                            alt=""
                          />
                        </DrQuoteLeft>{" "}
                        {props.Testimonials[2].comment}
                        <DrQuoteRight>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-right.svg"
                            }
                            alt=""
                          />
                        </DrQuoteRight>
                      </p>
                    </div>
                  </div>
                </div>
              </CarouselItem>
              <CarouselItem>
                <div className="customslides">
                  <div className="row" alt="First slide">
                    <div className="col-sm-12">
                      <DrtestmonialHead>
                        {props.Testimonials[0].patient_id.name}
                      </DrtestmonialHead>
                    </div>
                    <div className="col-sm-12 mt-1 mb-4 text-center">
                      <Rate
                        disabled
                        defaultValue={props.Testimonials[0].rating}
                      />
                    </div>
                    <div className="col-sm-12 text-center">
                      <p className="mb-0">
                        <DrQuoteLeft>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-left.svg"
                            }
                            alt=""
                          />
                        </DrQuoteLeft>
                        {props.Testimonials[0].comment}
                        <DrQuoteRight>
                          <img
                            className=""
                            src={
                              process.env.PUBLIC_URL +
                              "./images/svg/quotes-right.svg"
                            }
                            alt=""
                          />
                        </DrQuoteRight>
                      </p>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            </Carousel>
          </CarouselDiv>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CarouselBlock;
