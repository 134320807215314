import React, { useEffect, useState } from "react";
import {
  // NavLink,
  NavBlock,
  Image,
  LeftBlock,
  RightBlock,
  NavLinkL,
} from "./HeaderStyle";
import { isLogin } from "../../utils";
import { Modal, Dropdown, Menu, Avatar } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import config from "../../config";
// import { UserOutlined } from "@ant-design/icons";

const Header = () => {
  const [loc, setLoc] = useState("");
  let profile_pic = localStorage.getItem("profile_pic");
  if (profile_pic) {
    profile_pic = JSON.parse(localStorage.getItem("profile_pic"));
  }
  let gender = JSON.parse(localStorage.getItem("gender"));

  const SignOut = () => {
    Modal.confirm({
      content: "Are you sure you want to sign out?",
      onOk: () => {
        localStorage.removeItem("LilacPatientToken");
        localStorage.removeItem("LilacPatient");
        localStorage.removeItem("week");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("dob");
        localStorage.removeItem("phone");
        localStorage.removeItem("gender");
        localStorage.removeItem("tribe");
        localStorage.removeItem("interest");
        localStorage.removeItem("lmp_date");
        localStorage.removeItem("lmp_as_of");
        localStorage.removeItem("profile_pic");
        localStorage.removeItem("lmp");
        // console.log("i removed it");
        // window.location.reload();
        window.location.replace("/login");
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        {/* <NavLinkL
          className={
            loc === "/quick-support" ? "nav-link activePage" : "nav-link"
          }
          to="/quick-support"
        >
          Help
        </NavLinkL> */}
      </Menu.Item>
      <Menu.Item key="2">
        <NavLinkL
          className={loc === "/profile" ? "nav-link activePage" : "nav-link"}
          to="/profile"
        >
          Your Profile
        </NavLinkL>
      </Menu.Item>
      <Menu.Item key="3">
        <a className="nav-link" href="javascript:void(0)" onClick={SignOut}>
          Sign Out
        </a>
      </Menu.Item>
    </Menu>
  );

  // const history = useHistory();
  const location = useLocation();
  let currentPath = location.pathname;

  // useEffect(() => {
  //   if (currentPath === "/") {
  //     history.push("/#home");
  //   }
  // }, []);

  useEffect(() => {
    if (window.location.hash) {
      setLoc(window.location.hash);
    } else {
      setLoc(currentPath);
    }
  }, [currentPath]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = {
        ease: "#ease",
        health: "#health",
        home: "#home",
      };

      let closestSection = "#home";
      if (sections)
        for (const sectionId in sections) {
          const sectionOffset = document.querySelector(
            sections[sectionId]
          )?.offsetTop;
          // console.log(scrollPosition, sectionOffset, "scroll-position");

          if (scrollPosition >= sectionOffset) {
            closestSection = sections[sectionId];
            break;
          }
        }
      setLoc(closestSection);
    };
    if (
      location.hash === "#home" ||
      location.hash === "#ease" ||
      location.hash === "#health"
    ) {
      window.addEventListener("scroll", handleScroll);
    } else {
      return null;
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  return (
    <div className="webWeader">
      <div className="">
        <div className="row">
          <div className="col-sm-12 col-12">
            <nav className="navbar navbar-expand-xl navbar-light drHeader">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarToggler"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ background: "#fff" }}
              >
                <i class="fa fa-bars" aria-hidden="true"></i>
              </button>
              <div className="collapse navbar-collapse svc" id="navbarToggler">
                <NavLinkL to="/">
                  <Image
                    src={
                      process.env.PUBLIC_URL + "/images/lilac-insights-logo.svg"
                    }
                    alt="no"
                    className="mr-xl-3"
                  />
                </NavLinkL>
                <ul className="navbar-nav headerNavigation">
                  {/* <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <a
                      className={
                        loc === "#home" ? "nav-link activePage" : "nav-link"
                      }
                      href="/#home"
                    >
                      Home
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <NavLinkL
                      className={
                        loc === "#home"
                          ? "nav-link activePage"
                          : "nav-link"
                      }
                      to="/#home"
                    >
                     Home
                    </NavLinkL>
                  </li>
                  {/* <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <a
                      className={
                        loc === "#health" ? "nav-link activePage" : "nav-link"
                      }
                      href="/#health"
                    >
                      Your Health
                    </a>
                  </li> */}
                   <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <NavLinkL
                      className={
                        loc === "/yourhealth"
                          ? "nav-link activePage"
                          : "nav-link"
                      }
                      to="/yourhealth"
                    >
                      Your Health
                    </NavLinkL>
                  </li>
                  {/* <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <a
                      className={
                        loc === "#ease" ? "nav-link activePage" : "nav-link"
                      }
                      href="/#ease"
                    >
                      AtEase
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <NavLinkL
                      className={
                        loc === "/consultation"
                          ? "nav-link activePage"
                          : "nav-link"
                      }
                      to="/consultation"
                    >
                      {/* Consult An Expert */}
                      Consult
                    </NavLinkL>
                  </li>
                  <li
                    className="nav-item"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <NavLinkL
                      className={
                        loc === "/Appointments"
                          ? "nav-link activePage"
                          : "nav-link"
                      }
                      to="/Appointments"
                    >
                      Appointments
                    </NavLinkL>
                  </li>
                  {/* <li className="nav-item display-mobile">
                    <NavLinkL
                      className={
                        loc === "/quick-support"
                          ? "nav-link activePage"
                          : "nav-link"
                      }
                      to="/quick-support"
                    >
                      Help
                    </NavLinkL>
                  </li> */}
                  <li
                    className="nav-item display-mobile"
                    data-toggle="collapse"
                    data-target="#navbarToggler"
                  >
                    <NavLinkL
                      className={
                        loc === "/profile" ? "nav-link activePage" : "nav-link"
                      }
                      to="/profile"
                    >
                      Your Profile
                    </NavLinkL>
                  </li>
                  <li className="nav-item display-mobile">
                    <a
                      className="nav-link"
                      href="javascript:void(0)"
                      onClick={SignOut}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
                <div className="navRight mr-xl-3">
                  <Dropdown overlay={menu} className="display-laptop">
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Avatar
                        src={
                          profile_pic
                            ? `${config.api.s3_url}patient/${profile_pic}`
                            : gender === "female"
                            ? `/images/avatar_female.svg`
                            : gender === "male"
                            ? `/images/avatar_male.svg`
                            : `/images/avatar_transgender.svg`
                        }
                        className="header-pic"
                      />
                      <i className="fa fa-caret-down ml-1"></i>
                    </a>
                  </Dropdown>
                </div>
                <div
                  id="overlayCloseRight"
                  data-toggle="collapse"
                  data-target="#navbarToggler"
                >
                  &times;
                </div>
                {/* <ul className="navbar-nav">
                  <li className="nav-item">
                      <NavLinkL
                        className={
                          loc === "/quick-support"
                            ? "nav-link activePage"
                            : "nav-link"
                        }
                        to="/quick-support"
                      >
                        Help
                      </NavLinkL>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target="#navbarToggler"
                    >
                      <NavLinkL
                        className={
                          loc === "/profile"
                            ? "nav-link activePage"
                            : "nav-link"
                        }
                        to="/profile"
                      >
                        Your Profile
                      </NavLinkL>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="javascript:void(0)"
                        onClick={SignOut}
                      >
                        Sign out
                      </a>
                    </li>
                  </ul> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
