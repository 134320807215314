import styled from "styled-components";

export const Container = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;  
    width: 60%;  
    padding-Top: 10%;
    text-align: center;
`
export const Content = styled.div`
    object-fit: cover;
    margin: 2%;
`