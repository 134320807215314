import React from "react";
import { Typography, Card, Space } from "antd";

//STYLE
import {
  ListSpacing,
  TopContainer,
  ListHeading,
  SideCard,
  XImg,
  ListSideHead,
  MainImg,
  BackRight,
  BackLeft,
} from "./TextArticleStyle";

function TextArticle() {
  const { Text } = Typography;

  return (
    <>
      <BackRight alt="right-bg" src="./images/svg/doctorbgRight.svg" />
      <BackLeft alt="right-bg" src="./images/svg/doctorbgLeft.svg" />
      <TopContainer>
        <img
          className="align-center mobile-height "
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <ListSpacing className="container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="col-sm-9 col-12">
            <ListHeading>
              The Illustrated guide shows you how to take care of your Baby
            </ListHeading>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="items">
                <Text type="secondary listDatePara">17th May 2021 </Text>
                <i class="fa fa-circle listingDot" aria-hidden="true"></i>
                <Text type="secondary listDatePara">
                  <i class="fa fa-eye" aria-hidden="true"></i> 28 Views
                </Text>
              </div>
              <div style={{ flexWrap: "wrap-reverse" }}>
                <i class="fa fa-heart-o listIcons" aria-hidden="true"></i>
                <i class="fa fa-share listIcons" aria-hidden="true"></i>
                <i class="fa fa-bookmark-o listIcons" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>

        {/* <img className="listLeftBg hideMobile" src="./images/svg/list-helth-bg.png" alt="no"/>                
                <img class="listRightBg hideMobile" src="./images/svg/doctorbgRight.svg"/> */}
        <div className="row">
          <div className="col-sm-9 col-12 mt-4">
            <div className="row">
              <div className="col-sm-12 col-12">
                <MainImg
                  className="img-fluid"
                  src="./images/listenmain.svg"
                  alt=""
                />
                <p className="listingImgPara">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Faucibus dolor lacus amet ridiculus dignissim ut porta. Nullam
                  egestas interdum sed nec. Adipiscing enim present est, sit
                  varius congue. Amet ac nibh accumsan, congue nisl present. Id
                  eu malesuada vitae, imperdiet. Blandit sit quisque aliquet
                  tincidunt orci phasellus lorem arcu. Viverra quis nullam nulla
                  enim integer at ultrices facilisis eget. Sit metus, volutpat
                  odio risus, magna. Sed purus morbi a varius mattis egestas
                  duis posuere. Id pellentesque gravida quisque commodo
                  faucibus. Placerat solicitudin nulla hac proin parturient
                  pulvinar hendrerit varius. Nulla feugiat nunc volutpat
                  maecenas eu. Tortor, rhoncus suspendisse id justo sit.
                  Facilisis mattis a arcu, consequat enim. Faucibus sed
                  adipiscing semper mauris lectus quam.
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-12 mt-4">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="col-sm-12 col-12">
                <SideCard>
                  <XImg
                    className="img-fluid"
                    alt="example"
                    src="./images/side-img1.svg"
                  />
                  <div className="listSideWrap">
                    <ListSideHead className="mb-3">
                      The Illustrated guide shows you how to take care of your
                      Baby
                    </ListSideHead>
                    <div className="items">
                      <Text type="secondary listDatePara">17th May 2021 </Text>
                      <i class="fa fa-circle listingDot" aria-hidden="true"></i>
                      <Text type="secondary listDatePara">
                        <i class="fa fa-eye" aria-hidden="true"></i> 28 Views
                      </Text>
                    </div>
                  </div>
                </SideCard>
                <SideCard>
                  <XImg
                    className="img-fluid"
                    alt="example"
                    src="./images/side-img2.svg"
                  />
                  <div className="listSideWrap">
                    <ListSideHead className="mb-3">
                      The Illustrated guide shows you how to take care of your
                      Baby
                    </ListSideHead>
                    <div className="items">
                      <Text type="secondary listDatePara">17th May 2021 </Text>
                      <i class="fa fa-circle listingDot" aria-hidden="true"></i>
                      <Text type="secondary listDatePara">
                        <i class="fa fa-eye" aria-hidden="true"></i> 28 Views
                      </Text>
                    </div>
                  </div>
                </SideCard>
              </div>
            </div>
          </div>
        </div>
      </ListSpacing>
    </>
  );
}

export default TextArticle;
