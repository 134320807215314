import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ARTICLE_APP_LIST,
    getArticleAppListFail,
    getArticleAppListSuccess
} from '../action/getArticleAppListAction'

// api 
import getArticleAppListApi from '../api/getArticleAppListApi'

export function* getArticleAppListSaga(action) {
    try {
        const response = yield call(() => getArticleAppListApi(action.params))
        if (response.data.status === 1) {
            yield put(getArticleAppListSuccess(response, action))
        } else {
            yield put(getArticleAppListFail(response, action))
        }
    }
    catch (e) {
        yield put(getArticleAppListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ARTICLE_APP_LIST, getArticleAppListSaga)
}