import { call, put, takeLatest } from 'redux-saga/effects'

import {
    VALIDATE_COUPON,
    validateCouponSuccess,
    validateCouponFail
} from '../action/validateCouponAction'

import validateCouponApi from '../api/validateCouponApi'

export function* validateCouponSaga(action) {
    try {
        const response = yield call(() => validateCouponApi(action.params))
        if (response) {
            yield put(validateCouponSuccess(response, action))
        }
    }
    catch (e) {
        yield put(validateCouponFail(e.response, action))
    }
}

export default function* MySaga() {
    yield takeLatest(VALIDATE_COUPON, validateCouponSaga);
}