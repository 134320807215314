import axios from "axios";
import config from "../config";
import { getPatientToken } from "../utils";

const getBookTestApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}test?search=${params.test_name}&category=${params.test_category}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    });
    return response;
}
export default getBookTestApi;
