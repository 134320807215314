import axios from "axios";
import config from "../config"

const getAppointmentPaymentApi = async (params) => {
  const response = await axios({
    url: `${config.api.base_url}appointment-payment/${params.id}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
};

export default getAppointmentPaymentApi;
