import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { login } from '../../action/loginAction'
import moment from "moment";
import imageCompression from "browser-image-compression";
import {
  Form,
  Input,
  Select,
  Typography,
  Radio,
  Space,
  notification,
  Button,
  Checkbox,
  Modal,
} from "antd";
import {
  XImg,
  XimgDiv,
  XText,
  Xdiv,
  RadioButton,
  Ximg,
  XFDimg,
  BadgeImg,
  XMTitle,
  XAvatar,
  RadioButton1,
  XTdiv,
  XBdiv,
  FormItem,
  XButton,
  XDatePicker,
  Xfooter,
  BgPic1,
  BgPic2,
  ProfileHeader,
  ContainsProfile,
} from "./PatientProfileStyle";

//component
import { PDecorationIcon2, PDecorationIcon1 } from "../../../src/Customicons";
import axios from "axios";
import config from "../../config";
import {
  getPatientToken,
  getS3SingedUrl,
  validateImagePdfFile,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import "./PatientProfile.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

const PatientProfile = (props) => {
  // let interest = [];
  let fullName = JSON.parse(localStorage.getItem("name"));
  let name = `${fullName.firstName} ${fullName.lastName}`;
  let email = JSON.parse(localStorage.getItem("email"));
  let phone = JSON.parse(localStorage.getItem("phone"));
  let dob = JSON.parse(localStorage.getItem("dob"));
  let conceive_status = JSON.parse(localStorage.getItem("conceive_status"));
  let lmp_date = JSON.parse(localStorage.getItem("lmp"));
  let tribe = JSON.parse(localStorage.getItem("tribe"));
  let interest = JSON.parse(localStorage.getItem("interest"));
  let profile_pic = localStorage.getItem("profile_pic");
  if (profile_pic) {
    profile_pic = JSON.parse(localStorage.getItem("profile_pic"));
  }
  let gender = JSON.parse(localStorage.getItem("gender"));
  const hiddenFileInput = React.useRef(null);
  let obj = {};
  // console.log(profile_pic);

  const [form] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const [file, setFile] = useState([]);
  const [value, setValue] = useState([]);
  const [ButtonData, setButtonData] = useState({
    ease: { BgColor: "#FFFFFF", click: false },
    wellness: { BgColor: "#FFFFFF", click: false },
    onco: { BgColor: "#FFFFFF", click: false },
    prego: { BgColor: "#FFFFFF", click: false },
  });
  const [value1, setValue1] = useState([]);
  const [TribeData, setTribeData] = useState({
    depression: { BgColor: "#FFFFFF", click: false },
    anxiety: { BgColor: "#FFFFFF", click: false },
    grief: { BgColor: "#FFFFFF", click: false },
    infirtility: { BgColor: "#FFFFFF", click: false },
    perinatal: { BgColor: "#FFFFFF", click: false },
    postpartum: { BgColor: "#FFFFFF", click: false },
    queer: { BgColor: "#FFFFFF", click: false },
  });
  const [ConceiveValue, setConceiveValue] = useState("");
  const [ConceiveData, setConceiveData] = useState({
    "I am trying to get pregnant": { BgColor: "#FFFFFF", click: false },
    "I am pregnant": { BgColor: "#FFFFFF", click: false },
    "I have Delivered a Baby": { BgColor: "#FFFFFF", click: false },
  });
  const [clicked, setClicked] = useState(false);
  const [cli, setCli] = useState(false);
  const category = [
    { label: "I am trying to get pregnant", value: "Looking to Conceived" },
    { label: "I am pregnant", value: "Already Conceived" },
    { label: "I have Delivered a Baby", value: "Become A Mother" },
  ];
  const [cansvalue, setCansValue] = useState(conceive_status);
  const [compressedImage, setCompressedImage] = useState(null);
  const [image, setImage] = useState();
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const onFinish = (values) => {
    // console.log('Received values of form: ', values);
    submitHandler(values);
  };
  const [pictureModal, setPictureModal] = useState(false);

  const disabledLmpDate = (current) => {
    const today = moment().startOf("day");
    const fortyWeeksAgo = moment().subtract(280, "days").startOf("day");
    return current && (current < fortyWeeksAgo || current > today);
  };

  function disabledDate(current) {
    // let customDate =  moment("20040620", "YYYY-MM-DD").fromNow();
    let customDate = moment();
    return current &&
      moment().diff(
        moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"),
        "days"
      ) > 6574
      ? false
      : true;
  }
  function setIntData(array) {
    if (!array) return;
    let obj = {};
    let element;
    for (let i = 0; i < array.length; i++) {
      element = array[i];
      if (element === "At Ease") {
        obj = { ...Click("ease"), ...obj };
      } else if (element === "Pregnancy & Reproductive Wellness") {
        obj = { ...Click("prego"), ...obj };
      } else if (element === "Oncogenomic") {
        obj = { ...Click("onco"), ...obj };
      } else if (element === "General Wellness") {
        obj = { ...Click("wellness"), ...obj };
      }
    }
    setButtonData({ ...ButtonData, ...obj });
    console.log("ButtonData, obj => ", ButtonData, obj);
  }

  function setTriData(array) {
    let obj = {};
    let element;
    for (let i = 0; i < array.length; i++) {
      element = array[i];
      if (element === "Depression Support") {
        obj = { ...Click1("depression"), ...obj };
      } else if (element === "Anxiety Support") {
        obj = { ...Click1("anxiety"), ...obj };
      } else if (element === "Infertility Support") {
        obj = { ...Click1("infirtility"), ...obj };
      } else if (element === "Grief Support") {
        obj = { ...Click1("grief"), ...obj };
      } else if (element === "Perinatal Loss Support") {
        obj = { ...Click1("perinatal"), ...obj };
      } else if (element === "Postpartum Support") {
        obj = { ...Click1("postpartum"), ...obj };
      } else if (element === "Queer Support") {
        obj = { ...Click1("queer"), ...obj };
      }
      // console.log(element)
    }
    setTribeData({ ...TribeData, ...obj });
    // console.log(TribeData,obj)
  }
console.log('value11111',value1.toString())
  function onChange(e) {
    var interest = e;
    console.log('interestinterestinterest----------------',interest)
    setValue1(interest);
    if(interest.length === 0){
      setCansValue('')
      form.setFieldsValue({conceive_status:''})
      form.setFieldsValue({lmp:''})
      setValue1(' ')
    }
    // console.log(interest)
    if (interest === "At Ease") {
      obj = { ...Click("ease"), ...obj };
    } else if (interest === "Pregnancy & Reproductive Wellness") {
      obj = { ...Click("prego"), ...obj };
    } else if (interest === "Oncogenomic") {
      obj = { ...Click("onco"), ...obj };
    } else if (interest === "General Wellness") {
      obj = { ...Click("wellness"), ...obj };
    }
    setButtonData({ ...ButtonData, ...obj });
    // setData(interest)
  }

  function onTribeChange(e) {
    var tribe = e;
    setValue(tribe);
    console.log("tribe ", value);
    let obj = {};
    console.log("tribe clicked", tribe);
    if (tribe === "Depression Support") {
      obj = { ...Click1("depression"), ...obj };
    } else if (tribe === "Anxiety Support") {
      obj = { ...Click1("anxiety"), ...obj };
    } else if (tribe === "Infertility Support") {
      obj = { ...Click1("infirtility"), ...obj };
    } else if (tribe === "Grief Support") {
      obj = { ...Click1("grief"), ...obj };
    } else if (tribe === "Perinatal Loss Support") {
      obj = { ...Click1("perinatal"), ...obj };
    } else if (tribe === "Postpartum Support") {
      obj = { ...Click1("postpartum"), ...obj };
    } else if (tribe === "Queer Support") {
      obj = { ...Click1("queer"), ...obj };
    }
    setTribeData({ ...TribeData, ...obj });
    // console.log(TribeData)
  }

  function onConceiveChange(e) {
    var conceive_status;
    if (e.length === 1) {
      conceive_status = e;
    }

    console.log("conceive", e);
    setConceiveValue(conceive_status);
    if (conceive_status === "I am trying to get pregnant") {
      //obj = { ...conceiveClick("I am trying to get pregnant"), ...obj }
    } else if (conceive_status === "I am pregnant") {
      //obj = { ...conceiveClick("I am pregnant"), ...obj }
    }
    setConceiveData({ ...ConceiveData, ...obj });
  }

  function Click(interest) {
    let clicked;
    let BtnObj = { ...ButtonData };
    if (interest === "ease") {
      clicked = BtnObj.ease.click;
      if (!clicked) {
        return {
          ease: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          ease: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (interest === "wellness") {
      clicked = BtnObj.wellness.click;
      if (!clicked) {
        return {
          wellness: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          wellness: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (interest === "onco") {
      clicked = BtnObj.onco.click;
      if (!clicked) {
        return {
          onco: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          onco: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (interest === "prego") {
      clicked = BtnObj.prego.click;
      if (!clicked) {
        return {
          prego: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          prego: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    }
    console.log("ButtonData interest =>", BtnObj);
  }

  function Click1(tribe) {
    let clicked;
    let BtnObj = { ...TribeData };
    if (tribe === "depression") {
      clicked = BtnObj.depression.click;
      if (!clicked) {
        return {
          depression: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          depression: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "anxiety") {
      clicked = BtnObj.anxiety.click;
      if (!clicked) {
        return {
          anxiety: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          anxiety: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "grief") {
      clicked = BtnObj.grief.click;
      if (!clicked) {
        return {
          grief: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          grief: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "infirtility") {
      clicked = BtnObj.infirtility.click;
      if (!clicked) {
        return {
          infirtility: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          infirtility: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "perinatal") {
      clicked = BtnObj.perinatal.click;
      if (!clicked) {
        return {
          perinatal: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          perinatal: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "postpartum") {
      clicked = BtnObj.postpartum.click;
      if (!clicked) {
        return {
          postpartum: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          postpartum: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "queer") {
      clicked = BtnObj.queer.click;
      if (!clicked) {
        return {
          queer: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          queer: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    }
  }

  const handleChange = (params) => {
    setConceiveValue(params.target.value);
    setCansValue(params.target.value);
    form.setFieldsValue({"conceive_status":params.target.value})
    console.log("toConceiveData", params.target.value);
    let clicked;
    let BtnObj = { ...ConceiveData };
    setClicked(false);
    if (params.target.value === "Looking to Conceive") {
      clicked = BtnObj["I am trying to get pregnant"].click;
      clicked = !clicked;
      setClicked(false);
      if (clicked === true) {
        setConceiveData({
          ...ConceiveData,
          ["I am trying to get pregnant"]: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
          ["I am pregnant"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      } else {
        setConceiveData({
          ...ConceiveData,
          ["I am trying to get pregnant"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    } else if (params.target.value === "Already Conceived") {
      console.log("ConceiveData", ConceiveData);
      clicked = ConceiveData["I am pregnant"].click;
      clicked = !clicked;
      setClicked(true);
      if (clicked === true) {
        // alert("AlreadyConceive")
        setConceiveData({
          ...ConceiveData,
          ["I am pregnant"]: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
          ["I am trying to get pregnant"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      } else {
        setConceiveData({
          ...ConceiveData,
          ["I am pregnant"]: {
            BgColor: "#FFFFFF",
            click: false,
          },
        });
      }
    }
  };

  // function conceiveClick(params) {
  //     console.log("toConceiveData",ConceiveData)
  //     let clicked;
  //     let BtnObj = {...ConceiveData}
  //     if(params === 'toConceive'){
  //         clicked = BtnObj.toConceive.click
  //         clicked = !clicked
  //         if(!clicked){
  //             return{
  //                 'toConceive': {
  //                     BgColor: 'linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)',
  //                     click: true
  //                 }
  //             }

  //         }
  //         else{
  //             return {
  //                 'toConceive': {
  //                     BgColor: "#FFFFFF",
  //                     click: false
  //                 }
  //             }
  //         }
  //     }
  //     else if (params === 'conceived'){
  //     console.log("ConceiveData",ConceiveData)
  //         clicked = BtnObj.conceived.click
  //         if(!clicked){
  //             return{
  //                 'conceived': {
  //                     BgColor: 'linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)',
  //                     click: true
  //                 }
  //             }
  //         }
  //         else{
  //             return{
  //                 'conceived': {
  //                     BgColor: "#FFFFFF",
  //                     click: false
  //                 }
  //             }
  //         }
  //     }
  // }
  const handleFileDelete = async () => {
    try {
      await axios.post(
        `${config.api.base_url}patient/remove-profile-pic`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }
      );
      localStorage.removeItem("profile_pic");
      setPictureModal(false);
    } catch (error) {
      alert("Profile picture could not be deleted.");
      setPictureModal(false);
    }
  };

  const handleFileUpd = (event) => {
    hiddenFileInput.current.click();
    setPictureModal(false);
  };

  const handlefileUpdChange = async (event) => {
    try {
      const fileUploaded = event.target.files[0];
      const maxSizeMB = 10;
      const minSizeMB = 1;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      if (!fileUploaded) {
        return;
      } else if (fileUploaded.size < minSizeMB * 1024 * 1024) {
        setFile([fileUploaded]);
        return;
      } else if (fileUploaded.size > maxSizeMB * 1024 * 1024) {
        alert("Original image size exceeds 10 MB limit.");
        return;
      } else if (
        fileUploaded.size > minSizeMB * 1024 * 1024 &&
        fileUploaded.size < maxSizeMB * 1024 * 1024
      ) {
        const compressedImageBlob = await imageCompression(
          fileUploaded,
          options
        );
        if (compressedImageBlob.size > 1 * 1024 * 1024) {
          alert("Compressed image size exceeds 1 MB limit.");
          return;
        } else {
          const compressedFile = new File(
            [compressedImageBlob],
            fileUploaded.name,
            {
              type: compressedImageBlob.type,
            }
          );
          setFile([compressedFile]);
        }
      }
    } catch (error) {
      alert("Error compressing Image: ", error);
    }
  };

  const upload = async () => {
    if (file) {
      let file_path_url = [];
      const promises = file.map(async (file) => {
        // console.log("iam here", file)
        let filename = uuidv4();
        console.log(filename);
        let imageValidationRes = validateImagePdfFile(file);
        // console.log(imageValidationRes,"-imageValidationRes")
        if (imageValidationRes.status) {
          let s3UrlResImage = await getS3SingedUrl(
            filename,
            imageValidationRes.ext,
            file,
            "patient"
          );
          // console.log(s3UrlResImage, "-s3UrlResImage")
          await axios.put(s3UrlResImage.url, file);
          let data = {
            title: filename,
            file_path: `${filename}.${imageValidationRes.ext}`,
          };
          file_path_url.push(data);
          // console.log(file_path_url,"-file_path_url")
        } else {
          alert("Select file type JPG, PNG or PDF file.");
          return;
        }
      });
      await Promise.all(promises);
      return file_path_url;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("conceive_status =>", conceive_status);
    console.log(
      "conceive_status local storage",
      JSON.parse(localStorage.getItem("conceive_status"))
    );
    console.log(
      "interest local storage",
      JSON.parse(localStorage.getItem("interest"))
    );
    let temp_lmp_date = lmp_date == "Invalid date" ? null : moment(lmp_date);
    form.setFieldsValue({
      user: name,
      email: email,
      phone: phone,
      DOB: moment(dob),
      lmp: temp_lmp_date,
      interest: interest,
      tribe: tribe,
      conceive_status: conceive_status,
    });
    let alrClicked =
      conceive_status === "Already Conceived" ? setClicked(true) : false;
    setIntData(interest);
    setTriData(tribe);
    //conceiveClick(conceive_status)
    console.log("interest =>", interest);
    interest.map((item) => {
      if (item === "Pregnancy & Reproductive Wellness") {
        setCli(true);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  const submitHandler = async (eve) => {
    console.log("eve2222222222222222222222222222222", eve);
    let interest = [];
    let tribe = [];
    let week = 0;
    const file = await upload();
    // console.log(file)
    console.log("interest", interest);

    if (ButtonData.ease.click === true) {
      interest.push("At Ease");
    }
    if (ButtonData.prego.click === true) {
      interest.push("Pregnancy & Reproductive Wellness");
    }
    if (ButtonData.onco.click === true) {
      interest.push("Oncogenomic");
    }
    if (ButtonData.wellness.click === true) {
      interest.push("General Wellness");
    }

    let saveProfileParams;
    if (file.length > 0) {
      // console.log(file[0].file_path, "-in pc sv")
      console.log("if ");
      saveProfileParams = {
        name: eve.user,
        gender: "",
        dob: moment(eve.DOB).format("YYYY-MM-DD"),
        interest: value1.toString(),
        tribe: value.toString(),
        conceive_status: cansvalue,
        lmp:eve.lmp ? moment(eve.lmp).format("YYYY-MM-DD") : '',
        profile_pic: file[0].file_path,
        lmp_as_of:eve.lmp ? moment(eve.lmp).format("YYYY-MM-DD") : '',
      };
    } else {
      console.log("else");
      saveProfileParams = {
        name: eve.user,
        gender: "",
        dob: moment(eve.DOB).format("YYYY-MM-DD"),
        interest: value1.toString(),
        tribe: value.toString(),
        conceive_status: cansvalue,
        profile_pic: profile_pic,
        lmp: eve.lmp ? moment(eve.lmp).format("YYYY-MM-DD") : '',
        //"lmp_as_of":moment(eve.lmp).format("YYYY/MM/DD")
        lmp_as_of: eve.lmp ? moment(eve.lmp).format("YYYY-MM-DD") : '',
      };
    }

    console.log("before calling axios", saveProfileParams);
    // return
    // alert("stop")
    axios({
      url: `${config.api.base_url}patient/profile/save`,
      method: "post",
      data: saveProfileParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    })
      .then((respons) => {
        console.log("respons data=>:::", respons.data);
        if (respons.data.message == "Profile updated successfully.") {
          notification["success"]({
            message: respons.data.message,
            placement: "bottomRight",
          });
         
          form.setFieldsValue({
            user: respons.data.data.name,
            email: respons.data.data.email,
            phone: respons.data.data.phone,
            DOB: moment(respons.data.data.dob),
            lmp: moment(respons.data.data.lmp_date),
            interest: respons.data.data.interest,
            tribe: respons.data.data.tribe,
            conceive_status: respons.data.data.conceive_status,
          });
          let lmp_d = moment(respons.data.data.lmp);
          let lmp_date = lmp_d.format("YYYY-MM-DD");
          localStorage.setItem("lmp", JSON.stringify(lmp_date));
          if (lmp_date != null) {
            var today = moment();
            var lmp_date1 = moment(lmp_date);
            week = today.diff(lmp_date1, "week") + 1;
          }
          localStorage.setItem(
            "lmp_date",
            JSON.stringify(moment(respons.data.data.lmp_date))
          );
          if (respons.data.data.profile_pic != undefined) {
            localStorage.setItem(
              "profile_pic",
              JSON.stringify(respons.data.data.profile_pic)
            );
          }
          if (
            respons.data.data.interest.includes(
              "Pregnancy & Reproductive Wellness"
            )
          ) {
            localStorage.setItem("week", JSON.stringify(week));
          } else {
            localStorage.setItem("week", 0);
          }
          localStorage.setItem("name", JSON.stringify(respons.data.data.name));
          localStorage.setItem(
            "email",
            JSON.stringify(respons.data.data.email)
          );
          localStorage.setItem("dob", JSON.stringify(respons.data.data.dob));
          localStorage.setItem(
            "phone",
            JSON.stringify(respons.data.data.phone)
          );
          localStorage.setItem(
            "gender",
            JSON.stringify(respons.data.data.gender)
          );
          localStorage.setItem(
            "tribe",
            JSON.stringify(respons.data.data.tribe)
          );
          localStorage.setItem(
            "interest",
            JSON.stringify(respons.data.data.interest)
          );
          // if (respons.data.data.interest === "Pregnancy & Reproductive Wellness") {
          //     localStorage.setItem("conceive_status", JSON.stringify(respons.data.data.conceive_status))
          // } else {
          //     localStorage.removeItem("conceive_status")
          // }
          localStorage.setItem(
            "conceive_status",
            JSON.stringify(respons.data.data.conceive_status)
          );

          window.location.replace("/");
        }
      })
      .catch((error) => {
        // console.log(error);
        notification["error"]({
          message: error.data.message,
          placement: "bottomRight",
        });
      });
    // Todo : refresh page
  };

  return (
    <>
      <img
        alt="right-bg"
        className="profileRightBg"
        src="./images/svg/doctorbgRight.svg"
      />
      <img
        alt="right-bg"
        className="profileLeftBg"
        src="./images/svg/doctorbgLeft.svg"
      />
      <div className="pageWrap">
        <div className="container">
          <ProfileHeader>Edit Profile</ProfileHeader>
          <ContainsProfile>
            <div className="logpic">
              {file.length > 0 ? (
                <img
                  className="profile-pic"
                  src={URL.createObjectURL(file[0])}
                  alt={process.env.PUBLIC_URL + "./images/Profile.svg"}
                />
              ) : (
                <img
                  className="profile-pic"
                  src={
                    profile_pic
                      ? `${config.api.s3_url}patient/${profile_pic}`
                      : gender === "female"
                      ? `/images/avatar_female.svg`
                      : gender === "male"
                      ? `/images/avatar_male.svg`
                      : `/images/avatar_transgender.svg`
                  }
                  alt={process.env.PUBLIC_URL + "/images/Profile.svg"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    switch (gender) {
                      case "male":
                        currentTarget.src = "/images/avatar_male.svg";
                        break;
                      case "female":
                        currentTarget.src = "/images/avatar_female.svg";
                        break;
                      default:
                        currentTarget.src = "/images/avatar_transgender.svg";
                    }
                  }}
                />
              )}
              {}
              <input
                type={"file"}
                style={{ display: "none" }}
                ref={hiddenFileInput}
                onChange={handlefileUpdChange}
                accept="image/*"
              />
              <img
                className="img-fluid cliimg"
                src={process.env.PUBLIC_URL + "/images/Pbadge.svg"}
                onClick={() => setPictureModal(true)}
                alt=""
              />
              <Modal
                visible={pictureModal}
                onCancel={() => setPictureModal(false)}
                footer={null}
              >
                <h4>Profile Picture</h4>
                <p>Select from below options</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                      background:
                        "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
                      color: "#ffffff !important",
                      borderRadius: "16px",
                    }}
                    onClick={handleFileUpd}
                  >
                    Update profile picture
                  </Button>
                  <Button
                    style={{
                      width: "100%",
                      marginTop: 10,
                      color: "#a54ea5",
                      border: "1px solid #a54ea5",
                      borderRadius: "16px",
                    }}
                    onClick={handleFileDelete}
                  >
                    Delete profile picture
                  </Button>
                </div>
              </Modal>
            </div>

            <Form form={form} onFinish={onFinish} requiredMark={false}>
              <div className="row">
                <div className="col-md-6">
                  <Title level={5}>Your Name</Title>

                  <FormItem
                    className="inputBox col-12"
                    name={["user"]}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z\s]*$/),
                        message: "Enter a valid Name",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <Title level={5}>Your Date of Birth</Title>
                  <FormItem
                    className="inputBox col-12"
                    name={["DOB"]}
                    rules={[{ type: "date", required: true }]}
                  >
                    <XDatePicker
                      disabledDate={disabledDate}
                      size="large"
                      format="DD-MM-YYYY"
                    />
                  </FormItem>
                </div>
                <div className="col-md-6 offset-col-6">
                  {gender == "female" && conceive_status == "I am pregnant" ? (
                    <>
                      {lmp_date == "Invalid date" ? (
                        <></>
                      ) : (
                        <>
                          <Title level={5}>Last Menstrual Date</Title>
                          <FormItem
                            className="inputBox col-12"
                            name={["lmp"]}
                            rules={[{ type: "date", required: true }]}
                          >
                            <XDatePicker
                              size="large"
                              disabledDate={disabledLmpDate}
                              format="DD-MM-YYYY"
                              defaultPickerValue={moment().subtract(
                                280,
                                "days"
                              )}
                            />
                          </FormItem>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <h1 className="profileHeading2" level={1}>
                  Contact Details
                </h1>
                <div className="col-md-6">
                  <Title level={5}>Email</Title>
                  <FormItem
                    className="inputBox col-12"
                    name={["email"]}
                    rules={[{ type: "email", required: true }]}
                  >
                    <Input size="large" disabled />
                  </FormItem>
                </div>
                <div className="col-md-6">
                  <Title level={5}>Mobile</Title>
                  <FormItem
                    className="inputBox col-12"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input size="large" disabled />
                  </FormItem>
                </div>
                {
                  gender == "female" && (
<h1 className="profileHeading2" level={1}>
                  Interest
                </h1>
                  )
                }
                
                <div className="col-md-12">
                  <FormItem
                    name="interest"
                    rules={[
                      { required: false, message: "Please pick an interest!" },
                    ]}
                  >
                    <Checkbox.Group defaultValue={value1} onChange={onChange}>
                      {/* requested by lilac */}
                      {/* <Checkbox
                        value="At Ease"
                        onClick={() => {
                          obj = Click("ease");
                          setButtonData((old) => {
                            return { ...old, ...obj };
                          });
                        }}
                        style={{ border: "none" }}
                      >
                        AtEase
                      </Checkbox> */}
                      {/* requested by lilac */}
                      {/* <Checkbox
                        value="General Wellness"
                        onClick={() => {
                          obj = Click("wellness");
                          setButtonData((old) => {
                            return { ...old, ...obj };
                          });
                        }}
                        style={{ border: "none" }}
                      >
                        General Wellness
                      </Checkbox>
                      <Checkbox
                        value="Oncogenomic"
                        onClick={() => {
                          obj = Click("onco");
                          setButtonData((old) => {
                            return { ...old, ...obj };
                          });
                        }}
                        style={{ border: "none" }}
                      >
                        Oncogenomic
                      </Checkbox> */}

                      {gender == "female" ? (
                        <Checkbox
                          value="Pregnancy & Reproductive Wellness"
                          onChange={(e) => {
                            if (e.target.checked === false) {
                              // setCansValue("");
                              // form.setFieldsValue({"conceive_status":""})
                            } else {
                              
                              // if (conceive_status) {
                              //   setCansValue(conceive_status);
                              // }
                            }
                          }}
                          onClick={() => {
                            obj = Click("prego");
                            setButtonData((old) => {
                              return { ...old, ...obj };
                            });
                            if (cli == true) {
                              setCli(false);
                            } else {
                              setCli(true);
                            }
                          }}
                          style={{ border: "none" }}
                        >
                          Pregnancy & Reproductive Wellness
                        </Checkbox>
                      ) : null}
                    </Checkbox.Group>
                  </FormItem>
                </div>
                {/* {interest === "Pregnancy & Reproductive Wellness" ? <> */}
                {cli && (
                  <>
                    <h1 className="profileHeading2" level={1}>
                      Your Stage
                    </h1>
                    <div className="col-md-12">
                      <FormItem
                        name="conceive_status"
                        rules={[
                          {
                            required: true,
                            message: "Please select a Your Stage",
                          },
                        ]}
                      >
                        {/*<Checkbox.Group*/}

                        {/*    defaultValue={conceive_status}*/}
                        {/*    size='large' buttonStyle="solid">*/}
                        {/*    <Checkbox*/}
                        {/*        value="I am trying to get pregnant"*/}
                        {/*        onClick={() => conceiveClick('I am trying to get pregnant')}*/}
                        {/*            >*/}
                        {/*            I am trying to get pregnant*/}
                        {/*        </Checkbox>*/}
                        {/*    <Checkbox*/}
                        {/*        value="I am pregnant"*/}
                        {/*        onClick={() => conceiveClick('I am pregnant')}*/}
                        {/*            >*/}
                        {/*            I am pregnant*/}
                        {/*        </Checkbox>*/}

                        {/*</Checkbox.Group>*/}
                        {category.map((item) => {
                          return (
                            <div
                              className="ant-checkbox-group"
                              id="menstrualDetail"
                            >
                              <Checkbox
                                style={{ border: "none" }}
                                key={item.label}
                                onChange={handleChange}
                                checked={item.value == cansvalue}
                                value={item.value}
                              >
                                {item.label}
                              </Checkbox>
                            </div>
                          );
                        })}
                      </FormItem>
                    </div>
                    {clicked ? (
                      <>
                        <div className="col-md-6 offset-col-6">
                          <Title level={5}>Last Menstrual Date</Title>
                          <FormItem
                            className="inputBox col-12"
                            name={["lmp"]}
                            rules={[{ type: "date", required: true }]}
                          >
                            <XDatePicker
                              size="large"
                              disabledDate={disabledLmpDate}
                              format="DD-MM-YYYY"
                              defaultPickerValue={moment().subtract(
                                280,
                                "days"
                              )}
                            />
                          </FormItem>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {/* requested by lilac */}
                {/* <h1 className="profileHeading2" level={1}>
                  Your Tribes
                </h1>
                <div className="col-md-12">
                  <FormItem
                    name="tribe"
                    // rules={[{ required: true, message: 'Please pick a Tribe!' }]}
                  >
                    <Checkbox.Group
                      size="large"
                      buttonStyle="solid"
                      defaultValue={value}
                      onChange={onTribeChange}
                    >
                      <Checkbox
                        style={{ border: "none" }}
                        value="Depression Support"
                        onClick={onTribeChange}
                      >
                        Depression Support
                      </Checkbox>
                      <Checkbox
                        value="Anxiety Support"
                        onClick={onTribeChange}
                        style={{ border: "none" }}
                      >
                        Anxiety Support
                      </Checkbox>
                      <Checkbox
                        value="Grief Support"
                        onClick={onTribeChange}
                        style={{ border: "none" }}
                      >
                        Grief Support
                      </Checkbox>
                      <Checkbox
                        value="Infertility Support"
                        onClick={onTribeChange}
                        style={{ border: "none" }}
                      >
                        Infertility Support
                      </Checkbox>
                      <Checkbox
                        value="Perinatal Loss Support"
                        onClick={onTribeChange}
                        style={{ border: "none" }}
                      >
                        Perinatal Loss Support
                      </Checkbox>
                      <Checkbox
                        onClick={onTribeChange}
                        value="Postpartum Support"
                        style={{ border: "none" }}
                      >
                        Postpartum Support
                      </Checkbox>
                      <Checkbox
                        value="Queer Support"
                        onClick={onTribeChange}
                        style={{ border: "none" }}
                      >
                        Queer Support
                      </Checkbox>
                    </Checkbox.Group>
                  </FormItem>
                </div> */}
                {/* requested by lilac */}
                <Form.Item className="col-12 text-center">
                  <br />
                  <br />
                  <button
                    size="large"
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    className="themeBtn"
                  >
                    Save
                  </button>
                </Form.Item>
              </div>
            </Form>
          </ContainsProfile>
        </div>
      </div>
      {scrollTopBtn && <ScrollToTop />}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
//the tribe functions are not set yet
//the intrests still have a bug
//api is not integrated
//tribe click function has problems
