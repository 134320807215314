import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Spin } from "antd";
import { connect } from "react-redux";
import { s3_cdn } from "../../utils";
import Sticky from "react-sticky-el";

//COMPONENTS
import Header from "../../component/Header/Header";
import {
  getArticleBySlug,
  getArticleBySlugReset,
} from "../../action/getArticleBySlugAction";
import {
  saveArticleLike,
  saveArticleLikeReset,
} from "../../action/saveArticleLikeAction";
import {
  saveArticleBookmark,
  saveArticleBookmarkReset,
} from "../../action/saveArticleBookmarkAction";
import {
  HeartTwoTone,
  EyeOutlined,
  HeartFilled,
  CopyOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Container,
  ContentBlock,
  Title,
  Image,
  Description,
  LikeBlock,
  ShareBlock,
  ViewBlock,
  LeftBlock,
  RightBlock,
  ContentAction,
  Footer,
  ShareLink,
  BookmarkBlock,
  Likes,
  ListHeading,
  ArticleTitle,
  ArticleViews,
  PageContainer,
} from "./ArticleStyle";
import AudioArticle from "../../component/AudioArticle/AudioArticle";
import VideoArticle from "../../component/VideoArticle/VideoArticle";
import ImagesArticle from "../../component/ImagesArticle/ImagesArticle";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { FaCopy } from "react-icons/fa";
import "./ArticleStyle.css";
//STYLE
import { BlankSpace } from "../../component/Xcomponent";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

export const Article = (props) => {
  console.log("article/:slug");
  //CONSTANTS
  const {
    getArticleBySlug,
    getArticleBySlugReset,
    getArticleBySlugState,
    saveArticleLike,
    saveArticleLikeReset,
    saveArticleBookmark,
    saveArticleBookmarkReset,
  } = props;
  const slug = props.match.params.slug;
  console.log("slug------------------------------------------", slug);
  const [articleParams, getArticleParams] = useState({
    slug: slug,
  });
  const [click, setclick] = useState({
    liked: false,
    bookmark: false,
  });
  const [ArticleData, setArticleData] = useState({
    id: "",
    title: "",
    Description: "",
    img: "",
    views: "",
    likes: "",
  });

  const [imgSrc, setImgSrc] = useState(
    `${s3_cdn()}article/${getArticleBySlugState.data.image_path}`
  );
  const [copied, setCopied] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingArticle, setLoadingArticle] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  //FUNCTIONS
  // const toggle = (value) => {
  //     console.log(value)
  //     if (value === 'liked' && localStorage.getItem("LilacPatientToken")) {
  //         let clicked = click.liked;
  //         const data = {
  //             "article_id" : ArticleData.id
  //         }
  //         clicked = !clicked;
  //         if (clicked === true) {
  //             ArticleData.likes = ArticleData.likes + 1
  //             saveArticleLike(data)
  //         }
  //         else {
  //             ArticleData.likes = ArticleData.likes - 1
  //             saveArticleLike(data)
  //         }
  //         setclick({...click, ['liked']: clicked });
  //     }
  //     else if (value === 'bookmark' && localStorage.getItem("LilacPatientToken")) {
  //         let clicked1 = click.bookmark;
  //         clicked1 = !clicked1;
  //         const data1 = {
  //             "article_id" : ArticleData.id
  //         }
  //         saveArticleBookmark(data1)
  //         setclick({...click, ['bookmark']: clicked1 });
  //     }
  // };

  const toggle = (value) => {
    if (value === "liked" && localStorage.getItem("LilacPatientToken")) {
      let clicked = click.liked;
      console.log(getArticleBySlugState);
      const data = {
        article_id: getArticleBySlugState.data._id,
      };
      clicked = !clicked;
      if (clicked === true) {
        getArticleBySlugState.data.likes = getArticleBySlugState.data.likes + 1;
        saveArticleLike(data);
        console.log(data);
      } else {
        getArticleBySlugState.data.likes = getArticleBySlugState.data.likes - 1;
        saveArticleLike(data);
        console.log("ELSE");
      }
      setclick({ ...click, ["liked"]: clicked });
    } else if (
      value === "bookmark" &&
      localStorage.getItem("LilacPatientToken")
    ) {
      let clicked1 = click.bookmark;
      clicked1 = !clicked1;
      const data1 = {
        article_id: getArticleBySlugState.data._id,
      };
      console.log(data1);
      saveArticleBookmark(data1);
      setclick({ ...click, ["bookmark"]: !click.bookmark });
    }
  };

  // console.log(click);
  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    getArticleBySlug(articleParams);
    return () => {
      getArticleBySlugReset();
    };
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (getArticleBySlugState.apiState === "loading") {
      setLoadingArticle(true);
    }
    if (getArticleBySlugState.apiState === "success") {
      console.log(getArticleBySlugState);
      setArticleData({
        ["id"]: getArticleBySlugState.data._id,
        ["title"]: getArticleBySlugState.data.title,
        ["Description"]: getArticleBySlugState.data.description,
        ["img"]: getArticleBySlugState.data.image_path,
        ["views"]: getArticleBySlugState.data.views,
        ["likes"]: getArticleBySlugState.data.likes,
        ["type"]: getArticleBySlugState.data.type,
        ["audio_path"]: getArticleBySlugState.data.audio_path,
        ["video_path"]: getArticleBySlugState.data.video_path,
        ["video_link"]: getArticleBySlugState.data.video_link,
      });
      setclick({
        ["liked"]: getArticleBySlugState.data.meta.liked,
        ["bookmark"]: getArticleBySlugState.data.meta.bookmarked,
      });
      setLoadingArticle(false);
    }
  }, [getArticleBySlugState]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);
  // const renderArticleType = () => {
  //     console.log(ArticleData.type)
  //     if(ArticleData.type == "Audio Article"){
  //         return <AudioArticle ArticleData={ArticleData}/>

  //     }else if(ArticleData.type == "Text Article"){

  //     }else if(ArticleData.type == "Video Article") {
  //         return <VideoArticle ArticleData={ArticleData}/>
  //     }else {
  //         return null;
  //     }
  // }

  const renderArticleImage = () => {
    if (ArticleData.type == "Text Article") {
      return <ImagesArticle type={"text"} />;
    } else if (ArticleData.type == "Audio Article") {
      return <ImagesArticle type={"audio"} />;
    } else {
      return null;
    }
  };

  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  return (
    <PageContainer
      className={`ml-n3 mr-n3 ml-sm-0 mr-sm-0 pageContainer ${
        ArticleData.type == "Text Article" ? "articlePadding" : ""
      }`}
    >
      <div className="container">
        <div className="row">
          {loadingArticle ? (
            <div className="text-center loader-container">
              <Spin spinning={loadingArticle} tip="Loading..." />
            </div>
          ) : (
            <div className="col-12" id="responsiveTable">
              <ArticleTitle>{getArticleBySlugState.data.title}</ArticleTitle>
              <BlankSpace className="blankSpace" />
              {ArticleData.type == "Audio Article" ? (
                <>
                  <Row
                    className="article-row"
                    gutter={[24, 24]}
                    style={{
                      // boxShadow: "0px 8px 18px rgba(202, 212, 226, 0.3)",
                      padding: "1px",
                      marginLeft: "1px",
                      marginRight: "1px",
                    }}
                  >
                    {/* <Sticky> */}
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={8}
                      style={{
                        boxShadow: "0px 8px 18px rgba(202, 212, 226, 0.3)",
                        maxHeight: "490px",
                        padding: "15px",
                      }}
                    >
                      {renderArticleImage()}
                      {ArticleData.type == "Audio Article" ? (
                        <AudioArticle ArticleData={ArticleData} />
                      ) : null}
                    </Col>
                    {/* </Sticky> */}

                    <Col
                      className="views-like-bookmark"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={16}
                      xl={16}
                    >
                      <Row>
                        <ArticleViews className="Article starts"></ArticleViews>
                        <Col
                          className="views-like"
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                        >
                          <LeftBlock>
                            <ViewBlock>
                              <EyeOutlined
                                className="notificationIcon"
                                style={{ display: "inline-flex" }}
                              />{" "}
                              {getArticleBySlugState.data.views}{" "}
                              {getArticleBySlugState.data.views < 2
                                ? "view"
                                : "views"}
                            </ViewBlock>
                            <LikeBlock onClick={() => toggle("liked")}>
                              {click.liked ? (
                                <>
                                  <HeartFilled
                                    className="notificationIcon"
                                    style={{
                                      color: "#eb2f96",
                                      display: "inline-flex",
                                    }}
                                  />{" "}
                                  {getArticleBySlugState.data.likes}{" "}
                                  {getArticleBySlugState.data.likes < 2
                                    ? "like"
                                    : "likes"}{" "}
                                </>
                              ) : (
                                <>
                                  <HeartTwoTone
                                    className="notificationIcon"
                                    twoToneColor="#eb2f96"
                                    style={{ display: "inline-flex" }}
                                  />{" "}
                                  {getArticleBySlugState.data.likes}{" "}
                                  {getArticleBySlugState.data.likes < 2
                                    ? "like"
                                    : "likes"}{" "}
                                </>
                              )}
                            </LikeBlock>
                          </LeftBlock>
                        </Col>
                        <Col
                          style={{ textAlign: "right" }}
                          className="bookmark"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <RightBlock>
                            <ShareBlock>
                              <ShareAltOutlined
                                onClick={showModal}
                                style={{ display: "inline-flex" }}
                              />
                              {/* <CopyOutlined onClick={showModal} style={{display:"inline-flex"}}/> */}
                            </ShareBlock>
                            <BookmarkBlock onClick={() => toggle("bookmark")}>
                              {click.bookmark ? (
                                <>
                                  <i class="fa fa-bookmark"></i>
                                </>
                              ) : (
                                <>
                                  <i class="fa fa-bookmark-o"></i>
                                </>
                              )}
                            </BookmarkBlock>
                          </RightBlock>
                        </Col>
                      </Row>
                      <Row>
                        <BlankSpace />
                        <Col
                          className="description"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                        >
                          <Description
                            dangerouslySetInnerHTML={{
                              __html: decodeURIComponent(
                                getArticleBySlugState.data.description
                              ),
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row
                    className="article-row"
                    gutter={[24, 24]}
                    style={{
                      padding: "1px",
                      marginLeft: "1px",
                      marginRight: "1px",
                    }}
                  >
                    <Col
                      className="ArticleImage"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      {renderArticleImage()}
                      {ArticleData.type == "Audio Article" ? (
                        <AudioArticle ArticleData={ArticleData} />
                      ) : null}
                    </Col>
                  </Row>
                  <BlankSpace />
                  <Row gutter={[24, 24]}>
                    <Col
                      className="article-col"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      {ArticleData.type == "Video Article" ? (
                        <VideoArticle ArticleData={ArticleData} />
                      ) : null}
                    </Col>

                    <Col
                      className="views-like-bookmark"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <ArticleViews
                        className={
                          ArticleData.type == "Text Article"
                            ? "articleViewsWidth"
                            : ""
                        }
                      >
                        <Col
                          className="views-like"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <LeftBlock>
                            <ViewBlock>
                              <EyeOutlined
                                className="notificationIcon"
                                style={{ display: "inline-flex" }}
                              />{" "}
                              {getArticleBySlugState.data.views}{" "}
                              {getArticleBySlugState.data.views < 2
                                ? "view"
                                : "views"}
                            </ViewBlock>
                            <LikeBlock onClick={() => toggle("liked")}>
                              {click.liked ? (
                                <>
                                  <HeartFilled
                                    className="notificationIcon"
                                    style={{
                                      color: "#eb2f96",
                                      display: "inline-flex",
                                    }}
                                  />{" "}
                                  {getArticleBySlugState.data.likes}{" "}
                                  {getArticleBySlugState.data.likes < 2
                                    ? "like"
                                    : "likes"}{" "}
                                </>
                              ) : (
                                <>
                                  <HeartTwoTone
                                    className="notificationIcon"
                                    twoToneColor="#eb2f96"
                                    style={{ display: "inline-flex" }}
                                  />{" "}
                                  {getArticleBySlugState.data.likes}{" "}
                                  {getArticleBySlugState.data.likes < 2
                                    ? "like"
                                    : "likes"}{" "}
                                </>
                              )}
                            </LikeBlock>
                          </LeftBlock>
                        </Col>
                        <Col
                          style={{ textAlign: "right" }}
                          className="bookmark"
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                        >
                          <RightBlock className="right block">
                            <ShareBlock>
                              <ShareAltOutlined
                                onClick={showModal}
                                style={{ display: "inline-flex" }}
                              />
                              {/* <CopyOutlined onClick={showModal} style={{display:"inline-flex"}}/> */}
                            </ShareBlock>
                            <BookmarkBlock onClick={() => toggle("bookmark")}>
                              {click.bookmark ? (
                                <>
                                  <i class="fa fa-bookmark"></i>
                                </>
                              ) : (
                                <>
                                  <i class="fa fa-bookmark-o"></i>
                                </>
                              )}
                            </BookmarkBlock>
                          </RightBlock>
                        </Col>
                      </ArticleViews>
                    </Col>
                    <Col
                      className="description"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <Description
                        className="descriptionImg"
                        dangerouslySetInnerHTML={{
                          __html: decodeURIComponent(
                            getArticleBySlugState.data.description
                          ),
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Modal visible={isModalVisible} onCancel={handleOk} footer={null}>
                <h3 className="pb-2" style={{ borderBottom: "1px solid #CCC" }}>
                  Share
                </h3>
                <div className="container">
                  <div className="row">
                    <div className="col-12 mt-4 mb-3">
                      <div className="row text-center">
                        <div className="col-1"></div>
                        <div className="col-2 text-center">
                          <FacebookShareButton
                            url={`${window.location.href}`}
                            quote={""}
                            hashtag=""
                          >
                            <FacebookIcon size={36} round={true} />
                          </FacebookShareButton>
                        </div>
                        <div className="col-2">
                          <LinkedinShareButton
                            url={`${window.location.href}`}
                            quote={""}
                            hashtag=""
                          >
                            <LinkedinIcon size={36} round={true} />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-2">
                          <TwitterShareButton
                            url={`${window.location.href}`}
                            quote={""}
                            hashtag=""
                          >
                            <TwitterIcon size={36} round={true} />
                          </TwitterShareButton>
                        </div>
                        <div className="col-2">
                          <WhatsappShareButton
                            url={`${window.location.href}`}
                            quote={""}
                            hashtag=""
                            onShareWindowClose
                          >
                            <WhatsappIcon size={36} round={true} />
                          </WhatsappShareButton>
                        </div>
                        <div className="col-2">
                          <FaCopy
                            onClick={copy}
                            size={32}
                            round={true}
                            role="button"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
      {scrollTopBtn && <ScrollToTop />}
      <div className="clear-fix"></div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  getArticleBySlugState: state.getArticleBySlug,
  saveArticleLikeState: state.saveArticleLike,
  saveArticleBookmarkState: state.saveArticleBookmark,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleBySlug: (params) => dispatch(getArticleBySlug(params)),
  getArticleBySlugReset: () => dispatch(getArticleBySlugReset()),
  saveArticleLike: (params) => dispatch(saveArticleLike(params)),
  saveArticleLikeReset: () => dispatch(saveArticleLikeReset()),
  saveArticleBookmark: (params) => dispatch(saveArticleBookmark(params)),
  saveArticleBookmarkReset: () => dispatch(saveArticleBookmarkReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
