import styled from "styled-components";

export const Container = styled.div`
    display: block;
    margin-left: auto;
    margin-right: auto;  
    width: 60%;  
    padding-Top: 10%;
    text-align: center;
`
export const Content = styled.div`
    object-fit: cover;
    margin: 2%;
`

export const CuratedTitle = styled.h1`
    width: 100%;
    position: relative;
    font-family : Poppins;
    font-size: 34px;
    line-height: 32px;
    color: #1F274A;
    font-weight: 600;
    margin-left:8%;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        text-align: center;
        font-size: 24px;
    } 
`