import styled, { css } from "styled-components";
import { Button } from "antd";
import Carousel from "react-bootstrap/Carousel";

export const CarouselDiv = styled.div`
  position: relative;
  width: 60%;
  margin: 0 auto;
  background: none;
  margin-bottom: 60px;
  /*    padding-bottom: 100px;*/
  .carousel {
    background: none;
    box-shadow: none;
    border-radius: none;
    margin: 1% auto;
  }
  .customslides {
    position: relative;
    background: #fff;
    box-shadow: 1px 1px 12px #efe6ef;
    border-radius: 28px;
    border: 1px solid #a64fa6;
    padding: 5%;
  }
  p {
    font-size: 18px;
    color: #a64fa6;
  }
  .carousel-indicators li {
    background-color: white;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    cursor: pointer;
    bottom: -50px;
    border: 2px solid #a54ea5;
    border-radius: 2px;
  }
  .carousel-indicators .active {
    opacity: 1;
    color: #a54ea5;
    background-color: #a54ea5;
    width: 22px;
    border-radius: 6px;
    height: 6px;
    border: 0px solid black;
  }
  .carousel-control-next,
  .carousel-control-prev {
    position: absolute;
    visibility: visible;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 20%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 11%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-bottom: 20px;
    height: 200px;
  }
  @media (max-width: 768px) {
    .customslides {
      position: relative;
      background: #b35fb3;
      box-shadow: 1px 1px 12px #efe6ef;
      border-radius: 28px;
      border: 1px solid #a64fa6;
      padding: 5%;
    }
    p {
      font-size: 18px;
      color: #fefefe;
    }
  }
`;

export const CarouselItem = styled(Carousel.Item)`
  font-size: 30px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 3%;
    font-size: 16px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 6%;
    font-size: 7px;
    .customslides {
      width: 80%;
    }
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 10%;
    font-size: 5px;
    .Caption {
      margin-top: 10% !important;
    }
  }
`;

export const CarouselCaption = styled(Carousel.Caption)`
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 3%;
    font-size: 16px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 6%;
    font-size: 7px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 10% !important;
    font-size: 5px;
  }
`;

export const DrtestmonialHead = styled.h1`
  font-family: Poppins;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
  color: #a64fa6;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  @media (max-width: 768px) {
    font-family: Poppins;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: #fbf6fb;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
  }
`;
export const DrQuoteLeft = styled.sup`
  position: relative;
  margin-right: 10px;
  width: 22px;
`;

export const DrQuoteRight = styled.sub`
  position: relative;
  margin-left: 10px;
  width: 22px;
  margin-bottom: -10px;
`;
