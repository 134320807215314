export const GET_BOOK_TEST = "GET_BOOK_TEST";
export const GET_BOOK_TEST_FAIL = "GET_BOOK_TEST_FAIL";
export const GET_BOOK_TEST_SUCCESS = "GET_BOOK_TEST_SUCCESS";
export const GET_BOOK_TEST_RESET = "GET_BOOK_TEST_RESET";

export const getBookTest = (params) => {
    return {type: GET_BOOK_TEST, params}
}
export const getBookTestFail = (response) => {
    return {type: GET_BOOK_TEST_FAIL, response}
}

export const getBookTestSuccess = (response) => {
    return {type: GET_BOOK_TEST_SUCCESS, response}
}

export const getBookTestReset = () => {
    return {type: GET_BOOK_TEST_RESET}
}