/* eslint-disable default-case */
/* eslint-disable import/no-anonymous-default-export */
import{
    GET_LISTEN,
    GET_LISTEN_SUCCESS,
    GET_LISTEN_FAIL,
    GET_LISTEN_RESET
} from '../action/getListenAction'

import{ api_err_msg} from '../strings.json'

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}
export default function(state = initialState, action){
    switch(action.type){
        case GET_LISTEN :
            return{ 
                ...state,
                apiState: 'loading'
            }
        case GET_LISTEN_FAIL :
            console.log("LIS fail aaya")
            return{ 
                ...state,
                apiState: 'error',
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_LISTEN_SUCCESS :
            console.log("LIS success aaya",action.response.data.data.result.audio)
            return{ 
                ...state,
                apiState: 'success',
                data:action.response.data.data.result.audio,
            }
        case GET_LISTEN_RESET:
            return initialState
        default:
            return state
    }
}