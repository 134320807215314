import { Typography, Radio, Avatar, Form, Button, DatePicker } from "antd";
import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    max-width: 100%;
    align-self: center;
    width: 1200px;
    min-height: 800px;
    margin: 10%;
    // overflow-x: hidden;

`
export const ProfileHeader = styled.div`
box-sizing: border-box;

height: 100px;
left: 140px;
top: 150px;


background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
padding: 30px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 45px;
/* identical to box height */


color: #1F274A;
`
export const XImg = styled.image` 
// width: 200px;
// height: 200px;
// border-radius: 50%; 
`

export const XMTitle = styled(Typography.Title)` 
    width: 140%; 
`
export const XimgDiv = styled.div`  
width: 200px;
height: 200px;
border-radius: 50%;
overflow: hidden;
background-color: blue;
/* commented for demo
float: left;
margin-left: 125px;
margin-top: 20px;
*/

/*for demo*/
display:inline-block;
vertical-align:middle;
`

export const XAvatar = styled(Avatar)`  

`

export const FormItem = styled(Form.Item)`  
    .ant-input {
        border-radius: 10px; 
    }   
     input:disabled{
        color:rgb(43 41 41 / 62%)
    }
`

export const Xfooter = styled.div`
    position : absolute;
    width: 100%; 
    margin-top: 140%;
    margin-left: -10%; 
    @media (min-width: 800px) and (max-width: 1024px){ 
        margin-top: 150%;
    } 
    @media (min-width: 550px) and  (max-width: 800px){ 
        margin-top: 170%; 
    } 
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top: 200%;
    }
`

export const BgPic1 = styled.div`
    z-index: 0;
    margin-top: 65%; 
    margin-left: -45%;
`

export const BgPic2 = styled.div`
    z-index: 0;  
    margin-left: 65%;
`

export const Ximg = styled.img`
    margin-top: 50%;
    margin-left: -70%; 
`

export const BadgeImg = styled.img`
    margin-top: -35%;
    margin-left: 70%; 
`


export const XFDimg = styled.img`
margin-left: -70%;  
// border: 2px solid black;
`

export const XButton = styled(Button)` 
    width: 200px;
    background-color: #C473C4;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`

export const XDatePicker = styled(DatePicker)` 
    width: 100%;
    border-radius: 10px;  
`

export const XBdiv = styled.div` 
    display:flex;
    margin-top: 23%;
    margin-left: -22%;
    z-index: 1;    
`

export const Xdiv = styled.div` 
    display:flex; 
`

export const XText = styled.h5` 
    padding-top: 8.5%;  
    display:flex; 
`

export const RadioButton = styled(Radio.Button)` 
    width: 100%;  
    display: flex; 
    height: 45% !important;
    border-radius: 10px !important;
    padding: 2%;
    color: black;
    font-size: 14px;  
    line-height: 21px; 
`

export const RadioButton1 = styled(Radio.Button)` 
    width: 100%;  
    display: flex; 
    height: 45% !important;
    border-radius: 10px !important;
    padding-left:9%; 
    padding-Bottom : 5%;
    font-size: 14px;
    line-height: 21px;  
`
export const ContainsProfile = styled.div` 
box-sizing: border-box;
left: 140px;
margin-top:20px;
padding:20px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
`