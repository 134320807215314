// action
import {
    BOOKED_APPOINTMENT_ACTIVITY,
    BOOKED_APPOINTMENT_FAIL,
    BOOKED_APPOINTMENT_RESET,
    BOOKED_APPOINTMENT_SUCCESS
} from '../action/bookingAppointmentActivity'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    message: "",
    result:""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case BOOKED_APPOINTMENT_ACTIVITY:
            return {
                ...state,
                apiState: "loading"
            }
        case BOOKED_APPOINTMENT_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case BOOKED_APPOINTMENT_SUCCESS:
            return {
                ...state,
                apiState: "success",
                message: action.response.data.message,
                result:action.response.data.result
            }
        case BOOKED_APPOINTMENT_RESET:
            return initialState
        default:
            return state
    }
}