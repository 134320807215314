import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getArticleApi = async (params) => {
  const response = await axios({
    url: `${config.api.base_url}article/${params.id}`,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });

  return response;
};

export default getArticleApi;
