import config from '../config'
import axios from 'axios'
import {getPatientToken} from '../utils'

export const sendOtpApi = async (params) => {

    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}patient/send-otp`,
        data: {
            "phone": params.phone 
        },
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `${getPatientToken()}`
        }
    })

    return response
}

export default sendOtpApi