import { Button } from "antd";
import styled, { css } from "styled-components";

export const PageWrap = styled.div`
    width: 100%;
    position: relative;
    padding:100px 0px;
    @media (max-width: 768px) {
        padding:70px 0px;
    }
`

export const HomeTopBannerMobile = styled.img`
    display: none;
    width:30%;
    position: absolute;
    ${(props) => {
        switch (props.$mode) {
            case "doctorRightBg1":
                return css`
                    right:0;
                    top:35%;
                `;
            default:
                return css`
                left:0;
                top:90%;
        `;
        }
    }}
    @media (max-width: 768px) {
        display: block;
    }
`
export const SupportTribeTitle = styled.h1`
    width: 100%;
    position: relative;
    font-family : Poppins;
    font-size: 34px;
    line-height: 32px;
    color: #1F274A;
    font-weight: 600;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        text-align: center;
        font-size: 24px;
        margin-bottom: 0px;
        margin-top: 0px !important;
    }
    // @media(min-width: 1200px){
    //     margin-top: 24px;
    // }
    
`
export const SupportTribeSelect = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:8px;
    @media (max-width: 768px) {
         display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 50px;
        align-items: center;
    }
    
`

export const XButton = styled(Button)` 
    margin-top: 20px;
    left: 40%;
    width: 240px;
    height: 40px;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%); 
    box-shadow: 1px 6px 22px #EDE8ED;
    border-radius: 42px;
    @media (max-width: 900px) { 
        left: 30%; 
    }
    @media (max-width: 768px) { 
        left: 20%;    
    }
`

export const TribeImg = styled.img`
    @media (max-width: 500px) {
        width:26px;
    }
    
`