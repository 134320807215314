// action
import {
    GET_APPOINTMENT_PAYMENT,
    GET_APPOINTMENT_PAYMENT_FAIL,
    GET_APPOINTMENT_PAYMENT_SUCCESS,
    GET_APPOINTMENT_PAYMENT_RESET
} from '../action/getAppointmentPaymentAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: "",
    expired_status: false,
    already_exist: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_APPOINTMENT_PAYMENT:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_APPOINTMENT_PAYMENT_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_APPOINTMENT_PAYMENT_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data.result,
                expired_status: action.response.data.data.expired_status,
                already_exist: action.response.data.data.already_exist,
                booking_not_found: action.response.data.data.booking_not_found,
            }
        case GET_APPOINTMENT_PAYMENT_RESET:
            return initialState
        default:
            return state
    }
}