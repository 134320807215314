import React, { useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
import { HelthboxHeading, YourHelthBg } from "../../page/Home/HomeStyle";
import { YourHelthImg3 } from "../HealthBlock/HealthBlockStyle";
import {
  AtEasyboxFlex,
  AtEasyboxTiles,
  AtEasyColumn,
  XImg,
} from "./AtEasyStyle";
import "./AtEasy.css";
function AtEasy(props) {
  return (
    <>
      <YourHelthBg>
        <YourHelthImg3 className="" src="./images/CombinedShape.svg" alt="no" />
        {/* <img alt="right-bg" className="homeHelthRightBg hideMobile" src="./images/svg/helthRightBg.svg" /> */}
        {/* <img alt="right-bg" className="atEasyBg hideMobile" src="./images/svg/homeBottomBg.svg" /> */}
        {/* <div className="container">  */}
        <div className="atEasySpace">
          <div className="atEasylogoSpace">
            <div className="row">
              <div className="col-sm-12 text-center">
                <img className="img-fluid" src="/images/name.svg" alt="logo" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <AtEasyColumn
                className="col-sm-4 mb-4 mb-sm-0 mb-md-0 text-center"
                onClick={props.openDrawer}
              >
                <div className="atEasyHoverImg">
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-4">
                      <img
                        class="img-fluid"
                        src="./images/chat-with-sehej.svg"
                        alt="no"
                      />
                    </div>
                    <div className="col-sm-12 mt-3 mt-sm-3 mt-md-0 col-md-8">
                      <HelthboxHeading>Chat With Sahej</HelthboxHeading>
                    </div>
                  </div>
                </div>
              </AtEasyColumn>
              <AtEasyColumn
                className="col-sm-4 mb-4 mb-sm-0 mb-md-0 text-center"
                onClick={props.openDrawer}
              >
                <Link
                  className="invisiLink"
                  to={{
                    pathname: "/expert-detail",
                    state: { type: "MHC" },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <div className="atEasyHoverImg">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-4">
                        <img
                          class="img-fluid"
                          src="./images/book-session.svg"
                          alt="no"
                        />
                      </div>
                      <div className="col-sm-12 mt-3 mt-sm-3 mt-md-0 col-md-8">
                        <HelthboxHeading>Book A Session</HelthboxHeading>
                      </div>
                    </div>
                  </div>
                </Link>
              </AtEasyColumn>
              <AtEasyColumn className="col-sm-4 mb-4 mb-sm-0 mb-md-0 text-center">
                <Link to="/gifts" className="invisiLink">
                  <div className="atEasyHoverImg">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-4">
                        <img
                          class="img-fluid"
                          src="./images/gift-session.svg"
                          alt="no"
                        />
                      </div>
                      <div className="col-sm-12 mt-3 mt-sm-3 mt-md-0 col-md-8">
                        <HelthboxHeading>Gift A Session</HelthboxHeading>
                      </div>
                    </div>
                  </div>
                </Link>
              </AtEasyColumn>
            </div>
          </div>
        </div>
        {/* </div> */}
      </YourHelthBg>
    </>
  );
}
export default AtEasy;
