export const GET_PREG_HIGHLIGHT_APP_LIST = "GET_PREG_HIGHLIGHT_APP_LIST"
export const GET_PREG_HIGHLIGHT_APP_LIST_FAIL = "GET_PREG_HIGHLIGHT_APP_LIST_FAIL"
export const GET_PREG_HIGHLIGHT_APP_LIST_SUCCESS  = "GET_PREG_HIGHLIGHT_APP_LIST_SUCCESS"
export const GET_PREG_HIGHLIGHT_APP_LIST_RESET  = "GET_PREG_HIGHLIGHT_APP_LIST_RESET"

export const getPregHighlightAppList = (params) => {
    return { type: GET_PREG_HIGHLIGHT_APP_LIST, params }
}
export const getPregHighlightAppListFail = (response) => {
    return { type: GET_PREG_HIGHLIGHT_APP_LIST_FAIL, response }
}
export const getPregHighlightAppListSuccess = (response) => {
    return { type: GET_PREG_HIGHLIGHT_APP_LIST_SUCCESS, response }
}
export const getPregHighlightAppListReset = () => {
    return { type: GET_PREG_HIGHLIGHT_APP_LIST_RESET }
}