/* eslint-disable jsx-a11y/alt-text */
import { React, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { timeSlotFormat, DaySlotFormat } from "../../utils";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";
import CarouselBlock from "../../component/CarouselBlock/CarouselBlock";
import consultOptionOne from "../../images/image_gc.jpg";
import consultOptionTwo from "../../images/image_atease.jpg";
import noAppointment from "../../images/no_appointment.svg";

//STYLE
import {
  XText,
  NoApptDiv,
  DocImg,
  DoctorHeadParent,
  DoctorHeadChild,
  DoctorContainer,
  DoctorHeadSlide,
  DoctorParaInline,
  DocCard,
  XCard,
  IconDiv,
  XButton,
  TopContainer,
} from "./DoctorStyle";
import "./Doctor.css";
import { BlankSpace } from "../../component/Xcomponent";
import { Typography, Card, Tabs, Spin } from "antd";
import {
  CalendarTwoTone,
  ClockCircleTwoTone,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  getUpcomingAppointment,
  getUpcomingAppointmentReset,
} from "../../action/getUpcomingAppointmentAction";
import {
  getAppointmentHistory,
  getAppointmentHistoryReset,
} from "../../action/getAppointmentHistoryAction";
import getAppointmentApi from "../../api/getAppointmentApi";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

function Doctor(props) {
  const {
    getUpcomingAppointment,
    getUpcomingAppointmentState,
    getUpcomingAppointmentReset,
    getAppointmentHistory,
    getAppointmentHistoryState,
    getAppointmentHistoryReset,
  } = props;
  const { Title } = Typography;
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [pastAppointmentsData, setPastAppointmentsData] = useState([]);
  const [Testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const { TabPane } = Tabs;
  function getTabKey(key) {}
  useEffect(() => {
    window.scrollTo(0, 0);
    getUpcomingAppointment();
    getAppointmentHistory();
    axios({
      url: `${config.api.base_url}testimonial/list/`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    })
      .then((respons) => {
        // console.log(respons.data)
      })
      .catch((error) => {
        console.log(error);
      });
    const data = [
      {
        _id: "61388a82a42c695fdcf9d093",
        patient_id: {
          _id: "613f50c1bab60ee2115d60c7",
          name: "Kshitija Duraphe",
        },
        rating: 4,
        comment: "Excellent consultation",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
        status: "approved",
      },
      {
        _id: "61389796a42c695fdcf9d099",
        patient_id: {
          _id: "6138cee78b740d790ac422c4",
          name: "Asifa Shaikh",
          profile_pic: "",
        },
        rating: 2,
        comment: "Good experince I had with doctor consultancy",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
        status: "rejected",
      },
      {
        _id: "6138a542a42c695fdcf9d09c",
        patient_id: {
          _id: "613071c0b57292fde5aaac55",
          name: "asif shaikh",
          profile_pic: "",
        },
        rating: 3,
        comment: "Best experince I had with doctor consultancy",
        doctor_id: {
          _id: "6129d72025b7290647da9a01",
          fname: "Syeda",
          lname: "Ruksheda",
          profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
        },
      },
    ];
    setTestimonials(data);
    return () => {
      getUpcomingAppointmentReset();
      getAppointmentHistoryReset();
    };
  }, []);

  useEffect(() => {
    if (getUpcomingAppointmentState.apiState === "loading") {
      setLoading(true);
    } else if (getUpcomingAppointmentState.apiState === "success") {
      // console.log(getUpcomingAppointmentState.data)
      const appointmentss = [];
      const x = getUpcomingAppointmentState.data.data.map((T) => {
        appointmentss.push(T);
      });
      setAppointmentsData(appointmentss);
      setLoading(false);
      // console.log("appoint", appointmentsData);
    }
  }, [
    getUpcomingAppointmentState.apiState,
    getUpcomingAppointmentState.data.data,
  ]);

  useEffect(() => {
    if (getAppointmentHistoryState?.apiState === "success") {
      console.log(getAppointmentHistoryState.data);
      const appointmentss = [];
      const x = getAppointmentHistoryState.data.data.result.map((T) => {
        appointmentss.push(T);
      });
      setPastAppointmentsData(appointmentss);
      // console.log("past appoint", appointmentsData);
    }
  }, [getAppointmentHistoryState, getUpcomingAppointmentState.data]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  function Appointments(props) {
    function getSpeciality(spec) {
      let QuaValues = [];
      for (let i = 0; i < spec.length; i++) {
        QuaValues[i] = spec[i].value;
      }
      return QuaValues.join(" | ");
    }

    console.log("appointments", props.appointments);
    return (
      <>
        {loading ? (
          <div className="row text-center loader-container2">
            <Spin spinning={loading} tip="Loading Upcoming Appointments..." />
          </div>
        ) : props.appointments.length > 0 ? (
          <>
            <div className="carousel carouselWWrapDr customCarouselArrow mb-5">
              <Carousel
                showIndicators
                showControls
                data-interval="false"
                className="carousel"
                nextIcon={
                  <img width="75" src="/images/arrow-right.svg" alt="#" />
                }
                prevIcon={
                  <img width="75" src="/images/arrow-right.svg" alt="#" />
                }
              >
                {props.appointments.map((item) => (
                  <Carousel.Item class="carousel-item">
                    <div className="p-2">
                      <div class="row sc">
                        <div className="row align-items-center sc">
                          <div className="col-md-6 col-sm-6 col-xl-6 mb-2 mt-2 pl-2 pr-2 text-center">
                            <div className="appmntImg">
                              <img
                                style={{ width: "100%", maxHeight: "250px" }}
                                class="img-fluid svf"
                                src={
                                  item.doctor_id.profile_picture
                                    ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                    : item.doctor_id.gender.toLowerCase() ==
                                      "male"
                                    ? `/images/avatar_male.svg`
                                    : item.doctor_id.gender.toLowerCase() ==
                                      "female"
                                    ? `/images/avatar_female.svg`
                                    : `/images/avatar_transgender.svg`
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 col-xl-6 text-left">
                            <div className="docInfo">
                              <h1 className="appointmentsHeadingH1" level={4}>
                                {item.doctor_id.salutation.value}{" "}
                                {item.doctor_id.fname} {item.doctor_id.lname}
                              </h1>
                              <div className="docText">
                                <div className="row">
                                  <p className="appointmentsPara1">
                                    {getSpeciality(item.doctor_id.speciality)}
                                  </p>
                                  <div className="col-12 col-sm-12 text-left">
                                    <ul className="appointmentsList">
                                      <li>
                                        <CalendarOutlined
                                          className="doctorIcons"
                                          twoToneColor="#1F274A"
                                        />{" "}
                                        {new Date(item.date).getUTCDate() +
                                          " " +
                                          new Date(item.date).toLocaleString(
                                            "default",
                                            { month: "short" }
                                          ) +
                                          " " +
                                          new Date(item.date).getFullYear()}
                                      </li>
                                      <li>
                                        <ClockCircleOutlined className="doctorIcons" />{" "}
                                        {timeSlotFormat(item.time_slot_start)}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 submitRspn mt-md-2 mb-2">
                                    <Link
                                      to={{
                                        pathname: "/appointment-details",
                                        state: {
                                          Details: { item },
                                          isBooking: true,
                                          doctor: `${item.doctor_id._id}`,
                                        },
                                      }}
                                      className="appointmentsBtn-carousel"
                                    >
                                      View Appointment
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="carousel carouselWWrapDr d-none">
              <Carousel
                showIndicators
                showControls
                data-interval="false"
                className="carousel"
              >
                {props.appointments.map((item) => (
                  <Carousel.Item class="carousel-item">
                    <div class="container">
                      <DoctorContainer>
                        <div className="row">
                          <div className="col-6">
                            <DocImg
                              key={item._id}
                              src={
                                item.doctor_id.profile_picture
                                  ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                  : item.doctor_id.gender.toLowerCase() ==
                                    "male"
                                  ? `/images/avatar_male.svg`
                                  : item.doctor_id.gender.toLowerCase() ==
                                    "female"
                                  ? `/images/avatar_female.svg`
                                  : `/images/avatar_transgender.svg`
                              }
                              alt=""
                            />
                          </div>
                          <div class="col-6">
                            <div className="doc-info">
                              <DoctorHeadSlide key={item._id} level={3}>
                                {item.doctor_id.salutation.value}{" "}
                                {item.doctor_id.fname} {item.doctor_id.lname}
                              </DoctorHeadSlide>
                              <div className="doc-text">
                                <div className="row">
                                  <div className="col-sm-12 text-center mb-3">
                                    <DoctorParaInline>
                                      {getSpeciality(item.doctor_id.speciality)}
                                    </DoctorParaInline>
                                  </div>
                                  <div className="col-sm-11 col-12">
                                    <IconDiv className="">
                                      <ul>
                                        <li
                                          className="col-6 text-center list"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <CalendarOutlined
                                            className="doctorIcons"
                                            twoToneColor="#1F274A"
                                          />{" "}
                                          {DaySlotFormat(item.date)}
                                        </li>
                                        <li
                                          className="col-6 text-center list"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <ClockCircleOutlined className="doctorIcons" />{" "}
                                          {timeSlotFormat(item.time_slot_start)}
                                        </li>
                                        <li
                                          className="list-mob"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <CalendarOutlined
                                            className="doctorIcons"
                                            twoToneColor="#1F274A"
                                          />{" "}
                                          {DaySlotFormat(item.date)}
                                        </li>
                                        <li
                                          className="list-mob mt-3"
                                          style={{
                                            fontSize: "18px",
                                            lineHeight: "18px",
                                          }}
                                        >
                                          <ClockCircleOutlined className="doctorIcons" />{" "}
                                          {timeSlotFormat(item.time_slot_start)}
                                        </li>
                                      </ul>
                                    </IconDiv>
                                  </div>
                                  <div className="col-sm-12 text-center submitRspn mt-4">
                                    <Link
                                      to={{
                                        pathname: "/appointment-details",
                                        state: {
                                          Details: { item },
                                          isBooking: true,
                                          doctor: `${item.doctor_id._id}`,
                                        },
                                      }}
                                      className="invisiLink"
                                    >
                                      <XButton
                                        className="themeBtn mt-3"
                                        shape="round"
                                        type="primary"
                                      >
                                        <XText>View Appointment</XText>
                                      </XButton>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </DoctorContainer>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </>
        ) : (
          <div className="row text-center" style={{ marginTop: "-2rem" }}>
            <div className="col-12 empmsg">
              <div className="upcommingDiv">
                <img
                  className="upcomingIcon"
                  src={noAppointment}
                  alt="Appointment calendar"
                />
                <div className="no-upcoming-text">
                  <h3 style={{ color: "gray" }}>No Upcoming</h3>
                  <h3 style={{ color: "gray" }}>Appointments</h3>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  function PassAppointments(props) {
    function getSpeciality(spec) {
      let QuaValues = [];
      for (let i = 0; i < spec.length; i++) {
        QuaValues[i] = spec[i].value;
      }
      return QuaValues.join(" | ");
    }

    console.log("appointments", props.appointments);
    return (
      <>
        {props.appointments.length > 0 ? (
          <div className="carousel carouselWWrapDr">
            <Carousel
              showIndicators
              showControls
              data-interval="false"
              className="carousel"
            >
              {props.appointments.map((item) => (
                <Carousel.Item class="carousel-item">
                  <div class="container">
                    <DoctorContainer>
                      <div className="row">
                        <div className="col-6">
                          <DocImg
                            key={item._id}
                            src={
                              item.doctor_id.profile_picture
                                ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                : item.doctor_id.gender.toLowerCase() == "male"
                                ? `/images/avatar_male.svg`
                                : item.doctor_id.gender.toLowerCase() ==
                                  "female"
                                ? `/images/avatar_female.svg`
                                : `/images/avatar_transgender.svg`
                            }
                            alt=""
                          />
                        </div>
                        <div class="col-6">
                          <div className="doc-info">
                            <DoctorHeadSlide key={item._id} level={3}>
                              {item.doctor_id.salutation.value}{" "}
                              {item.doctor_id.fname} {item.doctor_id.lname}
                            </DoctorHeadSlide>
                            <div className="doc-text">
                              <div className="row">
                                <div className="col-sm-12 text-center mb-3">
                                  <DoctorParaInline>
                                    {getSpeciality(item.doctor_id.speciality)}
                                  </DoctorParaInline>
                                </div>
                                <div className="col-sm-11 col-12">
                                  <IconDiv className="">
                                    <ul>
                                      <li
                                        className="col-6 text-center list"
                                        style={{
                                          fontSize: "18px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <CalendarOutlined
                                          className="doctorIcons"
                                          twoToneColor="#1F274A"
                                        />{" "}
                                        {DaySlotFormat(item.date)}
                                      </li>
                                      <li
                                        className="col-6 text-center list"
                                        style={{
                                          fontSize: "18px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <ClockCircleOutlined className="doctorIcons" />{" "}
                                        {timeSlotFormat(item.time_slot_start)}
                                      </li>
                                      <li
                                        className="list-mob"
                                        style={{
                                          fontSize: "18px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <CalendarOutlined
                                          className="doctorIcons"
                                          twoToneColor="#1F274A"
                                        />{" "}
                                        {DaySlotFormat(item.date)}
                                      </li>
                                      <li
                                        className="list-mob mt-3"
                                        style={{
                                          fontSize: "18px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        <ClockCircleOutlined className="doctorIcons" />{" "}
                                        {timeSlotFormat(item.time_slot_start)}
                                      </li>
                                    </ul>
                                  </IconDiv>
                                </div>
                                <div className="col-sm-12 text-center submitRspn mt-4">
                                  <Link
                                    to={{
                                      pathname: "/appointment-details",
                                      state: {
                                        Details: { item },
                                        isBooking: true,
                                        doctor: `${item.doctor_id._id}`,
                                      },
                                    }}
                                    className="invisiLink"
                                  >
                                    <XButton
                                      className="themeBtn mt-3"
                                      shape="round"
                                      type="primary"
                                    >
                                      <XText>View Appointment</XText>
                                    </XButton>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DoctorContainer>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="row d-flex justify-content-center ">
            <div className="offset-col-4 col-4 empmsg">
              No Past Appointments
            </div>
          </div>
        )}
      </>
    );
  }
  // function Doctor(props) {
  //   const {
  //     getUpcomingAppointment,
  //     getUpcomingAppointmentState,
  //     getUpcomingAppointmentReset,
  //     getAppointmentHistory,
  //     getAppointmentHistoryState,
  //     getAppointmentHistoryReset,
  //   } = props;
  //   const { Title } = Typography;
  //   const [appointmentsData, setAppointmentsData] = useState([]);
  //   const [pastAppointmentsData, setPastAppointmentsData] = useState([]);
  //   const [Testimonials, setTestimonials] = useState([]);
  //   const { TabPane } = Tabs;
  //   function getTabKey(key) {}
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     getUpcomingAppointment();
  //     getAppointmentHistory();
  //     axios({
  //       url: `${config.api.base_url}testimonial/list/`,
  //       method: "get",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${getPatientToken()}`,
  //       },
  //     })
  //       .then((respons) => {
  //         // console.log(respons.data)
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     const data = [
  //       {
  //         _id: "61388a82a42c695fdcf9d093",
  //         patient_id: {
  //           _id: "613f50c1bab60ee2115d60c7",
  //           name: "Kshitija Duraphe",
  //         },
  //         rating: 4,
  //         comment: "Excellent consultation",
  //         doctor_id: {
  //           _id: "6129d72025b7290647da9a01",
  //           fname: "Syeda",
  //           lname: "Ruksheda",
  //           profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
  //         },
  //         status: "approved",
  //       },
  //       {
  //         _id: "61389796a42c695fdcf9d099",
  //         patient_id: {
  //           _id: "6138cee78b740d790ac422c4",
  //           name: "Asifa Shaikh",
  //           profile_pic: "",
  //         },
  //         rating: 2,
  //         comment: "Good experince I had with doctor consultancy",
  //         doctor_id: {
  //           _id: "6129d72025b7290647da9a01",
  //           fname: "Syeda",
  //           lname: "Ruksheda",
  //           profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
  //         },
  //         status: "rejected",
  //       },
  //       {
  //         _id: "6138a542a42c695fdcf9d09c",
  //         patient_id: {
  //           _id: "613071c0b57292fde5aaac55",
  //           name: "asif shaikh",
  //           profile_pic: "",
  //         },
  //         rating: 3,
  //         comment: "Best experince I had with doctor consultancy",
  //         doctor_id: {
  //           _id: "6129d72025b7290647da9a01",
  //           fname: "Syeda",
  //           lname: "Ruksheda",
  //           profile_picture: "db5a03ab-d471-4bbb-9482-3682a353e2af.jpeg",
  //         },
  //       },
  //     ];
  //     setTestimonials(data);
  //     return () => {
  //       getUpcomingAppointmentReset();
  //       getAppointmentHistoryReset();
  //     };
  //   }, []);

  //   useEffect(() => {
  //     if (getUpcomingAppointmentState.apiState === "success") {
  //       // console.log(getUpcomingAppointmentState.data)
  //       const appointmentss = [];
  //       const x = getUpcomingAppointmentState.data.data.map((T) => {
  //         appointmentss.push(T);
  //       });
  //       setAppointmentsData(appointmentss);
  //       console.log("appoint", appointmentsData);
  //     }
  //   }, [getUpcomingAppointmentState]);

  //   useEffect(() => {
  //     if (getAppointmentHistoryState?.apiState === "success") {
  //       // console.log(getUpcomingAppointmentState.data)
  //       const appointmentss = [];
  //       const x = getAppointmentHistoryState.data.data.map((T) => {
  //         appointmentss.push(T);
  //       });
  //       setPastAppointmentsData(appointmentss);
  //       console.log("past appoint", appointmentsData);
  //     }
  //   }, [getAppointmentHistoryState]);

  return (
    <div>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <img
        alt="right-bg"
        className="hideMobile doctorRightBg1"
        // style={{ top: "40%" }}
        src="./images/svg/doctorbgRight.svg"
      />
      <div className="container containerWidth mt-container">
        <div className="row mt-3">
          <div className="col-sm-12 col-12 text-center">
            <h1 className="heading-home" level={2}>
              Choose Consultation{" "}
            </h1>
            <div className="row mb-5 justify-content-center">
              <div class="col-6 col-sm-4 col-md-4 col-lg-3">
                <Link
                  to={{
                    pathname: "/expert-detail",
                    state: { type: "GC" },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <XCard
                    className="cards"
                    hoverable
                    cover={
                      <img
                        className="img-fluid"
                        alt=""
                        src={consultOptionOne}
                      />
                    }
                  >
                    <h5>
                      Genetic <br /> Counselling
                    </h5>
                  </XCard>
                </Link>
              </div>
              {/* requested by lilac */}
              {/* <div class="col-6 col-sm-4 col-md-4 col-lg-3">
                <Link
                  to={{
                    pathname: "/expert-detail",
                    state: { type: "MHC" },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <XCard
                    className="cards"
                    hoverable
                    cover={
                      <img
                        className="img-fluid"
                        alt="example"
                        src={consultOptionTwo}
                      />
                    }
                  >
                    <h5>
                      Mental Health <br /> Counselling
                    </h5>
                  </XCard>
                </Link>
              </div> */}
              {/* requested by lilac */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-12 ">
            <h2 className="heading-home text-center">Upcoming Appointments</h2>
            <Appointments appointments={appointmentsData} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-12 text-center mb-5">
            <CarouselBlock Testimonials={Testimonials} />
            <BlankSpace />
            <BlankSpace />
          </div>
        </div>
      </div>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getUpcomingAppointmentState: state.getUpcomingAppointment,
  getAppointmentHistoryState: state.getAppointmentHistoryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getUpcomingAppointment: (params) => dispatch(getUpcomingAppointment(params)),
  getUpcomingAppointmentReset: () => dispatch(getUpcomingAppointmentReset()),
  getAppointmentHistory: (params) => dispatch(getAppointmentHistory(params)),
  getAppointmentHistoryReset: () => dispatch(getAppointmentHistoryReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
