import styled from "styled-components";

export const SuccessModal = styled.div`
         h6{
        margin-bottom:30px;
      }
      p{
        display:flex;
      }
      p span{
        margin-left:auto;
      }
`