export const GET_ARTICLE_APP_LIST = "GET_ARTICLE_APP_LIST"
export const GET_ARTICLE_APP_LIST_FAIL = "GET_ARTICLE_APP_LIST_FAIL"
export const GET_ARTICLE_APP_LIST_SUCCESS  = "GET_ARTICLE_APP_LIST_SUCCESS"
export const GET_ARTICLE_APP_LIST_RESET  = "GET_ARTICLE_APP_LIST_RESET"

export const getArticleAppList = (params) => {
    console.log("params from actions", params)
    return { type: GET_ARTICLE_APP_LIST, params }
}
export const getArticleAppListFail = (response) => {
    return { type: GET_ARTICLE_APP_LIST_FAIL, response }
}
export const getArticleAppListSuccess = (response) => {
    return { type: GET_ARTICLE_APP_LIST_SUCCESS, response }
}
export const getArticleAppListReset = () => {
    return { type: GET_ARTICLE_APP_LIST_RESET }
}