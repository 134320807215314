import React from 'react'
import ReactPlayer from "react-player";


function VideoArticle(props) {
  return (
    <ReactPlayer 
    playing controls
    width="100%" style={{paddingLeft: "8px"}} 
    // url={"https://www.youtube.com/watch?v=Ii7IVuqdKTQ"}
    url={props.ArticleData.video_link}
    />
    
  )
}

export default VideoArticle