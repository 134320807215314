import axios from 'axios'
import config from '../config'
import { getPatientToken } from '../utils'

const updatePaymentApi = async(params) => {
    const response = await axios({
        url: `${config.api.base_url}update-payment`,
        method: "post",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    })
    return response
}

export default updatePaymentApi