import styled, { css } from "styled-components";

export const SmilyPara = styled.p`
  margin-top: 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media(min-width: 321px) and (max-width: 376px){
    margin-left: -4px;
  }
`;
export const FeelingTodayDiv = styled.div`
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const SmilyHeading = styled.h1`
  margin-bottom: 30px;
  color: #1f274a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 32px;
  margin-top: 16px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 0px;
  }
`;

export const SmilyCont = styled.div`
  width: 55%;
  margin: 0px auto;
  position: relative;
  background: #fff;
  padding: 30px;
  box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
  border-radius: 28px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 0px;
  }
  @media(min-width: 769px) and (max-width: 1024px){
    width: 65%;
  }
`;
