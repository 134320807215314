import config from '../config'
import axios from 'axios'
import {getPatientToken} from '../utils'

export const getChatBotApi = async (params) => {

    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}chat-with-sehej`,
        data: params,
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    }) 
    return response
}

export default getChatBotApi