import styled, { css } from "styled-components";

export const TopContainer  = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto; 
        width: 35%; 
        margin-top: 5%; 
    } 
`

export const ListSpacing = styled.div`
    width: 100%;
    position: relative;
    padding: 150px 15px 0px 15px;
    @media (max-width: 768px) {
        // padding: 0px;
        padding:10px 0px 0px 10px;
    }
`

export const ListHeading = styled.h1`
    position: relative;
    width: 100%;
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    @media (max-width: 768px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 52px; 
    }
`

export const SideCard = styled.div`
    width: 100%;
    box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
    border-radius: 10px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        width: 380px;
        height: 125px;  
    }
    @media (max-width: 576px) {
        width: 340px;
        height: 125px;  
    }
`

export const XImg = styled.img` 
    @media (max-width: 768px) {
        border-radius: 25%;
        width: 115px;
        height: 115px;  
    }
`

export const ListSideWrap = styled.div`
@media (max-width: 768px) {
    width: 92px;
    height: 92px;  
}
`
export const ListSideHead = styled.h3`
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #1F274A;
    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 20px;  
    }
`

export const MainImg = styled.div`
    width: 100%;
    border-radius: 15px;
    @media (max-width: 768px) {
        width: 390px;
        height: 210px;  
    }
`

export const BackRight = styled.img` 
    width:20%;
    position: absolute;
    right:0;
    top:10%;
`
export const BackLeft = styled.img` 
    width:40%;
    position: absolute;
    left:0;
    margin-top: 30%;
`