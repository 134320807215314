import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_BOOKMARK_ARTICLES,
    getBookmarkArticlesFail,
    getBookmarkArticlesSuccess
} from '../action/getBookmarkArticlesAction'

// api 
import getBookmarkArticlesApi from '../api/getBookmarkArticlesApi'

export function* getBookmarkArticlesSaga(action){

    try{
        const response = yield call(()=> getBookmarkArticlesApi(action.params))  
        if(response.data.status === 1){
            yield put(getBookmarkArticlesSuccess(response, action)) 
        }else
        {
            yield put(getBookmarkArticlesFail(response, action))
        }
    }
    catch(e){
        yield put(getBookmarkArticlesFail(e.response, action))
    }
}

export default function* mySaga(){
    yield takeLatest(GET_BOOKMARK_ARTICLES, getBookmarkArticlesSaga)
}