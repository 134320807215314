import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
import { InsightsboxHeading, YourHelthBg } from "../../page/Home/HomeStyle";
import { YourHelthImg3 } from "../HealthBlock/HealthBlockStyle";
import {
  AtEasyboxFlex,
  AtEasyboxTiles,
  AtEasyColumn,
  XImg,
} from "./ServicesStyle";
import "./Services.css";
import { Col, Row, Space } from "antd";

function LilacInsightsServices(props) {
  const { serviceList } = props;

  return (
    <>
      <YourHelthBg>
        <div className="atEasySpace mt-0">
          <div className="container">
            <h1 className="heading-home1 text-center top-space">
              Services at Lilac Insights
            </h1>
            <div className="row">
              {serviceList.map((item, index) => {
                return (
                  <>
                    <AtEasyColumn
                      style={
                        {
                          //height: "300px", // Fixed height for the card
                        }
                      }
                      key={index}
                      className="col-12 col-md-6 col-lg-3  mb-4 text-center"
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname:
                            item.redirectTo === "gcExpertList"
                              ? "/expert-detail"
                              : "/article/" + item.id,
                          state:
                            item.redirectTo === "gcExpertList"
                              ? { type: "GC" }
                              : {
                                  id: item.id,
                                },
                        }}
                      >
                        <div className="atEasyHoverImg">
                          <Row gutter={(15, 15)} className="align-items-center">
                            <Col xs={24} sm={8} lg={8} className="text-center">
                              <img
                                style={{
                                  // objectFit: "contain",
                                  filter:
                                    "invert(29%) sepia(60%) saturate(5356%) hue-rotate(270deg) brightness(95%) contrast(87%)",
                                  maxWidth: "60px",
                                }}
                                class="img-fluid"
                                src={item.imageIcon}
                                alt="no"
                              />
                            </Col>
                            <Col
                              xs={24}
                              sm={16}
                              lg={16}
                              className="text-center mt-3 mt-md-0 text-md-start"
                            >
                              <InsightsboxHeading>
                                {item.title}
                              </InsightsboxHeading>
                            </Col>
                          </Row>
                        </div>
                        <div className="atEasyHoverImg d-none">
                          <div className="row align-items-center">
                            <div className="col-3 d-flex justify-content-center">
                              <img
                                style={{
                                  // maxHeight: "40%", // Adjusted to fit better
                                  // maxWidth: "40%", // Adjusted to fit better
                                  objectFit: "contain",
                                  filter:
                                    "invert(29%) sepia(60%) saturate(5356%) hue-rotate(270deg) brightness(95%) contrast(87%)",
                                }}
                                class="img-fluid"
                                src={item.imageIcon}
                                alt="no"
                              />
                            </div>

                            <div className="col-9 d-flex justify-content-center">
                              <InsightsboxHeading>
                                {item.title}
                              </InsightsboxHeading>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </AtEasyColumn>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        {/* </div> */}
      </YourHelthBg>
    </>
  );
}

export default LilacInsightsServices;
