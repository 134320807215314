import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    UPDATE_PAYMENT,
    updatePaymentSuccess,
    updatePaymentFail
} from '../action/updatePaymentAction'

// api 
import updatePaymentApi from '../api/updatePaymentApi'

export function* updatePaymentSaga(action) {
    try {
        const response = yield call(() => updatePaymentApi(action.params))
        if (response.data.status === 1) {
            yield put(updatePaymentSuccess(response, action))
        } else {
            yield put(updatePaymentFail(response, action))
        }
    } catch (e) {
        yield put(updatePaymentFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(UPDATE_PAYMENT, updatePaymentSaga)
}