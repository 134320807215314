export const SEND_OTP = 'SendOtp'
export const SEND_OTP_SUCCESS = 'SendOtp_SUCCESS'
export const SEND_OTP_FAIL = 'SendOtp_FAIL'

export const sendOtp = (params) => {
    return { type: SEND_OTP, params }
}

export const sendOtpSuccess = (response) => {
    return { type: SEND_OTP_SUCCESS, response }
}

export const sendOtpFail = (response) => {
    return { type: SEND_OTP_FAIL, response }
}