import { combineReducers } from "redux";

// reducers
import login from "./reducer/loginReducer";
import getArticleBySlug from "./reducer/getArticleBySlugReducer";
import getBookTest from "./reducer/getBookTestReducer";
import getRead from "./reducer/getReadReducer";
import getListen from "./reducer/getListenReducer";
import getWatch from "./reducer/getWatchReducer";
import getPatientDetailForIntakeForm from "./reducer/getPatientDetailForIntakeFormReducer";
import savePatientIntakeForm from "./reducer/savePatientIntakeFormReducer";
import saveArticleLike from "./reducer/saveArticleLikeReducer";
import saveArticleBookmark from "./reducer/saveArticleBookmarkReducer";
import register from "./reducer/registerReducer";
import saveRegisterData from "./reducer/saveRegisterDataReducer";
import sendOtp from "./reducer/sendOtpReducer";
import validateOtp from "./reducer/validateOtpReducer";
import getDoctorDetail from "./reducer/getDoctorDetailReducer";
import getScheduleTimingList from "./reducer/getScheduleTimingListReducer";
import getArticleAppList from "./reducer/getArticleAppListReducer";
import getPregHighlightAppList from "./reducer/getPregHighlightAppListReducer";
import getDoctorList from "./reducer/getDoctorListReducer";
import savePatientProfile from "./reducer/savePatientProfileReducer";
import getTribeArticleList from "./reducer/getTribeArticleListReducer";
import getUpcomingAppointment from "./reducer/getUpcomingAppointmentReducer";
import getConsultationHistory from "./reducer/getConsultationHistoryReducer";
import getUpcomingConsultation from "./reducer/getUpcomingConsultationReducer";
import savePaymentData from "./reducer/savePaymentDataReducer";
import getBookmarkArticles from "./reducer/getBookmarkArticlesReducer";
import getChatBot from "./reducer/getChatBotReducer";
import getBookmarkArticlesReducer from "./reducer/getBookmarkArticlesReducer";
import getAppointmentPayment from "./reducer/getAppointmentPaymentReducer";
import getAppointmentDetails from "./reducer/getAppointmentDetailsReducer";
import getAppointmentHistoryReducer from "./reducer/getAppointmentHistoryReducer";
import getQuickSupport from "./reducer/getQuickSupportReducer";
import getMoodList from "./reducer/getMoodListReducer";
import saveMoodTrack from "./reducer/saveMoodTrackReducer";
import bookingAppointmentActivityState from "./reducer/bookingAppointmentActivityReducer";
import saveAppointmentFeedback from "./reducer/saveAppointmentFeedbackReducer";
import getDoctorSearch from "./reducer/getDoctorSearchReducer";
import bookAppointment from "./reducer/bookAppointmentReducer";
import validateCoupon from "./reducer/validateCouponReducer";
import updatePayment from "./reducer/updatePaymentReducer";
import getServicesList from "./reducer/getServicesListReducer";
import getArticle from "./reducer/getArticleReducer";

export default combineReducers({
  login,
  register,
  getArticleBySlug,
  getRead,
  getDoctorDetail,
  getListen,
  getWatch,
  getPatientDetailForIntakeForm,
  savePatientIntakeForm,
  saveArticleLike,
  saveArticleBookmark,
  saveRegisterData,
  sendOtp,
  validateOtp,
  getScheduleTimingList,
  getArticleAppList,
  getDoctorList,
  getPregHighlightAppList,
  savePatientProfile,
  getTribeArticleList,
  getUpcomingAppointment,
  getConsultationHistory,
  getUpcomingConsultation,
  savePaymentData,
  getBookmarkArticles,
  getChatBot,
  getBookmarkArticlesReducer,
  getAppointmentPayment,
  getAppointmentDetails,
  getAppointmentHistoryReducer,
  getBookTest,
  getQuickSupport,
  getMoodList,
  bookingAppointmentActivityState,
  saveMoodTrack,
  saveAppointmentFeedback,
  getDoctorSearch,
  bookAppointment,
  validateCoupon,
  updatePayment,
  getServicesList,
  getArticle,
});
