import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getUpcomingConsultation = async (params) => { 
  const response = await axios({
    url: `${config.api.base_url}patient/upcoming-bookings-by-doctor?doctor_id=${params.doctor}`,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });  
  return response; 
};

export default getUpcomingConsultation;
// /${params.type}