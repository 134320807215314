import {
    VALIDATE_COUPON,
    VALIDATE_COUPON_SUCCESS,
    VALIDATE_COUPON_FAIL,
} from '../action/validateCouponAction'
import { api_err_msg } from "../strings.json"

const initialState = {
    apiState: "", // loading, success, error
    message: "",
    data: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case VALIDATE_COUPON:
            return {
                ...state,
                apiState: "loading",
            }

        case VALIDATE_COUPON_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }

        case VALIDATE_COUPON_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.data.message
            }

        default:
            return state
    }
}
