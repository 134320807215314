import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import HealthBlock from "../../component/HealthBlock/HealthBlock";
import LilacInsightsServices from "../../component/LilacInsights/Services";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";
import {
  getServicesList,
  getServicesListReset,
} from "../../action/getServicesListAction";

const YourHealthAndServices = (props) => {
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const { getServicesListState, getServicesList, getServicesListReset } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    getServicesList();
  }, []);

  useEffect(() => {
    if (getServicesListState.apiState === "success") {
      setServiceList(getServicesListState?.data);
    }
    getServicesListReset();
  }, [getServicesListState]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  return (
    <div>
      <section className="pt-5">
        <HealthBlock />
        <LilacInsightsServices serviceList={serviceList} />
      </section>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getServicesListState: state.getServicesList,
});

const mapDispatchToProps = (dispatch) => ({
  getServicesList: (params) => dispatch(getServicesList(params)),
  getServicesListReset: () => dispatch(getServicesListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourHealthAndServices);
