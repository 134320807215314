import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "antd/dist/antd.less";
import "../src/assets/css/responsive.css";
// import "antd/dist/antd.css";
import { Layout } from "antd";
import { Provider } from "react-redux";
import store from "./store";

//CUSTOM COMPONENTS
import PageBody from "./component/PageBody/PageBody";
import PageContainer from "./component/PageContainer/PageContainer";
import Header from "./component/Header/Header";
import PrivateRoute from "./component/PrivateRoute";
import Footer from "./component/Footer/Footer";

//OTHER
import theme from "./theme";

//PAGES
import Login from "./page/login/Login.js";
import Home from "./page/Home/Home.js";
import Read from "./page/Read/Read.js";
import Watch from "./page/Watch/Watch.js";
import Listen from "./page/Listen/Listen";
import PatientIntakeForm from "./page/PatientIntakeForm/PatientIntakeForm";
import Article from "./page/Article/Article";
import Service from "./page/Service/Service";
import Registration from "./page/Registration/Registration";
import NonPregHome from "./page/NonPregHome/NonPregHome";
import PatientProfile from "./page/PatientProfile/PatientProfile";
import Doctor from "./page/Doctor/Doctor";
import DoctorDetail from "./page/DoctorDetail/DoctorDetail";
import DoctorAppoint from "./page/DoctorAppoint/DoctorAppoint";
import Consult from "./page/Consult/Consult";
import ExpertDetail from "./page/ExpertDetail/ExpertDetail";
import SupportTribe from "./page/SupportTribe/SupportTribe";
import ConsultationBooking from "./page/ConsultationBooking/ConsultationBooking";
import Bookings from "./page/Bookings/Bookings";
import BookTest from "./page/BookTest/BookTest";
import BookmarkArticle from "./page/BookmarkArticle/BookmarkArticle";
import MyReport from "./page/MyReport/MyReport";
import TextArticle from "./page/TextArticle/TextArticle";
import VideoArticle from "./page/VideoArticle/VideoArticle";
import PatientAppointmentPayment from "./page/PatientAppointmentPayment/PatientAppointmentPayment";
import PatientAppointmentPaymentSuccess from "./page/PatientAppointmentPaymentSuccess/PatientAppointmentPaymentSuccess";
import Gifts from "./page/Gifts/Gifts";
import AudioArticle from "./page/AudioArticle/AudioArticle";
import SupportTribeCompile from "./page/SupportTribeCompile/SupportTribeCompile";
import ArticleList from "./page/ArticleList/ArticleList";
import AppointmentMeeting from "./page/AppointmentMeeting/AppointmentMeeting";
import Appointments from "./page/Appointments/Appointments";
import BookingDetails from "./page/BookingDetails/BookingDetails";
import AboutUs from "./page/AboutUs/AboutUs";
import UserProvider from "./Provider/UserProvider";

import ContactUs from "./page/ContactUs/ContactUs";
import QuickSupport from "./page/QuickSupport/QuickSupport";
import WellKnown from "./page/WellKnown/Wellknown";
import YourHealthAndServices from './page/YourHealth/YourHealth.js'

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <Switch>
                <Route
                  path={[
                    "/",
                    "/.well-known",
                    "/gifts",
                    "/tribe-articles",
                    "/audio-article",
                    "/text-article",
                    "/video-article",
                    "/watch",
                    "/non",
                    "/listen",
                    "/bookmark-articles",
                    "/profile",
                    "/consult",
                    "/booking",
                    "/consultation",
                    "/yourhealth",
                    "/doctor-detail",
                    "/doctor-appoint",
                    "/expert-detail",
                    "/support-tribe",
                    "/book-test",
                    "/consultation-booking",
                    "/my-reports",
                    "/ArticleList",
                    "/read",
                    "/ContactUs",
                    "/appointment-details",
                    "/appointment/meeting/:id",
                    "/Appointments",
                    "/Appointments/:id",
                    "/quick-support",
                  ]}
                  exact
                >
                  <Layout>
                    <PageBody>
                      <Header />
                      <PageContainer>
                        <Switch>
                          <Route
                            exact
                            path="/.well-known"
                            component={WellKnown}
                          />
                          <PrivateRoute exact path="/" component={Home} />

                          <PrivateRoute
                            exact
                            path="/bookmark-articles"
                            component={BookmarkArticle}
                          />
                          <PrivateRoute
                            exact
                            path="/non"
                            component={NonPregHome}
                          />
                          {/* <PrivateRoute exact path='/read' component={Read} /> */}
                          <PrivateRoute
                            exact
                            path="/text-article"
                            component={TextArticle}
                          />
                          <PrivateRoute
                            exact
                            path="/video-article"
                            component={VideoArticle}
                          />
                          <PrivateRoute
                            exact
                            path="/video-article"
                            component={Read}
                          />
                          {/* <PrivateRoute exact path='/watch' component={Watch} /> */}
                          <PrivateRoute
                            exact
                            path="/tribe-articles"
                            component={SupportTribeCompile}
                          />
                          <PrivateRoute
                            exact
                            path="/listen"
                            component={Listen}
                          />
                          <PrivateRoute
                            exact
                            path="/consultation"
                            component={Doctor}
                          />
                            <PrivateRoute
                            exact
                            path="/yourhealth"
                            component={YourHealthAndServices}
                          />
                          
                          <PrivateRoute
                            exact
                            path="/doctor-detail"
                            component={DoctorDetail}
                          />
                          <PrivateRoute
                            exact
                            path="/consult"
                            component={Consult}
                          />
                          <PrivateRoute
                            exact
                            path="/expert-detail"
                            component={ExpertDetail}
                          />
                          <PrivateRoute
                            exact
                            path="/doctor-appoint"
                            component={DoctorAppoint}
                          />
                          <PrivateRoute
                            exact
                            path="/profile"
                            component={PatientProfile}
                          />
                          <PrivateRoute
                            exact
                            path="/support-tribe"
                            component={SupportTribe}
                          />
                          <PrivateRoute
                            exact
                            path="/consultation-booking"
                            component={ConsultationBooking}
                          />
                          <PrivateRoute
                            exact
                            path="/booking"
                            component={Bookings}
                          />
                          <PrivateRoute
                            exact
                            path="/book-test"
                            component={BookTest}
                          />
                          <PrivateRoute
                            exact
                            path="/my-reports"
                            component={MyReport}
                          />
                          <PrivateRoute exact path="/gifts" component={Gifts} />
                          <PrivateRoute
                            exact
                            path="/audio-article"
                            component={AudioArticle}
                          />
                          <PrivateRoute
                            exact
                            path="/ArticleList"
                            component={ArticleList}
                          />
                          {/* <PrivateRoute exact path='/ArticleList' component={ArticleList}/> */}
                          <PrivateRoute
                            exact
                            path="/read"
                            component={ArticleList}
                          />
                          <PrivateRoute
                            exact
                            path="/Appointments/:id"
                            component={Appointments}
                          />
                          <PrivateRoute
                            exact
                            path="/Appointments"
                            component={Appointments}
                          />

                          <PrivateRoute
                            exact
                            path="/watch"
                            component={ArticleList}
                          />
                          <PrivateRoute
                            exact
                            path="/ContactUs"
                            component={ContactUs}
                          />
                          <PrivateRoute
                            exact
                            path="/appointment-details"
                            component={BookingDetails}
                          />
                          <PrivateRoute
                            exact
                            path="/appointment/meeting/:id"
                            component={AppointmentMeeting}
                          />
                          <PrivateRoute
                            exact
                            path="/quick-support"
                            component={QuickSupport}
                          />
                        </Switch>
                      </PageContainer>
                    </PageBody>
                    <Footer />
                  </Layout>
                </Route>

                <Route
                  path={[
                    "/login",
                    "/registeration/register",
                    "/registeration/genderDob",
                    "/registeration/interest",
                    "/about-us",
                  ]}
                  exact
                >
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                      exact
                      path="/registeration/register"
                      component={Registration}
                    />
                    <Route
                      exact
                      path="/registeration/genderDob"
                      component={Registration}
                    />
                    <Route
                      exact
                      path="/registeration/interest"
                      component={Registration}
                    />
                    <Route exact path="/about-us" component={AboutUs} />
                  </Switch>
                </Route>

                <Route
                  path={["/article/:slug", "/video/:slug", "/audio/:slug"]}
                  exact
                >
                  <Header />
                  <Switch>
                    {/* <PrivateRoute
                      exact
                      path="/article/:slug"
                      component={Article}
                    /> */}
                    <PrivateRoute
                      exact
                      path="/article/:id"
                      component={Service}
                    />
                    <PrivateRoute
                      exact
                      path="/video/:slug"
                      component={VideoArticle}
                    />
                    <PrivateRoute
                      exact
                      path="/audio/:slug"
                      component={AudioArticle}
                    />
                  </Switch>
                  <Footer />
                </Route>

                <Route
                  path={[
                    "/patient-intake-form/:book_appointment_id",
                    "/patient-appointment-payment/:book_appointment_id",
                    "/patient-appointment-success/:book_appointment_id",
                  ]}
                  exact
                >
                  <Layout>
                    <PageBody>
                      <PageContainer>
                        <Switch>
                          <Route
                            exact
                            path="/patient-intake-form/:book_appointment_id"
                            component={PatientIntakeForm}
                          />
                          <Route
                            exact
                            path="/patient-appointment-payment/:book_appointment_id"
                            component={PatientAppointmentPayment}
                          />
                          <Route
                            exact
                            path="/patient-appointment-success/:book_appointment_id"
                            component={PatientAppointmentPaymentSuccess}
                          />
                        </Switch>
                      </PageContainer>
                    </PageBody>
                  </Layout>
                </Route>
              </Switch>
            </Router>
          </ThemeProvider>
        </UserProvider>
      </Provider>
    </div>
  );
}

export default App;
