export const SAVE_APPOINTMENT_FEEDBACK = "SAVE_APPOINTMENT_FEEDBACK"
export const SAVE_APPOINTMENT_FEEDBACK_FAIL = "SAVE_APPOINTMENT_FEEDBACK_FAIL"
export const SAVE_APPOINTMENT_FEEDBACK_SUCCESS = "SAVE_APPOINTMENT_FEEDBACK_SUCCESS" 
export const SAVE_APPOINTMENT_FEEDBACK_RESET = "SAVE_APPOINTMENT_FEEDBACK_RESET"

export const saveAppointmentFeedback = (params) => {
    return { type: SAVE_APPOINTMENT_FEEDBACK, params }
}
export const saveAppointmentFeedbackFail = (response) => {
    return { type: SAVE_APPOINTMENT_FEEDBACK_FAIL, response }
}
export const saveAppointmentFeedbackSuccess = (response) => {
    return { type: SAVE_APPOINTMENT_FEEDBACK_SUCCESS, response }
}
export const saveAppointmentFeedbackReset = (response) => {
    return { type: SAVE_APPOINTMENT_FEEDBACK_RESET, response }
} 