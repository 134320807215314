import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

import reducer from "./reducer";

// sagas
import loginSaga from "./saga/loginSaga";
import getArticleBySlugSaga from "./saga/getArticleBySlugSaga";
import getBookTestSaga from "./saga/getBookTestSaga";
import getReadSaga from "./saga/getReadSaga";
import getListenSaga from "./saga/getListenSaga";
import getWatchSaga from "./saga/getWatchSaga";
import savePatientIntakeFormSaga from "./saga/savePatientIntakeFormSaga";
import getPatientDetailForIntakeFormSaga from "./saga/getPatientDetailForIntakeFormSaga";
import saveArticleLikeSaga from "./saga/saveArticleLikeSaga";
import saveArticleBookmarkSaga from "./saga/saveArticleBookmarkSaga";
import registerSaga from "./saga/registerSaga";
import sendOtpSaga from "./saga/sendOtpSaga";
import validateOtpSaga from "./saga/validateOtpSaga";
import getDoctorDetailSaga from "./saga/getDoctorDetailSaga";
import getScheduleTimingListSaga from "./saga/getScheduleTimingListSaga";
import getArticleAppListSaga from "./saga/getArticleAppListSaga";
import getPregHighlightAppListSaga from "./saga/getPregHighlightAppListSaga";
import getDoctorListSaga from "./saga/getDoctorListSaga";
import savePatientProfileSaga from "./saga/savePatientProfileSaga";
import getTribeArticleListSaga from "./saga/getTribeArticleListSaga";
import getUpcomingAppointmentSaga from "./saga/getUpcomingAppointmentSaga";
import getAppoinmentHistorySaga from "./saga/getAppointmentHistorySaga";
import getUpcomingConsultationSaga from "./saga/getUpcomingConsultationSaga";
import getConsultationHistorytSaga from "./saga/getConsultationHistorySaga";
import getBookmarkArticlesSaga from "./saga/getBookmarkArticlesSaga";
import getChatBotSaga from "./saga/getChatBotSaga";
import getAppointmentPaymentSaga from "./saga/getAppointmentPaymentSaga";
import getAppointmentDetailsSaga from "./saga/getAppointmentDetailsSaga";
import getQuickSupportSaga from "./saga/getQuickSupportSaga";
import getMoodListSaga from "./saga/getMoodListSaga";
import saveMoodTrackSaga from "./saga/saveMoodTrackSaga";
import bookingAppointmentActivitySaga from "./saga/bookingAppointmentActivitySaga";
import saveAppointmentFeedbackSaga from "./saga/saveAppointmentFeedbackSaga";
import getDoctorSearchSaga from "./saga/getDoctorSearchSaga";
import bookAppointmentSaga from "./saga/bookAppointmentSaga";
import validateCouponSaga from "./saga/validateCouponSaga";
import updatePaymentSaga from "./saga/updatePaymentSaga";
import getServicesListSaga from "./saga/getServicesListSaga";
import getArticleSaga from "./saga/getArticleSaga";
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

// then run the saga
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(registerSaga);
sagaMiddleware.run(getArticleBySlugSaga);
sagaMiddleware.run(getBookTestSaga);
sagaMiddleware.run(getReadSaga);
sagaMiddleware.run(getListenSaga);
sagaMiddleware.run(getWatchSaga);
sagaMiddleware.run(savePatientProfileSaga);
sagaMiddleware.run(savePatientIntakeFormSaga);
sagaMiddleware.run(saveArticleLikeSaga);
sagaMiddleware.run(saveArticleBookmarkSaga);
sagaMiddleware.run(getPatientDetailForIntakeFormSaga);
sagaMiddleware.run(sendOtpSaga);
sagaMiddleware.run(validateOtpSaga);
sagaMiddleware.run(getDoctorDetailSaga);
sagaMiddleware.run(getScheduleTimingListSaga);
sagaMiddleware.run(getArticleAppListSaga);
sagaMiddleware.run(getPregHighlightAppListSaga);
sagaMiddleware.run(getDoctorListSaga);
sagaMiddleware.run(getTribeArticleListSaga);
sagaMiddleware.run(getUpcomingAppointmentSaga);
sagaMiddleware.run(getConsultationHistorytSaga);
sagaMiddleware.run(getUpcomingConsultationSaga);
sagaMiddleware.run(getBookmarkArticlesSaga);
sagaMiddleware.run(getChatBotSaga);
sagaMiddleware.run(getDoctorListSaga);
sagaMiddleware.run(getTribeArticleListSaga);
sagaMiddleware.run(getUpcomingAppointmentSaga);
sagaMiddleware.run(getConsultationHistorytSaga);
sagaMiddleware.run(getUpcomingConsultationSaga);
sagaMiddleware.run(getBookmarkArticlesSaga);
sagaMiddleware.run(getAppointmentPaymentSaga);
sagaMiddleware.run(getAppointmentDetailsSaga);
sagaMiddleware.run(getAppoinmentHistorySaga);
sagaMiddleware.run(getQuickSupportSaga);
sagaMiddleware.run(getMoodListSaga);
sagaMiddleware.run(saveMoodTrackSaga);
sagaMiddleware.run(bookingAppointmentActivitySaga);
sagaMiddleware.run(saveAppointmentFeedbackSaga);
sagaMiddleware.run(getDoctorSearchSaga);
sagaMiddleware.run(bookAppointmentSaga);
sagaMiddleware.run(validateCouponSaga);
sagaMiddleware.run(updatePaymentSaga);
sagaMiddleware.run(getServicesListSaga);
sagaMiddleware.run(getArticleSaga);
// render the application
export default store;
