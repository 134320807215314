export const GET_MOOD_LIST = "GET_MOOD_LIST"
export const GET_MOOD_LIST_FAIL = "GET_MOOD_LIST_FAIL"
export const GET_MOOD_LIST_SUCCESS = "GET_MOOD_LIST_SUCCESS"

export const getMoodList = (params) => {
    return {type: GET_MOOD_LIST, params}
}

export const getMoodListFail = (response) => {
    return {type: GET_MOOD_LIST_FAIL, response}
}

export const getMoodListSuccess = (response) => {
    return {type: GET_MOOD_LIST_SUCCESS, response}
}
