import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CONSULTATION_HISTORY,
    getConsultationHistoryFail,
    getConsultationHistorySuccess
} from '../action/getConsultationHistoryAction'

// api 
import getConsultationHistoryApi from '../api/getConsultationHistoryApi'

export function* getConsultationHistorySaga(action) {
    try {
        const response = yield call(() => getConsultationHistoryApi(action.params))
        if (response.data.status === 1) {
            yield put(getConsultationHistorySuccess(response, action))
        } else {
            yield put(getConsultationHistoryFail(response, action))
        }
    }
    catch (e) {
        yield put(getConsultationHistoryFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CONSULTATION_HISTORY, getConsultationHistorySaga)
}