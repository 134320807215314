import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Typography, Space } from "antd";

export const FootContainer = styled.div``;

export const XTitle = styled.h1`
  width: 100%;
  font-family: Poppins;
  font-size: 28px;
  line-height: 25px;
  font-weight: bold;
  color: #ffffff;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 10px;
    line-height: 10px;
  }
`;

export const Xlink = styled(Link)`
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  :hover {
    text-decoration: underline;
    color: #ffffff;
  }
`;

// export const Slinks = styled(Link)`
//     color: #FFFFFF;
//     font-family: Poppins;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 16px;
//     line-height: 21px;
// 	text-decoration: none;
//     @media (min-width: 800px) and (max-width: 1024px){
//         width: 30%;
//         font-size: 10px;
//         line-height: 13px;
//     }
//     @media (min-width: 550px) and  (max-width: 800px){
//         width: 30%;
//         font-size: 10px;
//         line-height: 8px;
//     }
//     @media (min-width: 300px) and  (max-width: 580px){
//         width: 30%;
//         font-size: 10px;
//         line-height: 8px;
//     }
// `
export const XCol = styled.div`
  padding-left: 5%;
  width: 40%;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  @media (min-width: 300px) and (max-width: 580px) {
    width: 35%;
    font-size: 10px;
    line-height: 10px;
  }
`;
export const YCol = styled.div`
  padding-left: 15%;
  width: 35%;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  @media (min-width: 300px) and (max-width: 580px) {
    width: 15%;
    font-size: 10px;
    line-height: 10px;
  }
`;
export const PCol = styled.div`
  padding-left: 5%;
  width: 15%;
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 15%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 15%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
  }
`;
export const QCol = styled.div`
  padding-left: 5%;
  width: 30%;
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 30%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 30%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 35%;
    font-size: 10px;
    line-height: 10px;
  }
`;
export const RCol = styled.div`
  padding-left: 10%;
  width: 25%;
  font-weight: 600;
  font-family: Poppins;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 30%;
    font-size: 10px;
    line-height: 10px;
  }
`;
export const SCol = styled.div`
  padding-left: 5%;
  width: 25%;
  font-family: Poppins;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 30%;
    font-size: 10px;
    line-height: 10px;
    margin-top: 65%;
    margin-left: -70%;
  }
`;
export const XFoot = styled.div`
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 400;
  @media (max-width: 575px) {
    padding: 0px 15px;
  }
`;
export const Image = styled.img`
  // width: 150px;
  // height: 150px;
  margin-bottom: 2%;
  color: white;
  @media (max-width: 768px) {
    // margin-top: 1%;
  }
`;

export const Xdiv = styled.div`
  @media (min-width: 300px) and (max-width: 580px) {
    width: 200px;
  }
`;

export const XSpace = styled(Space)`
  // display: flex;
  // justify-content: space-between;
  width: 20%;
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 10px;
    width: 150px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const XButton = styled(Button)`
  margin-top: 3%;
  width: 280px;
  background-color: #ffffff;
  color: #a64fa6;
  border: none;
  height: 100%;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 230px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 140px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 90px;
    height: 3%;
  }
`;

export const BtnTxt = styled.p`
  font-size: 20px;
  margin-top: -10%;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
    margin-top: -20%;
    margin-left: -10%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 7px;
    margin-top: -50%;
    margin-left: -30%;
  }
`;

export const FooterBtn = styled.button`
  display: block;
  line-height: 35px;
  width: 100%;
  font-size: 22px;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #ffff !important;
  background: #ffffff;
  border: none;
  text-align: center;
  margin: 10px 0px;

  :hover {
    background: linear-gradient(270deg, #ff7cc9 0%, #cf509b 100%);
    color: #ffffff !important;
  }

  .invisiLink {
    display: block;
    color: #c473c4 !important;
  }
  .invisiLink:hover {
    color: #ffffff !important;
  }
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 14px;
    line-height: 16px !important;
    padding: 5px 0px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 5px 0px;
    .invisiLink {
      font-size: 14px;
    }
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 12px;
    .invisiLink {
      font-size: 14px;
    }
  }
  @media (max-width: 281px) {
    font-size: 12px;
    .invisiLink {
      font-size: 14px;
      padding: 0px;
    }
  }
`;

export const SocialButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width:15rem;
`;

export const GoogleStoreImg = styled.img`
  color: white;
  margin-top: 16px;
  @media (max-width: 768px) {
    width: 108px;
    height: 36px;
  }
`;

export const AppleStoreImg = styled.img`
  color: white;
  @media (max-width: 768px) {
    width: 108px;
    height: 36px;
  }
`;

