import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Typography,
  Radio,
  Space,
  Modal,
  Button,
  TextArea,
  notification,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";
import {
  CouponCard,
  GiftTitle,
  GiftHeader,
  Giftleft,
  GiftRight,
} from "./GiftStyle";
import { confirmAlert } from "react-confirm-alert";
import { Description } from "../Article/ArticleStyle";
import { CopyOutlined } from "@ant-design/icons";
import "./Gift.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";
import { updatePayment } from "../../action/updatePaymentAction";

const Gifts = (props) => {
  const {
    updatePaymentState,
    updatePayment,
  } = props;
  const [formAmount, setFormAmount] = useState("1500");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isCouponVisible, setIsCouponVisible] = useState(false);
  const [couponData, setCouponData] = useState([1, 2]);
  const [couponDataReadMore, setCouponDataReadMore] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  let fullName = JSON.parse(localStorage.getItem("name"));
  let name = `${fullName.firstName} ${fullName.lastName}`;
  let email = JSON.parse(localStorage.getItem("email"));
  let phone = JSON.parse(localStorage.getItem("phone"));
  console.log("name", name);

  ///payment
  const [payCheck, setPayCheck] = useState(false);
  const [response, setResponse] = useState();
  const [bookResponse, setBookResponse] = useState();
  const [redirect, setRedirect] = useState({
    state: false,
    pathname: "",
  });
  const [summryData, setSummryData] = useState({
    amount: "",
    _id: "",
    payment_session_id: "",
  });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [ cashfreeInstance, setCashfreeInstance ] = useState(null);
  ///payment
  const [formData, setFormData] = useState({
    discount: formAmount,
    consolation_type: "MHC",
    some_one_else: true,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    pMessage: "",
  });
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Paragraph, Title } = Typography;

  const amountOnChange = (e) => {
    console.log(e);
    setFormAmount(e);
  };
  //  console.log("amount",formAmount)

  function closeModal() {
    setIsModalVisible(false);
  }

  function closeModal2() {
    setIsModalVisible2(false);
  }

  const showModal = () => {
    setIsModalVisible(true);
  };
  //console.log(isModalVisible)

  const handleOk = () => {};
  const handleOk2 = () => {};

  const showMore = (id) => {
    let readMoreTemp = [...couponDataReadMore];
    readMoreTemp[id] = !readMoreTemp[id];
    setCouponDataReadMore(readMoreTemp);
  };

  const visibleCoupon = (e) => {
    setIsCouponVisible(true);
  };

  const onFinish = async (values) => {
    //console.log('Received values of form: ', values);
    //console.log('Success:', values.fromName);
    const saveGiftParams = {
      discount: formAmount,
      consolation_type: "MHC",
      some_one_else: true,
      first_name: values.fname,
      last_name: values.lname,
      phone: values.phone,
      email: values.email,
      pMessage: values.pMessage,
    };
    //setFormData(saveGiftParams)
    try {
      setLoading(true);
      const resp = await axios({
        url: `${config.api.base_url}coupon`,
        method: "post",
        data: saveGiftParams,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      });
      // console.log("response after saving coupon to db", resp);
      setBookResponse(() => resp.data);
      if (resp.data.message === "Coupon created successfully") {
        // console.log("After saving storing it in states");
        setSummryData({
          amount: formAmount,
          _id: resp.data.data.rzpRes.order_id,
          code: resp.data.data.code,
          payment_session_id: resp.data.data.rzpRes.payment_session_id,
        });
        setPayCheck(true);
        setIsModalVisible(false);
        // console.log("Done");
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      async function getCoupon() {
        setLoadingCoupons(true);
        const response = await fetch(`${config.api.base_url}coupon`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        });
        const resp = await response.json();
        console.log(response);
        // console.log("past coupons", resp.data.result);
        let readMoreTemp = resp.data.result.map(() => false);
        setCouponDataReadMore(readMoreTemp);
        setCouponData(resp.data.result);
      }
      getCoupon();
    } catch (error) {
      alert(error);
    } finally {
      setLoadingCoupons(false);
    }
  }, []);

  ////payment
  useEffect(() => {
    if (summryData._id != "") {
      // displayRazoraPay();
      displayCashfree();
    }
  }, [payCheck]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
    });
  }

  function error() {
    Modal.error({
      title: "Your Payment Failed",
      content: "Please try again",
      onOk() {
        setRedirect({ state: true, pathname: "/gifts" });
      },
    });
  }

  async function displayRazoraPay(params) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("razorpay failed are you online?");
      return;
    }

    if (!bookResponse.data) {
      alert(bookResponse.data.message);
      return;
    }
    const options = {
      key: config.payment_gateway_key,
      amount: parseInt(formAmount) * 100,
      currency: "INR",
      name: "Lilac Insights.",
      description: "Test Transaction",
      image: "/images/lilac-Insights-logo1.png",
      order_id: summryData._id,
      handler: function (response, bookResponse) {
        console.log("response=> ", response);
        console.log("book response=> ", bookResponse);
        Modal.success({
          title: <Title level={6} style={{ textAlign: "center" }}></Title>,
          content: (
            <div className="mt-4">
              <p>
                <strong>Email :</strong> {form.getFieldValue("email")}
              </p>
              <p>
                <strong>Mobile :</strong> {form.getFieldValue("staticphone")}
              </p>
              <p>
                <strong>Amount Paid :</strong>{" "}
                <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{formAmount}
              </p>
              <p>
                <strong>Transaction id :</strong> {response.razorpay_order_id}
              </p>
              <p>
                <strong>Coupon Code :</strong> {summryData.code}{" "}
                {
                  <CopyOutlined
                    onClick={() => copyCode(summryData.code)}
                    style={{ display: "inline-flex" }}
                  />
                }
              </p>
              <p>
                Coupon code has been sent to {form.getFieldValue("phone")} &{" "}
                {form.getFieldValue("emailTo")}
              </p>
            </div>
          ),
          onOk: () => {
            window.location.reload();
          },
        });
      },
      prefill: {
        contact: "8080808080",
        email: "ashish@aiolos.cloud",
      },
    };
    console.log("options", options);
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment failed", function (response) {
      error();
    });
  }

  function Terms() {
    return (
      <div>
        <Description
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(response.data.data.description),
          }}
        />
      </div>
    );
  }
  ////Payment end

  function copyCode(code) {
    navigator.clipboard.writeText(code);
    notification["success"]({
      message: "Coupon Code copied successfully.",
      placement: "bottomRight",
    });
  }

  async function displayCashfree(params)
  {
    console.log(bookResponse);

    if (!bookResponse.data) {
      alert(bookResponse.message);
      return;
    }

    if(!summryData.payment_session_id)
    {
      notification.error({
        message: "Unable to make payment"
      });
      return;
    }
     
    setCashfreeInstance(new window.Cashfree(summryData.payment_session_id));
  }

  useEffect(()=>{
    if(cashfreeInstance!==null)
    {
      const dropinConfig = {
        components: [
          "order-details",
          "card",
          "netbanking",
          "app",
          "upi",
        ],
        onSuccess: function(data){
          console.log("Parvez Ahmed====>",data);
          setCashfreeInstance(null);
          setShowPaymentModal(false);
          updatePayment({
            payment_for: "coupon",
            platform: 'web',
            paymentDetails: data, 
          });
          Modal.success({
            title: <Title level={6} style={{ textAlign: "center" }}></Title>,
            content: (
              <div className="mt-4">
                <p>
                  <strong>Email :</strong> {form.getFieldValue("email")}
                </p>
                <p>
                  <strong>Mobile :</strong> {form.getFieldValue("staticphone")}
                </p>
                <p>
                  <strong>Amount Paid :</strong>{" "}
                  <i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{formAmount}
                </p>
                <p>
                  <strong>Transaction id :</strong> {data.razorpay_order_id}
                </p>
                <p>
                  <strong>Coupon Code :</strong> {summryData.code}{" "}
                  {
                    <CopyOutlined
                      onClick={() => copyCode(summryData.code)}
                      style={{ display: "inline-flex" }}
                    />
                  }
                </p>
                <p>
                  Coupon code has been sent to {form.getFieldValue("phone")} &{" "}
                  {form.getFieldValue("emailTo")}
                </p>
              </div>
            ),
            onOk: () => {
              window.location.reload();
            },
          });  
        },
        onFailure: function(data){
          setCashfreeInstance(null);
          setShowPaymentModal(false);
          updatePayment({
            payment_for: "coupon",
            paymentDetails: data, 
          });
          error();
        },
        style: {
              //to be replaced by the desired values
              backgroundColor: "#ffffff",
              color: "#865590", 
              fontFamily: "Lato",
              fontSize: "14px",
              errorColor: "#ff0000",
              theme: "light",
        }
      } 
      setShowPaymentModal(true);
      setTimeout(() => {  
        let paymentForm = document.createElement('div');
        paymentForm.id = "paymentForm";
        document.getElementById('paymentDiv').appendChild(paymentForm);
        cashfreeInstance.drop(document.getElementById('paymentForm'),dropinConfig);
      }, 100);
    }
    else
    {
      let removePaymentForm = document.getElementById('paymentForm');
      if(removePaymentForm)
      {
        removePaymentForm.remove();
      }
    }
  },[cashfreeInstance]);

  const closePaymentModal = () => {
    setCashfreeInstance(null);
    setShowPaymentModal(false);
    setPayCheck(false);
  };

  return (
    <div className="container">
      <div className="pageWrap">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12">
            <GiftHeader>
              <GiftTitle>Gift a Session</GiftTitle>
            </GiftHeader>
          </div>
          <div className="col-12 col-md-12 col-sm-12 col-lg-8 col-xl-8">
            <Giftleft>
              <GiftTitle>The Gift of Therapy</GiftTitle>
              <p className="gifttext">
                Sometimes, we need an extra push, Show a loved one that care
                with a therapy gift card
              </p>
              <div className="row">
                <div className="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                  <img className="" src="/images/svg/gift-logo.svg" />
                </div>
                <div className="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6">
                  <div className="cont">
                    <h5 className="headvalue">
                      Value: &nbsp;<i class="fa fa-inr" aria-hidden="true"></i>
                      {formAmount}
                    </h5>
                    <Select
                      name="amount"
                      className="amount"
                      placeholder="Change Amount"
                      size="large"
                      onChange={amountOnChange}
                    >
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={1500}>1500</option>
                      <option value={2000}>2000</option>
                      <option value={2500}>2500</option>
                      <option value={3000}>3000</option>
                      <option value={3500}>3500</option>
                      <option value={4000}>4000</option>
                      <option value={4500}>4500</option>
                      <option value={5000}>5000</option>
                    </Select>
                    <div className="clearfix"></div>
                    <button onClick={showModal} className="btngift">
                      Buy
                    </button>
                  </div>

                  <Modal
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={closeModal}
                    footer={null}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h5 className="mb-2">Required Information</h5>
                        <Form
                          form={form}
                          onFinish={onFinish}
                          layout={"vertical"}
                          requiredMark={true}
                          className="giftForm"
                          autoComplete="off"
                          initialValues={{
                            fromName: name,
                            staticphone: phone,
                            email,
                            readCheck: false,
                            ageCheck: false,
                          }}
                        >
                          <Form.Item
                            label={<e className="lableGift">From</e>}
                            name="fromName"
                            rules={[
                              { required: true, message: "Required!" },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message: "Enter a valid Name",
                              },
                            ]}
                          >
                            <Input
                              readOnly
                              name="fromName"
                              value="name"
                              onChange={() => {
                                console.log("input");
                              }}
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={<e className="lableGift">Phone</e>}
                            name="staticphone"
                            value="9999999"
                            rules={[
                              {
                                required: true,
                                message: "Please input your phone number!",
                              },
                              () => ({
                                validator(_, value) {
                                  if (!value || value.match(/^[6-9]\d{9}$/)) {
                                    return Promise.resolve().then(function (
                                      value
                                    ) {
                                      //console.log(value, 'success')
                                    });
                                  }
                                  return Promise.reject(
                                    new Error("Please check your phone number!")
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input
                              readOnly
                              name="staticphone"
                              maxLength={10}
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={<e className="lableGift">Email</e>}
                            name="email"
                            rules={[
                              { required: true, message: "Required!" },
                              {
                                type: "email",
                                message: "Enter a Valid email",
                              },
                            ]}
                          >
                            <Input
                              readOnly
                              name="emailFrom"
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <h5>To</h5>

                          <Form.Item
                            label={<e className="lableGift">First Name</e>}
                            name="fname"
                            initialValue="Parvez"
                            rules={[
                              { required: true, message: "Required!" },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message: "Enter a valid Name",
                              },
                            ]}
                          >
                            <Input
                              name="fname"
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={<e className="lableGift">Last Name</e>}
                            name="lname"
                            initialValue="Ahmed"
                            rules={[
                              { required: true, message: "Required!" },
                              {
                                pattern: new RegExp(/^[a-zA-Z\s]*$/),
                                message: "Enter a valid Name",
                              },
                            ]}
                          >
                            <Input
                              name="lname"
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={<e className="lableGift">Phone</e>}
                            name="phone"
                            initialValue="9768867388"
                            rules={[
                              {
                                required: true,
                                message: "Please input your phone number!",
                              },
                              () => ({
                                validator(_, value) {
                                  if (!value || value.match(/^[6-9]\d{9}$/)) {
                                    return Promise.resolve().then(function (
                                      value
                                    ) {
                                      //console.log(value, 'success')
                                    });
                                  }
                                  return Promise.reject(
                                    new Error("Please check your phone number!")
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input
                              name="phone"
                              maxLength={10}
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={<e className="lableGift">Email</e>}
                            name="emailTo"
                            initialValue="parvez@gmail.com"
                            rules={[
                              { required: true, message: "Required!" },
                              {
                                type: "email",
                                message: "Enter a Valid email",
                              },
                            ]}
                          >
                            <Input
                              name="email"
                              className="giftInput"
                              size="large"
                            />
                          </Form.Item>

                          <Form.Item
                            label={
                              <e className="lableGift">Personalized Message</e>
                            }
                            name="pMessage"
                            rules={[{ required: false }]}
                          >
                            <TextArea
                              name="short_description"
                              className="giftInput"
                              placeholder="Enter Short Description"
                            />
                          </Form.Item>

                          <Form.Item className="col-12 text-center mt-3">
                            <button
                              size="large"
                              shape="round"
                              type="primary"
                              htmlType="submit"
                              className="themeBtn"
                              disabled={loading}
                            >
                              {loading ? <Spin spinning={loading} /> : "Next"}
                            </button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    visible={isModalVisible2}
                    onOk={handleOk2}
                    onCancel={closeModal2}
                    footer={null}
                  >
                    <div className="row">
                      <div className="col-sm-12 pt-4 pb-4 text-center">
                        <h3>
                          <i
                            class="fa fa-check text-success mr-3"
                            aria-hidden="true"
                          ></i>
                          Coupon created successfully
                        </h3>
                        <button
                          onClick={closeModal2}
                          size="large"
                          shape="round"
                          className="themeBtn mt-4"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </Giftleft>
          </div>
          <div className="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
            <GiftRight>
              <div className="couponScroll">
                <div className="copn">
                  <CouponCard>
                    <h3 style={{ textAlign: "center" }} className="mb-3 ">
                      Coupon History
                    </h3>
                    <hr />
                    {/* {console.log("couponData", couponData)} */}
                  </CouponCard>
                  {loadingCoupons ? (
                    <div className="text-center">
                      <Spin spinning={loadingCoupons} tip="Loading..." />
                    </div>
                  ) : couponData.length > 0 ? (
                    couponData.map((data, id) => (
                      <>
                        <CouponCard>
                          <div className="row">
                            <div className="col-6 text-left">
                              <h5 className="giftValueHead mt-0">
                                {data.code}
                              </h5>
                            </div>
                            <div className="col-6 text-right">
                              {data.Expired == "Expired" && (
                                <span
                                  className={"couponStatus couponStatusInUse"}
                                >
                                  Expired
                                </span>
                              )}
                              {data.Expired != "Expired" &&
                                data.used == "Used" && (
                                  <span
                                    className={"couponStatus couponStatusUsed"}
                                  >
                                    Used
                                  </span>
                                )}
                              {data.Expired != "Expired" &&
                                data.used == "Not Used" && (
                                  <span
                                    className={"couponStatus couponStatusInUse"}
                                  >
                                    In use
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6">
                              <Paragraph
                                style={{ color: "#C473C4" }}
                                copyable={{ text: data.code }}
                              >
                                {" "}
                                Copy
                              </Paragraph>
                            </div>
                            <div className="col-6 text-right">
                              <h5 className="giftValueHead mt-0">
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                {data.discount}
                              </h5>
                            </div>
                          </div>
                          <div className="row mt-1 text-right">
                            <div className="col-12">
                              <spam
                                style={{ cursor: "pointer", color: "#a54ea5" }}
                                onClick={(e) => showMore(id)}
                              >
                                {couponDataReadMore[id] ? (
                                  <>
                                    {" "}
                                    Show Less &nbsp;
                                    <svg
                                      width="16"
                                      height="10"
                                      viewBox="0 0 16 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.3335 8.5L8.00016 1.83333L14.6668 8.5"
                                        stroke="#a54ea5"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    Read More &nbsp;
                                    <svg
                                      width="16"
                                      height="10"
                                      viewBox="0 0 16 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.33325 1.5L7.99992 8.16667L14.6666 1.5"
                                        stroke="#a54ea5"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </>
                                )}
                              </spam>
                            </div>
                          </div>
                          {couponDataReadMore[id] && (
                            <>
                              <div className="row mt-2">
                                <div className="col-12">
                                  <strong>First Name </strong>
                                  {data.patient_id.name.firstName}
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-12">
                                  <strong>Last Name </strong>
                                  {data.patient_id.name.lastName}
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-12">
                                  <strong>Mobile </strong>
                                  {data.patient_id.phone}
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-12">
                                  <strong>Email Address </strong>
                                  {data.patient_id.email}
                                </div>
                              </div>
                            </>
                          )}
                          <hr />
                        </CouponCard>
                      </>
                    ))
                  ) : (
                    <p className="pt-5 text-center">No coupon available</p>
                  )}
                </div>
              </div>
            </GiftRight>
          </div>
        </div>
      </div>

      <Modal
        title="Make payment"
        visible={showPaymentModal}
        footer={null} 
        onCancel={closePaymentModal}
      >
        <div id="paymentDiv">

        </div>
      </Modal>

      {/*<div className="pageWrap">*/}
      {/*    <div className="row">*/}
      {/*        <div className="col-12 text-center mb-2">*/}
      {/*            <h1 level={1}>Gift a Session</h1>*/}
      {/*        </div>*/}
      {/*    </div>*/}

      {/*    <div className="row mt-3">*/}
      {/*        <div className="col-sm-8 col-12" style={{ borderRight: '1px solid #C473C4' }}>*/}
      {/*            <div className="row">*/}
      {/*                <div className="col-12 mb-3">*/}
      {/*                    <div className="gift">*/}
      {/*                        <h4>The Gift of Therapy</h4>*/}
      {/*                        <p>*/}
      {/*                            Sometimes, we need an extra push.*/}
      {/*                            Show a loved one that care with a therapy gift card*/}
      {/*                        </p>*/}
      {/*                         <p className="text-right mb-0">*/}
      {/*                            <button className="themeBtn giftLink pl-3 pr-3" onClick={visibleCoupon} > */}
      {/*                                View Coupon History*/}
      {/*                            </button>*/}
      {/*                        </p> */}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*                <div className="col-12">*/}
      {/*                    <h4 className="mb-3">Purchase a Therapy Gift Card</h4>*/}
      {/*                    <div className="row align-items-center">*/}
      {/*                        <div className="col-sm-5 col-6">*/}
      {/*                            <img width="85%" src="images/gift_card.png" />*/}
      {/*                        </div>*/}
      {/*                        <div className="col-sm-4 col-6">*/}
      {/*                            <h5 className="giftValueHead">Value: <i class="fa fa-inr" aria-hidden="true"></i>{formAmount}</h5>*/}
      {/*                            <Select*/}
      {/*                                name="amount"*/}
      {/*                                className="giftSelectBox"*/}
      {/*                                placeholder="Change Amount"*/}
      {/*                                size="large"*/}
      {/*                                onChange={amountOnChange}*/}
      {/*                                style={{ width: 110 }}*/}
      {/*                            >*/}
      {/*                                <option value={500}>500</option>*/}
      {/*                                <option value={1000}>1000</option>*/}
      {/*                                <option value={1500}>1500</option>*/}
      {/*                                <option value={2000}>2000</option>*/}
      {/*                                <option value={2500}>2500</option>*/}
      {/*                                <option value={3000}>3000</option>*/}
      {/*                                <option value={3500}>3500</option>*/}
      {/*                                <option value={4000}>4000</option>*/}
      {/*                                <option value={4500}>4500</option>*/}
      {/*                                <option value={5000}>5000</option>*/}
      {/*                            </Select>*/}
      {/*                            <div className="clearfix"></div>*/}
      {/*                            <button onClick={showModal} className="themeBtn mt-5">Buy</button>*/}
      {/*                        </div>*/}
      {/*                    </div>*/}
      {/*                    <div className="row">*/}
      {/*                        <div className="col-12 text-center mb-5">*/}

      {/*                            <Modal*/}
      {/*                                visible={isModalVisible}*/}
      {/*                                onOk={handleOk}*/}
      {/*                                onCancel={closeModal}*/}
      {/*                                footer={null}*/}
      {/*                            >*/}
      {/*                                <div className="row">*/}
      {/*                                    <div className="col-12">*/}
      {/*                                        <h5 className="mb-2">Required Information</h5>*/}
      {/*                                        <Form*/}
      {/*                                            form={form}*/}
      {/*                                            onFinish={onFinish}*/}
      {/*                                            layout={"vertical"}*/}
      {/*                                            requiredMark={true}*/}
      {/*                                            className="giftForm"*/}
      {/*                                            autoComplete="off"*/}
      {/*                                            initialValues={{*/}
      {/*                                                fromName: name, staticphone: phone, email, readCheck: false, ageCheck: false*/}
      {/*                                            }}*/}

      {/*                                        >*/}
      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">From</e>}*/}
      {/*                                                name="fromName"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: true, message: 'Required!' },*/}
      {/*                                                    {*/}
      {/*                                                        pattern: new RegExp(/^[a-zA-Z\s]*$/),*/}
      {/*                                                        message: "Enter a valid Name",*/}
      {/*                                                    },*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input readOnly name="fromName" value="name" onChange={() => { console.log("input") }} className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Phone</e>}*/}
      {/*                                                name="staticphone"*/}
      {/*                                                value="9999999"*/}
      {/*                                                rules={[*/}
      {/*                                                    {*/}
      {/*                                                        required: true,*/}
      {/*                                                        message: 'Please input your phone number!'*/}
      {/*                                                    },*/}
      {/*                                                    () => ({*/}
      {/*                                                        validator(_, value) {*/}
      {/*                                                            if (!value || value.match(/^[6-9]\d{9}$/)) {*/}
      {/*                                                                return Promise.resolve().then(function (value) {*/}
      {/*                                                                    //console.log(value, 'success')*/}
      {/*                                                                });*/}
      {/*                                                            }*/}
      {/*                                                            return Promise.reject(new Error('Please check your phone number!'));*/}
      {/*                                                        },*/}
      {/*                                                    }),*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input readOnly name="staticphone" maxLength={10} className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Email</e>}*/}
      {/*                                                name="email"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: true, message: 'Required!' }, {*/}
      {/*                                                        type: "email", message: "Enter a Valid email"*/}
      {/*                                                    }*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input readOnly name="emailFrom" className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <h5>To</h5>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">First Name</e>}*/}
      {/*                                                name="fname"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: true, message: 'Required!' },*/}
      {/*                                                    {*/}
      {/*                                                        pattern: new RegExp(/^[a-zA-Z\s]*$/),*/}
      {/*                                                        message: "Enter a valid Name",*/}
      {/*                                                    },*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input name="fname" className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Last Name</e>}*/}
      {/*                                                name="lname"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: true, message: 'Required!' },*/}
      {/*                                                    {*/}
      {/*                                                        pattern: new RegExp(/^[a-zA-Z\s]*$/),*/}
      {/*                                                        message: "Enter a valid Name",*/}
      {/*                                                    },*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input name="lname" className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Phone</e>}*/}
      {/*                                                name="phone"*/}
      {/*                                                rules={[*/}
      {/*                                                    {*/}
      {/*                                                        required: true,*/}
      {/*                                                        message: 'Please input your phone number!'*/}
      {/*                                                    },*/}
      {/*                                                    () => ({*/}
      {/*                                                        validator(_, value) {*/}
      {/*                                                            if (!value || value.match(/^[6-9]\d{9}$/)) {*/}
      {/*                                                                return Promise.resolve().then(function (value) {*/}
      {/*                                                                    //console.log(value, 'success')*/}
      {/*                                                                });*/}
      {/*                                                            }*/}
      {/*                                                            return Promise.reject(new Error('Please check your phone number!'));*/}
      {/*                                                        },*/}
      {/*                                                    }),*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input name="phone" maxLength={10} className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Email</e>}*/}
      {/*                                                name="emailTo"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: true, message: 'Required!' }, {*/}
      {/*                                                        type: "email", message: "Enter a Valid email"*/}
      {/*                                                    }*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <Input name="email" className="giftInput" size='large' />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item*/}
      {/*                                                label={<e className="lableGift">Personalized Message</e>}*/}
      {/*                                                name="pMessage"*/}
      {/*                                                rules={[*/}
      {/*                                                    { required: false },*/}
      {/*                                                ]}*/}
      {/*                                            >*/}
      {/*                                                <TextArea name="short_description" className="giftInput" placeholder="Enter Short Description"*/}

      {/*                                                />*/}
      {/*                                            </Form.Item>*/}

      {/*                                            <Form.Item className="col-12 text-center mt-3">*/}
      {/*                                                <button size='large' shape='round' type="primary" htmlType="submit" className="themeBtn">*/}
      {/*                                                    Next*/}
      {/*                                                </button>*/}
      {/*                                            </Form.Item>*/}
      {/*                                        </Form>*/}
      {/*                                    </div>*/}
      {/*                                </div>*/}
      {/*                            </Modal>*/}
      {/*                            <Modal*/}
      {/*                                visible={isModalVisible2}*/}
      {/*                                onOk={handleOk2}*/}
      {/*                                onCancel={closeModal2}*/}
      {/*                                footer={null}*/}
      {/*                            >*/}
      {/*                                <div className="row">*/}
      {/*                                    <div className="col-sm-12 pt-4 pb-4 text-center">*/}
      {/*                                        <h3>*/}
      {/*                                            <i class="fa fa-check text-success mr-3" aria-hidden="true"></i>*/}
      {/*                                            Coupon created successfully*/}
      {/*                                        </h3>*/}
      {/*                                        <button onClick={closeModal2} size='large' shape='round' className="themeBtn mt-4">*/}
      {/*                                            Close*/}
      {/*                                        </button>*/}
      {/*                                    </div>*/}
      {/*                                </div>*/}
      {/*                            </Modal>*/}
      {/*                        </div>*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className="col-sm-4 col-12 pt-2 pb-2 couponBox couponScroll">*/}
      {/*             {isCouponVisible ? */}
      {/*            <div className="">*/}
      {/*                <h3 style={{ textAlign: "center" }} className="mb-3 ">Coupon History</h3>*/}
      {/*                {console.log("couponData", couponData)}*/}
      {/*                {couponData.length === 0 ? <p className="pt-5 text-center">No coupon available</p> : couponData.map((data, id) =>*/}
      {/*                    <>*/}
      {/*                        <div className="row">*/}
      {/*                            <div className="col-12">*/}
      {/*                                <div className="giftBox">*/}
      {/*                                    <CouponCard>*/}
      {/*                                        <div className="row">*/}
      {/*                                            <div className="col-6 text-left">*/}
      {/*                                                <h5 className="giftValueHead mt-0">{data.code}</h5>*/}
      {/*                                            </div>*/}
      {/*                                            <div className="col-6 text-right">*/}
      {/*                                                {*/}
      {/*                                                    data.Expired == "Expired" && <span className={"couponStatus couponStatusInUse"}>Expired</span>*/}
      {/*                                                }*/}
      {/*                                                {*/}
      {/*                                                    data.Expired != "Expired" &&*/}
      {/*                                                    (!data.used) && <span className={"couponStatus couponStatusUsed"}>Used</span>*/}
      {/*                                                }*/}
      {/*                                                {*/}
      {/*                                                    data.Expired != "Expired" && (data.used == "Not Used") && <span className={"couponStatus couponStatusInUse"}>In use</span>*/}
      {/*                                                }*/}
      {/*                                                 use of classe "couponStatusUsed", "couponStatusInUse", "couponStatusExpired" */}
      {/*                                            </div>*/}
      {/*                                        </div>*/}
      {/*                                        <div className="row mt-3">*/}
      {/*                                            <div className="col-6">*/}
      {/*                                                <Paragraph style={{ color: "#C473C4" }} copyable={{ text: data.code }}> Copy</Paragraph>*/}
      {/*                                            </div>*/}
      {/*                                            <div className="col-6 text-right">*/}
      {/*                                                <h5 className="giftValueHead mt-0"><i class="fa fa-inr" aria-hidden="true"></i>{data.discount}</h5>*/}
      {/*                                            </div>*/}
      {/*                                        </div>*/}
      {/*                                        <div className="row mt-1 text-right">*/}
      {/*                                            <div className="col-12">*/}
      {/*                                                <spam style={{ cursor: "pointer" }} onClick={e => showMore(id)}>{couponDataReadMore[id] ? "Show Less" : 'Read More'}</spam>*/}
      {/*                                            </div>*/}
      {/*                                        </div>*/}
      {/*                                        {*/}
      {/*                                            couponDataReadMore[id] && <>*/}
      {/*                                                <div className="row mt-2">*/}
      {/*                                                    <div className="col-12">*/}
      {/*                                                        <strong>First Name </strong>{data.patient_id.name.firstName}*/}
      {/*                                                    </div>*/}
      {/*                                                </div>*/}

      {/*                                                <div className="row mt-2">*/}
      {/*                                                    <div className="col-12">*/}
      {/*                                                        <strong>Last Name </strong>{data.patient_id.name.lastName}*/}
      {/*                                                    </div>*/}
      {/*                                                </div>*/}

      {/*                                                <div className="row mt-2">*/}
      {/*                                                    <div className="col-12">*/}
      {/*                                                        <strong>Mobile  </strong>{data.patient_id.phone}*/}
      {/*                                                    </div>*/}
      {/*                                                </div>*/}

      {/*                                                <div className="row mt-2">*/}
      {/*                                                    <div className="col-12">*/}
      {/*                                                        <strong>Email Address </strong>{data.patient_id.email}*/}
      {/*                                                    </div>*/}
      {/*                                                </div>*/}
      {/*                                            </>*/}
      {/*                                        }*/}

      {/*                                    </CouponCard>*/}

      {/*                                </div>*/}
      {/*                            </div>*/}
      {/*                        </div>*/}
      {/*                    </>*/}
      {/*                )*/}

      {/*                }*/}
      {/*            </div>*/}
      {/*             // :*/}
      {/*        // <></>*/}
      {/*        // } */}

      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  updatePaymentState: state.updatePayment,
});

const mapDispatchToProps = (dispatch) => ({
  updatePayment: (params) => dispatch(updatePayment(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gifts);
// attach report form item