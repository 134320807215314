import React from "react";
import { Input, Card, Typography } from "antd";
import Footer from "../../component/Footer/Footer";

//STYLE
import { SearchOutlined, FilterTwoTone } from "@ant-design/icons";
import "./Consult.css";
import { Xfooter } from "./ConsultStyle";

function Consult() {
  const { Text } = Typography;

  return (
    <div className="container">
      <img
        alt="right-bg"
        className="doctorRightBg1"
        src="./images/svg/doctorbgRight.svg"
      />
      <img
        alt="right-bg"
        className="doctorLeftBg"
        src="./images/svg/doctorbgLeft.svg"
      />
      <div className="row" id="title-bar">
        <div className="offset-6 col-sm-6" align="right">
          <Input
            className="consult-search"
            prefix={<SearchOutlined style={{ color: "gray" }} />}
            placeholder="Search"
          />
          <FilterTwoTone twoToneColor="gray" className="filter" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12" align="center">
          <h1>Upcoming Consultation</h1> <br />
        </div>
      </div>
      <div className="row" id="today-row">
        <div className="col-sm-12" align="center">
          <h3>Today</h3>
          <br />
          <br />
          <Card
            hoverable
            style={{ width: 240 }}
            className="doc-card"
            cover={
              <img
                className="card-img"
                alt="example"
                src="./images/Doc-img.svg"
              />
            }
          >
            <div>
              <h5>Dr. Haresh</h5>
              <Text type="secondary">Clinical Geneticist</Text>
              <br />
              <br />
              <h5>11:15 am</h5>
            </div>
          </Card>
        </div>
      </div>
      <div className="row" id="next-row">
        <h3>12 Aug 2021</h3>
        <br />
        <br />
        <br />
        <br />
        <div className="col-sm-4">
          <Card
            hoverable
            style={{ width: 240 }}
            className="doc-card"
            cover={
              <img
                className="today-img"
                alt="example"
                src="./images/Doc1.svg"
              />
            }
          >
            <div className="consult-info">
              <h5>Dr. Divya</h5>
              <Text type="secondary">Clinical Geneticist</Text>
              <br />
              <br />
              <h5>11:15 am</h5>
            </div>
          </Card>
        </div>
        <div className="col-sm-4">
          <Card
            hoverable
            style={{ width: 240 }}
            className="doc-card"
            cover={
              <img
                className="today-img"
                alt="example"
                src="./images/Doc2.svg"
              />
            }
          >
            <div className="consult-info">
              <h5>Dr. Gupta</h5>
              <Text type="secondary">Clinical Geneticist</Text>
              <br />
              <br />
              <h5>11:15 am</h5>
            </div>
          </Card>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Consult;
