import styled from "styled-components";
import { Button } from "antd";

export const TopContainer  = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto;
        width: 35%;
        margin-top: 15px;
    } 
`

export const DocImg  = styled.img`
    width: 170px;
    height: 200px;
    padding: 0%;
    text-align: left;
    border-radius:10px !important; 
    @media (max-width: 768px) {
        width: 160px;
        height: 240px;
        padding:10% 0% 5% 0%; 
        border-radius:20px !important;
    }  
    @media (max-width: 950px) and (min-width:768px) {
        width: 210px;
    }
`
export const DoctorName  = styled.h1`
    width: 100%;
    margin-top:1%;
    font-size: 25px;
    line-height:50px;
    color: #1F274A;
    font-weight: 600;
    text-align: center; 
    @media (max-width: 768px) {
        font-size: 18px;
        line-height:25px;
        margin-top:5%;
    }  
`
export const IconDiv = styled.div`
    width: 100%;
    margin:0px auto;
    position: relative;
    text-align: center;  
    ul .list-mob{
        display:none;
    }
    ul{
        margin:0px;
        padding:0px;
    }
    ul li{
        display: flow-root;
        float:left;
        font-size:20px;
        list-style-type:  none;
        padding: 0px 5px;
        color: #1F274A;
    }
   
    @media (max-width: 768px) {
        ul .list{
            display: none;
        }
        ul .list-mob{
            display: block;
        }
        ul .list-mob .doctorIcons svg{ 
            color
        }
        ul li:last-child{
            margin-left: 20px;
        }
        display: flow-root;
        list-style-type: none;
        ul{
            padding-left:2rem;
        }
        ul li{
            font-size: 14px;
        }
        ul{
            padding:0;
        }
    }  
`

export const XButton = styled(Button)` 
    @media (max-width: 768px) {
        width: 100px;
        .span{
            font-size: 10px; 
        }
    }  
    @media (max-width: 950px) and (min-width:768px) {
        width: 70px; 
    }
`
export const XText  =  styled.span` 
    @media (max-width: 768px) { 
        font-size: 12px;
        margin-left: -55px; 
    }
    @media (max-width: 950px) and (min-width:768px) {
        font-size: 12px;
        margin-left: -50px;
    }
` 
export const NoApptDiv  = styled.div`
    // align-items: center; 
    @media (max-width: 768px) {
        width: 100%;
        text-decoration: none;
    }
`