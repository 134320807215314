import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_LISTEN,
    getListenFail,
    getListenSuccess
} from '../action/getListenAction'

// api 
import getListenApi from '../api/getListenApi'

export function* getListenSaga(action){

    try{
        const response = yield call(()=> getListenApi(action.params))
        console.log(response)
        if(response.data.status ===1){
            yield put(getListenSuccess(response, action))
        }else
        {
            yield put(getListenFail(response, action))
        }
    }
    catch(e){
        yield put(getListenFail(e.response, action))
    }
}

export default function* mySaga(){
    yield takeLatest(GET_LISTEN, getListenSaga)
}