import styled from "styled-components";
import { Modal, Radio, Button } from "antd";

export const Xh1 = styled.h1`
  // color: #a64fa6;
  margin-left: 10%;
  font-weight: 600;
  font-size: 30px;
`;
export const XModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 22px;
  }
`;
export const RadioButton = styled(Radio.Button)`
  width: 100% !important;
  border-radius: 22px !important;
  padding-left: 5%;
  margin-bottom: 25px;
  line-height: 14px;
`;
export const XText = styled.h6`
  padding: 10px 0px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  @media only screen and (max-width: 320px) {
    font-size: 10px;
    padding: 10px;
  }
  @media only screen and (max-width: 281px) {
    font-size: 10px;
    padding: 10px 5px !important;
    line-height: 10px;
    text-align: center;
  }
`;
export const Xdiv = styled.div``;
export const XButton = styled(Button)``;
export const ImgDiv = styled.div`
  margin-bottom: 20px;
  @media only screen and (max-width: 281px) {
    width: 50%;
  }
`;
