//import {React, useState} from 'react'
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Radio, Space, Row, Col, Slider } from "antd";
import {
  Xh1,
  XModal,
  FormItem,
  XDatePicker,
  DatePicker,
  XButton,
  ImgDiv,
} from "./FeelingModalStyle";
import "./FeelingModal.css";
export const FeelingModal = (props) => {
  //const [day, setDay] = useState()

  //onClick={() => showModalFeeling("ModalData_2")}

  function closeModal() {
    props.onClose();
  }

  return (
    <XModal
      visible={props.visible}
      //onOk={handleOk}
      onCancel={closeModal}
      footer={null}
      style={{ borderRadius: "22px!important" }}
    >
      {/* props.modalType == "ModalData_1" */}
      {props.modalType == "angry" && (
        <div>
          <h1 className="moodometerModalHeading">
            Anger affects you physically and mentally and can be tough to deal
            with. Try this when you’re angry:
          </h1>
          <div className="row">
            <div className="col-12 mb-2">
              <ul className="fellingList">
                <li>
                  <span>Take 5 deep breaths.</span>
                </li>
                <li>
                  <span>Drink a glass of water.</span>
                </li>
                <li>
                  <span>Go for a walk to relax your mind.</span>
                </li>
                <li>
                  <span>Splash some cool water on your face.</span>
                </li>
                <li>
                  <span>
                    Write about the situation and emotions you are currently
                    experiencing
                  </span>
                </li>
                <li>
                  <span>Talk to someone about how you feel</span>
                </li>
              </ul>
            </div>
            <div className="col-12 mb-4">
              <Link
                className="fellingSpeek"
                to={{
                  pathname: "/expert-detail",
                  state: { type: "MHC" },
                }}
              >
                <img
                  className="img-fluid mr-2"
                  src="./images/female_doctor_icon.svg"
                />
                Speak to a therapist
              </Link>
            </div>
          </div>
        </div>
      )}
      {props.modalType == "sad" && (
        <div>
          <h1 className="moodometerModalHeading">
            I’m sorry that you’re sad today. Though sadness is a part of life,
            here’s what can improve your mood:
          </h1>
          <div className="row">
            <div className="col-12 mb-2">
              <ul className="fellingList">
                <li>
                  <span>
                    Sit with your feelings and acknowledge your sadness.
                  </span>
                </li>
                <li>
                  <span>
                    Let your emotions out - whether it's through crying,
                    writing, art, movement, or talking.
                  </span>
                </li>
                <li>
                  <span>Engage in an activity or hobby that you enjoy.</span>
                </li>
                <li>
                  <span>
                    Move your body - dance, jump, exercise, or go for a run.
                  </span>
                </li>
                <li>
                  <span>
                    Tell someone a joke. Nothing uplifts the mood like spreading
                    smiles!
                  </span>
                </li>
                <li>
                  <span>Speak to a professional about your emotions.</span>
                </li>
              </ul>
            </div>
            <div className="col-12 mb-4">
              <Link
                className="fellingSpeek"
                to={{
                  pathname: "/expert-detail",
                  state: { type: "MHC" },
                }}
              >
                <img
                  className="img-fluid mr-2"
                  src="./images/female_doctor_icon.svg"
                />
                Speak to a therapist
              </Link>
            </div>
          </div>
        </div>
      )}
      {props.modalType == "anxious" && (
        <div>
          <h1 className="moodometerModalHeading">
            I’m sorry that you are feeling anxious. Here are some tips to help
            you manage your anxiety:
          </h1>
          <div className="row">
            <div className="col-12 mb-2">
              <ul className="fellingList">
                <li>
                  <span>
                    Practice 4-2-6 breathing. Inhale for 4 counts, hold your
                    breath for 2 counts, exhale for 6 counts and repeat.
                  </span>
                </li>
                <li>
                  <span>
                    Focus your mind away from things that are out of your
                    control and on to the things that are within your control.
                  </span>
                </li>
                <li>
                  <span>
                    Use your senses to ground yourself - identify 5 things you
                    can see, 4 things you can feel, 3 things you can hear, 2
                    things you can smell and 1 thing you can taste.
                  </span>
                </li>
                <li>
                  <span>
                    Close your eyes and visualize a calm and soothing image in
                    your mind.
                  </span>
                </li>
                <li>
                  <span>
                    Write down the anxious thoughts in your mind and try to find
                    logical alternatives to your thoughts.
                  </span>
                </li>
                <li>
                  <span>
                    Reach out for support from a professional to manage your
                    anxiety.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 mb-4">
              <Link
                className="fellingSpeek"
                to={{
                  pathname: "/expert-detail",
                  state: { type: "MHC" },
                }}
              >
                <img
                  className="img-fluid mr-2"
                  src="./images/female_doctor_icon.svg"
                />
                Speak to a therapist
              </Link>
            </div>
          </div>
        </div>
      )}
      {props.modalType == "neutral" && (
        <div>
          <h1 className="moodometerModalHeading">
            Feeling neutral means your day was not good, but not bad either.
            Here’s what can make your day better:
          </h1>
          <div className="row">
            <div className="col-12 mb-2">
              <ul className="fellingList">
                <li>
                  <span>Read a few pages of a book or watch a movie.</span>
                </li>
                <li>
                  <span>
                    Call a friend or relative you’ve been meaning to get in
                    touch with.
                  </span>
                </li>
                <li>
                  <span>Cook and eat your favorite meal.</span>
                </li>
                <li>
                  <span>Play some music and dance along.</span>
                </li>
                <li>
                  <span>Have a warm, soothing bath.</span>
                </li>
                <li>
                  <span>
                    Work on your well-being and book a session with a mental
                    health expert.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 mb-4">
              <Link
                className="fellingLink"
                to={{
                  pathname: "/expert-detail",
                  state: { type: "MHC" },
                }}
              >
                <img
                  className="img-fluid mr-2"
                  src="./images/female_doctor_icon.svg"
                />
                Speak to a therapist
              </Link>
            </div>
          </div>
        </div>
      )}
      {/* props.modalType == "ModalData_3" */}
      {props.modalType == "happy" && (
        <div>
          <h1 className="moodometerModalHeading">
            It’s great that you’re feeling happy today! Here are some ways you
            can maintain your happiness:
          </h1>
          <div className="row">
            <div className="col-12 mb-2">
              <ul className="fellingList">
                <li>
                  <span>
                    Do a good deed or act of kindness for someone else.
                  </span>
                </li>
                <li>
                  <span>Write a love letter to yourself.</span>
                </li>
                <li>
                  <span>Do a quick workout that you enjoy.</span>
                </li>
                <li>
                  <span>Watch a funny video.</span>
                </li>
                <li>
                  <span>Spend some time in nature.</span>
                </li>
                <li>
                  <span>
                    Speak to a professional about healthy habits you can build
                    to maintain your happiness levels.
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 mb-4">
              <Link
                className="fellingSpeek"
                to={{
                  pathname: "/expert-detail",
                  state: { type: "MHC" },
                }}
              >
                <img
                  className="img-fluid mr-2"
                  src="./images/female_doctor_icon.svg"
                />
                Speak to a therapist
              </Link>
            </div>
          </div>
          {/* <Xh1
            className="mb-1 mt-3"
            style={{ fontSize: "26px", textAlign: "left" }}
          >
            Great to know that you are Feeling Good
          </Xh1>
          <div className="row">
            <div className="col-12 mb-2 text-center">
              <img
                className="img-fluid"
                width="50%"
                src="images/thumb_images.gif"
              />
            </div>
          </div> */}
        </div>
      )}
    </XModal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FeelingModal);
