export const SAVE_PATIENT_PROFILE = "SAVE_PATIENT_PROFILE"
export const SAVE_PATIENT_PROFILE_FAIL = "SAVE_PATIENT_PROFILE_FAIL"
export const SAVE_PATIENT_PROFILE_SUCCESS = "SAVE_PATIENT_PROFILE_SUCCESS"
export const SAVE_PATIENT_PROFILE_RESET = "SAVE_PATIENT_PROFILE_RESET"

export const savePatientProfile = (params) => {
    return { type: SAVE_PATIENT_PROFILE, params }
}

export const savePatientProfileSuccess = (response) => {
    return { type: SAVE_PATIENT_PROFILE_SUCCESS, response }
}

export const savePatientProfileFail = (response) => {
    return { type: SAVE_PATIENT_PROFILE_FAIL, response }
}

export const savePatientProfileReset = () => {
    return { type: SAVE_PATIENT_PROFILE_RESET }
}