import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from '../action/registerAction'
import { api_err_msg } from "../strings.json"

const initialState = {
    apiState: "", // loading, success, error
    message: "",
    data: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case REGISTER:
            return {
                ...state,
                apiState: "loading",
            }

        case REGISTER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }

        case REGISTER_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.data.message
            }

        default:
            return state
    }
}
