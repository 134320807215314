import React, { useState, useEffect } from "react";
import FeelingModal from "../FeelingModal/FeelingModal";
import {
  SmilyPara,
  FeelingTodayDiv,
  SmilyHeading,
  SmilyCont,
} from "./MoodOMeterStyle";
import { getMoodList } from "../../action/getMoodListAction";
import { saveMoodTrack } from "../../action/saveMoodTrackAction";
import { connect } from "react-redux";
import { Spin } from "antd";

function MoodOMeter(props) {
  const {
    getMoodList,
    getMoodListState,
    saveMoodTrack,
    saveMoodTrackState,
  } = props;

  const [isFeelingModalVisible, setIsFeelingModalVisible] = useState({});
  const [moodList, setMoodList] = useState([]);
  const [loading, setLoading] = useState(false);

  /* callbacks */
  useEffect(() => {
    getMoodList();
  }, []);

  useEffect(() => {
    if (getMoodListState.apiState === "loading") {
      setLoading(true);
    } else if (getMoodListState.apiState === "success") {
      setMoodList(getMoodListState.data);
      setLoading(false);
    }
  }, [getMoodListState]);

  const showModalFeeling = (modalType) => {
    setIsFeelingModalVisible({ show: true, modalType });
    saveMoodTrack({ mood: modalType });
    console.log(saveMoodTrackState);
  };

  return (
    <div className="container">
      <img
        alt="right-bg"
        class="homeRightBg hideMobile"
        src="./images/svg/doctorbgRight.svg"
      />
      <SmilyCont>
        <div className="row">
          <div className="col-sm-12 col-12 mb-3 mb-sm-4">
            <SmilyHeading className="text-center">
              How are you feeling today?
            </SmilyHeading>
          </div>
        </div>
        <FeelingTodayDiv className="row" id="galaxyFoldSmily">
          {loading ? (
            <Spin spinning={loading} tip="Loading..." />
          ) : moodList ? (
            moodList.map((element) => {
              return (
                <div className="col-sm-2 text-center col-2" key={element.sortOrder}>
                  <img
                    className="img-fluid feelingPointer"
                    onClick={() => showModalFeeling(element.mood)}
                    src={"./" + element.mood_icon}
                    alt="no"
                  />
                  <SmilyPara onClick={() => showModalFeeling(element.mood)}>
                    {element.display_mood}
                  </SmilyPara>
                </div>
              );
            })
          ) : (
            ""
          )}
          <FeelingModal
            visible={isFeelingModalVisible.show}
            modalType={isFeelingModalVisible.modalType}
            onClose={() => setIsFeelingModalVisible({})}
          />
        </FeelingTodayDiv>
      </SmilyCont>
    </div>
  );
}

const mapStateToProps = (state) => ({
  getMoodListState: state.getMoodList,
  saveMoodTrackState: state.saveMoodTrack,
});

const mapDispatchToProps = (dispatch) => ({
  getMoodList: () => dispatch(getMoodList()),
  saveMoodTrack: (params) => dispatch(saveMoodTrack(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoodOMeter);