import {
    SAVE_REGISTER_DATA 
} from '../action/saveRegisterDataAction' 

const initialState = {
    apiState: "", // loading, success, error
    message: "",
    data: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_REGISTER_DATA:
            console.log("from reducer: ",action)
            return {
                ...state,
                apiState: "Saved",
                data: action.params,
            }  
        default:
            return state
    }
}
