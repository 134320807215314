import {
  Form,
  Button,
  Input,
  DatePicker,
  Typography,
  Radio,
  Select,
  Space,
  Checkbox,
  Modal,
  Upload,
  message,
  Spin,
  notification,
  Row,
  Col,
  Card,
} from "antd";
import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useLocation, Redirect, Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { Description } from "../Article/ArticleStyle";
import {
  getPatientToken,
  getS3SingedUrl,
  nameValidation,
  validateImagePdfFile,
  phoneValidation,
} from "../../utils";
import {
  PaperClipOutlined,
  FilePdfOutlined,
  DeleteTwoTone,
  LinkOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { savePaymentData } from "../../action/savePaymentDataAction";
import { confirmAlert } from "react-confirm-alert";
import getcommonMaster from "../../api/getcommonMaster";
import "./CB.css";
import {
  Styled,
  SuccessModal,
  BookingHeader,
  ContainsBooking,
} from "./ConsultationBookingStyle";
import getDoctorDetailApi from "../../api/getDoctorDetailApi";

import { getDoctorDetail } from "../../action/getDoctorDetailAction";
import { updatePayment } from "../../action/updatePaymentAction";
import { validateCoupon } from "../../action/validateCouponAction";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
}

export const ConsultationBooking = (props) => {
  const {
    savePaymentData,
    savePaymentDataState,
    getDoctorDetail,
    getDoctorDetailState,
    validateCoupon,
    validateCouponState,
    updatePaymentState,
    updatePayment,
  } = props;
  const [CommondataPrice, setCommondataPrice] = useState([]);
  const [Price, setPrice] = useState();
  const [feesz, setfeesz] = useState();
  const [feetype, setfeetype] = useState();
  const [consultationFees, setconsultationFees] = useState();
  const [firstConsultationFees, setFirstConsultationFees] = useState();
  const [followUpFees, setFollowUpFees] = useState();

  let fullName = JSON.parse(localStorage.getItem("name"));
  let name = `${fullName.firstName} ${fullName.lastName}`;
  let email = JSON.parse(localStorage.getItem("email"));
  let phone = JSON.parse(localStorage.getItem("phone"));
  let dob = JSON.parse(localStorage.getItem("dob"));
  let gender = JSON.parse(localStorage.getItem("gender"));
  let patient_id = JSON.parse(localStorage.getItem("LilacPatient"));
  let Man = "male";
  let Woman = "female";
  let Transgender = "Transgender";
  let Non = "Non-Binary";
  let Others = "Others";

  const [background, Setbackground] = useState();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("gender")) === "male") {
      gender = "Man";
    }
    if (JSON.parse(localStorage.getItem("gender")) === "female") {
      gender = "Women";
    }
  }, []);

  const [form] = Form.useForm();
  const [formName, setFormName] = useState("test");
  const { Title, Text } = Typography;
  const { Option } = Select;
  const [value, setValue] = useState();
  // const [booking, setBoooking] = useState(false);
  const [redirect, setRedirect] = useState({
    state: false,
    pathname: "",
  });
  const [bookData, setBookData] = useState({});
  const [payCheck, setPayCheck] = useState(false);
  const [response, setResponse] = useState();
  const [reports, setreports] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [names, setNames] = useState("#000");
  const [whos, setWhos] = useState("#000");
  const [isDoctor, setIsDoctor] = useState(false);
  const [isFileModalVisible, setIsFileModalVisible] = useState(false);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [forWho, setForWho] = useState("");
  const [summryData, setSummryData] = useState({
    amount: "",
    _id: "",
    email: "",
    phone: "",
    payment_session_id: "",
    appointment_no: "",
  });

  const [reportsData, setReportsData] = useState([]);
  const [reportsDisplay, setReportsDisplay] = useState([]);
  const [bookResponse, setBookResponse] = useState();
  const [reportFile, setReportFile] = useState([]);
  const [filepath, setFilePath] = useState([]);
  const [gcReportList, setGcReportList] = useState([]);
  const [reportID, setReportID] = useState([]);
  const [fileArray, setFileArray] = useState([]);
  const [Filesdata, setFilesdata] = useState();
  const [formValues, setFormValues] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");
  const [doctorAmount, setDoctorAmount] = useState();
  const [finalAmount, setFinalAmount] = useState(0);
  const [discount, setDiscount] = useState();
  const [originalAmount, setOriginalAmount] = useState();
  const [isResponse, setIsResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [cashfreeInstance, setCashfreeInstance] = useState(null);
  const [category, setCategory] = useState("");
  const [reportCategory,setReportCategory] = useState('')
  
  useEffect(() => {
    if (validateCouponState.apiState === "success") {
      if ([null].includes(validateCouponState.data)) {
        setCouponMessage(validateCouponState.message);
        setIsResponse(false);
        return;
      }
      setIsCouponApplied(true);
      setCouponMessage(validateCouponState.message);
      setOriginalAmount(validateCouponState.data.original_amount);
      setDiscount(validateCouponState.data.discount);
      setFinalAmount(validateCouponState.data.total_amount);
      setIsResponse(true);
    } else if (validateCouponState.apiState === "error") {
      setCouponMessage(validateCouponState.message);
      setIsResponse(false);
    }
  }, [validateCouponState]);

  const handleApplyCoupon = async () => {
    if (savePaymentDataState.type == "MHC" && feesz == 0) {
      form.validateFields("price");
      return;
    }
    form.setFieldsValue({ coupon: couponCode });
    setIsResponse(false);
    validateCoupon({
      amount: feesz,
      coupon_code: couponCode,
    });

    response.data.data ? setIsResponse(true) : setIsResponse(false);
    setIsCouponApplied(true);
    setCouponMessage(response.data.message);
    response.data.data && setOriginalAmount(response.data.data.original_amount);
    response.data.data && setDiscount(response.data.data.discount);
    response.data.data && setFinalAmount(response.data.data.total_amount);
  };

  const handleClearCoupon = () => {
    form.setFieldsValue({ coupon: "" });
    setIsCouponApplied(false);
    setCouponMessage("");
    setOriginalAmount(0);
    setDiscount(0);
    setFinalAmount(0);
    setIsResponse(false);
    setCouponCode("");
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
  };

  const location = useLocation();
  const Type = savePaymentDataState.data.type;
  const doctor = savePaymentDataState.data.doctor;
  let date = savePaymentDataState.data.date;
  const end_time = savePaymentDataState.data.end_time;
  
  if (date === undefined) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    date = today;
  }
  if (JSON.parse(localStorage.getItem("gender")) === "Male") {
  }
  const time_slot_start = moment(
    savePaymentDataState.data.slot ? savePaymentDataState.data.slot[0] : null,
    "hh:mm A"
  ).format("HH:mm");
  
  const time_slot_end = moment(
    savePaymentDataState.data.slot ? savePaymentDataState.data.slot[1] : null,
    "hh:mm A"
  ).format("HH:mm");

  const disabledDate = (current) => {
    let customDate = moment();
    return current &&
      moment().diff(
        moment(current, "YYYY-MM-DD").format("YYYY-MM-DD"),
        "days"
      ) > 6574
      ? false
      : true;
  };

  const params = {
    type: Type?.toLowerCase(),
    doctor: doctor,
  };

  const showFileModal = (file) => {
    setIsFileModalVisible(true);
    if (file.isCategory) {
      setCategory(file);
    }
  };

  const handleFileOk = () => {
    setCategory("");
    setIsFileModalVisible(false);
  };

  const showReportModal = () => {
    setIsReportModalVisible(true);
  };

  const handleReportOk = () => {
    //
    // setReportsDisplay((prevItems) =>
    //   prevItems.map((item) =>
    //     item.id === category.id
    //       ? { ...item, isFileUpload: true, file_path: filePath }
    //       : item
    //   )
    // );
    //
    if (reportsData.result.length === 0) {
      setIsReportModalVisible(false);
      return;
    }
    if (category) {
      // Filesdata?.map((item) => {
      setReportFile([
        ...reportFile,
        {
          // id: item._id,
          // file_path: item.file_path,
          file_name: Filesdata.file_path,
          is_from_gallery: false,
          report_Id: Filesdata._id,
          title: category.id,
        },
      ]);
      // for (const result of reportsData.result) {
      //   const match = reportsDisplay.some(
      //     (report) => report.id === result._id
      //   );
      //   if (!match) {
      // setReportsDisplay([
      //   ...reportsDisplay,
      //   {
      //     id: item._id,
      //     name: item.title + "." + item.file_path.split(".")[1],
      //     from: "reports",
      //     file_path: item.file_path,
      //   },
      // ]);
      setReportsDisplay((prevItems) =>
        prevItems.map((report) =>
          report.id === category.id
            ? {
                ...report,
                // name: Filesdata.title + "." + Filesdata.file_path.split(".")[1],
                isFileUpload: true,
                file_path: Filesdata.file_path,
                from: "reports",
                is_report:true,
                uploadedFileName:Filesdata.file_path
              }
            : report
        )
      );
      //     break;
      //   }
      // }
      // });
    } else {
      // Filesdata?.map((item) => {
      setReportFile([
        ...reportFile,
        {
          id: Filesdata._id,
          file_path: Filesdata.file_path,
        },
      ]);
      // for (const result of reportsData.result) {
      //   const match = reportsDisplay.some(
      //     (report) => report.id === result._id
      //   );
      //   if (!match) {
      setReportsDisplay([
        ...reportsDisplay,
        {
          id: Filesdata._id,
          name: Filesdata.title + "." + Filesdata.file_path.split(".")[1],
          from: "reports",
          file_path: Filesdata.file_path,
        },
      ]);
      //     break;
      //   }
      // }
      // });
    }

    setIsReportModalVisible(false);
    handleFileOk();
    setFilesdata([]);
  };

  const cancelOk = () => {
    setIsReportModalVisible(false);
    handleFileOk();
    setFilesdata([]);
  };

  function onGenderChange(e) {
    // console.log(`radio checked:${e.target.value}`);
  }
 
  const onFinish = async (values) => {
    let typs = values.apptType;
    if (Type === "MHC") {
      typs = "first";
    }
    let upd = await Upload();
    // console.log("upd", upd);
    let person;

    if (forWho === "Someone else") {
      person = true;
    } else {
      person = false;
    }
   
    const data = {
      doctor_id: savePaymentDataState.data.doctor,
      date: date,
      time_slot_start: time_slot_start,
      time_slot_end: time_slot_end,
      some_one_else: person,
      file_path: filepath,
      gc_report_list: gcReportList,
      report_id: reportID,
      referred_by: values.ref,
      is_accepted_my_age_is_18_and_above: values.ageCheck,
      is_accepted_tnc: values.readCheck,
      is_this_your_first_consultation:
        Type == "MHC" ? "first" : values.apptType,
      tnc_id: response.data.data._id,
      coupon_code: values.coupon ?? "",
      amount: isCouponApplied ? finalAmount : feesz,
      patient_id: patient_id,
      full_name: values.user,
      gender: values.gender,
      dob: values.DOB._d ?? values.DOB._i,
      phone: values.phone,
      email: values.email,
      fee_type: feetype ?? "",
      referred_by_doctor: values.refDoc ?? "",
      referred_by_clinic_or_hospital: values.refAdd ?? "",
      //
      consult_type:Type,
      report_category:reportCategory
    };
 
    setBookData(data);
    bookAppointment(data);
    setFormValues(values);

    //     if (form.getFieldValue("readCheck") === true && form.getFieldValue("is_accepted_my_age_is_18_and_above") === true) {
    //         bookAppointment(true)
    //     }
    //     else {
    //         confirmAlert({
    //             title: 'Alert',
    //             message: 'Please check both the conditions',
    //             buttons: [
    //                 {
    //                     label: 'OK',
    //                     onClick: () => {
    //                         console.log("yes")
    //                     }
    //                 },
    //                 {
    //                     label: 'Cancel',
    //                     onClick: () => {
    //                         console.log("no")
    //                     }
    //                 }
    //             ]
    //         });
    //     }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // FUNCTIONS

  function GetBooking() {
    let person;
    if (forWho === "Someone else") {
      person = true;
    } else {
      person = false;
    }
    setBookData({
      doctor_id: savePaymentDataState.data.doctor,
      date: date,
      time_slot_start: time_slot_start,
      time_slot_end: time_slot_end,
      some_one_else: person,
      report_id: reportID,
      file_path: filepath,
      gc_report_list: gcReportList,
      fee_type: feetype,
      is_accepted_my_age_is_18_and_above: form.getFieldValue("ageCheck"),
      is_accepted_tnc: form.getFieldValue("readCheck"),
      tnc_id: response.data.data._id,
      coupon_code: form.getFieldValue("coupon"),
      amount: feesz,
      referred_by: form.getFieldValue("ref"),
      referred_by_doctor: form.getFieldValue("refDoc"),
      referred_by_clinic_or_hospital: form.getFieldValue("refAdd"),
      is_this_your_first_consultation: form.getFieldValue("apptType"),
      // consult_type:Type,
      // report_category:reportCategory
    });
    return "Done";
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    form.setFieldsValue("readCheck", true);
  };

  const UploadSingleFile = (e) => {
    
    // console.log('e.target.files[0]',e.target.files[0])
    if (e.target.files) {
      let uploadedFile = e.target.files[0];
      if(!uploadedFile){
        return
      }
      // Create file path for preview
      let filePath;
      if(uploadedFile){
         filePath = URL.createObjectURL(uploadedFile);
        
      }
      
      if (category) {
        setFileArray([
          ...fileArray,
          {
            file: e.target.files[0],
            fileName: uploadedFile.name,
            is_from_gallery: true,
            report_Id: null,
            title: category.id,
          },
        ]);
        setReportsDisplay((prevItems) =>
          prevItems.map((item) =>
            item.id === category.id
              ? { ...item, isFileUpload: true, from: "add", file_path: filePath,uploadedFileName:uploadedFile.name,isGallery:true }
              : item
          )
        );
      } else {
        setFileArray([
          ...fileArray,
          {
            file: e.target.files[0],
            fileName: uploadedFile.name,
          },
        ]);
        setReportsDisplay([
          ...reportsDisplay,
          {
            id: uploadedFile.name,
            name: uploadedFile.name,
            from: "add",
            file_path: filePath,
          },
        ]);
      }

      handleFileOk();
    }
  };

  const SelectSingleFile = (checkedValues) => {
    setFilesdata(
      checkedValues.length > 0 ? checkedValues[checkedValues.length - 1] : null
    );
    // setFilesdata(checkedValues);
    // Filesdata.map((file) => {
    //   reportFile.push({
    //     id: file._id,
    //     file_path: file.file_path,
    //   });
    //   reportsDisplay.push({
    //     id: file._id,
    //     name: file.title,
    //     from: "reports",
    //   });
    // });
    //handleReportOk()
    // handleFileOk()
  };

  function deleteFile(no) {
    const s = fileArray.filter((item, index) => index !== no);
    setFileArray(s);
    console.log(s);
  }

  const handleConsultationType = (val) => {
    setfeetype(val);
    if (val === "first") {
      setconsultationFees(
        "Consultation price: INR " + firstConsultationFees + "/-"
      );
      setfeesz(firstConsultationFees);
    } else if (val === "follow_up") {
      setconsultationFees("Consultation price: INR " + followUpFees + "/-");
      setfeesz(followUpFees);
    }
  };

  function deleteReport(file, id) {
  
    if (file.from === "reports") {
      if (file.isCategory) {
        setReportsDisplay((prevItems) =>
          prevItems.map((item) =>
            item.id === file.id
              ? { ...item, isFileUpload: false, file_path: file.name,is_report:false,uploadedFileName:'' }
              : item
          )
        );
        setFileArray((prevItems) =>
          prevItems.filter((item) => item.title !== file.id)
        );
      } else {
        let name = file.name;
        const s = reportsDisplay.filter((item, index) => index === name);
        setReportsDisplay(s);
        const t = fileArray.filter((item, index) => index === name);
        setFileArray(t);
      }
    } else {
      if (file.isCategory) {
        setReportsDisplay((prevItems) =>
          prevItems.map((item) =>
            item.id === file.id
              ? { ...item, isFileUpload: false, file_path: file.name,isGallery:false,uploadedFileName:'' }
              : item
          )
        );
        setFileArray((prevItems) =>
          prevItems.filter((item) => item.title !== file.id)
        );
      } else {
        const tempDisplay = [
          ...reportsDisplay.slice(0, id),
          ...reportsDisplay.slice(id + 1),
        ];
        setReportsDisplay(tempDisplay);
      }

      // const tempReportFile = [...reportFile.slice(0,id),...reportFile.slice(id+1)]
      // let id = file.id;
      // console.log(file.name, "add");
      // const s = reportsDisplay.filter((item, index) => item.id !== id);
      // setReportsDisplay(s);
      // const t = reportFile.filter((item, index) => item.id !== id);
      // setReportFile(tempReportFile);
    }
  }

  const Upload = async () => {
    // e.preventDefault();
    let isFileSafe = true;
    if (fileArray.length > 0) {
      const promises = fileArray.map(async (file) => {
        let filename = uuidv4();
        let imageValidationRes = validateImagePdfFile(file.file);
        if (imageValidationRes.status) {
          let s3UrlResImage = await getS3SingedUrl(
            filename,
            imageValidationRes.ext,
            file.file,
            "patient-report"
          );
          await axios.put(s3UrlResImage.url, file.file);
          if (file.title) {
            gcReportList.push({
              file_name: `${filename}.${imageValidationRes.ext}`,
              title: file.title,
              is_from_gallery: file.is_from_gallery,
              report_Id: file.report_Id,
            });
          } else {
            filepath.push(`${filename}.${imageValidationRes.ext}`);
          }
        } else {
          alert("Select file type JPG, PNG or PDF file.");
          isFileSafe = false;
          return false;
        }
      });
      await Promise.all(promises);
      if (isFileSafe) {
        // return "Done";
        console.log("fileSave");
      } else {
        return false;
      }
    }
    
    if (reportFile.length > 0) {
      reportFile.map((file) => {
        if (file.title) {
          gcReportList.push({
            file_name: file.file_name,
            title: file.title,
            is_from_gallery: file.is_from_gallery,
            report_Id: file.report_Id,
          });
        } else {
          reportID.push(file.id);
        }
      });
      return "Done";
    }
  };

  function prefillForm() {
    form.setFieldsValue({
      user: name,
      email: email,
      phone: phone,
      DOB: moment(dob),
      gender: gender,
    });
  }

  function Who(params) {
    if (params === "Someone else") {
      setForWho("Someone else");
      form.resetFields();
      setIsDoctor(false);
      Setbackground("gainsboro");
      gender = "";
      setNames("black");
      setWhos("#a54ea5");
    } else {
      setForWho(name);
      prefillForm();
      setIsDoctor(false);
      Setbackground("#C473C4");
      // console.log(background);
      setWhos("#000");
      setNames("#a54ea5");
    }

    form.setFieldsValue({ ref: "self" });
    form.setFieldsValue({ apptType: "first" });
    const val = JSON.stringify(
      categoryReports[0]
    )
    form.setFieldsValue({reports_cat:val})
    setfeesz(firstConsultationFees);
    setconsultationFees(
      "Consultation price: INR " + firstConsultationFees + "/-"
    );
    
    //
    setReportsDisplay([]);
    setFileArray([]);
    setFilesdata([]);
    setReportCategory('')
  }

  // function success(amount, _id) {
  //     Modal.success({
  //         className: "SuccessModal",
  //         title: 'Payment Success',
  //         content: (
  //             <div className='mt-4'>
  //                 <p><strong>Email :</strong> {form.getFieldValue("email")}</p>
  //                 <p><strong>Mobile :</strong> {form.getFieldValue("phone")}</p>
  //                 <p><strong>Amount Paid :</strong> {summryData.amount}</p>
  //                 <p><strong>Transaction id :</strong> {summryData._id}</p>
  //             </div>
  //         ),
  //         style:{},
  //         onOk() { setRedirect({ state: true, pathname: "/consultation" }) }
  //     });
  // }

  function error() {
    Modal.error({
      title: "Payment Failed",
      content: "Please try again",
      onOk() {
        console.log(
          "done"
        ); /*setRedirect({ state: true, pathname: "/consultation-booking" }) */
      },
    });
  }

  async function displayCashfree(params) {
    if (!bookResponse.data.data) {
      alert(bookResponse.data.message);
      return;
    }

    if (!summryData.payment_session_id) {
      notification.error({
        message: "Unable to make payment",
      });
      return;
    }

    setCashfreeInstance(new window.Cashfree(summryData.payment_session_id));
  }

  useEffect(() => {
    if (cashfreeInstance !== null) {
      const dropinConfig = {
        components: ["order-details", "card", "netbanking", "app", "upi"],
        onSuccess: function (data) {
          setCashfreeInstance(null);
          setShowPaymentModal(false);
          updatePayment({
            payment_for: "booking",
            platform: "web",
            paymentDetails: data,
          });
          Modal.success({
            title: <Title level={2} style={{ textAlign: "center" }}></Title>,
            content: (
              <SuccessModal>
                <h6 className="text-center mt-3">Congratulations</h6>
                <p>
                  <strong>Appointment Id: </strong>{" "}
                  <span className="appointment-id">
                    {summryData.appointment_no}
                  </span>
                </p>
                <p>
                  <strong>Email: </strong> <span>{formValues.email}</span>
                </p>
                <p>
                  <strong>Mobile: </strong> <span>{formValues.phone}</span>
                </p>
                <p>
                  <strong>Amount Paid: </strong>{" "}
                  <span>₹{summryData.amount}</span>
                </p>
                {formValues.coupon != undefined && (
                  <p>
                    <strong>Coupon Code:</strong>{" "}
                    <span>{formValues.coupon}</span>
                  </p>
                )}
                {formValues.coupon != undefined && (
                  <p style={{ textDecoration: "underline" }}>Coupon Used</p>
                )}
              </SuccessModal>
            ),
            onOk() {
              setRedirect({ state: true, pathname: "/consultation" });
            },
          });
        },
        onFailure: function (data) {
          setCashfreeInstance(null);
          setShowPaymentModal(false);
          updatePayment({
            payment_for: "booking",
            platform: "web",
            paymentDetails: data,
          });
          error();
        },
        style: {
          //to be replaced by the desired values
          backgroundColor: "#ffffff",
          color: "#865590",
          fontFamily: "Lato",
          fontSize: "14px",
          errorColor: "#ff0000",
          theme: "light",
        },
      };
      setShowPaymentModal(true);
      setTimeout(() => {
        let paymentForm = document.createElement("div");
        paymentForm.id = "paymentForm";
        document.getElementById("paymentDiv").appendChild(paymentForm);
        cashfreeInstance.drop(
          document.getElementById("paymentForm"),
          dropinConfig
        );
      }, 100);
    } else {
      let removePaymentForm = document.getElementById("paymentForm");
      if (removePaymentForm) {
        removePaymentForm.remove();
      }
    }
  }, [cashfreeInstance]);

  async function displayRazorPay(params) {
    console.log("------", feesz);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    // if (!res) {
    //   alert("Razorpay failed! Are you online?");
    //   return;
    // }

    if (!bookResponse.data.data) {
      alert(bookResponse.data.message);
      return;
    }

    const options = {
      key: config.payment_gateway_key,
      amount: parseInt(feesz) * 100,
      currency: "INR",
      name: "Lilac Insights.",
      description: "Test Transaction",
      image: "/images/lilac-Insights-logo1.png",
      order_id: summryData._id,
      handler: function (response, bookResponse) {
        //alert(response.razorpay_payment_id);
        //alert(response.razorpay_order_id);
        //alert(response.razorpay_signature);
        //alert("Your Appointent is booked");
        // success()
        //confirmAlert({
        //    title: 'Confirmation',
        //    message: (
        //    <div className='mt-4'>
        //        <p><strong>Email :</strong> {form.getFieldValue("email")}</p>
        //        <p><strong>Mobile :</strong> {form.getFieldValue("phone")}</p>
        //        <p><strong>Amount Paid :</strong> {summryData.amount}</p>
        //        <p><strong>Transaction id :</strong> {summryData._id}</p>
        //    </div>),
        //    buttons: [
        //      {
        //        label: 'Yes',
        //        onClick: () => {
        //            setRedirect({ state: true, pathname: "/consultation" })
        //        }
        //      },
        //      {
        //        label: 'No',
        //        onClick: () => {
        //            console.log("NO? Then what do you WANTTTTT")
        //            setRedirect({ state: true, pathname: "/consultation" })
        //        }
        //      }
        //    ]
        //});
        Modal.success({
          title: <Title level={2} style={{ textAlign: "center" }}></Title>,

          content: (
            <SuccessModal>
              <h6 className="text-center mt-3">Congratulations</h6>
              <p>
                <strong>Appointment Id: </strong>{" "}
                <span className="appointment-id">{summryData._id}</span>
              </p>
              <p>
                <strong>Email: </strong> <span>{formValues.email}</span>
              </p>
              <p>
                <strong>Mobile: </strong> <span>{formValues.phone}</span>
              </p>
              <p>
                <strong>Amount Paid: </strong> <span>₹{summryData.amount}</span>
              </p>
              {formValues.coupon != undefined && (
                <p>
                  <strong>Coupon Code:</strong> <span>{formValues.coupon}</span>
                </p>
              )}
              {formValues.coupon != undefined && (
                <p style={{ textDecoration: "underline" }}>Coupon Used</p>
              )}
            </SuccessModal>
          ),

          onOk() {
            setRedirect({ state: true, pathname: "/consultation" });
          },
        });
      },
      prefill: {
        contact: phone,
        email: email,
      },
      modal: {
        ondismiss: function () {
          error();
        },
      },
    };
    console.log("options", options);
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    console.log(rzp1);

    rzp1.on("payment failed", function (response) {
      error();
    });
  }

  function Terms() {
    return (
      <div>
        <Description
          className="mt-3"
          dangerouslySetInnerHTML={{
            __html: decodeURIComponent(response.data.data.description),
          }}
        />
      </div>
    );
  }

  const BookNCall = async () => {
    if (
      form.getFieldValue("readCheck") === true &&
      form.getFieldValue("ageCheck") === true
    ) {
      console.log(
        "field value ",
        form.getFieldValue("readCheck"),
        form.getFieldValue("ageCheck")
      );
      if (reportsDisplay.length > 0) {
        let upd = await Upload();
        console.log("upd", upd);
        if (upd === "Done") {
          GetBooking();
        }
      } else {
        GetBooking();
      }
    }
    //else {
    //    console.log(form.getFieldValue("readCheck"),form.getFieldValue("ageCheck"))
    //    confirmAlert({
    //        title: 'Alert',
    //        message: 'Please check both the conditions',
    //        buttons: [
    //            {
    //                label: 'OK',
    //                onClick: () => {
    //                    console.log("yes")
    //                }
    //            },
    //            {
    //                label: 'Cancel',
    //                onClick: () => {
    //                    console.log("no")
    //                }
    //            }
    //        ]
    //    });
    //}
  };

  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    form.setFieldsValue({ ref: "self" });
    form.setFieldsValue({ apptType: "first" });
    axios({
      url: `${config.api.base_url}page/tnc?type=${params.type}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setResponse(() => response);
    });

    fetch(`${config.api.base_url}report/list?page=1&records=1000`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    }).then((result) => {
      result.json().then((resp) => {
        setReportsData(resp.data);
      });
    });
  }, []);

  useEffect(() => {
    // console.log("stsd", savePaymentDataState);
  }, null);

  // hadcoaded data
  const categoryReports = [
    {
      id: 0,
      displayName: "Please select category",
      reportModels: [],
    },
    {
      id: 1,
      displayName: "Recurrent pregnancy losses",
      reportModels: [
        {
          displayName: "USG reports of all conceptions",
          reportTitle: "USG reports of all conceptions",
        },
        {
          displayName: "Genetic test reports for POC",
          reportTitle: "Genetic test reports for poc",
        },
        {
          displayName:
            "Blood test reports of Wife- Metabolic factors, thrombotic factors",
          reportTitle: "Blood test reports of wife",
        },
        { displayName: "Couple Karyotype", reportTitle: "Couple karyotype" },
        {
          displayName: "Couple Thalassemia screening",
          reportTitle: "Couple thalassemia screening",
        },
      ],
    },
    {
      id: 2,
      displayName: "Infertility",
      reportModels: [
        {
          displayName: "Semen analysis and/or DFI of husband",
          reportTitle: "Semen analysis dfi husband",
        },
        {
          displayName: "Hormonal profile for wife- AMH, metabolic factors",
          reportTitle: "Hormonal profile",
        },
        { displayName: "Couple Karyotype", reportTitle: "Couple karyotype" },
        {
          displayName: "Couple Thalassemia screening",
          reportTitle: "Couple thalassemia screening",
        },
        {
          displayName:
            "Previous IVF cycle and/or IUI cycle details if available",
          reportTitle: "Previous ivf cycle iui cycle",
        },
      ],
    },
    {
      id: 3,
      displayName: "Pre-natal",
      reportModels: [
        {
          displayName:
            "USG reports of ongoing pregnancy- dating scan, NT scan, Anomaly scan, growth scan",
          reportTitle: "USG reports of ongoing pregnancy",
        },
        {
          displayName:
            "Prenatal screening test reports- DMT, NIPT, Quadruple marker",
          reportTitle:
            "Prenatal screening test reports dmt nipt quadruple marker",
        },
        {
          displayName:
            "In case of genetic diagnosis established in the family- share the genetic test reports",
          reportTitle:
            "In case of genetic diagnosis established in the family share the genetic test reports",
        },
        { displayName: "Blood group", reportTitle: "Blood group" },
        {
          displayName: "Thalassemia screening report",
          reportTitle: "Thalassemia screening report",
        },
      ],
    },
    {
      id: 4,
      displayName: "Pediatric cases (index child affected)",
      reportModels: [
        {
          displayName:
            "Medical test reports (all medical notes or prescriptions) - Blood reports, MRI brain, EEG, X-rays, USGs, IQ test, CT scan etc.",
          reportTitle: "Medical test reports",
        },
        {
          displayName: "Referring clinician’s notes/ Referral letter",
          reportTitle: "Referral Letter",
        },
        {
          displayName: "Discharge summary/ Developmental history notes",
          reportTitle: "Discharge summary",
        },
        {
          displayName: "Any genetic reports",
          reportTitle: "Any genetic reports",
        },
      ],
    },
  ];
  
  const handleChange = (value) => {
    const parsedItem = JSON.parse(value);
    if(parsedItem.id !==0){
      setReportCategory(parsedItem.displayName);
    }

    const arr = parsedItem.reportModels.map((item, indes) => {
      return {
        id: item.reportTitle,
        name: item.displayName,
        from: "add",
        file_path: item.displayName,
        isCategory: true,
        isFileUpload: false,
      };
    });
    setCategory("");
    setFileArray([]);
    setFilesdata([]);
    setReportFile([]);
    setGcReportList([]);
    setReportsDisplay(arr);
  };

  // console.log(bookdata)
  const bookAppointment = async (data) => {
    // const data = {
    //     "doctor_id": bookdata.doctor_id,
    //     "date": bookdata.date,
    //     "time_slot_start": bookdata.time_slot_start,
    //     "time_slot_end": bookdata.time_slot_end,
    //     "some_one_else": bookdata.some_one_else,
    //     "report_id": reportID,
    //     "file_path": filepath,
    //     "is_accepted_my_age_is_18_and_above": bookdata.is_accepted_my_age_is_18_and_above,
    //     "is_accepted_tnc": bookdata.is_accepted_tnc,
    //     "tnc_id": bookdata.tnc_id,
    //     "coupon_code": bookdata.coupon_code,
    //     "amount": feesz,
    //     "referred_by": bookdata.referred_by,
    //     "referred_by_doctor": bookdata.referred_by_doctor,
    //     "referred_by_clinic_or_hospital": bookdata.referred_by_clinic_or_hospital,
    //     "is_this_your_first_consultation": bookdata.is_this_your_first_consultation,
    // }
    // console.log("Data before saving to backend", data);
    // if(bookdata.doctor_id && bookdata.date){
    try {
      setLoading(true);
      let res = {};
      if (bookResponse) {
        res = bookResponse;
      } else {
        res = await axios({
          url: `${config.api.base_url}book-appointment?type=${params.type}`,
          method: "post",
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        });
        // console.log("Data after saving to backend", res);
      }

      if (!res.data.data) {
        alert(res.data.message);
        return;
      } else {
        setBookResponse(res);
        // setSummryData({ "amount": res.data.data.amount, _id: res.data.data.razorpay_order_id })
        // let summrydata = {
        //   amount: data.amount,
        //   _id: res.data.data.razorpay_order_id ?? "",
        // };

        let summrydata = {
          amount: data.amount,
          _id: res.data.data.cashfree_order_id ?? "",
          payment_session_id: res.data.data.cashfree_payment_session_id ?? "",
          appointment_no: res.data.data.appointment_no ?? "",
        };

        setSummryData(summrydata);
        console.log("response data=>", res.data.data);
        if (res.data.data.skip_payment) {
          Modal.success({
            title: <Title level={2} style={{ textAlign: "center" }}></Title>,

            content: (
              <SuccessModal>
                <h6 className="text-center mt-3">Congratulations</h6>
                <p>
                  <strong>Appointment Id: </strong>{" "}
                  <span>{res.data.data.appointment_no}</span>
                </p>
                <p>
                  <strong>Email: </strong>{" "}
                  <span style={{ fontSize: "12px" }}>{formValues.email}</span>
                </p>
                <p>
                  <strong>Mobile: </strong> <span>{formValues.phone}</span>
                </p>
                <p>
                  <strong>Amount Paid: </strong> <span>₹{finalAmount}</span>
                </p>
                {formValues.coupon != undefined && (
                  <p>
                    <strong>Coupon Code:</strong> 13:00
                    <span>{formValues.coupon}</span>
                  </p>
                )}
                {formValues.coupon != undefined && (
                  <p style={{ textDecoration: "underline" }}>Coupon Used</p>
                )}
              </SuccessModal>
            ),

            onOk() {
              setRedirect({ state: true, pathname: "/consultation" });
            },
          });
        } else {
          setPayCheck(true);
        }
      }
      // setPayCheck(true);
      // if (summrydata._id != "") {
      //   if (data.amount == 0) {
      //     Modal.success({
      //       title: <Title level={2} style={{ textAlign: "center" }}></Title>,

      //       content: (
      //         <SuccessModal>
      //           <h6 className="text-center mt-3">Congratulations</h6>
      //           <p>
      //             <strong>Appointment Id: </strong>{" "}
      //             <span>{res.data.data.appointment_no}</span>
      //           </p>
      //           <p>
      //             <strong>Email: </strong> <span>{formValues.email}</span>
      //           </p>
      //           <p>
      //             <strong>Mobile: </strong> <span>{formValues.phone}</span>
      //           </p>
      //           <p>
      //             <strong>Amount Paid: </strong> <span>{finalAmount}</span>
      //           </p>
      //           {formValues.coupon != undefined && (
      //             <p>
      //               <strong>Coupon Code:</strong>{" "}
      //               <span>{formValues.coupon}</span>
      //             </p>
      //           )}
      //           {formValues.coupon != undefined && (
      //             <p style={{ textDecoration: "underline" }}>Coupon Used</p>
      //           )}
      //         </SuccessModal>
      //       ),

      //       onOk() {
      //         setRedirect({ state: true, pathname: "/consultation" });
      //       },
      //     });
      //   } else {
      //     // displayRazorPay();
      //     setPayCheck(true);
      //   }
      // }
      console.log("Done");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    // }
  };

  useEffect(() => {
    if (summryData._id != "" && summryData.amount != 0 && payCheck) {
      // displayRazorPay();

      displayCashfree();
    }
  }, [payCheck]);

  //Master
  useEffect(async () => {
    let datas = [];
    datas.push(await getcommonMaster());
    if (datas.length > 0) {
      let cdata = datas[0].data.data.result.filter((person) =>
        person.slug.toLowerCase().includes("consultation-type")
      );
      if (cdata.length > 0) {
        setCommondataPrice(cdata[0].common_master_detail);
      }

      // console.log("commonloist", cdata);
    }
    getDoctorDetail(params);
  }, []);

  useEffect(() => {
    if (getDoctorDetailState.apiState == "success") {
      if (getDoctorDetailState.data.data.data.type == "GC") {
        setPrice(
          "Consultation price: INR " +
            getDoctorDetailState.data.data.data.tier_1_fee +
            "/-"
        );
        setFirstConsultationFees(
          getDoctorDetailState.data.data.data.tier_1_fee
        );
        setFollowUpFees(getDoctorDetailState.data.data.data.tier_2_fee);
        setconsultationFees(
          "Consultation price: INR " +
            getDoctorDetailState.data.data.data.tier_1_fee +
            "/-"
        );
        setfeesz(getDoctorDetailState.data.data.data.tier_1_fee);
      }
    }
  }, [getDoctorDetailState]);

  useEffect(() => {
    if (!savePaymentDataState.data.slot) {
      setRedirect({
        ...redirect,
        ["state"]: true,
        ["pathname"]: "/",
      });
    }
  }, null);

  const onChangefee = (checkedValues) => {
    // console.log("checkedValues", checkedValues);
    setfeetype(checkedValues.target.value);
    if (checkedValues.target.value === "Self") {
      setPrice(
        "Consultation price: INR " + savePaymentDataState.data.self_fee + "/-"
      );
      setfeesz(savePaymentDataState.data.self_fee);
    }
    if (checkedValues.target.value === "Couple") {
      setPrice(
        "Consultation price: INR " + savePaymentDataState.data.couple_fee + "/-"
      );
      setfeesz(savePaymentDataState.data.couple_fee);
    }
    if (checkedValues.target.value === "Family") {
      setPrice(
        "Consultation price: INR " + savePaymentDataState.data.family_fee + "/-"
      );
      setfeesz(savePaymentDataState.data.family_fee);
    }
  };
  function Files() {
    if (reportsData.length === 0) {
      return <h3>No Slots Available</h3>;
    } else {
      return (
        <div>
          {/*{reportsData.length > 0 && reportsData.map((item, i) => (*/}
          {/*    <FileCheckBox*/}
          {/*        onChange={(e) => SelectSingleFile(e, item)}*/}
          {/*        value={item.file_path}*/}
          {/*        className="col-sm-2 col-12 mr-4 mb-4 dateSlotBorder"*/}
          {/*    >*/}

          {/*            <b className='bold-heading'>{item.file_path}</b>*/}

          {/*    </FileCheckBox>*/}
          {/*))}*/}
        </div>
      );
    }
  }

  function mapImageInFileArray(file, fileArray) {
    const mappedImg = fileArray.find(
      (report) => report.fileName === file.name
    ).file;
    return mappedImg;
  }

  const closePaymentModal = () => {
    setCashfreeInstance(null);
    setShowPaymentModal(false);
    setPayCheck(false);
  };

  return (
    <div className="pageWrap">
      {redirect.state ? (
        <>
          <Redirect
            to={{
              pathname: redirect.pathname,
            }}
          />
        </>
      ) : (
        <>
          <div className="container">
            <BookingHeader>
              <div className="row">
                <div className="col-12 col-md-7">
                  Booking this consultation for
                </div>
                <div className="col-12 col-md-5 text-left text-sm-right">
                  <a
                    href="javascript:void(0);"
                    className="linkstag"
                    style={{ color: names }}
                    onClick={() => Who(name)}
                  >
                    {name}
                  </a>
                  <span style={{ color: "lightgrey" }}>|</span>
                  <a
                    href="javascript:void(0);"
                    className="linkstag"
                    style={{ color: whos }}
                    onClick={() => Who("Someone else")}
                  >
                    Someone else
                  </a>
                </div>
              </div>
            </BookingHeader>

            {forWho === "" ? (
              <></>
            ) : (
              <>
                <ContainsBooking>
                  <h4 className="rinfo">Required Information</h4>
                  {forWho === name ? (
                    <>
                      <Form
                        form={form}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {Type === "MHC" && (
                              <>
                                <Title level={5}>
                                  What type of counselling are you looking for?
                                </Title>
                                <Form.Item
                                  name={["price"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select counselling type",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    style={{
                                      width: "100%",
                                    }}
                                    onChange={onChangefee}
                                  >
                                    {CommondataPrice.map((price) => {
                                      return (
                                        <Radio value={price.value}>
                                          {price.value === "Self"
                                            ? "Therapy for me(Individual)"
                                            : price.value === "Couple"
                                            ? "Therapy for us(Couples)"
                                            : price.value === "Family"
                                            ? "Therapy for all(Family)"
                                            : ""}
                                        </Radio>
                                      );
                                    })}
                                  </Radio.Group>
                                </Form.Item>
                                <Input
                                  className="p-0 mb-4"
                                  style={{
                                    border: "none",
                                    fontWeight: 600,
                                  }}
                                  readOnly
                                  value={Price}
                                  size="large"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Full Name</Title>
                            <Form.Item
                              name={["user"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter full name",
                                },
                              ]}
                            >
                              <Input
                                readOnly={forWho === name}
                                className="cbTextBorder"
                                size="large"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Gender</Title>
                            <Form.Item
                              className="consltBookRadio"
                              name={["gender"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select gender",
                                },
                              ]}
                            >
                              <Radio.Group
                                readOnly
                                name="radiogroup"
                                disabled={forWho === name}
                                onChange={onGenderChange}
                                defaultValue={gender}
                              >
                                <Radio value={Man}>Man</Radio>
                                <Radio value={Woman}>Woman</Radio>
                                <Radio value={Transgender}>Transgender</Radio>
                                <Radio value={Non}>Non-Binary</Radio>
                                <Radio value={Others}>Others</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Date of Birth</Title>
                            <Form.Item
                              className="cbTextBorder"
                              name={["DOB"]}
                              rules={[
                                {
                                  type: "date",
                                  required: true,
                                  message: "Please select date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                disabled={forWho === name}
                                size="large"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Email</Title>
                            <Form.Item
                              name={["email"]}
                              rules={[
                                {
                                  type: "email",
                                  required: true,
                                  message: "Please enter valid email id",
                                },
                              ]}
                            >
                              <Input
                                className="cbTextBorder"
                                size="large"
                                readOnly={forWho === name}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Phone Number</Title>
                            <Form.Item
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter phone number",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (phoneValidation(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        "Please enter valid phone number"
                                      );
                                    }
                                  },
                                }),
                                {
                                  max: 10,
                                  message: "Please enter valid Phone number",
                                },
                              ]}
                            >
                              <Input
                                readOnly={forWho === name}
                                maxLength={10}
                                className="cbTextBorder"
                                size="large"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Coupon Code</Title>
                            <Form.Item name={["coupon"]}>
                              <Input
                                className="cbTextBorder"
                                size="large"
                                value={couponCode}
                                onChange={handleCouponChange}
                                addonAfter={
                                  <Button
                                    onClick={
                                      isCouponApplied
                                        ? handleClearCoupon
                                        : handleApplyCoupon
                                    }
                                    style={{
                                      background:
                                        "linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%)",
                                      color: "white",
                                      borderRadius: "4px",
                                    }}
                                    disabled={!couponCode}
                                  >
                                    {isCouponApplied ? "Clear" : "Apply"}
                                  </Button>
                                }
                              />
                            </Form.Item>
                            <p style={{ color: isResponse ? "green" : "red" }}>
                              {couponMessage}
                            </p>
                            {isResponse && (
                              <p>
                                Original Amount was ₹{originalAmount}. Discount
                                of ₹{discount} applied and final payable amount
                                is ₹{finalAmount}.
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            {Type == "GC" && (
                              <>
                                <Title level={5}>
                                  Is this your First Consultation?
                                </Title>
                                <div style={{ marginBottom: "15px" }}>
                                  <Form.Item
                                    style={{ marginBottom: 0 }}
                                    name="apptType"
                                    // defaultValue={form.getFieldValue("apptType")}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select counseling type",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="cbTextBorder"
                                      placeholder="Select"
                                      size="large"
                                      onChange={(value) => {
                                        handleConsultationType(value);
                                      }}
                                    >
                                      <Option value="first">
                                        First Consultation
                                      </Option>
                                      <Option value="follow_up">
                                        Follow Up Consultation
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                  <span>{consultationFees}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {Type === "GC" ? (
                              <>
                                <Title level={5}>Attach Reports (If any)</Title>

                                <Form.Item name='reports_cat'>
                                  <Select
                                    className="cbTextBorder"
                                    placeholder="Select"
                                    size="large"
                                    defaultValue={JSON.stringify(
                                      categoryReports[0]
                                    )} // Set the default value here
                                    onChange={handleChange} // Handle value change
                                  >
                                    {categoryReports.map((item, index) => {
                                      return (
                                        <Option
                                          key={item.id}
                                          value={JSON.stringify(item)}
                                        >
                                          {item.displayName}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </>
                            ) : (
                              <Form.Item>
                                <Button
                                  size="large"
                                  shape="round"
                                  className="w-100 attach-report-btn"
                                  onClick={showFileModal}
                                >
                                  Attach Reports (If any)
                                  <PaperClipOutlined className="hideGalaxyFold" />
                                </Button>
                              </Form.Item>
                            )}

                            {reportsDisplay.length > 0 ? (
                              <>
                                {reportsDisplay.map((file, index) => (
                                   <Card
                                   onClick={() =>
                                     file.isCategory && !file.isFileUpload && showFileModal(file)
                                   }
                                   bodyStyle={{ padding: "10px" }}
                                   className="mb-3"
                                   style={{
                                     cursor: "pointer",
                                   }}
                                 >
                                   <Row
                                     gutter={(15, 15)}
                                     className="align-items-center"
                                   >
                                     {file.isCategory && !file.isFileUpload ? (
                                       <Col xs={2} className="text-center">
                                         <FilePdfOutlined
                                           //  onClick={()=>showFileModal(file)}
                                           style={{
                                             marginRight: "5px",
                                             fontSize: "24px",
                                           }}
                                         />
                                       </Col>
                                     ) : file.isCategory && file.isFileUpload ? 
                                         file.uploadedFileName.split(".")[1] === "pdf" ? 
                                         (
                                          <Col xs={2} className="text-center">
                                          <FilePdfOutlined
                                            style={{
                                              marginRight: "5px",
                                              fontSize: "24px",
                                              color: "red", // Change color to red
                                            }}
                                          />
                                        </Col>
                                         ) 
                                         : ( <Col xs={2} className="text-center">
                                         <img
                                           src={file.is_report ? `${config.api.s3_url}patient-report/${file.file_path}` : file.file_path}
                                           alt="Thumbnail"
                                           style={{
                                             width: "24px",
                                             height: "24px",
                                             marginRight: "5px",
                                           }}
                                         />
                                       </Col>)
                                     
                                     :  file.name.split(".")[1] === "pdf" ? (
                                       <Col xs={2} className="text-center">
                                         <FilePdfOutlined
                                           style={{
                                             marginRight: "5px",
                                             fontSize: "24px",
                                           }}
                                         />
                                       </Col>
                                     ) : (
                                       <Col xs={2} className="text-center">
                                         <img
                                           src={
                                             file.from === "reports" ?
                                           `${config.api.s3_url}patient-report/` +
                                                 file.file_path
                                               : file.file_path
                                                
                                           }
                                           alt="Thumbnail"
                                           style={{
                                             width: "24px",
                                             height: "24px",
                                             marginRight: "5px",
                                           }}
                                         />
                                       </Col>
                                     )}
                                     <Col xs={20}>{file.name}</Col>
                                     {file.isCategory && !file.isFileUpload ? (
                                       <Col xs={2} className="text-center">
                                         <LinkOutlined
                                           className="drNotificationIcon"
                                           style={{
                                             margin: "8px 8px 0px 0px",
                                           }}
                                           onClick={(e) => {
                                             e.stopPropagation();
                                             showFileModal(file);
                                           }}
                                         />
                                       </Col>
                                     ) : (
                                       <Col xs={2} className="text-center">
                                         <DeleteTwoTone
                                           className="drNotificationIcon"
                                           style={{
                                             margin: "8px 8px 0px 0px",
                                           }}
                                           onClick={(e) => {
                                             e.stopPropagation();
                                             deleteReport(file, index);
                                           }}
                                         />
                                       </Col>
                                     )}
                                    
                                   </Row>
                                 </Card>
                                  // <>
                                  //   <div
                                  //     onClick={() =>
                                  //       file.isCategory && showFileModal(file)
                                  //     }
                                  //     style={{
                                  //       marginBottom: "0.4em",
                                  //       marginTop: "0.6em",
                                  //       border: "1px solid #d9d9d9",
                                  //       padding: "20px",
                                  //       display: "flex-inline",
                                  //       justifyContent: "space-end",
                                  //       borderRadius: "8px",
                                  //     }}
                                  //   >
                                  //     {file.isCategory ? (
                                  //       <FilePdfOutlined
                                  //         onClick={() => showFileModal(file)}
                                  //         style={{
                                  //           marginRight: "15px",
                                  //           fontSize: "24px",
                                  //         }}
                                  //       />
                                  //     ) : file.name.split(".")[1] === "pdf" ? (
                                  //       <FilePdfOutlined
                                  //         style={{
                                  //           marginRight: "15px",
                                  //           fontSize: "24px",
                                  //         }}
                                  //       />
                                  //     ) : (
                                  //       <img
                                  //         src={
                                  //           file.isCategory
                                  //             ? file.file_path
                                  //             : `${config.api.s3_url}patient-report/` +
                                  //               file.file_path
                                  //         }
                                  //         alt="Thumbnail"
                                  //         style={{
                                  //           width: "24px",
                                  //           height: "24px",
                                  //           marginRight: "5px",
                                  //         }}
                                  //       />
                                  //     )}
                                  //     {file.name}&nbsp;
                                  //     {file.isCategory && !file.isFileUpload ? (
                                  //       <LinkOutlined
                                  //         className="drNotificationIcon"
                                  //         style={{ margin: "8px 8px 0px 0px" }}
                                  //         onClick={(e) => {
                                  //           e.stopPropagation();
                                  //           showFileModal(file);
                                  //         }}
                                  //       />
                                  //     ) : (
                                  //       <DeleteTwoTone
                                  //         className="drNotificationIcon"
                                  //         style={{ margin: "8px 8px 0px 0px" }}
                                  //         onClick={(e) => {
                                  //           e.stopPropagation();
                                  //           deleteReport(file, index);
                                  //         }}
                                  //       />
                                  //     )}
                                  //   </div>
                                  // </>
                                ))}
                                <br />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <Title level={5}>Reffered By</Title>
                          <Form.Item
                            name={["ref"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select reffered by",
                              },
                            ]}
                          >
                            <Radio.Group
                              defaultValue={"self"}
                              onChange={(e) =>
                                // form.setFieldsValue({"ref"});
                                e.target.value === "doctor"
                                  ? setIsDoctor(true)
                                  : setIsDoctor(false)
                              }
                            >
                              <Space direction="horizontal">
                                <Radio value={"self"}>Self</Radio>
                                <Radio value={"doctor"}>Doctor</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {isDoctor ? (
                            <>
                              <div className="col-md-6">
                                <Title level={5}>Doctor Name</Title>
                                <Form.Item
                                  name={["refDoc"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Doctor name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="cbTextBorder"
                                    size="large"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Title level={5}>Hospital/Clinic Address</Title>
                                <Form.Item
                                  name={["refAdd"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please enter Hospital/Clinic Address!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="cbTextBorder"
                                    size="large"
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <Form.Item
                            name="ageCheck"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error("Please confirm your age")
                                      ),
                              },
                            ]}
                          >
                            <Checkbox style={{ border: "none" }}>
                              My age is 18 and above{" "}
                            </Checkbox>
                          </Form.Item>

                          <Form.Item
                            name="readCheck"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Please accept terms & conditions"
                                        )
                                      ),
                              },
                            ]}
                          >
                            <Checkbox
                              style={{ border: "none" }}
                              value="read true"
                            >
                              {" "}
                              I have read the
                              <Button
                                onClick={showModal}
                                style={{ padding: "0.5%" }}
                                type="link"
                              >
                                terms and conditions
                              </Button>
                              and I give my consent to accept services from
                              Lilac Insights
                            </Checkbox>
                          </Form.Item>

                          <Form.Item>
                            <div className="text-center">
                              <button
                                className="themeBtn"
                                htmlType="submit"
                                disabled={loading}
                              >
                                {loading ? <Spin spinning={loading} /> : "Pay"}
                              </button>
                            </div>
                          </Form.Item>
                        </div>
                      </Form>
                      <Modal
                        className="uploadModalHeader"
                        title="Upload"
                        width={"600px"}
                        footer={null}
                        visible={isFileModalVisible}
                        onCancel={handleFileOk}
                      >
                        <div className="row">
                          <div className="col-6 mb-2 text-center galaxyFoldUpload">
                            <div className="helthboxTiles">
                              <Input
                                type="file"
                                disabled={fileArray.length === 5}
                                className="file_upload"
                                accept="image/jpeg,application/pdf"
                                onChange={UploadSingleFile}
                                style={{
                                  cursor: "pointer",
                                  height: "100%",
                                  position: "absolute",
                                }}
                              />
                              <img
                                className="img-fluid"
                                width="100px"
                                src="./images/gallery.svg"
                                alt="no"
                              />
                              <h4 className="helthboxHeading">Gallery</h4>
                            </div>
                          </div>
                          <div
                            className="col-6 mb-2 text-center galaxyFoldUpload"
                            onClick={showReportModal}
                          >
                            <div
                              className="helthboxTiles"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="img-fluid"
                                width="100px"
                                src="./images/report.svg"
                                alt="no"
                              />
                              <h4 className="helthboxHeading">My Reports</h4>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        onOk={handleOk}
                        okText="I agree"
                      >
                        <Terms />
                      </Modal>
                      <Modal
                        title="Your Reports (click to select)"
                        visible={isReportModalVisible}
                        onCancel={cancelOk}
                        onOk={handleReportOk}
                        okText="OK"
                        centered
                        bodyStyle={{
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 200px)",
                        }}
                      >
                        {reportsData.result.length > 0 ? (
                          <Checkbox.Group
                            value={Filesdata ? [Filesdata] : []}
                            onChange={SelectSingleFile}
                            className="w-100"
                          >
                            <div className="row">
                              {reportsData.result.map((item) => {
                                return (
                                  <>
                                    <div className="col-sm-6 col-md-12 mb-3">
                                      <Styled>
                                        <Checkbox
                                          value={item}
                                          // checked={item}
                                        >
                                          <Row>
                                            {item.file_path.split(".")[1] ===
                                            "pdf" ? (
                                              <Col xs={3}>
                                                <FilePdfOutlined
                                                  style={{
                                                    marginRight: "5px",
                                                    fontSize: "24px",
                                                    marginTop: "8px",
                                                  }}
                                                />
                                              </Col>
                                            ) : (
                                              <Col xs={3}>
                                                <img
                                                  src={
                                                    `${config.api.s3_url}patient-report/` +
                                                    item.file_path
                                                  }
                                                  alt="report"
                                                  style={{
                                                    height: "24px",
                                                    width: "24px",
                                                    marginRight: "5px",
                                                    marginTop: "8px",
                                                  }}
                                                />
                                              </Col>
                                            )}
                                            <Col xs={21}>
                                              <span
                                                style={{
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {item.title}
                                              </span>
                                            </Col>
                                            {/* <a
                                            className="col-md-2"
                                              target="_blank"
                                              rel="noreferrer"
                                              href={
                                                `${config.api.s3_url}patient-report/` +
                                                item.file_path
                                              }
                                              download
                                            >
                                              <i
                                                className="fa fa-download reportDownload"
                                                style={{ marginTop: "8px" }}
                                              ></i>
                                            </a> */}
                                          </Row>
                                        </Checkbox>
                                      </Styled>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </Checkbox.Group>
                        ) : (
                          <h4 className="text-center">No Reports Available</h4>
                        )}
                      </Modal>
                    </>
                  ) : (
                    <>
                      {/* SOME ONE ELSE */}
                      <Form
                        form={form}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            {Type === "MHC" && (
                              <>
                                <Title level={5}>
                                  What type of counselling are you looking for?
                                </Title>
                                <Form.Item
                                  name={["price"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select conuseling type",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    style={{
                                      width: "100%",
                                    }}
                                    onChange={onChangefee}
                                  >
                                    {CommondataPrice.map((price) => {
                                      return (
                                        <Radio value={price.value}>
                                          {price.value === "Self"
                                            ? "Therapy for me(Individual)"
                                            : price.value === "Couple"
                                            ? "Therapy for us(Couples)"
                                            : price.value === "Family"
                                            ? "Therapy for all(Family)"
                                            : ""}
                                        </Radio>
                                      );
                                    })}
                                  </Radio.Group>
                                </Form.Item>
                                <Input
                                  style={{
                                    border: "none",
                                    fontWeight: 600,
                                    paddingBottom: 20,
                                  }}
                                  readOnly
                                  value={Price}
                                  size="large"
                                />
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Full Name</Title>
                            <Form.Item
                              name={["user"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter full name",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (nameValidation(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        "Name should only contain alphabet"
                                      );
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input
                                className="cbTextBorder"
                                size="large"
                                type="text"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Gender</Title>
                            <Form.Item
                              name={["gender"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select gender",
                                },
                              ]}
                            >
                              <Radio.Group
                                name="radiogroup"
                                onChange={onGenderChange}
                              >
                                <Radio value={Man}>Man</Radio>
                                <Radio value={Woman}>Woman</Radio>
                                <Radio value={Transgender}>Transgender</Radio>
                                <Radio value={Non}>Non-Binary</Radio>
                                <Radio value={Others}>Others</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Date of Birth</Title>
                            <Form.Item
                              className="cbTextBorder"
                              name={["DOB"]}
                              rules={[
                                {
                                  type: "date",
                                  required: true,
                                  message: "Please select date of birth",
                                },
                              ]}
                            >
                              <DatePicker
                                size="large"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                                defaultPickerValue={moment().subtract(
                                  18,
                                  "years"
                                )}
                                format={"DD-MM-YYYY"}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Title level={5}>Email</Title>
                            <Form.Item
                              name={["email"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter email id",
                                },
                                {
                                  type: "email",
                                  message: "Please enter valid email id",
                                },
                              ]}
                            >
                              <Input className="cbTextBorder" size="large" />
                            </Form.Item>
                          </div>
                          <div class="col-md-6">
                            <Title level={5}>Phone Number</Title>
                            <Form.Item
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter phone number",
                                },
                              ]}
                            >
                              <Input
                                className="cbTextBorder"
                                maxLength={10}
                                size="large"
                              />
                            </Form.Item>
                          </div>

                          <div className="col-md-6">
                            <Title level={5}>Coupon Code</Title>
                            <Form.Item name={["coupon"]}>
                              <Input
                                className="cbTextBorder"
                                size="large"
                                value={couponCode}
                                onChange={handleCouponChange}
                                addonAfter={
                                  <Button
                                    onClick={
                                      isCouponApplied
                                        ? handleClearCoupon
                                        : handleApplyCoupon
                                    }
                                    style={{
                                      background:
                                        "linear-gradient(270deg, #ff7cc9 0%, #cf509b 100%)",
                                      color: "white",
                                      borderRadius: "4px",
                                    }}
                                    disabled={!couponCode}
                                  >
                                    {isCouponApplied ? "Clear" : "Apply"}
                                  </Button>
                                }
                              />
                            </Form.Item>
                            <p style={{ color: isResponse ? "green" : "red" }}>
                              {couponMessage}
                            </p>
                            {isResponse && (
                              <p>
                                Original Amount was ₹{originalAmount}. Discount
                                of ₹{discount} applied and final payable amount
                                is ₹{finalAmount}.
                              </p>
                            )}
                          </div>

                          <div className="col-md-6">
                            {Type === "GC" && (
                              <>
                                <Title level={5}>
                                  Is this your First Consultation?
                                </Title>
                                <div style={{ marginBottom: "15px" }}>
                                  <Form.Item
                                    style={{ marginBottom: 0 }}
                                    name={["apptType"]}
                                    // defaultValue={form.getFieldValue("apptType")}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select counselling type",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="cbTextBorder"
                                      size="large"
                                      onChange={(value) => {
                                        handleConsultationType(value);
                                      }}
                                    >
                                      <Option value="first">
                                        First Consultation
                                      </Option>
                                      <Option value="follow_up">
                                        Follow Up Consultation
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                  <span className="mt-0">
                                    {consultationFees}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {Type === "GC" ? (
                              <>
                                <Title level={5}>Attach Reports (If any)</Title>
                                <Form.Item name="reports_cat">
                                  <Select
                                    className="cbTextBorder"
                                    placeholder="Select"
                                    size="large"
                                    defaultValue={JSON.stringify(
                                      categoryReports[0]
                                    )} // Set the default value here
                                    onChange={handleChange} // Handle value change
                                  >
                                    {categoryReports.map((item, index) => {
                                      return (
                                        <Option
                                          key={item.id}
                                          value={JSON.stringify(item)}
                                        >
                                          {item.displayName}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </>
                            ) : (
                              <Form.Item>
                                <Button
                                  size="large"
                                  shape="round"
                                  className="w-100 attach-report-btn"
                                  onClick={showFileModal}
                                >
                                  Attach Reports (If any)
                                  <PaperClipOutlined className="hideGalaxyFold" />
                                </Button>
                              </Form.Item>
                            )}

                            {reportsDisplay.length > 0 ? (
                              <>
                                {reportsDisplay.map((file, index) => (
                                  <Card
                                  onClick={() =>
                                    file.isCategory && !file.isFileUpload && showFileModal(file)
                                  }
                                    bodyStyle={{ padding: "10px" }}
                                    className="mb-3"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Row
                                      gutter={(15, 15)}
                                      className="align-items-center"
                                    >
                                      {file.isCategory && !file.isFileUpload ? (
                                       <Col xs={2} className="text-center">
                                         <FilePdfOutlined
                                           //  onClick={()=>showFileModal(file)}
                                           style={{
                                             marginRight: "5px",
                                             fontSize: "24px",
                                           }}
                                         />
                                       </Col>
                                     ) : file.isCategory && file.isFileUpload ? 
                                         file.uploadedFileName.split(".")[1] === "pdf" ? 
                                         (
                                          <Col xs={2} className="text-center">
                                          <FilePdfOutlined
                                            style={{
                                              marginRight: "5px",
                                              fontSize: "24px",
                                              color: "red", // Change color to red
                                            }}
                                          />
                                        </Col>
                                         ) 
                                         : ( <Col xs={2} className="text-center">
                                         <img
                                           src={file.is_report ? `${config.api.s3_url}patient-report/${file.file_path}` : file.file_path}
                                           alt="Thumbnail"
                                           style={{
                                             width: "24px",
                                             height: "24px",
                                             marginRight: "5px",
                                           }}
                                         />
                                       </Col>)
                                       : file.name.split(".")[1] === "pdf" ? (
                                        <Col xs={2} className="text-center">
                                          <FilePdfOutlined
                                            style={{
                                              marginRight: "5px",
                                              fontSize: "24px",
                                            }}
                                          />
                                        </Col>
                                      ) : (
                                        <Col xs={2} className="text-center">
                                          <img
                                           src={
                                            file.from === "reports" ?
                                          `${config.api.s3_url}patient-report/` +
                                                file.file_path
                                              : file.file_path
                                               
                                          }
                                            alt="Thumbnail"
                                            style={{
                                              width: "24px",
                                              height: "24px",
                                              marginRight: "5px",
                                            }}
                                          />
                                        </Col>
                                      )}
                                      <Col xs={20}>{file.name}</Col>
                                      {file.isCategory && !file.isFileUpload ? (
                                        <Col xs={2} className="text-center">
                                          <LinkOutlined
                                            className="drNotificationIcon"
                                            style={{
                                              margin: "8px 8px 0px 0px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              showFileModal(file);
                                            }}
                                          />
                                        </Col>
                                      ) : (
                                        <Col xs={2} className="text-center">
                                          <DeleteTwoTone
                                            className="drNotificationIcon"
                                            style={{
                                              margin: "8px 8px 0px 0px",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              deleteReport(file, index);
                                            }}
                                          />
                                        </Col>
                                      )}
                                     
                                    </Row>
                                  </Card>
                                ))}
                                <br />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Title level={5}>Reffered By</Title>
                          <Form.Item
                            name={["ref"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select reffered by",
                              },
                            ]}
                          >
                            <Radio.Group
                              defaultValue={"self"}
                              // value={value}
                              onChange={(e) =>
                                e.target.value === "doctor"
                                  ? setIsDoctor(true)
                                  : setIsDoctor(false)
                              }
                            >
                              <Space direction="horizontal">
                                <Radio value={"self"}>Self</Radio>
                                <Radio value={"doctor"}>Doctor</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          {isDoctor ? (
                            <>
                              <div className="col-md-6">
                                <Title level={5}>Doctor Name</Title>
                                <Form.Item
                                  name={["refDoc"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter Doctor name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="cbTextBorder"
                                    size="large"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-md-6">
                                <Title level={5}>
                                  Hospital/ Clinic Address
                                </Title>
                                <Form.Item
                                  name={["refAdd"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please enter Hospital/Clinic Address!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="cbTextBorder"
                                    size="large"
                                  />
                                </Form.Item>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <Form.Item
                            name="ageCheck"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error("Please confirm your age")
                                      ),
                              },
                            ]}
                          >
                            <Checkbox style={{ border: "none" }}>
                              My age is 18 and above{" "}
                            </Checkbox>
                          </Form.Item>

                          <Form.Item
                            name="readCheck"
                            valuePropName="checked"
                            rules={[
                              {
                                validator: (_, value) =>
                                  value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                          "Please accept terms & conditions"
                                        )
                                      ),
                              },
                            ]}
                          >
                            <Checkbox
                              style={{ border: "none" }}
                              value="read true"
                            >
                              {" "}
                              I have read the
                              <Button
                                onClick={showModal}
                                style={{ padding: "0.5%" }}
                                type="link"
                              >
                                terms and conditions
                              </Button>
                              and I give my consent to accept services from
                              Lilac Insights
                            </Checkbox>
                          </Form.Item>

                          <Form.Item>
                            <div className="text-center">
                              <Button
                                className="themeBtn"
                                htmlType="submit"
                                disabled={loading}
                              >
                                {loading ? <Spin spinning={loading} /> : "Pay"}
                              </Button>
                            </div>
                          </Form.Item>
                        </div>
                      </Form>
                      <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        onOk={handleOk}
                        okText="I agree"
                      >
                        <Terms />
                      </Modal>
                      <Modal
                        title="Upload"
                        width={"600px"}
                        footer={null}
                        visible={isFileModalVisible}
                        onCancel={handleFileOk}
                      >
                        <div className="row">
                          <div className="col-6 mb-2 text-center">
                            <div className="helthboxTiles">
                              <Input
                                type="file"
                                disabled={fileArray.length === 5}
                                className="file_upload"
                                accept="image/jpeg,application/pdf"
                                onChange={UploadSingleFile}
                                style={{
                                  cursor: "pointer",
                                  height: "100%",
                                  position: "absolute",
                                }}
                              />
                              <img
                                className="img-fluid"
                                width="100px"
                                src="./images/gallery.svg"
                                alt="no"
                              />
                              <h4 className="helthboxHeading">Gallery</h4>
                            </div>
                          </div>
                          <div
                            className="col-6 mb-2 text-center"
                            onClick={showReportModal}
                          >
                            <div
                              className="helthboxTiles"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                className="img-fluid"
                                width="100px"
                                src="./images/report.svg"
                                alt="no"
                              />
                              <h4 className="helthboxHeading">My Reports</h4>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        onOk={handleOk}
                        okText="I agree"
                      >
                        <Terms />
                      </Modal>
                      {/* <Modal
                        title="Your Reports (click to select)"
                        visible={isReportModalVisible}
                        onCancel={cancelOk}
                        onOk={handleReportOk}
                        okText="OK"
                      >
                        {reportsData.result.length > 0 ? (
                          <Checkbox.Group
                          value={Filesdata ? [Filesdata] : []}
                           onChange={SelectSingleFile}>
                            {" "}
                            <div className="row">
                              {reportsData.result.map((item) => {
                                return (
                                  <div className="col-sm-6 col-md-12 mb-3">
                                    <Styled>
                                      <Checkbox
                                        cheacked={item}
                                        value={item}
                                        style={{
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            padding: "8px",
                                          }}
                                        >
                                          {item.file_path.split(".")[1] ===
                                          "pdf" ? (
                                            <FilePdfOutlined
                                              style={{
                                                marginRight: "5px",
                                                fontSize: "24px",
                                                marginTop: "8px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={
                                                `${config.api.s3_url}patient-report/` +
                                                item.file_path
                                              }
                                              alt="report"
                                              style={{
                                                height: "24px",
                                                width: "24px",
                                                marginRight: "5px",
                                                marginTop: "8px",
                                              }}
                                            />
                                          )}
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {item.title}
                                          </span>
                                          <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={
                                              `${config.api.s3_url}patient-report/` +
                                              item.file_path
                                            }
                                            download
                                          >
                                            <i
                                              className="fa fa-download reportDownload"
                                              style={{ marginTop: "8px" }}
                                            ></i>
                                          </a>
                                        </div>
                                      </Checkbox>
                                    </Styled>
                                  </div>
                                );
                              })}{" "}
                            </div>
                          </Checkbox.Group>
                        ) : (
                          <h4 className="text-center">No Reports Available</h4>
                        )}
                      </Modal> */}
                       <Modal
                        title="Your Reports (click to select)"
                        visible={isReportModalVisible}
                        onCancel={cancelOk}
                        onOk={handleReportOk}
                        okText="OK"
                        centered
                        bodyStyle={{
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 200px)",
                        }}
                      >
                        {reportsData.result.length > 0 ? (
                          <Checkbox.Group
                            value={Filesdata ? [Filesdata] : []}
                            onChange={SelectSingleFile}
                            className="w-100"
                          >
                            <div className="row">
                              {reportsData.result.map((item) => {
                                return (
                                  <>
                                    <div className="col-sm-6 col-md-12 mb-3">
                                      <Styled>
                                        <Checkbox
                                          value={item}
                                          // checked={item}
                                        >
                                          <Row>
                                            {item.file_path.split(".")[1] ===
                                            "pdf" ? (
                                              <Col xs={3}>
                                                <FilePdfOutlined
                                                  style={{
                                                    marginRight: "5px",
                                                    fontSize: "24px",
                                                    marginTop: "8px",
                                                  }}
                                                />
                                              </Col>
                                            ) : (
                                              <Col xs={3}>
                                                <img
                                                  src={
                                                    `${config.api.s3_url}patient-report/` +
                                                    item.file_path
                                                  }
                                                  alt="report"
                                                  style={{
                                                    height: "24px",
                                                    width: "24px",
                                                    marginRight: "5px",
                                                    marginTop: "8px",
                                                  }}
                                                />
                                              </Col>
                                            )}
                                            <Col xs={21}>
                                              <span
                                                style={{
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {item.title}
                                              </span>
                                            </Col>
                                            {/* <a
                                            className="col-md-2"
                                              target="_blank"
                                              rel="noreferrer"
                                              href={
                                                `${config.api.s3_url}patient-report/` +
                                                item.file_path
                                              }
                                              download
                                            >
                                              <i
                                                className="fa fa-download reportDownload"
                                                style={{ marginTop: "8px" }}
                                              ></i>
                                            </a> */}
                                          </Row>
                                        </Checkbox>
                                      </Styled>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </Checkbox.Group>
                        ) : (
                          <h4 className="text-center">No Reports Available</h4>
                        )}
                      </Modal>
                    </>
                  )}
                </ContainsBooking>
              </>
            )}
          </div>
          <Modal
            title="Make payment"
            visible={showPaymentModal}
            footer={null}
            onCancel={closePaymentModal}
          >
            <div id="paymentDiv"></div>
          </Modal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  savePaymentDataState: state.savePaymentData,
  getDoctorDetailState: state.getDoctorDetail,
  validateCouponState: state.validateCoupon,
  updatePaymentState: state.updatePayment,
});

const mapDispatchToProps = (dispatch) => ({
  savePaymentData: (params) => dispatch(savePaymentData(params)),
  getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
  validateCoupon: (params) => dispatch(validateCoupon(params)),
  updatePayment: (params) => dispatch(updatePayment(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationBooking);
// attach report form item
