import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";
// import SupportBlock from "../../component/SupportBlock/SupportBlock";
import { TopContainer } from "../DoctorAppoint/DoctorAppointStyle";
import { PageContainer } from "../../component/Xcomponent";
import { CuratedTitle, ArticleCard } from "../ArticleList/ArticleListStyle";
import { Row, Col, Spin } from "antd";
import { EyeOutlined, HeartOutlined } from "@ant-design/icons";
import { DotIcon } from "../../component/CustomIcons";
import moment from "moment";
import { Link } from "react-router-dom";
import "./BookmarkArticle.css";

export const BookmarkArticle = (props) => {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    async function getBookmarks() {
      try {
        setLoading(true);
        const response = await axios({
          url: `${config.api.base_url}article/bookmarked`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        });
        console.log(response);
        const res = await response.data.data.result;
        setResponse(res);
      } catch (error) {
        console.log("Error in getting bookmarks:", error);
      } finally {
        setLoading(false);
      }
    }
    getBookmarks();
  }, []);

  return (
    <>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <PageContainer>
      {/* <div className="container roe1"> */}
        <div className="container roe">
          <CuratedTitle>My Bookmarks</CuratedTitle>
        </div>
        <div className="container">
          <Row
            gutter={[20, 20]}
            style={{
              marginLeft: "0.5%",
              marginRight: "0.5%",
              paddingBottom: "6%",
            }}
          >
            {loading ? (
              <div className="text-center loader-container">
                <Spin spinning={loading} tip="Loading Bookmarks..." />
              </div>
            ) : response ? (
              <>
                {response.length > 0 ? (
                  <>
                    {response.map((article) => (
                      <Col
                        className="coltest"
                        // xs={24} sm={24} md={12} lg={12} xl={8}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                      >
                        <Link
                          style={{ textDecoration: "none" }}
                          to={{ pathname: "/article/" + article.slug }}
                        >
                          <ArticleCard
                            className="card-tablets"
                            cover={
                              <img
                                alt="example"
                                className="msgv"
                                src={`https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/article/${article.image_path}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "/images/ArticlePlaceholder.svg";
                                }}
                              />
                            }
                          >
                            <div className="description">
                              <p>{article.title}</p>

                              <div className="views">
                                <span>
                                  {moment(article.createdAt).format(
                                    "Do MMM YYYY"
                                  )}
                                </span>
                                <span style={{ margin: "0px 2px" }}>
                                  {/* <DotIcon size="6" height="6" /> */}
                                </span>
                                <EyeOutlined />
                                <span>{article.views} views</span>
                                <span style={{ margin: "0 2px" }}>
                                  {/* <DotIcon size="6" height="6" /> */}
                                </span>
                                <HeartOutlined />
                                <span>{article.likes} Likes</span>
                              </div>
                            </div>
                          </ArticleCard>
                        </Link>
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    <img
                      className="img-fluid"
                      src="./images/noBookmark.svg"
                      height={"500px"}
                      alt="exp"
                    />
                  </>
                )}
              </>
            ) : (
              <p>You have no Articles Bookmarked</p>
            )}
          </Row>
        </div>
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkArticle);
