/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react"
import { Typography } from 'antd';
import { connect } from "react-redux";
import { useLocation, Link } from 'react-router-dom';

//COMPONENTS 
import {getRead, getReadReset} from '../../action/getReadAction'
import { TopContainer } from "../DoctorAppoint/DoctorAppointStyle";
import config from "../../config";

const Read = (props)=>{

    //CONSTANTS
    const { getRead, getReadReset, getReadState } = props
    const { Title } = Typography;
    const location = useLocation();
    let page_week = JSON.parse(localStorage.getItem('week'));  
    console.log(location.state.category) 


    useEffect(() => {
        window.scrollTo(0, 0)
        getRead({
            cat: location.state.category,
            week: location.state.week
        })
        return () => {
            getReadReset()
        }
      }, [])

      
    useEffect(() => {
        if (getReadState.apiState === "success") {
            window.scrollTo(0, 0)
            console.log("real",getReadState) 
        }
    }, [getReadState]) 

    return(<>
            <TopContainer>
                {/* <img className='align-center' src="./images/lilac-insights-logo.svg"  alt = "exp" />  */}
            </TopContainer>
        <div className="container">
            {/* <img alt="right-bg" className="doctorRightBg" src="./images/svg/doctorbgRight.svg" />
            <img alt="right-bg" className="doctorLeftBg" src="./images/svg/doctorbgLeft.svg" /> */}
            <div className="pageWrap"> 
                {(getReadState.data.length > 0)&& 
                <div className="row">
                <div className="offset-sm-1 col-sm-10 col-12">
                <div className="row">
                {getReadState.data.map((ReadData)=>{
            
            return(
                <div className="col-sm-6 col-12 mb-3">
                    <div className="drReadBox"> 
                    <Link to={{ pathname:"/article/"+ReadData.slug, state: {"is_bookmarked": ReadData.is_bookmarked}}} style={{textDecoration:'none', color:"#1F274A"}} >
                        <div className="row">
                            <div className="col-sm-12 col-3">
                                <img className="img-fluid drReadImgRound" src={`${config.api.s3_url}article/${ReadData.image_path}`} alt="Card image cap"/>
                            </div>
                            <div className="col-sm-12 col-9">
                                <div className="row">
                                        <div className="col-sm-12 col-12 mb-2">
                                        <h4 className="drReadHeading">{ReadData.title}</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <div className="drReadComment">
                                            <img className="img-fluid" src="./images/eye.svg" alt="no"></img>  {ReadData.views}  Views &nbsp; &nbsp;  &nbsp;
                                            <img className="img-fluid" src="./images/heart.svg" alt="no"></img>   {ReadData.likes}  likes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </Link>
                    </div>
                </div>
            )
        })
    }
            </div>
            </div>
            </div>
        }
        </div>
        </div>
    </>)
}
// export default Read

const mapStateToProps = (state) => ({  
    getReadState: state.getRead,
});
  
 const mapDispatchToProps = (dispatch) => ({ 
   getRead: (params) => dispatch(getRead(params)),
   getReadReset: () => dispatch(getReadReset()),   
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Read); 
