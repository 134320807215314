export const SAVE_MOOD_TRACK = "SAVE_MOOD_TRACK"
export const SAVE_MOOD_TRACK_FAIL = "SAVE_MOOD_TRACK_FAIL"
export const SAVE_MOOD_TRACK_SUCCESS = "SAVE_MOOD_TRACK_SUCCESS"

export const saveMoodTrack = (params) => {
    return {type: SAVE_MOOD_TRACK, params}
}

export const saveMoodTrackFail = (response) => {
    return {type: SAVE_MOOD_TRACK_FAIL, response}
}

export const saveMoodTrackSuccess = (response) => {
    return {type: SAVE_MOOD_TRACK_SUCCESS, response}
}
