export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const register = (params) => {
    return { type: REGISTER, params }
}

export const registerSuccess = (response) => {
    return { type: REGISTER_SUCCESS, response }
}
export const registerFail = (response) => {
    return { type: REGISTER_FAIL, response }
}