//action
import {
  GET_BOOK_TEST,
  GET_BOOK_TEST_FAIL,
  GET_BOOK_TEST_SUCCESS,
  GET_BOOK_TEST_RESET,
} from "../action/getBookTestAction"

import { api_err_msg } from "../strings.json";

//initialState
const initialState = {
  apiState: "",
  data: {},
  message: "",
};

export default function getBookTestReducer (state = initialState, action) {
  switch (action.type) {
    case GET_BOOK_TEST:
      return {
        ...state,
        apiState: "loading",
      };
    case GET_BOOK_TEST_FAIL:
      return {
        ...state,
        apiState: "error",
        message:
          (action.response &&
            action.response.data &&
            action.response.data.message) ||
          api_err_msg,
      };
    case GET_BOOK_TEST_SUCCESS:
      return {
        ...state,
        apiState: "success",
        data: action.response.response.data,
      };
    case GET_BOOK_TEST_RESET:
      return initialState;
    default:
      return state;
  }
}
