import axios from "axios";
import config from "../config"
import { getPatientToken } from '../utils'

const getArticleAppList = async (params) => {
  var url = `${config.api.base_url}article/app-list`;
  if (params.category) {
    url += `?category=${params.category}`
  }
  if (params.week) {
    url += `&week=${params.week}`
  }
  console.log("params recieved by article api", params);
  console.log("url", url);
  const response = await axios({
    // url: `${config.api.base_url}article/app-list?week=${params.week}?category=${params.category}`,
    url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });
  console.log("response=>", response)
  return response;
};

export default getArticleAppList;
// /${params.type}