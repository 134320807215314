import { Row, Col, Modal, Button, Result, Input, Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
//action
// import { getPatientList } from '../../action/getPatientListAction'
import {
  getAppointmentPayment,
  getAppointmentPaymentReset,
} from "../../action/getAppointmentPaymentAction";
//other
import {
  PageContainer,
  ViewBox,
  ViewLabel,
  ViewValue,
} from "../../component/Xcomponent";
import { dateFormat, timeSlotFormat } from "../../utils";
import config from "../../config";
import {
  AppleStoreImg,
  FootContainer,
  GoogleStoreImg,
  Image,
  SocialButton,
  XCard,
  XFoot,
  Xlink,
} from "./PatientAppointmentPaymentStyle";

import moment from 'moment';
import { updatePayment } from "../../action/updatePaymentAction";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
}

const PatientAppointmentPayment = (props) => {
  const [form] = Form.useForm();
  const [patientAddress, setPatientAddress] = useState({
    address: "",
    pinCode: "",
    city: "",
    patient_id: "",
  });

  const history = useHistory();
  // Variables
  const book_appointment_id = props.match.params.book_appointment_id;
    const {
        getAppointmentPayment, 
        getAppointmentPaymentReset, 
        getAppointmentPaymentState,
        updatePaymentState,
        updatePayment,
    } = props

    const [hideButton, setHideButton] = useState(true)
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [ cashfreeInstance, setCashfreeInstance ] = useState(null); 

  console.log(patientAddress, "address");
  const handlePatientAddress = (event) => {
    const { name, value } = event.target;
    setPatientAddress({
      ...patientAddress,
      [name]: value,
    });
  };

  // Callbacks
  useEffect(() => {
    getAppointmentPayment({ id: book_appointment_id });
    return () => {
      getAppointmentPaymentReset();
    };
  }, []);

  useEffect(() => {
    if (getAppointmentPaymentState.apiState === "success") {
      setPatientAddress({
        ...patientAddress,
        patient_id: getAppointmentPaymentState.data?.patient_id?._id,
      });
    }
  }, [getAppointmentPaymentState.apiState]);

  //Functions
  const success = () => {
    setHideButton(false);
    Modal.success({
      title: "",
      content: (
        <div className="mt-4">
          <h5 style={{ textAlign: "center", padding: "15px" }}>
            Payment Success
          </h5>
          <p>
            Name :{" "}
            {getAppointmentPaymentState.data.some_one_else
              ? getAppointmentPaymentState.data.full_name
              : `${getAppointmentPaymentState.data.patient_id?.name.firstName} ${getAppointmentPaymentState.data.patient_id?.name.lastName}`}
          </p>
          <p>
            Mobile :{" "}
            {getAppointmentPaymentState.data.some_one_else
              ? getAppointmentPaymentState.data.phone
              : getAppointmentPaymentState.data.patient_id
              ? getAppointmentPaymentState.data.patient_id.phone
              : ""}
          </p>
          <p>
            Email :{" "}
            {getAppointmentPaymentState.data.some_one_else
              ? getAppointmentPaymentState.data.email
              : getAppointmentPaymentState.data.patient_id
              ? getAppointmentPaymentState.data.patient_id.email
              : ""}
          </p>
          <p>
            Time Slot :{" "}
            {timeSlotFormat(getAppointmentPaymentState.data?.time_slot_start)} -{" "}
            {timeSlotFormat(getAppointmentPaymentState.data?.time_slot_end)}
          </p>
          <p>Date : {dateFormat(getAppointmentPaymentState.data.date)}</p>
          <p className="mb-0">
            Amount Paid : {getAppointmentPaymentState.data.total_amount}
          </p>
        </div>
      ),
      onOk() {
        const appointmentId = getAppointmentPaymentState.data._id;
        console.log("patient-appointment-success");
        history.push(`/patient-appointment-success/${appointmentId}`);
      },
    });
  };
  const error = () => {
    Modal.error({
      title: "Your Payment Failed",
      content: "Please try again",
    });
  };

    useEffect(() => {
        if(getAppointmentPaymentState.apiState==="success"){
        setPatientAddress({
            ...patientAddress,
            patient_id: getAppointmentPaymentState.data?.patient_id?._id
        })
        }
    }, [getAppointmentPaymentState.apiState])

    useEffect(()=>{
        if(cashfreeInstance!==null)
        {
          const dropinConfig = {
            components: [
                "order-details",
                "card",
                "netbanking",
                "app",
                "upi",
            ],
            onSuccess: function(data){
    
              setCashfreeInstance(null);
              setShowPaymentModal(false);
              updatePayment({
                payment_for: "booking",
                platform: 'web',
                paymentDetails: data, 
              });
              success()  
            },
            onFailure: function(data){
              setCashfreeInstance(null);
              setShowPaymentModal(false);
              updatePayment({
                payment_for: "booking",
                platform: 'web',
                paymentDetails: data, 
              });
              error();
            },
            style: {
                  //to be replaced by the desired values
                  backgroundColor: "#ffffff",
                  color: "#865590", 
                  fontFamily: "Lato",
                  fontSize: "14px",
                  errorColor: "#ff0000",
                  theme: "light",
            }
          } 
          setShowPaymentModal(true);
          setTimeout(() => {  
            let paymentForm = document.createElement('div');
            paymentForm.id = "paymentForm";
            document.getElementById('paymentDiv').appendChild(paymentForm);
            cashfreeInstance.drop(document.getElementById('paymentForm'),dropinConfig);
          }, 100);
        }
        else
        {
          let removePaymentForm = document.getElementById('paymentForm');
          if(removePaymentForm)
          {
            removePaymentForm.remove();
          }
        }
      },[cashfreeInstance]);
    
    // if (!res) {
    //   alert("Payment failed are you online?");
    //   return;
    // }
    // const result =  axios({
    //   method: "post",
    //   url: `${config.api.base_url}appointment-payment/razorpay/create-order`,
    //   data: {
    //     book_appointment_id: getAppointmentPaymentState.data._id,
    //     total_amount: getAppointmentPaymentState.data.total_amount,
    //     patient_address: patientAddress,
    //   },
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    // });
    // if (!result) {
    //   alert("Server error. Are you online?");
    //   return;
    // }
    // const options = {
    //   key: config.payment_gateway_key,
    //   amount: getAppointmentPaymentState.data.total_amount,
    //   currency: "INR",
    //   name: "Lilac Insights.",
    //   description: "Test Transaction",
    //   image: "/images/lilac-Insights-logo1.png",
    //   order_id: result.data.data.result.razorpay_order_id,
    //   handler: function (response) {
    //     success();
    //   },
    //   prefill: {
    //     contact: getAppointmentPaymentState.data.some_one_else
    //       ? getAppointmentPaymentState.data.phone
    //       : getAppointmentPaymentState.data.patient_id
    //       ? getAppointmentPaymentState.data.patient_id.phone
    //       : "",
    //     email: getAppointmentPaymentState.data.some_one_else
    //       ? getAppointmentPaymentState.data.email
    //       : getAppointmentPaymentState.data.patient_id
    //       ? getAppointmentPaymentState.data.patient_id.email
    //       : "",
    //   },
    // };
    // var rzp1 = new window.Razorpay(options);
    // rzp1.open();
    // rzp1.on("payment.failed", function (response) {
    //   error();
    // }); 

    const displayCashfree = async () => {
        try{
            await axios({
                method: 'post',
                url: `${config.api.base_url}appointment-payment/cashfree/create-order`,
                data: {
                    book_appointment_id: getAppointmentPaymentState.data._id,
                    total_amount: getAppointmentPaymentState.data.total_amount,
                    patient_address: patientAddress,
                },
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                }
            })
            .then(res=>{
                
                if(res.data.status!==1)
                {
                    notification.error({
                        message: "Unable to make Payment"
                    });
                    return;        
                }

                if(res.data.data.booking_not_found)
                {
                    notification.error({
                        message: "Appointment booking not found"
                    });
                    return;
                }

                if(res.data.data.expired_status)
                {
                    notification.error({
                        message: "Your Payment link is expired. Please book the appointment again"
                    });
                    return;
                }

                if(res.data.data.already_exist)
                {
                    notification.error({
                        message: "Payment for this booking has already done"
                    });
                    return;
                }

                setCashfreeInstance(new window.Cashfree(res.data.data.result.cashfree_payment_session_id));
            });
        }
        catch(error){

            console.log("Error==>", error.message);
            notification.error({
                message: "Unable to make Payment"
            });
        }
    }

    // const displayRazoraPay = async () => {

    //     const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

    //     if (!res) {
    //         alert("razorpay failed are you online?")
    //         return
    //     }
    //     const result = await axios({
    //         method: 'post',
    //         url: `${config.api.base_url}appointment-payment/razorpay/create-order`,
    //         data: {
    //             book_appointment_id: getAppointmentPaymentState.data._id,
    //             total_amount: getAppointmentPaymentState.data.total_amount,
    //             patient_address: patientAddress,
    //         },
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8",
    //         }
    //     })
    //     if (!result) {
    //         alert("Server error. Are you online?");
    //         return;
    //     }
    //     const options = {
    //         "key": config.payment_gateway_key,
    //         "amount": getAppointmentPaymentState.data.total_amount,
    //         "currency": "INR",
    //         "name": "Lilac Insights.",
    //         "description": "Test Transaction",
    //         "image": "/images/lilac-Insights-logo1.png",
    //         "order_id": result.data.data.result.razorpay_order_id,
    //         "handler": function (response) {
    //             success()
    //         },
    //         'prefill': {
    //             'contact': getAppointmentPaymentState.data.some_one_else ? getAppointmentPaymentState.data.phone : getAppointmentPaymentState.data.patient_id ? getAppointmentPaymentState.data.patient_id.phone : "",
    //             'email': getAppointmentPaymentState.data.some_one_else ? getAppointmentPaymentState.data.email : getAppointmentPaymentState.data.patient_id ? getAppointmentPaymentState.data.patient_id.email : ""
    //         }
    //     };
    //     var rzp1 = new window.Razorpay(options);
    //     rzp1.open();
    //     rzp1.on('payment.failed', function (response) {
    //         error()
    //     });

    // }

    const closePaymentModal = () => {
        setCashfreeInstance(null);
        setShowPaymentModal(false);
    };

    return (
      <>
      <PageContainer>
        <>
          <XCard
            style={{ padding: "2%" }}
            title={
              <>
                <div className="d-lg-block d-md-flex d-sm-flex justify-content-center">
                  <div>
                    <img
                      src="/images/lilac-insights-logo.svg"
                      alt="no"
                      class="sc-jSgvzq"
                    />
                    <h5 style={{ margin: "30px 0 0 0" }}>
                      Payment Confirmation
                    </h5>
                  </div>
                </div>
              </>
            }
          >
            {getAppointmentPaymentState.apiState === "success" &&
            getAppointmentPaymentState.booking_not_found ? (
              <>
                <Result status="404" title="Appointment booking not found." />
                {/* <h3>
                                Appointment booking not found.
                            </h3> */}
              </>
            ) : getAppointmentPaymentState.apiState === "success" &&
              getAppointmentPaymentState.expired_status ? (
              <>
                <Result
                  status="warning"
                  title="Your Payment link is expired. Please book the appointment again."
                />
                {/* <h3>
                                Your Payment link is expired. Please book the appointment again.
                            </h3> */}
              </>
            ) : getAppointmentPaymentState.apiState === "success" &&
              getAppointmentPaymentState.already_exist ? (
              <>
                <Result
                  // status="warning"
                  title="Payment for this booking has already done"
                />
                {/* <h3>
                                    Payment for this booking has already done
                                </h3> */}
              </>
            ) : (
              getAppointmentPaymentState.apiState === "success" && (
                <>
                  <Row gutter={20}>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Appointment No.</ViewLabel>
                        <ViewValue>
                          <span>
                            {getAppointmentPaymentState.data.appointment_no}
                          </span>
                        </ViewValue>
                      </ViewBox>
                    </Col>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Date</ViewLabel>
                        <ViewValue>
                          {dateFormat(getAppointmentPaymentState.data.date)}
                        </ViewValue>
                      </ViewBox>
                    </Col>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Name</ViewLabel>
                        <ViewValue>
                          {getAppointmentPaymentState.data.some_one_else
                            ? getAppointmentPaymentState.data.full_name
                            : `${getAppointmentPaymentState.data.patient_id?.name.firstName} ${getAppointmentPaymentState.data.patient_id?.name.lastName}`}
                        </ViewValue>
                      </ViewBox>
                    </Col>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Mobile No.</ViewLabel>
                        <ViewValue>
                          {getAppointmentPaymentState.data.some_one_else
                            ? getAppointmentPaymentState.data.phone
                            : getAppointmentPaymentState.data.patient_id
                            ? getAppointmentPaymentState.data.patient_id.phone
                            : ""}
                        </ViewValue>
                      </ViewBox>
                    </Col>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Time Slot</ViewLabel>
                        <ViewValue>
                          {timeSlotFormat(
                            getAppointmentPaymentState.data.time_slot_start
                          )}{" "}
                          -{" "}
                          {timeSlotFormat(
                            getAppointmentPaymentState.data.time_slot_end
                          )}
                        </ViewValue>
                      </ViewBox>
                    </Col>
                  </Row>
                  <Form form={form} onFinish={displayCashfree}>
                  <Row gutter={20}>
                    <Col span="24">
                      <ViewBox>
                        <ViewLabel>Address</ViewLabel>
                        <Form.Item
                            name="address"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter address',
                              },
                            ]}
                          >
                        <Input.TextArea
                          name="address"
                          placeholder="Enter Address"
                          value={
                            getAppointmentPaymentState.data.patient_id
                              ? getAppointmentPaymentState.data.patient_id
                                  .address
                              : ""
                          }
                          onChange={handlePatientAddress}
                          style={{ border: "none" }}
                        />
                        </Form.Item>
                      </ViewBox>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>City</ViewLabel>
                        <Form.Item
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter city',
                              },
                            ]}
                          >
                        <Input
                          name="city"
                          value={
                            getAppointmentPaymentState.data.patient_id
                              ? getAppointmentPaymentState.data.patient_id.city
                              : ""
                          }
                          placeholder="Enter City"
                          onChange={handlePatientAddress}
                          style={{ border: "none" }}
                        />
                        </Form.Item>
                      </ViewBox>
                    </Col>
                    <Col span="12">
                      <ViewBox>
                        <ViewLabel>Pincode</ViewLabel>
                          <Form.Item
                            name="pinCode"
                            rules={[
                              {
                                validator: (rule, value) => {
                                  if (!value) {
                                    return Promise.reject('Please enter pincode');
                                  }
                                  if (!/^[0-9]+$/.test(value)) {
                                    return Promise.reject('Pincode must contain only numbers');
                                  }
                                  if (value.length !== 6) {
                                    return Promise.reject('Pincode must be 6 digits long');
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              name="pinCode"
                              value={
                                getAppointmentPaymentState.data.patient_id
                                  ? getAppointmentPaymentState.data.patient_id
                                      .pinCode
                                  : ""
                              }
                              placeholder="Enter Pincode"
                              onChange={handlePatientAddress}
                              style={{ border: "none" }}
                            />
                          </Form.Item>
                      </ViewBox>
                    </Col>
                  </Row>
                  {hideButton && (
                    <Row gutter={20}>
                      <Col span="24" style={{ padding: 10 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          // onClick={displayRazoraPay}
                        >
                          Pay&nbsp;{" "}
                          <span style={{ fontFamily: "initial" }}>₹&nbsp;</span>{" "}
                          {getAppointmentPaymentState.data.total_amount}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  </Form>
                </>
              )
            )}
          </XCard>
          <FootContainer className="footerWrap">
            <div className="container">
              <div className="row pt-5">
                <div className="col-sm-6 col-12">
                  <div>
                    <Image
                      src={
                        process.env.PUBLIC_URL +
                        "/images/lilac-insights-logo (1) 1.svg"
                      }
                      alt="no"
                    />
                  </div>
                  <h1 level={3} className="compy">
                    Lilac Insights Pvt. Ltd.
                  </h1>
                  <p className="footerPara1">
                    301-302 Rupa Solitaire Premises Co-Op Society Ltd.,
                    Sector-1, Building A -1, Millennium Business Park, MIDC.
                    Navi Mumbai 400710, Maharashtra, India
                  </p>
                </div>
                <div className="col-sm-6 col-12 d-sm-flex justify-content-end">
                  <SocialButton className="socialButton">
                    <Link
                      to={{
                        pathname:
                          "https://play.google.com/store/apps/details?id=com.lilacinsights.healthcare",
                      }}
                      target="_blank"
                    >
                      <GoogleStoreImg
                        className="img-fluid svapp"
                        src="../images/GoogleStore.svg"
                        alt="no"
                      />
                    </Link>
                    <Link
                      to={{ pathname: "https://apps.apple.com/in/app/" }}
                      target="_blank"
                    >
                      <AppleStoreImg
                        className="img-fluid svapp"
                        src="../images/AppleStore.svg"
                        alt="no"
                      />
                    </Link>
                  </SocialButton>
                </div>
              </div>
              <hr className="divderHr" />
              <XFoot className="mt-3">
                Copyright Lilac Insights, All Rights Reserved.
              </XFoot>
            </div>
            <br />
          </FootContainer>
        </>
      </PageContainer>
      <Modal
                title="Make payment"
                visible={showPaymentModal}
                footer={null} 
                onCancel={closePaymentModal}
            >
                <div id="paymentDiv">
                
                </div>
            </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
    getAppointmentPaymentState: state.getAppointmentPayment,
    updatePaymentState: state.updatePayment,
})

const mapDispatchToProps = (dispatch) => ({
    getAppointmentPayment: (params) => dispatch(getAppointmentPayment(params)),
    getAppointmentPaymentReset: () => dispatch(getAppointmentPaymentReset()),
    updatePayment: (params) => dispatch(updatePayment(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAppointmentPayment);
