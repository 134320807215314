export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_FAIL = "GET_ARTICLE_FAIL";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_RESET = "GET_ARTICLE_RESET";

export const getArticle = (params) => {
  console.log("getArticle called--------------------");
  return { type: GET_ARTICLE, params };
};
export const getArticleFail = (response) => {
  return { type: GET_ARTICLE_FAIL, response };
};
export const getArticleSuccess = (response) => {
  return { type: GET_ARTICLE_SUCCESS, response };
};
export const getArticleReset = () => {
  return { type: GET_ARTICLE_RESET };
};
