import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_DOCTOR_DETAIL,
    getDoctorDetailFail,
    getDoctorDetailSuccess
} from '../action/getDoctorDetailAction'

// api 
import getDoctorDetailApi from '../api/getDoctorDetailApi'

export function* getDoctorDetailSaga(action) {
    try {
        const response = yield call(() => getDoctorDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getDoctorDetailSuccess(response, action))
        } else {
            yield put(getDoctorDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getDoctorDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_DOCTOR_DETAIL, getDoctorDetailSaga)
}