import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const AtEasyboxTiles = styled.div`
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 40px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 768px) {
    width: 95px;
    height: 80px;
    flex-direction: column;
    align-content: left;
  }
`;
export const AtEasyboxFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AtEasyColumn = styled.div`
  align-items: center;
  justify-content: space-between;
`;
export const XImg = styled.img`
  @media (max-width: 768px) {
    width: 20px;
    height: 26px;
  }
`;
