export const GET_CONSULTATION_HISTORY = "GET_CONSULTATION_HISTORY"
export const GET_CONSULTATION_HISTORY_FAIL = "GET_CONSULTATION_HISTORY_FAIL"
export const GET_CONSULTATION_HISTORY_SUCCESS = "GET_CONSULTATION_HISTORY_SUCCESS"
export const GET_CONSULTATION_HISTORY_RESET = "GET_CONSULTATION_HISTORY_RESET"

export const getConsultationHistory = (params) => {
    return { type: GET_CONSULTATION_HISTORY, params }
}
export const getConsultationHistoryFail = (response) => {
    return { type: GET_CONSULTATION_HISTORY_FAIL, response }
}
export const getConsultationHistorySuccess = (response) => {
    return { type: GET_CONSULTATION_HISTORY_SUCCESS, response }
}
export const getConsultationHistoryReset = () => {
    return { type: GET_CONSULTATION_HISTORY_RESET }
}