import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

//COMPONENTS
import Header from "../../component/Header/Header";
import { getWatch, getWatchReset } from "../../action/getWatchAction";
import { TopContainer } from "../DoctorAppoint/DoctorAppointStyle";

//Style
import { Container, Content } from "./WatchStyle" 
import config from "../../config";

const Watch = (props) => {
  //CONSTANTS
  const { getWatch, getWatchReset, getWatchState } = props;
  const location = useLocation();
  let page_week = JSON.parse(localStorage.getItem("week"));
  console.log(location);

  //CALLBACKS

  useEffect(() => {
    getWatch({
      cat: location.state.category,
      week: location.state.week,
    });
    window.scrollTo(0, 0);
    return () => {
      getWatchReset();
    };
  }, []);

  useEffect(() => {
    if (getWatchState.apiState === "success") {
      window.scrollTo(0, 0);
    }
  }, [getWatchState]);

  return (
    <>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <div className="container">
        <img
          alt="right-bg"
          className="doctorRightBg1"
          src="./images/svg/doctorbgRight.svg"
        />
        <img
          alt="right-bg"
          className="doctorLeftBg"
          src="./images/svg/doctorbgLeft.svg"
        />
        <div className="pageWrap">
          {getWatchState.data.length > 0 && (
            <div className="row">
              <div className="offset-sm-1 col-sm-10 col-12">
                <div className="row">
                  {getWatchState.data.map((WatchData) => {
                    return (
                      <>
                        {" "}
                        {console.log(WatchData)}
                        <div className="col-sm-6 col-12 mb-3">
                          <div className="drReadBox">
                            <Link
                              to={{
                                pathname: "/video/" + WatchData.slug,
                                state: {
                                  is_bookmarked: WatchData.is_bookmarked,
                                },
                              }}
                              style={{
                                textDecoration: "none",
                                color: "#1F274A",
                              }}
                            >
                              <div className="row">
                                <div className="col-sm-12 col-3">
                                  <img
                                    className="img-fluid drReadImgRound"
                                    src={`${config.api.s3_url}article/${WatchData.image_path}`} 
                                    alt="Card image cap"
                                  />
                                </div>
                                <div className="col-sm-12 col-9">
                                  <div className="row">
                                    <div className="col-sm-12 col-12 mb-2">
                                      <h4 className="drReadHeading">
                                        {WatchData.title}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-12 col-12">
                                      <div className="drReadComment">
                                        <img
                                          className="img-fluid"
                                          src="./images/eye.svg"
                                          alt="no"
                                        ></img>{" "}
                                        {WatchData.views} Views &nbsp; &nbsp;
                                        &nbsp;
                                        <img
                                          className="img-fluid"
                                          src="./images/heart.svg"
                                          alt="no"
                                        ></img>{" "}
                                        {WatchData.likes} likes
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <br />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      )
    </>
  );
};
// export default Watch

const mapStateToProps = (state) => ({
  getWatchState: state.getWatch,
});

const mapDispatchToProps = (dispatch) => ({
  getWatch: (params) => dispatch(getWatch(params)),
  getWatchReset: () => dispatch(getWatchReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Watch);
