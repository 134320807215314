import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_UPCOMING_CONSULTATION,
    getUpcomingConsultationFail,
    getUpcomingConsultationSuccess
} from '../action/getUpcominConsultationAction'

// api 
import getUpcomingConsultationApi from '../api/getUpcomingConsultationApi'

export function* getUpcomingConsultationSaga(action) {
    try {
        const response = yield call(() => getUpcomingConsultationApi(action.params))
        if (response.data.status === 1) {
            yield put(getUpcomingConsultationSuccess(response, action))
        } else {
            yield put(getUpcomingConsultationFail(response, action))
        }
    }
    catch (e) {
        yield put(getUpcomingConsultationFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_UPCOMING_CONSULTATION, getUpcomingConsultationSaga)
}