import styled from "styled-components";

export const JitsiMeet  = styled.div`
    div:first-child
    {
        height: 400px !important;
    } 

    div:first-child iframe #document html
    {
        display: none !important;
    }
`

export const TopContainer  = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto;
        width: 35%;
        margin-top: 15px;
    } 
`