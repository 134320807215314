export const BOOKED_APPOINTMENT_ACTIVITY = "BOOKED_APPOINTMENT_ACTIVITY"
export const BOOKED_APPOINTMENT_FAIL = "BOOKED_APPOINTMENT_FAIL"
export const BOOKED_APPOINTMENT_SUCCESS = "BOOKED_APPOINTMENT_SUCCESS"
export const BOOKED_APPOINTMENT_RESET = "BOOKED_APPOINTMENT_RESET"

export const bookedAppointmentActivity = (params) => {
    return { type: BOOKED_APPOINTMENT_ACTIVITY, params }
}
export const bookedAppointmentFail = (response) => {
    return { type: BOOKED_APPOINTMENT_FAIL, response }
}
export const bookedAppointmentSuccess = (response) => {
    return { type: BOOKED_APPOINTMENT_SUCCESS, response }
}
export const bookedAppointmentReset = () => {
    return { type: BOOKED_APPOINTMENT_RESET }
}

