export const GET_APPOINTMENT_PAYMENT = "GET_APPOINTMENT_PAYMENT"
export const GET_APPOINTMENT_PAYMENT_FAIL = "GET_APPOINTMENT_PAYMENT_FAIL"
export const GET_APPOINTMENT_PAYMENT_SUCCESS = "GET_APPOINTMENT_PAYMENT_SUCCESS"
export const GET_APPOINTMENT_PAYMENT_RESET = "GET_APPOINTMENT_PAYMENT_RESET"

export const getAppointmentPayment = (params) => {
    return { type: GET_APPOINTMENT_PAYMENT, params }
}
export const getAppointmentPaymentFail = (response) => {
    return { type: GET_APPOINTMENT_PAYMENT_FAIL, response }
}
export const getAppointmentPaymentSuccess = (response) => {
    return { type: GET_APPOINTMENT_PAYMENT_SUCCESS, response }
}
export const getAppointmentPaymentReset = () => {
    return { type: GET_APPOINTMENT_PAYMENT_RESET }
}