import axios from "axios";
import config from "../config"
import { getPatientToken } from '../utils'

const getcommonMaster = async () => {
    const response = await axios({
        url: `${config.api.base_url}common-master?slug=concerns,expert-approaches,expert-type,language,consultation-type`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        },
    });

    return response;
};

export default getcommonMaster;
