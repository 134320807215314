export const GET_TRIBE_ARTICLE_LIST = "GET_TRIBE_ARTICLE_LIST"
export const GET_TRIBE_ARTICLE_LIST_FAIL = "GET_TRIBE_ARTICLE_LIST_FAIL"
export const GET_TRIBE_ARTICLE_LIST_SUCCESS  = "GET_TRIBE_ARTICLE_LIST_SUCCESS"
export const GET_TRIBE_ARTICLE_LIST_RESET  = "GET_TRIBE_ARTICLE_LIST_RESET"

export const getTribeArticleList = (params) => {
    return { type: GET_TRIBE_ARTICLE_LIST, params }
}
export const getTribeArticleListFail = (response) => {
    return { type: GET_TRIBE_ARTICLE_LIST_FAIL, response }
}
export const getTribeArticleListSuccess = (response) => {
    return { type: GET_TRIBE_ARTICLE_LIST_SUCCESS, response }
}
export const getTribeArticleListReset = () => {
    return { type: GET_TRIBE_ARTICLE_LIST_RESET }
}