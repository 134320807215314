// action
import {
    GET_MOOD_LIST,
    GET_MOOD_LIST_FAIL,
    GET_MOOD_LIST_SUCCESS
} from '../action/getMoodListAction'

import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: "",
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch(action.type){
        case GET_MOOD_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_MOOD_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_MOOD_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
            }
        default:
            return state
    }
}