const config = {
  url: "",
  api: {
    // base_url: "https://lilac-patient-new-stage-api.aiolos.solutions/",
    base_url: "https://lilac-patient-stage-api.aiolos.solutions/",

    // base_url: "http://localhost:8187/",
    // base_url: "https://lilac-patient-test.aiolos.solutions/",
    // base_url: "http://3.7.97.20:8186/",
    // base_url: "http://localhost:8186/",
    //  base_url: "http://3.109.38.77:8187/",
    // base_url: "http://lilac-api.aiolos.solutions/",
    s3_url: "https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/",
  },
  payment_gateway_key: "rzp_test_g2T6QNbD867ixe",
  cashfree_script_url:
    "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js",
  jitsi_meet_domian: "jitsi-demo.aiolos.solutions",
};
// if (process.env.REACT_APP_ENV === "dev") {
//     //config.api.base_url = "http://3.7.97.20:8186/"
//     config.api.base_url = "http://3.7.97.20:8187/"
// }
export default config;
