import { React, useEffect, useState } from "react";
import { Typography, Card, Skeleton } from "antd";
import {
  getArticleBySlug,
  getArticleBySlugReset,
} from "../../action/getArticleBySlugAction";
import {
  saveArticleLike,
  saveArticleLikeReset,
} from "../../action/saveArticleLikeAction";
import {
  saveArticleBookmark,
  saveArticleBookmarkReset,
} from "../../action/saveArticleBookmarkAction";
// import { HeartTwoTone, EyeOutlined, HeartFilled } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from "moment";
import ReactPlayer from "react-player";

//STYLE
import {
  ListSpacing,
  TopContainer,
  ListHeading,
  SideCard,
  XImg,
  ListSideHead,
  MainImg,
  BackRight,
  BackLeft,
} from "./VideoArticleStyle";

function VideoArticle(props) {
  const { Text } = Typography;
  const {
    getArticleBySlug,
    getArticleBySlugReset,
    getArticleBySlugState,
    saveArticleLike,
    saveArticleLikeReset,
    saveArticleBookmark,
    saveArticleBookmarkReset,
  } = props;
  const slug = props.match.params.slug;
  const [articleParams, getArticleParams] = useState({
    slug: slug,
  });
  const [click, setclick] = useState({
    liked: false,
    bookmark: false,
  });
  const [ArticleData, setArticleData] = useState({
    id: "",
    title: "",
    Description: "",
    img: "",
    views: "",
    likes: "",
  });

  //FUNCTIONS
  const toggle = (value) => {
    console.log(value);
    if (value === "liked" && localStorage.getItem("LilacPatientToken")) {
      let clicked = click.liked;
      const data = {
        article_id: ArticleData.id,
      };
      clicked = !clicked;
      if (clicked === true) {
        ArticleData.likes = ArticleData.likes + 1;
        saveArticleLike(data);
      } else {
        ArticleData.likes = ArticleData.likes - 1;
        saveArticleLike(data);
      }
      setclick({ ...click, ["liked"]: clicked });
    } else if (
      value === "bookmark" &&
      localStorage.getItem("LilacPatientToken")
    ) {
      let clicked1 = click.bookmark;
      clicked1 = !clicked1;
      const data1 = {
        article_id: ArticleData.id,
      };
      saveArticleBookmark(data1);
      setclick({ ...click, ["bookmark"]: clicked1 });
    }
  };

  console.log(click);
  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    getArticleBySlug(articleParams);
    return () => {
      getArticleBySlugReset();
    };
  }, []);

  useEffect(() => {
    if (getArticleBySlugState.apiState === "success") {
      // console.log(getArticleBySlugState)
      setArticleData({
        ["id"]: getArticleBySlugState.data._id,
        ["title"]: getArticleBySlugState.data.title,
        ["Description"]: getArticleBySlugState.data.description,
        ["img"]: getArticleBySlugState.data.image_path,
        ["views"]: getArticleBySlugState.data.views,
        ["likes"]: getArticleBySlugState.data.likes,
      });
      setclick({
        ["liked"]: getArticleBySlugState.data.meta.liked,
        ["bookmark"]: getArticleBySlugState.data.meta.bookmarked,
      });
    }
  }, [getArticleBySlugState]);

  console.log(getArticleBySlugState);
  return (
    <>
      {getArticleBySlugState.apiState === "loading" ? (
        <>
          {/* <Container> */}
          {/* <ContentBlock> */}
          <Skeleton.Image />
          <Skeleton active />
          {/* </ContentBlock> */}
          {/* </Container> */}
        </>
      ) : (
        <>
          {/* <img alt="right-bg" className="doctorRightBg" src="./images/doctorbgRight.svg" />
            <img alt="right-bg" className="doctorLeftBg" src="./images/doctorbgLeft.svg" /> */}
          <TopContainer>
            <img
              className="align-center mobile-height"
              src="../images/lilac-insights-logo.svg"
              alt="exp"
            />
          </TopContainer>
          <ListSpacing className="container">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="col-sm-9 col-12">
                <ListHeading>{getArticleBySlugState.data.title}</ListHeading>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="items">
                    <Text type="secondary listDatePara">
                      {moment(getArticleBySlugState.data.createdAt).format(
                        "MMM Do YY"
                      )}{" "}
                    </Text>
                    <i class="fa fa-circle listingDot" aria-hidden="true"></i>
                    <Text type="secondary listDatePara">
                      <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                      {getArticleBySlugState.data.views} Views
                    </Text>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <i class="fa fa-heart-o listIcons" aria-hidden="true"></i>
                    </div>
                    <div>
                      <i class="fa fa-share listIcons" aria-hidden="true"></i>
                    </div>
                    <div>
                      <i
                        onClick={console.log("i was clicked")}
                        class="fa fa-bookmark-o listIcons"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <img className="listLeftBg hideMobile" src="./images/svg/list-helth-bg.png" alt="no"/>                
                <img class="listRightBg hideMobile" src="./images/svg/doctorbgRight.svg"/> */}
            <div className="row">
              <div className="col-sm-9 col-12 mt-4">
                <div className="row">
                  <div className="col-sm-12 col-12">
                    {/* <MainImg className='img-fluid' src = "./images/VideoImg.svg" alt = "" /> */}
                    <ReactPlayer
                      width={"100%"}
                      style={{ paddingLeft: "8px" }}
                      url={`${getArticleBySlugState.data.video_link}`}
                    />
                    <p className="listingImgPara">
                      <b>Description:</b>
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeURIComponent(
                            getArticleBySlugState.data.description
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-12 mt-4">
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <SideCard>
                      <img
                        className="img-fluid"
                        alt="example"
                        src="../images/side-img1.svg"
                      />
                      <div className="listSideWrap">
                        <ListSideHead className="mb-3">
                          The Illustrated guide shows you how to take care of
                          your Baby
                        </ListSideHead>
                        <div className="items">
                          <Text type="secondary listDatePara">
                            17th May 2021{" "}
                          </Text>
                          <i
                            class="fa fa-circle listingDot"
                            aria-hidden="true"
                          ></i>
                          <Text type="secondary listDatePara">
                            <i class="fa fa-eye" aria-hidden="true"></i> 28
                            Views
                          </Text>
                        </div>
                      </div>
                    </SideCard>
                    <SideCard>
                      <img
                        className="img-fluid"
                        alt="example"
                        src="../images/side-img2.svg"
                      />
                      <div className="listSideWrap">
                        <ListSideHead className="mb-3">
                          The Illustrated guide shows you how to take care of
                          your Baby
                        </ListSideHead>
                        <div className="items">
                          <Text type="secondary listDatePara">
                            17th May 2021{" "}
                          </Text>
                          <i
                            class="fa fa-circle listingDot"
                            aria-hidden="true"
                          ></i>
                          <Text type="secondary listDatePara">
                            <i class="fa fa-eye" aria-hidden="true"></i> 28
                            Views
                          </Text>
                        </div>
                      </div>
                    </SideCard>
                  </div>
                </div>
              </div>
            </div>
          </ListSpacing>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  getArticleBySlugState: state.getArticleBySlug,
  saveArticleLikeState: state.saveArticleLike,
  saveArticleBookmarkState: state.saveArticleBookmark,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleBySlug: (params) => dispatch(getArticleBySlug(params)),
  getArticleBySlugReset: () => dispatch(getArticleBySlugReset()),
  saveArticleLike: (params) => dispatch(saveArticleLike(params)),
  saveArticleLikeReset: () => dispatch(saveArticleLikeReset()),
  saveArticleBookmark: (params) => dispatch(saveArticleBookmark(params)),
  saveArticleBookmarkReset: () => dispatch(saveArticleBookmarkReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoArticle);
