//import {React, useState} from 'react'
import React, { useState } from "react"
import { connect } from 'react-redux'
import { Input, notification } from "antd"
import { Xh1, XModal } from "./MyReportModalStyle"
import 'react-calendar/dist/Calendar.css';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getS3SingedUrl, validateImagePdfFile, getPatientToken } from "../../utils"
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import config from "../../config";


export const MyReportModal = (props) => {

    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState("");
    const [fileNameError, setFileNameError] = useState("");
    // const [ uploadUrl, setUploadUrl ] = useState("")

    const uploadSingleFile = (e) => {
        console.log("fileName", fileName)
        if (fileName) {
            setFileNameError("")

        } else {
            // alert("Please input document Title first")
            e.target.value = null;
            setFileNameError("Please input document title")
            return
        }
        e.target.files[0].fileName = fileName
        //setFile([...file, URL.createObjectURL(e.target.files[0])]);
        setFile([...file, ...e.target.files]);
        // console.log("file", e.target.files);
        //uploadSingleFileName();
        setFileName("")
        e.target.value = null;
        console.log("e.target.value", e.target.value)

    }

    const uploadSingleFileName = (evt) => {
        setFileName(evt.target.value);
        setFileNameError("")
    };

    const upload = async (e) => {
        e.preventDefault();
        // console.log(file);
        // console.log(fileName);
        if (file.length > 0) {
            let file_path_url = []
            const promises = file.map(async file => {
                let filename = uuidv4()// let filename = fileName
                let imageValidationRes = validateImagePdfFile(file)
                if (imageValidationRes.status) {
                    let s3UrlResImage = await getS3SingedUrl(filename, imageValidationRes.ext, file, 'patient-report')
                    await axios.put(s3UrlResImage.url, file)
                    let data = {
                        "title": file.fileName,
                        "file_path": `${filename}.${imageValidationRes.ext}`
                    }
                    file_path_url.push(data)

                }
                else {
                    alert("Select file type JPG, PNG or PDF file.")
                    return
                }
            })
            await Promise.all(promises)
            const reports = {
                "reports": file_path_url
            }
            // console.log(reports)
            axios.post(`${config.api.base_url}report/upload`, reports, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${getPatientToken()}`
                }
            })
                .then(function (response) {
                    console.log(response);
                    notification["success"]({
                        message: response.data.message,
                        placement: "bottomRight"
                    })
                    props.getReportList()
                    setFile([])
                    closeModal()
                })
                .catch(function (error) {
                    console.log(error);
                    notification["error"]({
                        message: error.message,
                        placement: "bottomRight"
                    })
                });
        }
    }

    function deleteFile(e) {
        const s = file.filter((item, index) => index !== e);
        setFile(s);
        // console.log(s);
    }

    function closeModal() {
        props.onClose();
    }


    const handleOk = () => {
        props.onClose();
    }

    return (
        <XModal
            visible={props.visible}
            onOk={handleOk}
            onCancel={closeModal}
            footer={null}
            style={{ borderRadius: '22px!important' }}
        >
            <div>
                <Xh1 className='mb-4'>Upload Report</Xh1>
                <div className='row'>
                    <div className='col-12 mb-2'>
                        <lable className="toolBoxLabel">Title</lable>
                        <Input
                            name={"titleName"}
                            rules={[{ required: true, message: 'Please input document title' }]}
                            onChange={uploadSingleFileName}
                            value={fileName}
                            className="inputBox rounded mt-2"
                            placeholder="Enter document title"
                            size='large'
                        />
                        <label className="errorInput">{fileNameError}</label>
                    </div>
                    <div className='col-12 mt-3'>
                        <div className="form-group mb-0">
                            <label className="lablePic" for="pic">
                                <span><i class="fa fa-cloud-upload"></i> Select report</span>
                                <input
                                    id="pic"
                                    type="file"
                                    disabled={file.length === 5}
                                    className="form-control"
                                    onInput={uploadSingleFile}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='col-12 mt-3'>
                        <div className="row form-group">
                            {file.length > 0 &&
                                file.map((item, index) => {
                                    return (
                                        <div className="col-6 mb-5" key={item}>
                                            <div className="reportWrap">
                                                {console.log("heyyyyy", item.type == "application/pdf")}
                                                <img className="img-fluid reportimgView" src={item.type == "application/pdf" ? "../../images/pdf_logo.png" : URL.createObjectURL(item)} alt="" />
                                                <button className="reportDelete" type="button" onClick={() => deleteFile(index)}>
                                                    <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1.5em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg>
                                                </button>
                                                <h6 className="reportFileName">{item.fileName}</h6>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-12 mt-4 text-center'>
                        <button className={`themeBtn ${file.length == 0 && "disabled"}`} onClick={upload} shape='round' type="primary" htmlType='submit' >Upload</button>
                    </div>
                </div>
            </div>
        </XModal>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MyReportModal)
