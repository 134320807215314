import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_APPOINTMENT_FEEDBACK,
    saveAppointmentFeedbackFail,
    saveAppointmentFeedbackSuccess
} from '../action/saveAppointmentFeedbackAction'

// api 
import saveAppointmentFeedbackApi from '../api/saveAppointmentFeedbackApi'

export function* saveAppointmentFeedbackSaga(action) {
    try {
        const response = yield call(() => saveAppointmentFeedbackApi(action.params))
        if (response.data.status === 1) {
            yield put(saveAppointmentFeedbackSuccess(response, action))
        } else {
            yield put(saveAppointmentFeedbackFail(response, action))
        }
    }
    catch (e) {
        yield put(saveAppointmentFeedbackFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_APPOINTMENT_FEEDBACK, saveAppointmentFeedbackSaga)
}