import { Button, Card } from "antd";
import styled from "styled-components";

export const ArticleCard = styled(Card)`
    text-decoration: none;
    border-color: transparent;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
/* width: 33%; */
  height: 330px;
border-radius: 15px;
.ant-card-body{
  padding: 8px 18px;
}
.ant-card-cover img{
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  height: 250px;
}
    p{
      margin:0;
       color: #121111;
       font-size:14px;
       font-weight:600;
    }
    div.description{
      text-decoration: none;
      display: flex;
      flex-direction:column;
      p{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    div.views{
      gap:8px;
      display: flex;
      align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    color: #A3A9BF;
    font-size:12px;
    margin-top: 8px;
    }
@media (max-width: 768px) {
 text-decoration: none;
    border-color: transparent;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
/* width: 33%; */
  height: 100px;
border-radius: 15px;
.ant-card-body{
  padding: 8px 18px;
}
.ant-card-cover img{
  border-radius: 15px 15px 15px 15px;
  width: 70px;
  height: 80px;
  margin:10px;
}
.msgv{
 width: 70px;
  height: 80px;
  margin:10px;
}
    p{
      margin:0;
       color: #121111;
       font-size:14px;
       font-weight:600;
    }
    div.description{
      text-decoration: none;
      display: flex;
      flex-direction:column;
      p{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    div.views{
      gap:5px;
      display: flex;
      align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    color: #A3A9BF;
    font-size:10px;
    margin-top: 8px;
    }
.ant-card-cover{
float:left;
}
`;

export const CuratedTitle = styled.h1`
  width: 100%;
  position: relative;
  font-family: Poppins;
  font-size: 34px;
  line-height: 32px;
  color: #1f274a;
  font-weight: 600;
  // margin-top:10%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 24px;
  }
`;
