import styled from "styled-components";
import { Button } from "antd";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Content = styled.div`
  display: flex;
  max-width: 1300px;
  align-self: center;
  width: 1200px;
  min-height: 800px;
`;
export const Left = styled.div`
  width: 50%;
  padding-top: 10%;
`;
export const Right = styled.div`
  width: 50%;
  top: 0;
  left: 0;
`;

export const OTPInput = styled.input`
  margin-right: 15px;
  border: solid 1px #d9d9d9;
  height: 40px;
  width: 35px;
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
`;

export const LoginInput = styled.div`
  .ant-input {
    border-radius: 5px;
    heigh: 50px !important;
  }
`;

export const Xlink = styled(Link)`
  /*
    color: #C473C4;
    font-Weight: 500;
    */
`;
export const XButton = styled(Button)`
  width: 200px;
  background-color: #c473c4;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
