import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_MOOD_LIST,
    getMoodListFail,
    getMoodListSuccess
} from "../action/getMoodListAction"

// api
import getMoodListApi from "../api/getMoodListApi"

export function* getMoodListSaga(action) {
    try{
        const response = yield call(() => getMoodListApi(action.params))
        if(response.data.status===1){
            yield put(getMoodListSuccess(response, action))
        } else {
            yield put(getMoodListFail(response, action))
        }
    }
    catch(e)
    {
        yield put(getMoodListFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_MOOD_LIST, getMoodListSaga)
}