import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_UPCOMING_APPOINTMENT,
    getUpcomingAppointmentFail,
    getUpcomingAppointmentSuccess
} from '../action/getUpcomingAppointmentAction'

// api 
import getUpcomingAppointmentApi from '../api/getUpcomingAppointmentApi'

export function* getUpcomingAppointmentSaga(action) {
    try {
        const response = yield call(() => getUpcomingAppointmentApi(action.params))
        if (response.data.status === 1) {
            yield put(getUpcomingAppointmentSuccess(response, action))
        } else {
            yield put(getUpcomingAppointmentFail(response, action))
        }
    }
    catch (e) {
        yield put(getUpcomingAppointmentFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_UPCOMING_APPOINTMENT, getUpcomingAppointmentSaga)
}