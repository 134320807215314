import axios from 'axios'
import config from '../config'
import { getPatientToken } from '../utils'

const getMoodListApi = async () => {
    const response = await axios({
        url:`${config.api.base_url}patient/get-mood-list`,
        method:"get",
        headers:{
            "Content-Type":"application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    })
    return response
}

export default getMoodListApi