export const GET_LISTEN = "GET_LISTEN"
export const GET_LISTEN_FAIL = "GET_LISTEN_FAIL"
export const GET_LISTEN_SUCCESS = "GET_LISTEN_SUCCESS"
export const GET_LISTEN_RESET = "GET_LISTEN_RESET"

export const getListen = (params) => {
    return { type: GET_LISTEN, params }
}
export const getListenFail = (response) => {
    return { type: GET_LISTEN_FAIL, response }
}
export const getListenSuccess = (response) => {
    return { type: GET_LISTEN_SUCCESS, response }
}
export const getListenReset = () => {
    return { type: GET_LISTEN_RESET }
}
