import styled from "styled-components"; 
import { Modal, Radio, Button } from "antd";
import { Typography, Form, DatePicker } from "antd";
export const Xh1 = styled.h1`
    color: #A64FA6;
    font-weight: 650;
    text-align:center;
`
export const XModal = styled(Modal)` 
    .ant-modal-content {
        border-radius: 22px;
    }
`
export const RadioButton = styled(Radio.Button)` 
    width: 100% !important;  
    border-radius: 22px !important;
    padding-left:5%; 
    margin-bottom:25px;
    line-height:14px;
`
export const XText = styled.h6` 
    padding: 10px 0px; 
    margin:0;
    font-size:14px;
    line-height:14px;
    @media only screen and (max-width: 320px) {
        font-size: 10px;
     }
`
export const FormItem = styled.div` 
`
export const XDatePicker = styled(DatePicker)` 
    width: 100%;
    border-radius: 10px;  
`
export const XButton = styled(Button)` 
    
`
export const ImgDiv = styled.div`
    margin-bottom:20px;
`
