import axios from 'axios'
import config from '../config'

const getPatientDetailForIntakeFormApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}book-appointment/get-patient-detail-for-intake-form/${params.id}`,
        method: "get",
        params: params,
        headers: {
            "Content-Type": "application/json",
        }
    });
    return response
}

export default getPatientDetailForIntakeFormApi