import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Input,
  Typography,
  Modal,
  Form,
  Radio,
  Checkbox,
  CheckboxValueType,
  Spin,
} from "antd";

import {
  getDoctorList,
  getDoctorListReset,
} from "../../action/getDoctorListAction";

import {
  getDoctorSearch,
  getDoctorSearchReset,
} from "../../action/getDoctorSearchAction";

import getcommonMaster from "../../api/getcommonMaster";
// import getDoctorSearchApi from "../../api/getDoctorSearchApi";
import { useLocation } from "react-router-dom";

//STYLE
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import "./ExpertDetail.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  ListExpertBox,
  XImg,
  ListBoxPara,
  TopContainer,
} from "./ExpertDetailStyle";
import { Xpagination } from "../../component/Xcomponent";
import moment from "moment";
import config from "../../config";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

export const ExpertDetail = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [concerns, setconcerns] = useState(false);
  const location = useLocation();
  const type = location.state.type;
  //constants
  const {
    getDoctorList,
    getDoctorListReset,
    getDoctorListState,
    getDoctorSearch,
    getDoctorSearchReset,
    getDoctorSearchState,
  } = props;
  const { Title } = Typography;
  const Params = {
    type: type,
    page: "1",
    records: "10",
  };
  const [List, setList] = useState([]);
  const [params, setParams] = useState({
    query: "",
    type: type,
    page: "1",
    records: "10",
    language: [],
    expert_approaches: [],
    concerns: [],
  });
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [Commondata, setCommondata] = useState([]);
  const [Langdata, setLangdata] = useState([]);
  const [expertApproch, setExpertApproch] = useState([]);
  // const [Liststore, setListstore] = useState([]);
  const [IdChekedFromRequest, setIdChekedFromRequest] = useState();
  const [IdChekedFromRequestLang, setIdChekedFromRequestLang] = useState();
  const [IdChekedFromRequestExpApproach, setIdChekedFromRequestExpApproach] =
    useState();
  const page = new URLSearchParams().get("page");
  const [pagination, setPagination] = useState({
    page: page ? page : 1,
    records: 10,
    total_records: 0,
    next_page: null,
    prev_page: null,
  });
  const [scrollTopBtn, setScrollTopBtn] = useState(false);

  // Callbacks
  useEffect(() => {
    if (getDoctorSearchState.apiState == "loading") {
      setLoading(true);
      setList([]);
    } else if (getDoctorSearchState.apiState == "success") {
      let data = getDoctorSearchState.data.list;

      let pagi = getDoctorSearchState.data.pagination;

      setPagination({
        ...pagination,
        page: pagi.current_page,
        total_records: pagi.total_records,
        records: pagi.records,
      });
      setList(data);
      setLoading(false);
    }
  }, [
    getDoctorSearchState.apiState,
    getDoctorSearchState.data.list,
    getDoctorSearchState.data.pagination,
    pagination,
  ]);

  useEffect(() => {
    if (getDoctorListState.apiState === "loading") {
      setListLoading(true);
    } else if (getDoctorListState.apiState === "success") {
      let data = getDoctorListState.data.list;
      setList(data);
      setListLoading(false);
    }
  }, [getDoctorListState.apiState, getDoctorListState.data.list]);

  // Functions
  function getSpeciality(spec) {
    let QuaValues = [];
    for (let i = 0; i < spec.length; i++) {
      QuaValues[i] = spec[i].value;
    }
    // console.log("getSpeciality",QuaValues)
    return QuaValues.join(", ");
  }

  function getExperience(date) {
    const expDate = moment(date);
    const experience = moment().diff(expDate, "years");
    return experience;
  }

  const searchExpert = (e) => {
    let query = e.target.value;

    let parms = params;

    // setParams({
    //   ...parms,
    //   query,
    // });

    if (query.length > 3)
      getDoctorList({
        ...parms,
        query,
      });
    else if (query.length === 0) {
      getDoctorList({ ...parms });
    }
  };
  const filters = (e) => {
    let dvalue = e.target.value;

    if (e.target.value != "") {
      // let data = List.filter((person) => getExperience(person.exprience).includes(dvalue));
      let data = List.filter(
        (person) =>
          person.fname.toLowerCase().includes(dvalue.toLowerCase()) ||
          person.lname.toLowerCase().includes(dvalue.toLowerCase()) ||
          person.speciality.find((e) =>
            e.value.toLowerCase().includes(dvalue.toLowerCase())
          )
      );

      setList(data);
    } else {
      // setList(Liststore);
    }

    console.log("data", List);
  };
  const searchFilter = async () => {
    setIsModalVisible(false);

    let data;

    let filter = {
      ...params,
      language: IdChekedFromRequestLang,
      concerns: IdChekedFromRequest,
      expert_approaches: IdChekedFromRequestExpApproach,
    };

    console.log(filter);

    setParams(filter);
    getDoctorList(filter);
    // Old code for filters
    // let data;
    // //if (IdChekedFromRequestLang && IdChekedFromRequest) {
    // //    data = {
    // //        "type": type,
    // //        "language": [IdChekedFromRequestLang],
    // //        "concerns": [IdChekedFromRequest],

    // //    }
    // //}
    // //if (IdChekedFromRequestLang && !IdChekedFromRequest) {
    // //     data = {
    // //         "type": type,
    // //         "language": IdChekedFromRequestLang,
    // //         "concerns": [],

    // //    }
    // //}
    // //if (IdChekedFromRequest && !IdChekedFromRequestLang) {
    // //    data = {
    // //        "type": type,
    // //        "concerns": [IdChekedFromRequest],
    // //        "language": []

    // //    }
    // //}
    // if (type == "GC") {
    //   data = {
    //     type: type,
    //     language: IdChekedFromRequestLang,
    //     concerns: IdChekedFromRequest,
    //     expert_approaches: IdChekedFromRequestExpApproach,
    //   };
    // } else {
    //   data = {
    //     type: type,
    //     language: IdChekedFromRequestLang,
    //     concerns: IdChekedFromRequest,
    //     expert_approaches: IdChekedFromRequestExpApproach,
    //   };
    // }

    // let datas = null;
    // datas = await getDoctorSearchApi(data);
    // console.log("datas", datas);
    // if (datas) {
    //   let listdata = datas.data.data.list;
    //   setList(listdata);
    //   console.log("search", List);
    // }
  };

  const onChangelng = (checkedValues) => {
    setIdChekedFromRequestLang(checkedValues);
  };
  const onChangecons = (checkedValues) => {
    setIdChekedFromRequest(checkedValues);
  };
  const onChangeExpApproch = (checkedValues) => {
    setIdChekedFromRequestExpApproach(checkedValues);
  };

  function closeModal() {
    setIsModalVisible(false);
  }
  const showModal = () => {
    setIsModalVisible(true);

    setIdChekedFromRequest();
    setIdChekedFromRequestLang();
    if (type === "GC") {
      setconcerns(false);
    }
    if (type === "MHC") {
      setconcerns(true);
    }
  };
  const handleOk = () => {};
  const onFinish = (values) => {};
  const [form] = Form.useForm();

  const Experts = () => {
    return (
      <div className="col-12">
        <div className="row">
          {listLoading ? (
            <div className="text-center loader-container">
              <Spin spinning={listLoading} tip="Loading Experts..." />
            </div>
          ) : List.length === 0 ? (
            <div className="text-center loader-container">
              <p>No Expert found</p>
            </div>
          ) : (
            List.map((doctor) => (
              <div className="col-md-12 col-lg-6 col-sm-12 col-12 col-xl-6">
                <Link
                  to={{
                    pathname: "/doctor-detail",
                    state: { doctor: doctor._id, type: type, isBooking: false },
                  }}
                  className="invisiLink"
                >
                  <ListExpertBox className="expert box">
                    <div className="row align-items-center">
                      <div className="col-5 col-sm-3 col-md-3 col-lg-4">
                        <XImg
                          className="img-fluid"
                          src={
                            doctor.profile_picture
                              ? `${config.api.s3_url}doctor/${doctor.profile_picture}`
                              : doctor.gender.toLowerCase() === "male"
                              ? `/images/avatar_male.svg`
                              : doctor.gender.toLowerCase() === "female"
                              ? `/images/avatar_female.svg`
                              : `/images/avatar_transgender.svg`
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            switch (doctor.gender.toLowerCase()) {
                              case "male":
                                currentTarget.src = "/images/avatar_male.svg";
                                break;
                              case "female":
                                currentTarget.src = "/images/avatar_female.svg";
                                break;
                              default:
                                currentTarget.src =
                                  "/images/avatar_transgender.svg";
                            }
                          }}
                          alt=""
                        />
                      </div>
                      <div className="col-7 col-sm-9 col-md-9 col-lg-8">
                        <h5 className="listBoxHeader">
                          {" "}
                          {doctor.salutation?.value} {doctor.fname}{" "}
                          {doctor.lname}
                        </h5>
                        <ListBoxPara type="secondary">
                          {doctor.pronoun}
                        </ListBoxPara>
                        <ListBoxPara type="secondary">
                          Language:{" "}
                          <strong>
                            {getSpeciality(doctor.language) !== ""
                              ? getSpeciality(doctor.language)
                              : "-"}
                          </strong>
                        </ListBoxPara>
                        {/* {doctor.exprience && ( */}

                        <ListBoxPara type="secondary">
                          Experience:{" "}
                          <strong>
                            {" "}
                            {doctor.exprience
                              ? getExperience(doctor.exprience)
                              : 0}
                          </strong>{" "}
                          yrs
                        </ListBoxPara>
                        {/* )} */}
                        {/* {doctor.qualification && ( */}
                        <ListBoxPara type="secondary">
                          Qualification:{" "}
                          <strong>
                            {doctor.qualification
                              ? doctor.qualification.length <= 20
                                ? doctor.qualification
                                : doctor.qualification.substring(0, 20) + "..."
                              : "-"}
                          </strong>
                        </ListBoxPara>
                        {/* )} */}
                      </div>
                    </div>
                  </ListExpertBox>
                </Link>
              </div>
            ))
          )}
        </div>
        <Xpagination
          pageSizeOptions={["10", "25", "50", "100", "500"]}
          showSizeChanger
          // total={
          //   getDoctorListState?.data?.data?.data?.pagination?.total_records
          //     ? getDoctorListState?.data?.data?.data?.pagination?.total_pages *
          //       10
          //     : 20
          // }
          total={pagination.total_records}
          onChange={(page, pageSize) => {
            setParams({ ...params, page, records: pageSize });
            getDoctorList({ ...params, page, records: pageSize });
          }}
          current={pagination.page}
          defaultPageSize={pagination.records}
          defaultCurrent={1}
        />
      </div>
    );
  };

  //Callbacks
  useEffect(() => {
    window.scrollTo(0, 0);
    getDoctorList(params);
    return () => {
      getDoctorListReset();
    };
  }, []);

  useEffect(async () => {
    let datas = [];
    let datasl = [];
    datas.push(await getcommonMaster());
    console.log("datas", datas);
    // datasl.push(await getcommonMaster());
    if (datas.length > 0) {
      //setCommondata(datas[0].data.data.result.filter((person) => person.key.toLowerCase().includes('concerns')))
      let language_data = datas[0].data.data.result.filter((person) =>
        person.key.toLowerCase().includes("language")
      );
      // setCommondata(datas[0].data.data.result.filter((person) => person.key.toLowerCase().includes('concerns')))
      let concern_data = datas[0].data.data.result.filter((person) =>
        person.key.toLowerCase().includes("concerns")
      );
      let expertApp = datas[0].data.data.result.filter((person) =>
        person.key.toLowerCase().includes("expert approaches")
      );
      if (concern_data.length > 0) {
        setCommondata(concern_data[0].common_master_detail);
      }
      if (language_data.length > 0) {
        setLangdata(language_data[0].common_master_detail);
      }
      if (expertApp.length > 0) {
        setExpertApproch(expertApp[0].common_master_detail);
      }
      console.log("concern", concern_data);
      console.log("language_data", language_data);
      console.log("expertApp", expertApp);
    }
  }, []);

  useEffect(() => {
    if (getDoctorListState.apiState === "success") {
      let data = getDoctorListState.data.list;

      let pagi = getDoctorListState.data.pagination;

      // setListstore(getDoctorListState.data.data.data.list);

      setPagination({
        ...pagination,
        page: pagi.current_page,
        total_records: pagi.total_records,
        records: pagi.records,
      });
      setList(data);
    }
  }, [getDoctorListState.apiState]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  return (
    <div className="mb-5 " style={{ position: "relative" }}>
      <div className="expertDetail">
        <TopContainer>
          <img
            className="align-center mobile-height"
            src="./images/lilac-insights-logo.svg"
            alt="exp"
          />
          {/* <Title level={1}>Expert</Title> */}
        </TopContainer>
        <img className="drListLeftBg" src="./images/svg/doctorbgLeft.svg" />
        <img
          alt="right-bg"
          className="drListRightBg"
          src="./images/svg/doctorbgRight.svg"
        />
        <div className="container">
          <div className="expertWrap">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h1 className="expertHeading">Experts List</h1>
              </div>
              <div className="col-12 col-sm-7">
                <div className="expertAlign">
                  <Input
                    className="consultSearch inputBox"
                    onChange={searchExpert}
                    prefix={<SearchOutlined style={{ color: "gray" }} />}
                    placeholder="Search"
                  />
                  <img
                    className="img-fluid expertFilter"
                    onClick={loading ? null : showModal}
                    src="/images/svg/Filter.svg"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {loading ? (
                <Spin spinning={loading} tip="Loading Experts..." />
              ) : (
                <Experts />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center mb-5">
              <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={closeModal}
                footer={null}
              >
                <div className="row">
                  <div className="col-12" id="checkboxMargin">
                    <Title level={2}>Filters</Title>

                    <div className="mb-4">
                      <h5 className="mb-2">Select Language</h5>
                      <Checkbox.Group
                        style={{
                          width: "100%",
                          marginLeft: 15,
                        }}
                        onChange={onChangelng}
                      >
                        {Langdata.map((doctor) => {
                          return (
                            <Checkbox
                              className="language-checkbox"
                              style={{ border: "none", padding: 5 }}
                              value={doctor._id}
                            >
                              {doctor.value}
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    </div>
                    {/* <div className="mb-4">
                      {concerns ? (
                        <div>
                          <h5 className="mb-3">Select Concerns</h5>
                          <Checkbox.Group
                            style={{
                              width: "100%",
                            }}
                            onChange={onChangecons}
                          >
                            {Commondata.map((doctor) => {
                              return (
                                <Checkbox
                                  style={{ border: "none", padding: 5 }}
                                  className="concern-checkbox"
                                  value={doctor._id}
                                >
                                  {doctor.value}
                                </Checkbox>
                              );
                            })}
                          </Checkbox.Group>
                        </div>
                      ) : null}
                    </div> */}
                    {/* <div className="mb-4">
                      {expertApproch ? (
                        <div>
                          <h5 className="mb-3">Select Expert Approaches</h5>
                          <Checkbox.Group
                            style={{
                              width: "100%",
                            }}
                            onChange={onChangeExpApproch}
                          >
                            {expertApproch.map((doctor) => {
                              return (
                                <Checkbox
                                  className="concern-checkbox"
                                  style={{
                                    border: "none",
                                    marginLeft: 0,
                                    marginBottom: 15,
                                  }}
                                  value={doctor._id}
                                >
                                  {doctor.value}
                                </Checkbox>
                              );
                            })}
                          </Checkbox.Group>
                        </div>
                      ) : null}
                    </div> */}

                    <div className="col-12 text-center mt-3">
                      <button
                        size="large"
                        shape="round"
                        type="primary"
                        onClick={searchFilter}
                        htmlType="submit"
                        className="themeBtn mt-10"
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getDoctorListState: state.getDoctorList,
  getDoctorSearchState: state.getDoctorSearch,
});

const mapDispatchToProps = (dispatch) => ({
  getDoctorList: (params) => dispatch(getDoctorList(params)),
  getDoctorListReset: () => dispatch(getDoctorListReset()),
  getDoctorSearch: (params) => dispatch(getDoctorSearch(params)),
  getDoctorSearchReset: () => dispatch(getDoctorSearchReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpertDetail);
