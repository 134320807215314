import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    BOOKED_APPOINTMENT_ACTIVITY,
    bookedAppointmentSuccess,
    bookedAppointmentFail
} from '../action/bookingAppointmentActivity'

// api 
import bookedAppointmentActivityApi from '../api/bookingAppointmentActivityApi'

export function* bookedAppointmentActivitySaga(action) {
    try {
        const response = yield call(() => bookedAppointmentActivityApi(action.params))
        if (response.data.status === 1) {
            yield put(bookedAppointmentSuccess(response, action))
        } else {
            yield put(bookedAppointmentFail(response, action))
        }
    }
    catch (e) {
        yield put(bookedAppointmentFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(BOOKED_APPOINTMENT_ACTIVITY, bookedAppointmentActivitySaga)
}