import styled from "styled-components";
import { Button } from "antd";

export const XButton = styled(Button)
` 
width: 340px;
background-color: #C473C4;
border: none;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
@media (min-width: 300px) and  (max-width: 580px){
    width: 240px;
    height: 40px !important;
    margin-left: 5%;
}
@media (min-width: 550px) and  (max-width: 800px) {
    width: 210px;
}
`

export const Xfooter = styled.div `
    position : absolute;
    width: 100%;  
    margin-left: -7.4%;
    @media (min-width: 300px) and  (max-width: 580px){
        margin-left: -2.9%;
    }
`

export const DrDetailsRightHead = styled.h4 `
    margin:0;
    font-family: Poppins;
    color: #1F274A;
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    margin-left: 10px;
    @media (min-width: 700px) and  (max-width: 800px){
        font-size: 12px;
    }
`

export const TopContainer = styled.div `
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto;
        width: 35%;
        margin-top: 15px;
    } 
`

export const PageWrap = styled.div `
    width: 100%;
    position: relative;
    // padding:90px 0px;
    padding:72px 0px;
    @media (min-width: 280px) and  (max-width: 580px){
        padding:0px 0px;
        margin-bottom: 25px;
    }
    @media (min-width: 581px) and (max-width: 768px) {
        padding-top: 15px;
      }
`