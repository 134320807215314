import styled from "styled-components";
import { Button } from "antd";

export const XButton = styled(Button)`
  width: 340px;
  background-color: #c473c4;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 300px) and (max-width: 580px) {
    width: 240px;
    height: 40px !important;
    margin-left: 5%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 210px;
  }
`;

export const Xfooter = styled.div`
  position: absolute;
  width: 100%;
  margin-left: -7.4%;
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: -2.9%;
  }
`;

export const DrDetailsRightHead = styled.h4`
  margin: 0;
  font-family: Poppins;
  color: #1f274a;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  margin-left: 0px;
  @media (min-width: 700px) and (max-width: 800px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    line-height: 18px;
    margin-top: 4px;
  }
`;

export const TopContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: auto;
    width: 35%;
    margin-top: 15px;
  }
`;

export const PageWrap = styled.div`
  width: 100%;
  position: relative;
  padding-top: 90px;
  @media (max-width: 768px) {
    padding-top: 15px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    padding-top: 15px;
    margin-bottom: 25px;
  }
`;
