import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { login } from '../../action/loginAction'
import moment from "moment";
import config from "../../config";
import { Collapse } from "antd";
import { getQuickSupport } from "../../action/getQuickSupportAction";
const { Panel } = Collapse;
//component

const QuickSupport = (props) => {
  const { getQuickSupport, getQuickSupportState } = props;
  const [quickSupportData, setQuickSupportData] = useState([]);
  const [dataFound, setDataFound] = useState();
  const onChange = (key) => {
    console.log(key);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(`${config.api.base_url}quick-support/question-answer/Patient`, {
      headers: {
        "Content-Type": "application/json",
      },
    }).then((result) => {
      result.json().then((resp) => {
        if (resp && resp.data) {
          setQuickSupportData(resp.data.result);
        }
      });
    });
  }, []);
  useEffect(() => {
    if (getQuickSupportState.apiState === "success") {
      console.log("getQuickSupportState", getQuickSupportState);
      setQuickSupportData(getQuickSupportState.result);
    }
  }, [getQuickSupportState]);
  const QuickSupportParent = () => {
    return (
      <>
        <Collapse onChange={onChange}>
          {quickSupportData.map((category, id1) => (
            <Panel header={category.name}>{QuickSupportChild(category)}</Panel>
          ))}
        </Collapse>
      </>
    );
  };

  const QuickSupportChild = (category) => {
    return (
      <>
        {category.children.length > 0 &&
          category.children.map((child_category, id2) => (
            <>
              <Collapse>
                <Panel header={child_category.name}>
                  {QuickSupportChild(child_category)}
                </Panel>
              </Collapse>
            </>
          ))}
        <br></br>
        {category.quick_support_question_answer.map((questionAnswers) => (
          <>
            <div>
              <h6>{questionAnswers.question}</h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(questionAnswers.answer),
                }}
              />
            </div>
          </>
        ))}
      </>
    );
  };
  return (
    <>
      <img
        alt="right-bg"
        className="profileRightBg1"
        src="./images/svg/doctorbgRight.svg"
      />
      <img
        alt="right-bg"
        className="profileLeftBg1"
        src="./images/svg/doctorbgLeft.svg" 
      />
      <div className="pageWrap4">
        <div className="container">
          {quickSupportData && (
            <div
              style={{ width: "100%", justifyContent: "flex-start",}}
              id="quickAccroding"
            >
              <h2 className="heading-home mb-4">Quick Support</h2>
              <QuickSupportParent></QuickSupportParent>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getQuickSupportState: state.getQuickSupport,
});

const mapDispatchToProps = (dispatch) => ({
  getQuickSupport: (params) => dispatch(getQuickSupport(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickSupport);
