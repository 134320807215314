export const GET_QUICK_SUPPORT = "GET_QUICK_SUPPORT"
export const GET_QUICK_SUPPORT_FAIL = "GET_QUICK_SUPPORT_FAIL"
export const GET_QUICK_SUPPORT_SUCCESS = "GET_QUICK_SUPPORT_SUCCESS"
export const GET_QUICK_SUPPORT_RESET = "GET_QUICK_SUPPORT_RESET"

export const getQuickSupport = (params) => {
    return { type: GET_QUICK_SUPPORT, params }
}

export const getQuickSupportSuccess = (response) => {
    return { type: GET_QUICK_SUPPORT_SUCCESS, response }
}

export const getQuickSupportFail = (response) => {
    return { type: GET_QUICK_SUPPORT_FAIL, response }
}

export const getQuickSupportResetState = () => {
    return { type: GET_QUICK_SUPPORT_RESET }
}