export const GET_SCHEDULE_TIMING_LIST = "GET_SCHEDULE_TIMING_LIST";
export const GET_SCHEDULE_TIMING_LIST_FAIL = "GET_SCHEDULE_TIMING_LIST_FAIL";
export const GET_SCHEDULE_TIMING_LIST_SUCCESS = "GET_SCHEDULE_TIMING_LIST_SUCCESS";
export const GET_SCHEDULE_TIMING_LIST_RESET = "GET_SCHEDULE_TIMING_LIST_RESET";

//getAppointmentList
export const getScheduleTimingList = (params) => {
  return { type: GET_SCHEDULE_TIMING_LIST, params };
};
export const getScheduleTimingListFail = (response) => {
  return { type: GET_SCHEDULE_TIMING_LIST_FAIL, response };
};
export const getScheduleTimingListSuccess = (response) => {
  return { type: GET_SCHEDULE_TIMING_LIST_SUCCESS, response };
};
export const getScheduleTimingListReset = () => {
  return { type: GET_SCHEDULE_TIMING_LIST_RESET };
};
