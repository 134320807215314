import axios from "axios";
import config from "../config"
import { getPatientToken } from '../utils'

const getPregHighlightAppListApi = async(params) => {
    const response = await axios({
        url: `${config.api.base_url}pregnancy-highlights/app-list?week=${params.week}`,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        },
    });
    // console.log("prag" + JSON.stringify(response.data) + "week" + params.week);
    return response;

};

export default getPregHighlightAppListApi;