import axios from "axios";
import config from "../config";
import { getPatientToken } from "../utils";

const getServicesList = async (params) => {
  const response = await axios({
    url: `${config.api.base_url}article/services_list`,
    method: "get",
    params: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getPatientToken()}`,
    },
  });
  console.log("response=>", response);
  return response;
};

export default getServicesList;
