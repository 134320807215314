import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Radio, notification } from "antd";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";
import { useLocation } from 'react-router-dom';
//STYLE
import {
  Xh1,
  XModal,
  Xdiv,
  XText,
  RadioButton,
  XButton,
  ImgDiv,
} from "./TribeModalStyle";

export const TribeModal = (props) => {

  const location = useLocation();
  const { pathname } = location;
  //STATES
  const [value, setValue] = useState([]);
  const [TribeData, setTribeData] = useState({
    depression: { BgColor: "#FFFFFF", click: false },
    anxiety: { BgColor: "#FFFFFF", click: false },
    grief: { BgColor: "#FFFFFF", click: false },
    infirtility: { BgColor: "#FFFFFF", click: false },
    perinatal: { BgColor: "#FFFFFF", click: false },
    postpartum: { BgColor: "#FFFFFF", click: false },
    queer: { BgColor: "#FFFFFF", click: false },
  });
  let obj = {};

  function onTribeChange(e) {
    var tribe = e.target.value;
    setValue([...value, tribe]);
    console.log(tribe);
    if (tribe === "Depression Support") {
      obj = { ...Click("depression"), ...obj };
    } else if (tribe === "Anxiety Support") {
      obj = { ...Click("anxiety"), ...obj };
    } else if (tribe === "Infertility Support") {
      obj = { ...Click("infirtility"), ...obj };
    } else if (tribe === "Grief Support") {
      obj = { ...Click("grief"), ...obj };
    } else if (tribe === "Perinatal Loss Support") {
      obj = { ...Click("perinatal"), ...obj };
    } else if (tribe === "Postpartum Support") {
      obj = { ...Click("postpartum"), ...obj };
    } else if (tribe === "Queer Support") {
      obj = { ...Click("queer"), ...obj };
    }
    setTribeData({ ...TribeData, ...obj });
  }

  const handleCancle = () => {
    props.onClose();
  };

  const handleOk = (e) => {
    props.onClose();
    let tribe = [];
    let fullName = JSON.parse(localStorage.getItem("name"));
    let name = `${fullName.firstName} ${fullName.lastName}`
    if (TribeData.anxiety.click === true) {
      tribe.push("Anxiety Support");
    }
    if (TribeData.depression.click === true) {
      tribe.push("Depression Support");
    }
    if (TribeData.infirtility.click === true) {
      tribe.push("Infertility Support");
    }
    if (TribeData.grief.click === true) {
      tribe.push("Grief Support");
    }
    if (TribeData.perinatal.click === true) {
      tribe.push("Perinatal Loss Support");
    }
    if (TribeData.postpartum.click === true) {
      tribe.push("Postpartum Support");
    }
    if (TribeData.queer.click === true) {
      tribe.push("Queer Support");
    }
    const saveTribeModalParams = {
      name: name,
      tribe: tribe.join(","),
    };
    console.log(saveTribeModalParams);
    axios({
      url: `${config.api.base_url}patient/profile/save`,
      method: "post",
      data: saveTribeModalParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getPatientToken()}`,
      },
    })
      .then((respons) => {
        console.log(respons.data);
        if (respons.data.message == "Profile updated successfully.") {
          notification["success"]({
            message: respons.data.message,
            placement: "bottomRight",
          });

          localStorage.setItem(
            "tribe",
            JSON.stringify(respons.data.data.tribe)
          );
          setTriData(respons.data.data.tribe);

          if(tribe.length==0 && pathname=='/support-tribe')
          {
            window.location.replace('/');
          }
          else
          {
            window.location.replace("/support-tribe");
          }
          
        }
      })
      .catch((error) => {
        console.log(error);
        notification["error"]({
          message: error.message,
          placement: "bottomRight",
        });
      });
  };

  function setTriData(array) {
    let obj = {};
    let element;
    for (let i = 0; i < array.length; i++) {
      element = array[i];
      if (element === "Depression Support") {
        obj = { ...Click("depression"), ...obj };
      } else if (element === "Anxiety Support") {
        obj = { ...Click("anxiety"), ...obj };
      } else if (element === "Infertility Support") {
        obj = { ...Click("infirtility"), ...obj };
      } else if (element === "Grief Support") {
        obj = { ...Click("grief"), ...obj };
      } else if (element === "Perinatal Loss Support") {
        obj = { ...Click("perinatal"), ...obj };
      } else if (element === "Postpartum Support") {
        obj = { ...Click("postpartum"), ...obj };
      } else if (element === "Queer Support") {
        obj = { ...Click("queer"), ...obj };
      }
      console.log(element);
    }
    setTribeData({ ...TribeData, ...obj });
    console.log(TribeData, obj);
  }

  function Click(tribe) {
    let clicked;
    let BtnObj = { ...TribeData };
    if (tribe === "depression") {
      clicked = BtnObj.depression.click;
      if (!clicked) {
        return {
          depression: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          depression: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "anxiety") {
      clicked = BtnObj.anxiety.click;
      if (!clicked) {
        return {
          anxiety: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          anxiety: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "grief") {
      clicked = BtnObj.grief.click;
      if (!clicked) {
        return {
          grief: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          grief: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "infirtility") {
      clicked = BtnObj.infirtility.click;
      if (!clicked) {
        return {
          infirtility: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          infirtility: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "perinatal") {
      clicked = BtnObj.perinatal.click;
      if (!clicked) {
        return {
          perinatal: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          perinatal: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "postpartum") {
      clicked = BtnObj.postpartum.click;
      if (!clicked) {
        return {
          postpartum: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          postpartum: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    } else if (tribe === "queer") {
      clicked = BtnObj.queer.click;
      if (!clicked) {
        return {
          queer: {
            BgColor:
              "linear-gradient(164.69deg, #C473C4 11.24%, #A952A9 89.74%)",
            click: true,
          },
        };
      } else {
        return {
          queer: {
            BgColor: "#FFFFFF",
            click: false,
          },
        };
      }
    }
  }

  //CALLBACKS
  useEffect(() => {
    window.scrollTo(0, 0);
    let tribe = JSON.parse(localStorage.getItem("tribe"));
    setTriData(tribe);
  }, []);

  return (
    <XModal
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancle}
      footer={null}
      style={{ borderRadius: "22px!important" }}
    >
      <div>
        <Xh1>Choose Your Tribe</Xh1>
        <br />
        <div className="row">
          <div className="offset-1 col-2 text-center">
            <ImgDiv>
              {TribeData.depression.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/DCat.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/DcatW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.anxiety.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/anxiety.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/anxietyW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.grief.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/grief.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/griefW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.infirtility.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/infer.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/inferW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.perinatal.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/perin.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/perinW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.postpartum.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/post.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/postW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
            <ImgDiv>
              {TribeData.queer.click ? (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/queer.svg"}
                  alt=""
                />
              ) : (
                <img
                  className="tribeModalIcon"
                  src={process.env.PUBLIC_URL + "/images/queerW.svg"}
                  alt=""
                />
              )}
            </ImgDiv>
          </div>
          <div className="col-8 homeModalBorder">
            <Radio.Group
              size="large"
              buttonStyle="solid"
              defaultValue={value}
              onChange={onTribeChange}
            >
              <div>
                <RadioButton
                  value="Depression Support"
                  onClick={onTribeChange}
                  style={{ background: `${TribeData.depression.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.depression.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.depression.click ? (
                      <XText style={{ color: "white" }}>
                        Depression Support
                      </XText>
                    ) : (
                      <XText style={{ color: "black" }}>
                        Depression Support
                      </XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  value="Anxiety Support"
                  onClick={onTribeChange}
                  style={{ background: `${TribeData.anxiety.BgColor}` }}
                >
                  <Xdiv>
                    {TribeData.anxiety.click ? (
                      <XText style={{ color: "white" }}>Anxiety Support</XText>
                    ) : (
                      <XText style={{ color: "black" }}>Anxiety Support</XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  value="Grief Support"
                  onClick={onTribeChange}
                  style={{ background: `${TribeData.grief.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.grief.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.grief.click ? (
                      <XText style={{ color: "white" }}>Grief Support</XText>
                    ) : (
                      <XText style={{ color: "black" }}>Grief Support</XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  value="Infertility Support"
                  onClick={onTribeChange}
                  style={{ background: `${TribeData.infirtility.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.infirtility.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.infirtility.click ? (
                      <XText style={{ color: "white" }}>
                        Infertility Support
                      </XText>
                    ) : (
                      <XText style={{ color: "black" }}>
                        Infertility Support
                      </XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  onClick={onTribeChange}
                  value="Perinatal Loss Support"
                  style={{ background: `${TribeData.perinatal.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.perinatal.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.perinatal.click ? (
                      <XText style={{ color: "white" }}>
                        Perinatal Loss Support
                      </XText>
                    ) : (
                      <XText style={{ color: "black" }}>
                        Perinatal Loss Support
                      </XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  value="Postpartum Support"
                  onClick={onTribeChange}
                  style={{ background: `${TribeData.postpartum.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.postpartum.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.postpartum.click ? (
                      <XText style={{ color: "white" }}>
                        Postpartum Support
                      </XText>
                    ) : (
                      <XText style={{ color: "black" }}>
                        Postpartum Support
                      </XText>
                    )}
                  </Xdiv>
                </RadioButton>
                <RadioButton
                  onClick={onTribeChange}
                  value="Queer Support"
                  style={{ background: `${TribeData.queer.BgColor}` }}
                >
                  <Xdiv>
                    {/* <div>{TribeData.queer.click ? <img src={process.env.PUBLIC_URL + "/images/Tickedcircle.svg"} style={{padding:'10px'}} alt="" /> : <img src={process.env.PUBLIC_URL + "/images/circle.svg"} style={{padding:'10px'}} alt="" />}</div> */}
                    {TribeData.queer.click ? (
                      <XText style={{ color: "white" }}>Queer Support</XText>
                    ) : (
                      <XText style={{ color: "black" }}>Queer Support</XText>
                    )}
                  </Xdiv>
                </RadioButton>
              </div>
            </Radio.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <button
              className="themeBtn"
              shape="round"
              type="primary"
              htmlType="submit"
              onClick={handleOk}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </XModal>
  );
};

export default TribeModal;
