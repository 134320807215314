import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import GenderDob from '../GenderDob/GenderDob'
import Register from '../Register/Register'
import Interest from '../Interest/Interest';
import { Redirect, useLocation } from "react-router-dom"
import { saveRegisterData } from '../../action/saveRegisterDataAction'
import { message, Modal, Steps, Button, Spin, Switch } from 'antd';
import moment from "moment";
import { SuccessModal } from './RegistrationStyle';
import { register } from '../../action/registerAction'
import FormItemLabel from 'antd/lib/form/FormItemLabel';

export const Registration = (props) => {
    const [patientData, setPatientData] = useState({
        fname: "",
        lname: "",
        phone: "",
        email: "",
        gender: "",
        dob: "",
        interest: "",
        lmp: "",
        lmp_as_of: "",
        conceive_status: "",
      });
    //CONSTANTS
    const {
        register, registerState, saveRegisterData, saveRegisterDataState,
    } = props
    const location = useLocation();
    let week = 0;

    //STATES
    const [SignedIn, setSignedIn] = useState(false)
    const [loading, setLoading] = useState(false);
    // const [Success, setSuccess] = useState(1)
    const [redirect, getRedirect] = useState({
        register: true,
        gender: false,
        interest: false
    })
    //console.log('this is registration') 

    useEffect(() => {
        //console.log([location.pathname]);
    }, [location]);

    const setSuccess= (Success,data) => {
        props.history.push()
        console.log("Before accessing patientData in setSuccess:", data); // Debug line

        if (Success === 2) {

            console.log("Patient Data 2 => ",patientData);
            // props.history.push("/registeration/genderDob")
            next();
        }
        if (Success === 3) {
            console.log("Patient Data 3 => ",patientData);
            // props.history.push("/registeration/interest")
            next();
        }
        if (Success === 4) {
            console.log("Patient Data 4 => ",data);

            if (patientData.socialSignin) {
                // const data = {
                //     ...patientData,
                // }
                console.log("Social SignIn data =>", data);
                setLoading(true);
                register(data);

            } else {
                const patient = {
                    "name": data.fname + " " + data.lname,
                    "phone": data.phone ? data.phone : null,
                    "email": data.email ? data.email : null,
                    "password": data.password ? data.password : null,
                    "gender": data.gender ? data.gender : null,
                    "dob": data.dob ? data.dob : null,
                    "interest": data.interest ? data.interest : null,
                    "conceive_status": data.conceive_status ? data.conceive_status : null,
                    "lmp": data.lmp ? data.lmp : null,
                    "lmp_as_of": data.lmp_as_of ? data.lmp_as_of : null,
                    "patient_id": data.patient_id ? data.patient_id : null
                }
                console.log("Regular data =>", patient)
                setLoading(true);
                register(patient);
                // setTimeout(()=>{setLoading(false);},5000)
            }
        }
    }

    // useEffect(() => {
    //     if (location.pathname === "/registeration/register") {
    //         getRedirect({
    //             ['register']: true,
    //             ['gender']: false,
    //             ['interest']: false,
    //         })
    //     }
    //     else if (location.pathname === "/registeration/genderDob") {
    //         getRedirect({
    //             ['register']: false,
    //             ['gender']: true,
    //             ['interest']: false,
    //         })
    //     }
    //     else if (location.pathname === "/registeration/interest") {
    //         getRedirect({
    //             ['register']: false,
    //             ['gender']: false,
    //             ['interest']: true,
    //         })
    //     }
    // }, [location.pathname])

    // console.log("Success", Success)
    // console.log("SignedIn",SignedIn) 
    //CALLBACKS
    useEffect(() => {
        // console.log("registerState",registerState) 
 
        if (registerState.apiState === "success" && registerState.data != null) {
            Modal.success({
                title: '',

                content: (
                    <SuccessModal>
                        <h6 style={{ paddingLeft: "30%" }}>
                            
                        </h6 >
                        <p>Your account has been successfully created</p>
                    </SuccessModal>
                ),
            
                onOk() { 

            console.log("After registring setting localstorage", registerState.data);
            registerState.apiState = ""
            localStorage.setItem("LilacPatientToken", registerState.data.token)
            console.log("registerState.data.token", registerState.data.token)
            localStorage.setItem("LilacPatient", JSON.stringify(registerState.data.patient.patient_id))
            let lmp_d = moment(registerState.data.patient.lmp);
            let lmp_date = lmp_d.format("YYYY-MM-DD")
            localStorage.setItem("lmp", JSON.stringify(lmp_date))
            if (lmp_date != null) {
                var today = moment()
                var lmp_date1 = moment(lmp_date)
                week = today.diff(lmp_date1, "week")+1
            }
            localStorage.setItem("lmp_as_of", JSON.stringify(registerState.data.patient.lmp_as_of))
            localStorage.setItem("week", JSON.stringify(week))
            localStorage.setItem("name", JSON.stringify(registerState.data.patient.name))
            localStorage.setItem("email", JSON.stringify(registerState.data.patient.email))
            localStorage.setItem("dob", JSON.stringify(registerState.data.patient.dob))
            localStorage.setItem("phone", JSON.stringify(registerState.data.patient.phone))
            localStorage.setItem("gender", JSON.stringify(registerState.data.patient.gender))
            localStorage.setItem("tribe", JSON.stringify(registerState.data.patient.tribe))
            localStorage.setItem("interest", JSON.stringify(registerState.data.patient.interest))
            localStorage.setItem("conceive_status", JSON.stringify(registerState.data.patient.conceive_status))

            setLoading(false);
            setSignedIn(true)
                    console.log("SignedIn", SignedIn)
                },
            });
        }
        else {
            if(registerState.apiState !== "loading") setLoading(false);
            registerState.apiState = ""
        }
    }, [registerState])
    
    const next = () => {
        console.log(current + 1);
      setCurrent(current + 1);
    };
  
    const prev = () => {
        console.log(current - 1);
      setCurrent(current - 1);
    };

    const toggle = (loading) => {
        setLoading(loading);
    };

    const steps = [
        {
          title: 'First',
          content: <Register spinnerToggle={toggle} setSuccess={setSuccess} next={next} setPatientData={setPatientData} patientData={patientData}/>,
        },
        {
          title: 'Second',
          content:  <GenderDob spinnerToggle={toggle} setSuccess={setSuccess} next={next} previous={prev} setPatientData={setPatientData} patientData={patientData}/>,
        },
        {
          title: 'Last',
          content: <Interest spinnerToggle={toggle} setSuccess={setSuccess} next={next} previous={prev} setPatientData={setPatientData} patientData={patientData}/>,
        },
    ];
    const [current, setCurrent] = useState(0);


    const items = steps.map((item) => ({ key: item.title, title: item.title }));
    
    return (
        <>
            {SignedIn && <Redirect to='/' />}

            <Spin spinning={loading} size="large">
                
                <Steps current={current} items={items} />
                <div className="steps-content">{steps[current].content}</div>
            </Spin>
        </>
    )
}

const mapStateToProps = (state) => ({
    registerState: state.register,
    saveRegisterDataState: state.saveRegisterData
})

const mapDispatchToProps = (dispatch) => ({
    register: (params) => dispatch(register(params)),
    saveRegisterData: (params) => dispatch(saveRegisterData(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
