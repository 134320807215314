export const SAVE_PATIENT_INTAKE_FORM = "SAVE_PATIENT_INTAKE_FORM"
export const SAVE_PATIENT_INTAKE_FORM_FAIL = "SAVE_PATIENT_INTAKE_FORM_FAIL"
export const SAVE_PATIENT_INTAKE_FORM_SUCCESS = "SAVE_PATIENT_INTAKE_FORM_SUCCESS"
export const SAVE_PATIENT_INTAKE_FORM_RESET = "SAVE_PATIENT_INTAKE_FORM_RESET"

export const savePatientIntakeForm = (params) => {
    return { type: SAVE_PATIENT_INTAKE_FORM, params }
}
export const savePatientIntakeFormFail = (response) => {
    return { type: SAVE_PATIENT_INTAKE_FORM_FAIL, response }
}
export const savePatientIntakeFormSuccess = (response) => {
    return { type: SAVE_PATIENT_INTAKE_FORM_SUCCESS, response }
}
export const savePatientIntakeFormReset = () => {
    return { type: SAVE_PATIENT_INTAKE_FORM_RESET }
}