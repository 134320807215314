import styled from "styled-components";
import { Button, Radio } from "antd";

export const XButton = styled(Button)``;

export const DocDetailsHeading2 = styled.h1`
  margin: 0;
  font-size: 34px;
  line-height: 32px;
  font-family: Poppins;
  color: #1f274a;
  font-weight: 500;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 24px;
  }
`;
export const SlotRadioButton = styled(Radio.Button)`
  width: auto;
  height: max-content;
  border-radius: 15px;
  box-shadow: 1px 6px 22px #ede8ed;
  border: none;
  line-height: 20px;
  :last-child {
    width: auto;
    height: max-content;
    border-radius: 15px;
    box-shadow: 1px 6px 22px #ede8ed;
    border: none;
  }
  :first-child {
    width: auto;
    height: max-content;
    border-radius: 15px;
    box-shadow: 1px 6px 22px #ede8ed;
    border: none;
  }
  @media (max-width: 768px) {
    padding: 0px !important;
    margin-right: 5px !important;
    width: 94px !important;
    height: max-content;
    :last-child {
      padding: 0px !important;
      width: 94px !important;
      height: max-content;
    }
    :first-child {
      padding: 0px !important;
      width: 94px !important;
      height: max-content;
    }
  }
`;

export const TimeRadioButton = styled(Radio.Button)`
  width: auto;
  height: max-content;
  border-radius: 40px;
  box-shadow: 1px 6px 22px #ede8ed;
  border: none;
  :last-child {
    width: auto;
    height: max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #ede8ed;
    border: none;
  }
  :first-child {
    width: auto;
    height: max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #ede8ed;
    border: none;
  }
  :first-child:last-child {
    width: auto;
    height: max-content;
    border-radius: 40px;
    box-shadow: 1px 6px 22px #ede8ed;
    border: none;
  }
  @media (max-width: 768px) {
    padding: 0px !important;
    margin-left: 7px !important;
    width: 104px !important;

    height: 42px;
    :last-child {
      padding: 0px !important;
      width: 104px !important;
      height: 42px;
    }
    :first-child {
      padding: 0px !important;
      width: 104px !important;
      height: 42px;
    }
  }
`;
export const TopContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: auto;
    width: 35%;
    margin-top: 15px;
  }
`;

export const PageWrap = styled.div`
  width: 100%;
  position: relative;
  padding: 90px 0px;
  @media (min-width: 300px) and (max-width: 580px) {
    padding: 0px 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    /* padding: 150px 0px; */
    padding: 0px 0px;
  }
`;
