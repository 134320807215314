export const SAVE_ARTICLE_BOOKMARK = "SAVE_ARTICLE_BOOKMARK"
export const SAVE_ARTICLE_BOOKMARK_FAIL = "SAVE_ARTICLE_BOOKMARK_FAIL"
export const SAVE_ARTICLE_BOOKMARK_SUCCESS = "SAVE_ARTICLE_BOOKMARK_SUCCESS" 
export const SAVE_ARTICLE_BOOKMARK_RESET = "SAVE_ARTICLE_BOOKMARK_RESET"

export const saveArticleBookmark = (params) => {
    return { type: SAVE_ARTICLE_BOOKMARK, params }
}
export const saveArticleBookmarkFail = (response) => {
    return { type: SAVE_ARTICLE_BOOKMARK, response }
}
export const saveArticleBookmarkSuccess = (response) => {
    return { type: SAVE_ARTICLE_BOOKMARK_SUCCESS, response }
}
export const saveArticleBookmarkReset = (response) => {
    return { type: SAVE_ARTICLE_BOOKMARK_RESET, response }
} 