import styled, { css } from "styled-components";
import { Button, Card } from "antd";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export const XSlider = styled(Slider)`
  .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    top: -18%;
    color: #fff;
  }
  .next {
    right: 40%;
  }
  .prev {
    left: 40%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .next {
      right: 35%;
    }
    .prev {
      left: 35%;
    }
  }
  @media (max-width: 768px) {
    .next {
      right: 30%;
    }
    .prev {
      left: 30%;
    }
  }
  @media (max-width: 500px) {
    .next {
      right: 10%;
    }
    .prev {
      left: 10%;
    }
  }
`;

export const HeaderAlign = styled.div`
  margin: auto;
  .hero-left {
    width: 40%;
  }
  height: 70%;
`;
export const TopContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: auto;
    background: #ffffff;
    width: 35%;
    margin-top: -8%;
  }
`;

export const UserInfo = styled.div`
  font-size: 34px;
  margin: 5rem auto 3.5rem auto;
  // text-align: center;
  p.username {
    font-weight: 600;
    font-size: 60px;
  }
  p {
    margin-bottom: 0;
    line-height: 1.2;
    span {
      font-weight: 600;
    }
  }
  .username-text {
    color: #a54ea5;
    font-size: 60px;
  }
  @media (max-width: 424px) {
    font-size: 16px;
    margin: 1.5rem auto 1rem auto;
    p.username {
      font-size: 24px;
    }
    .username-text {
      font-size: 24px;
    }
  }
  @media (min-width: 425px) and (max-width: 576px) {
    font-size: 20px;
    margin: 2rem auto 1rem auto;
    p.username {
      font-size: 28px;
    }
    .username-text {
      font-size: 28px;
    }
  }
  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 24px;
    margin: 1.5rem auto;
    p.username {
      font-size: 32px;
    }
    .username-text {
      font-size: 32px;
    }
  }
  @media (min-width: 768px) and (max-width: 912px) {
    font-size: 28px;
    margin: 1.5rem auto;
    p.username {
      font-size: 36px;
    }
    .username-text {
      font-size: 36px;
    }
  }
  @media (min-width: 913px) and (max-width: 1024px) {
    font-size: 32px;
    margin: 2rem auto;
    p.username {
      font-size: 40px;
    }
    .username-text {
      font-size: 40px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    font-size: 40px;
    margin: 3rem auto;
    p.username {
      font-size: 48px;
    }
    .username-text {
      font-size: 48px;
    }
  }
`;
export const SocialButton = styled.div`
  display: flex;
  // position: relative;
  flex-direction: column;
  gap: 20px;
  // margin-top: 50px;
  width: 50%;
  // margin: 0px !important;
  // a{
  //   display: inline-block !important;
  // }
  @media screen and (max-width: 576px) {
    // margin-top: 32px;
    gap: 8px;
    flex-direction: column;
    width: 75%;
  }
  @media (min-width: 768px) and (max-width: 912px) {
    // margin-top: 32px;
    // margin-left: 48px;
    gap: 16px;
  }
  @media (min-width: 913px) and (max-width: 1024px) {
    // margin-top: 32px;
    // margin-left: 64px;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    // margin-top: 32px;
    // margin-left: 64px;
  }
`;
export const HomeWrap = styled.div`
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-top: 10%;
  background: #fff;
  @media screen and (max-width: 576px) {
    ${"" /* padding-top: 16%; */}
  }
`;
export const HomeSmilyWrap = styled.div`
  background: #f9fbfe;
  padding-top: 80px;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-top: 60px;
    background: #ffffff;
    padding-bottom: 32px;
  }
  @media (max-width: 576px) {
    padding-top: 16px;
    padding-bottom: 0px;
  }
`;

export const WeeklyHeWrap = styled.div`
  width: 100%;
  position: relative;
  margin-top: 80px;
  border-radius: 15px;
  @media (max-width: 768px) {
    margin-top: 32px;
    margin-bottom: 20%;
  }
  @media (max-width: 576px) {
    padding-bottom: 10%;
  }
`;
export const PregaHeading = styled.h1`
  margin: 32px 0px 0px 0px;
  // color: #1f274a;
  // font-family: Poppins;
  // font-style: normal;
  // font-weight: 600;
  // font-size: 48px;
  // line-height: 48px;
  // text-align: center;
  @media (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 24px;
  }
`;
export const Selector = styled.div`
  width: 100%;
  position: relative;
  min-height: 100%;
`;
export const WeeklyHeWrap2 = styled.div`
  width: 100%;
  position: relative;
`;
export const YourHelthBg = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 24px;
  @media (max-width: 376px) {
    padding-bottom: 16px;
  }
`;
export const HelthboxTiles = styled.div`
  width: 100%;
  position: relative;
  background: #ffffff;
  box-shadow: 1px 6px 22px #ede8ed;
  border-radius: 20px;
  padding: 20px 10px 20px 10px;
  margin-bottom: 50px;
`;
export const DrtestmonialHead = styled.h1`
  font-family: Poppins;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
  color: #a64fa6;
  font-weight: 600;
  line-height: 33px;
  text-align: center;
`;
export const DrQuoteLeft = styled.sup`
  position: absolute;
  left: -5px;
`;
export const MomBabyCard = styled.label`
  ${(props) => {
    switch (props.$mode) {
      case "baby-card":
        return css`
          right: 30px;
          grid-area: baby;
          background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);
          --swing: 55px;
          --overlap: 5px;
          ::-webkit-scrollbar-thumb {
            background-color: #ffffff;
          }
        `;
      default:
        return css`
          left: 15px;
          background: white;
          grid-area: mom;
          --swing: -55px;
          --overlap: -5px;
          ::-webkit-scrollbar-thumb {
            background-color: linear-gradient(
              94.68deg,
              #d487d4 0%,
              #a54ea5 100%
            );
          }
        `;
    }
  }}

  position : absolute;
  width: 70%;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  padding: 15px 0px;
  height: 470px;
  overflow-y: scroll;
  @media (max-width: 576px) {
    height: 300px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e9eff9;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #d4e4f8;
  }
`;

export const BabyRadio = styled.input`
  display: none;
`;
export const MomRadio = styled.input`
  display: none;
`;
export const HomeBabyHeading = styled.h1`
  color: white;
  text-align: right;
  padding-right: 32px;
  font-size: 34px;
  font-family: Poppins;
  font-weight: 600;
  // margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const HomeBabySubHeading = styled.h3`
  color: white;
  text-align: right;
  padding-right: 32px;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const HomeBabySubHeading2 = styled.h3`
  color: #1f274a;
  text-align: left;
  padding-left: 32px;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const HomeBabyHeading2 = styled.h1`
  color: #1f274a;
  text-align: left;
  padding-left: 24px;
  font-size: 34px;
  // line-height: 32px;
  font-family: Poppins;
  font-weight: 600;
  // margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const WeeklyList = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: list-item;
    list-style-type: none;
    font-family: Poppins;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    background: url("images/card_icon.svg") left top no-repeat;
    padding: 0px 20px 0px 40px;
    margin: 0px 20px;
    margin-bottom: 20px;
    @media (max-width: 576px) {
      line-height: 20px;
    }
  }
  .weeklyList2 li {
    background: url("images/card_icon2.svg") left top no-repeat !important;
    color: #1f274a;
  }
`;

export const HomeCardPrgaImg = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    .cardParaImg {
      width: 40%;
    }
  }
`;
export const HomeCardPrgaPara = styled.div`
  text-align: center;
  height: 96px;
  h3 {
    color: white;
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
    margin-top: 5px;
  }
  span {
    color: white;
    font-size: 22px;
    line-height: 22px;
    font-weight: normal;
  }
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 16px;
    word-break: break-word;
  }
`;
export const HomePregaCurveBg = styled.div`
  position: relative;
  width: 100%;
  padding: 3% 0% 3% 0%;
  height: fit-content;
  margin-top: -10%;
  background: linear-gradient(90deg, #d487d4 0%, #a54ea5 100%);
  width: 100%;
  @media (max-width: 768px) {
    position: relative;
    text-align: center;
    margin: -10px 0px 60px 0px;
  }
`;
export const HomeCorveData = styled.div`
  z-index: 10;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;
export const BabySliderDiv = styled.div`
  display: flex !important ;
  justify-content: center;

  ${
    "" /* .arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    color: #fff;
    top: 0;
  }

  .arrow svg {
    transition: color 300ms;
  }

  .next {
    right: 43%;
    top: -18%;
  }

  .prev {
    left: 43%;
    top: -18%;
  } */
  }
`;
export const HomeWeekTitie = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 34px;
  color: #fff;
  font-family: Poppins;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const WeeklyHeWrapBg = styled.img`
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
`;

export const GoogleStoreImg = styled.img`
  @media (max-width: 768px) {
    width: 108px;
    // height: 36px;
  }
`;
export const AppleStoreImg = styled.img`
  @media (max-width: 768px) {
    width: 108px;
    // height: 36px;
  }
`;

export const WomanImg = styled.img`
  position: relative;
  width: 100%;
  height: 78%;

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 288px;
  }
`;
export const ManImg = styled.img`
  position: relative;
  width: 100%;
  //  height: 78%;
   height: 445px; /* Fallback for cases where parent height isn't defined */
   max-height: 78%; /* Maximum height as a percentage of the parent */
  // @media (max-width: 1200px) {
  //     // width: 500px;
  //     height: 550px;
  // }
  // @media (max-width: 1000px) {
  //     // width: 500px;
  //     height: 550px;    
  //     }
  @media (max-width: 768px) {
    position: relative;
    // width: 280px;
    // height: 315px;
     width: 100%;
    height: 315px;
  }
`;
export const WomanImgMobile = styled.img`
  @media (max-width: 768px) {
  }
`;

// export const Container = styled.h1`
//     position: absolute;
//     width: 40%;
//     height: 20%;
//     left: 10%;
//     top: 25%;
//     font-family: Poppins;
//     font-style: bold;
//     font-weight: bold;
//     font-size: 50px;
//     line-height: 65px;
//     color: #000000;
//     @media screen and (min-width: 300px) and  (max-width: 580px){
//         width: 35%;
//         height: 17%;
//         font-size: 10px;
//         line-height: 20px;
//         top: 10%;
//         left: 10%;
// 	}
//     @media (min-width: 550px) and  (max-width: 800px){
//         width: 45%;
//         height: 17%;
//         font-size: 30px;
//         line-height: 28px;
//         top: 16%;
//         left: 10%;
//     }
//     @media (min-width: 800px) and  (max-width: 1080px){
//         width: 40%;
//         height: 20%;
//         font-size: 40px;
//         line-height: 40px;
//         left: 10%;
// 	}
// `;

export const Topdiv = styled.div`
  display: flex;
`;

export const Cardimg = styled.img`
  height: 60px !important;
  @media (min-width: 800px) and (max-width: 1024px) {
    height: 30px !important;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    padding-top: -15% !important;
    height: 30px !important;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    height: 20px !important;
  }
`;

export const BtnTxt = styled.p`
  font-size: 20px;
  margin-top: -10%;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 8px;
    margin-top: 0%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 5px;
  }
`;
export const BtnTxtC = styled.h5`
  font-size: 20px;
  margin-top: 3%;
  color: white;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 20px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 18px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 13px;
    margin-top: -6%;
    margin-left: -25%;
  }
`;

export const XCButton = styled(Button)`
  position: absolute;
  background: linear-gradient(164.69deg, #c473c4 11.24%, #a952a9 89.74%);
  box-shadow: 1px 6px 22px #ede8ed;
  margin-top: 80%;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 80px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 60px;
    margin-top: 90%;
    height: 20%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 50px;
    margin-top: 70%;
    height: 20%;
  }
`;

export const XButton = styled(Button)`
  position: absolute;
  color: #ffffff;
  box-shadow: 0px 6px 60px rgba(174, 127, 184, 0.08);
  margin-top: 70%;
  margin-left: 20%;
  z-index: 2;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 8%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 2%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 4%;
  }
`;

export const Ximg = styled.img`
  width: 100%;
`;
export const XTG1img = styled.img`
  width: 320px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 250px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 100px;
  }
`;

export const XTGimg = styled.img`
  width: 400px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 300px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 170px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 120px;
  }
`;

export const XTimg = styled.img`
  width: 580px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 450px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 320px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 180px;
  }
`;

export const Box1 = styled.div`
  position: absolute;
  width: 40%;
  height: 40%;
  top: 65%;
  bottom: 50.25%;
  left: 8%;
  @media (min-width: 800px) and (max-width: 1204px) {
    width: 30%;
    height: 20%;
    top: 63%;
    bottom: 50.25%;
    left: 8%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 25%;
    height: 25%;
    top: 28%;
    bottom: 50.25%;
    left: 7%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 20%;
    height: 20%;
    top: 20%;
    bottom: 50.25%;
    left: 6%;
  }
`;

export const Box2 = styled.div`
  position: absolute;
  width: 40%;
  height: 40%;
  top: 85%;
  bottom: 50.25%;
  left: 8%;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 30%;
    height: 30%;
    top: 77%;
    bottom: 50.25%;
    left: 8%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    top: 35%;
    bottom: 50.25%;
    left: 7%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    top: 27%;
    bottom: 50.25%;
    left: 6%;
  }
`;

export const Box3 = styled.div`
  position: absolute;
  width: 40%;
  top: 27%;
  bottom: 50.25%;
  padding-left: 50%;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 30%;
    top: 30%;
    bottom: 50.25%;
    padding-left: 50%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    top: 15%;
    left: 5%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 10%;
    top: 10%;
    bottom: 50.25%;
    padding-left: 50%;
  }
`;
export const Box4 = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0px;
  top: 711px;
  line-height: 42px;
`;
export const Title = styled.h4`
  font-size: 48px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  color: white;
  padding-left: 10%;
  padding-top: 5%;
`;

export const Title2 = styled.p`
  font-size: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  color: white;
  padding-left: 10%;
`;

export const BackcardTitle = styled.div`
  color: white;
  font-size: 32px;
  margin-left: 5%;
  margin-top: 7%;
  font-family: Poppins;
  font-weight: bold;
  line-height: 40px;
  font-style: normal;
`;

export const FrontcardTitle = styled.div`
  color: black;
  font-size: 32px;
  margin-left: 5%;
  margin-top: 7%;
  font-family: Poppins;
  font-weight: bold;
  line-height: 40px;
  font-style: normal;
`;
//mom
export const Cardcontent = styled.span`
  margin-left: 3%;
  font-weight: normal;
  font-size: 16px;
  font-family: Poppins;
  line-height: 21px;
  @media (min-width: 800px) and (max-width: 1024px) {
    line-height: 8px;
    font-size: 8px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    line-height: 5px;
    font-size: 5px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    line-height: 4px;
    font-size: 3px;
  }
`;

//baby
export const Cardcontent1 = styled.span`
  color: white;
  margin-left: 3%;
  font-weight: normal;
  font-size: 16px;
  font-family: Poppins;
  line-height: 21px;
  @media (min-width: 800px) and (max-width: 1024px) {
    line-height: 10px;
    font-size: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    line-height: 7px;
    font-size: 7px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    line-height: 6px;
    font-size: 3px;
  }
`;

export const Box5 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  width: 50%;
  border-radius: 36px;
  left: 10%;
  right: 10%;
  position: absolute;
  padding: 0px 10px;
  margin-top: 90px;
`;
export const Box6 = styled.body`
  margin: 0 !important;
`;
export const Content1 = styled.p`
  width: 35%;
  position: absolute;
  margin-top: -112%;
  margin-left: 50%;
  font-size: 16px;
`;

export const Shortcard = styled.div`
  margin-top: 63%;
  position: absolute;
  margin-left: 2%;
  width: 383px;
  height: 59px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 68%;
    width: 283px;
    height: 40px;
    border-radius: 20px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 73%;
    width: 210px;
    height: 25px;
    border-radius: 20px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 153%;
    width: 140px;
    height: 20px;
    border-radius: 20px;
  }
`;
export const Shortcard1 = styled.div`
  margin-top: 82%;
  position: absolute;
  margin-left: 20%;
  width: 312px;
  height: 59px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 88%;
    width: 283px;
    height: 40px;
    border-radius: 20px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 88%;
    width: 183px;
    height: 30px;
    border-radius: 20px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 168%;
    width: 120px;
    height: 20px;
    border-radius: 20px;
  }
`;
export const Shortcard2 = styled.div`
  margin-top: 108%;
  position: absolute;
  margin-left: 67%;
  width: 383px;
  height: 59px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 110%;
    margin-left: 65%;
    width: 280px;
    height: 40px;
    border-radius: 20px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 110%;
    width: 210px;
    height: 25px;
    border-radius: 20px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 183%;
    margin-left: 63%;
    width: 140px;
    height: 20px;
    border-radius: 20px;
  }
`;
export const Shortcard3 = styled.div`
  margin-top: 127%;
  position: absolute;
  margin-left: 47%;
  width: 312px;
  height: 59px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0px 4px 17px rgba(31, 39, 74, 0.1);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 127%;
    width: 283px;
    height: 40px;
    border-radius: 20px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 125%;
    width: 183px;
    height: 30px;
    border-radius: 20px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 198%;
    width: 120px;
    height: 20px;
    margin-left: 35%;
    border-radius: 20px;
  }
`;

export const Cardp = styled.p`
  white-space: nowrap;
  font-size: 14px;
  color: #857085;
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 5px;
    margin-top: -15px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
  }
`;

export const Cardtext = styled.p`
font-size : 14px;
white-space: nowrap;
font-family : Poppins
font-weight : normal;
line-height : 21px;
margin-top: 15px;
color: #1F274A;
text-align : center; 
@media (min-width: 800px) and (max-width: 1204px){
    font-size : 12px;
    margin-top: 10px;  
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 9px;  
    margin-top: -2px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 6px; 
    margin-top: -4px;
}
`;

export const Cardtext1 = styled.p`
font-size : 14px;
font-family : Poppins
font-weight : normal;
line-height : 21px;
color: #1F274A;
text-align : center;
margin-top : 15px;
padding: 3px;
@media (min-width: 800px) and (max-width: 1204px){
    font-size : 12px;
    margin-top: 8px;  
} 
@media (min-width: 550px) and  (max-width: 800px){
    font-size : 9px;  
    margin-top: 2px;
} 
@media (min-width: 300px) and  (max-width: 580px){
    font-size : 6px; 
    margin-top: -2px;
}
`;

export const Viewmore1 = styled(Button)`
  margin-top: 80%;
  position: absolute;
  margin-left: 50%;
  width: 230px;
  height: 50px !important;
  background-color: linear-gradient(164.69deg, #c473c4 11.24%, #a952a9 89.74%);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 800px) and (max-width: 1204px) {
    width: 200px;
    margin-top: 85%;
    margin-left: 53%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 190px;
    margin-top: 88%;
    margin-left: 50%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 80px;
    margin-top: 168%;
    margin-left: 58%;
    height: 20px !important;
  }
`;

export const Viewmore2 = styled(Button)`
  margin-top: 125%;
  position: absolute;
  margin-left: 12%;
  width: 230px;
  height: 50px !important;
  background-color: linear-gradient(164.69deg, #c473c4 11.24%, #a952a9 89.74%);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  @media (min-width: 800px) and (max-width: 1204px) {
    width: 200px;
    margin-top: 130%;
    margin-left: 4%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 190px;
    margin-top: 123%;
    margin-left: 9%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 80px;
    margin-top: 198%;
    margin-left: 4%;
    height: 20px !important;
  }
`;

export const Heading = styled.h3`
  position: absolute;
  padding-top: 17%;
  color: white;
  padding-left: 10%;
  font-family: Poppins;
  line-height: 85px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 90%;
    padding-top: 40%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 90%;
    padding-top: 23%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 90%;
    line-height: 35px;
    padding-top: 25%;
    font-size: 8px;
  }
`;
export const Para = styled.p`
  position: absolute;
  padding-top: 22%;
  color: white;
  padding-left: 10%;
  font-family: Poppins;
  font-size: 36px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 90%;
    padding-top: 45%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    padding-top: 30%;
    width: 90%;
    font-size: 12px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    padding-top: 30%;
    width: 90%;
    font-size: 8px;
  }
`;

export const InfoDiv = styled.div`
  position: absolute;
  width: 100%;
  padding-top: 30%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  @media (min-width: 800px) and (max-width: 1204px) {
    width: 90%;
    height: 20%;
    padding-top: 45%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 90%;
    height: 15%;
    padding-top: 50%;
    margin-left: 3%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 90%;
    height: 20%;
    padding-top: 85%;
    margin-left: 5%;
  }
`;

export const Div1 = styled.div`
  position: absolute;
  padding-top: 33%;
  text-align: center;
  padding-left: 3%;
`;

export const Container2 = styled.div`
  position: absolute;
  padding-top: 33%;
  text-align: center;
  padding-left: 3%;
`;

export const HeadTag = styled.p`
  color: black;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  padding-top: 0%;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 44px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    padding-top: -35%;
    font-size: 24px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    padding-top: -25%;
    font-size: 14px;
  }
`;
export const FirstCard = styled.div`
  width: 60%;
  margin-left: 20%;
  height: 60%;
  margin-top: 6%;
  @media (min-width: 800px) and (max-width: 1024px) {
    height: 50%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    height: 40%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    height: 30%;
  }
`;

export const FirstContainer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 30%;
  padding: 2%;
`;

export const EmojiImg = styled.img`
  height: 80%;
  @media (min-width: 800px) and (max-width: 1024px) {
    height: 60%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    height: 50%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    height: 40%;
  }
`;

export const Para2 = styled.p`
  font-size: 16px;
  margin-bottom: 3%;
  margin-top: 3%;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 12px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 10px;
    width: 40px;
    padding-top: 5px;
    margin-left: -30%;
  }
`;

export const InfoImg = styled.img`
  width: 100px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 80px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 60px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 40px;
  }
`;

// export const Day = styled.b`

// color : white;
// font-size : 64px;
// `

export const XTitle = styled.p`
  color: white;
  font-size: 18px;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 14px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 12px;
    width: 80px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 10px;
    width: 70px;
  }
`;

export const Number = styled.span`
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 44px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 34px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 15px;
  }
`;

export const Weight = styled.span`
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: normal;
`;

export const Weight1 = styled.p`
  color: white;
  font-size: 18px;
  padding-right: 27%;
`;

export const Size = styled.p`
  color: white;
  font-size: 18px;
  padding-left: 10%;
  padding-top: 5%;
`;

export const Size1 = styled.span`
  color: white;
  font-size: 64px;
  font-weight: bold;
`;

export const Size2 = styled.span`
  color: white;
  font-size: 18px;
`;

export const Size3 = styled.p`
  color: white;
  font-size: 18px;
  padding-right: 55%;
`;

export const Title1 = styled.p`
  margin-top: 15%;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 44px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 32px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 14px;
  }
`;

export const Image = styled.img`
  position: absolute;
  margin-top: 13%;
  margin-left: 5%;
  height: 70%;
  @media (min-width: 800px) and (max-width: 1024px) {
    height: 50%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    height: 27%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    height: 25%;
    margin-top: 5%;
    margin-left: 30%;
  }
`;

export const Xh1 = styled.h1`
  color: white;
  margin-left: 70%;
  font-size: 64px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 60%;
    font-size: 50px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 65%;
    font-size: 40px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 50%;
    font-size: 24px;
  }
`;

export const Xh2 = styled.h1`
  color: black;
  margin-left: 5%;
  font-size: 64px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 6%;
    font-size: 50px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 5%;
    font-size: 40px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 5%;
    font-size: 25px;
  }
`;

export const Image1 = styled.img`
  height: 32px;
  margin-left: 5%;
`;

export const Image2 = styled.img`
  height: 32px;
  margin-left: 5%;
`;

export const Image3 = styled.img`
  margin-top: 60%;
  position: absolute;
  margin-left: 20%;
  width: 300px;
  border-radius: 32px;
  width: 25%;
  border: 1px solid grey;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 70%;
    margin-left: 23%;
    width: 210px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 70%;
    margin-left: 25%;
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 150%;
    margin-left: 28%;
    width: 80px;
  }
`;

export const Image4 = styled.img`
  margin-top: 72%;
  position: absolute;
  margin-left: 4%;
  width: 300px;
  border-radius: 32px;
  width: 25%;
  border: 1px solid grey;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 80%;
    margin-left: 6%;
    width: 210px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 80%;
    margin-left: 8%;
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 160%;
    margin-left: 15%;
    width: 80px;
  }
`;

export const Image5 = styled.img`
  margin-top: 105%;
  position: absolute;
  margin-left: 48%;
  width: 300px;
  border-radius: 32px;
  width: 25%;
  border: 1px solid grey;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 210px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 105%;
    margin-left: 50%;
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 180%;
    margin-left: 55%;
    width: 80px;
  }
`;

export const Image6 = styled.img`
  margin-top: 117%;
  position: absolute;
  margin-left: 63%;
  width: 300px;
  border-radius: 32px;
  width: 25%;
  border: 1px solid grey;
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 210px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 80px;
    margin-top: 190%;
  }
`;

export const VideoTitle = styled.p`
  width: 45%;
  position: absolute;
  margin-top: 103%;
  margin-left: 12%;
  font-size: 48px;
  font-family: Poppins;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 105%;
    font-size: 38px;
    margin-left: 4%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 103%;
    font-size: 28px;
    margin-left: 9%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 178%;
    font-size: 18px;
    margin-left: 4%;
    width: 50%;
  }
`;

export const VideoContent = styled.p`
  width: 35%;
  position: absolute;
  margin-top: 110%;
  margin-left: 12%;
  font-size: 16px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 110%;
    font-size: 12px;
    margin-left: 4%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 110%;
    font-size: 10px;
    margin-left: 9%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 184%;
    font-size: 6px;
    margin-left: 4%;
    width: 50%;
  }
`;

export const Whitecurve = styled.img`
  width: 100%;
  z-index: 0;
  margin-top: 27%;
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 90%;
  }
`;

export const WhitecurveB = styled.img`
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 38%;
`;

export const AudioTitle = styled.p`
  width: 35%;
  position: absolute;
  margin-top: 57%;
  margin-left: 50%;
  font-size: 48px;
  font-family: Poppins;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 68%;
    font-size: 38px;
    margin-left: 54%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 69%;
    font-size: 28px;
    margin-left: 50%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 148%;
    font-size: 18px;
    margin-left: 55%;
    width: 40%;
  }
`;

export const AudioContent = styled.p`
  width: 35%;
  position: absolute;
  margin-top: 65%;
  margin-left: 50%;
  font-size: 16px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 73%;
    font-size: 12px;
    margin-left: 54%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 75%;
    font-size: 10px;
    margin-left: 50%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 155%;
    font-size: 6px;
    margin-left: 55%;
    width: 45%;
  }
`;

export const Image7 = styled.img`
  width: 100%;
  margin-top: -40%;
  position: relative;
  z-index: 1;
`;
export const Heading1 = styled.p`
  font-size: 55px;
  font-family: Poppins;
  font-weight: bold;
  position: relative;
  margin-top: -200%;
  text-align: center;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 45px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 35px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 20px;
  }
`;
export const Image8 = styled.img`
  width: 100%;
  margin-top: -25%;
  position: relative;
  z-index: 0;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: -23%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: -20%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: -18%;
  }
`;

export const Image9 = styled.img`
  width: 70%;
  margin-top: 5%;
  position: relative;
  margin-left: 15%;
  z-index: 1;
`;

export const Image10 = styled.img`
  margin-top: -5%;
  margin-left: 40%;
  z-index: 1;
  width: 250px;
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 200px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 150px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 50px;
  }
`;

export const Combinedshape = styled.img`
  width: 100%;
  position: relative;
  margin-top: 10%;
  z-index: 1;
`;

export const Bigcard = styled.div`
  margin-top: 53%;
  position: absolute;
  width: 60%;
  margin-left: 20%;
`;

export const Card1 = styled.p`
  white-space: nowrap;
  color: #857085;
  font-size: 14px;
`;

export const Card2 = styled.p`
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  font-family: Poppins;
  margin-top: -15px;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 13px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 7px;
  }
`;

export const Card3 = styled.p`
  white-space: nowrap;
  font-size: 17px;
  font-weight: 900;
  font-family: Poppins;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 10px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    font-size: 7px;
  }
`;

export const HelthboxHeading = styled.h4`
  margin: 30px 0px 15px 0px;
  font-family: Poppins;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  color: #1f274a;
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin: 0px 0px 0px 0px;
  }
`;

export const InsightsboxHeading = styled.h4`
  width: 100%;
  margin: 0px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: #1f274a;
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 15px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin: 0px 0px 0px 0px;
  }
`;

export const Mask = styled.img`
  position: absolute;
  width: 100%;
  margin-top: 90%;
`;

export const MomBabyImg = styled.img`
  ${
    "" /* @media (max-width: 768px) {
    width: 225px;
    height: 256px;
    margin: -30px 0px 30px 70px;
  } */
  }
`;

export const XFCdiv = styled.div`
  margin-top: 3%;
  width: 100%;
  line-height: 50px;
  color: black;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 30px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 10px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    line-height: 5x;
  }
`;
export const XCdiv = styled.div`
  margin-top: 77%;
  position: absolute;
  width: 60%;
  margin-left: 35%;
`;

export const XCarddiv = styled.div`
  margin-top: 55%;
  position: absolute;
  width: 60%;
  margin-left: 20%;
`;

export const XChatCarddiv = styled.div`
  margin-top: 130%;
  position: absolute;
  width: 60%;
  margin-left: 27%;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 30%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 30%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 30%;
  }
`;

export const LinksCard = styled.div`
  position: absolute;
  padding-left: 25%;
  margin-top: 43%;
  z-index: 2;
  @media (min-width: 800px) and (max-width: 1024px) {
    padding-left: 20%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    padding-left: 24%;
    margin-top: 45%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    padding-left: 10%;
    margin-top: 45%;
  }
`;

export const Limg = styled.img`
  @media (min-width: 800px) and (max-width: 1024px) {
    width: 170px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    width: 80px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 50px;
  }
`;

export const TribeDiv = styled.div`
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 12%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 10%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 18%;
  }
`;

export const CarouselDiv = styled.div`
  position: relative;
  width: 60%;
  margin: 0px auto;
  background: none;
  padding-bottom: 100px;
  .carousel {
    background: none;
    box-shadow: none;
    border-radius: none;
  }
  .customslides {
    position: relative;
    background: #fff;
    box-shadow: 1px 1px 12px #efe6ef;
    border-radius: 28px;
    border: 1px solid #a64fa6;
    padding: 5%;
  }
  p {
    font-size: 18px;
    color: #a64fa6;
  }
  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 800px) and (max-width: 1024px) {
    margin-left: 14%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-left: 11%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-left: 10%;
    margin-bottom: 20px;
  }
`;

export const CarouselItem = styled(Carousel.Item)`
  font-size: 30px;
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 3%;
    font-size: 16px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 6%;
    font-size: 7px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 10%;
    font-size: 5px;
    .Caption {
      margin-top: 10% !important;
    }
  }
`;

export const CarouselCaption = styled(Carousel.Caption)`
  @media (min-width: 800px) and (max-width: 1024px) {
    margin-top: 3%;
    font-size: 16px;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    margin-top: 6%;
    font-size: 7px;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    margin-top: 10% !important;
    font-size: 5px;
  }
`;

export const Xfooter = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 140%;
  z-index: 2;
  @media (min-width: 800px) and (max-width: 1024px) {
    font-size: 16px;
    width: 100%;
  }
  @media (min-width: 550px) and (max-width: 800px) {
    font-size: 7px;
    width: 100%;
  }
  @media (min-width: 300px) and (max-width: 580px) {
    width: 100%;
    margin-top: 145%;
  }
`;

export const BackgroundDiv = styled.div`
    position : relative;
    width: auto; s
    margin-top: 43%; 	
`;

export const XText = styled.span`
  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: -55px;
  }
  @media (max-width: 950px) and (min-width: 768px) {
    font-size: 12px;
    margin-left: -50px;
  }
`;

export const DoctorHeadParent = styled.h1`
  width: 100%;
  margin-top: 150px;
  font-size: 45px;
  line-height: 40px;
  color: #1f274a;
  font-weight: 600;
  @media (max-width: 768px) {
    text-align: left;
    margin-top: 0px;
    font-size: 30px;
    font-weight: 600;
  }
`;
export const DoctorHeadChild = styled.h2`
  width: 100%;
  margin-top: 10px;
  font-size: 45px;
  line-height: 60px;
  color: #1f274a;
  font-weight: 610;
  @media (max-width: 768px) {
    text-align: left;
    font-weight: 610;
    font-size: 35px;
  }
`;

export const DoctorContainer = styled.div`
  width: 100%;
  display: grid;
  @media (max-width: 768px) {
    width: 100%;
    text-decoration: none;
  }
`;

export const NoApptDiv = styled.div`
  // align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    text-decoration: none;
  }
`;

export const DocImg = styled.img`
  width: 258px;
  height: 292px;
  padding: 10% 0% 3% 5%;
  border-radius: 50px !important;
  @media (max-width: 768px) {
    width: 160px;
    height: 240px;
    padding: 10% 0% 5% 0%;
    border-radius: 20px !important;
  }
  @media (max-width: 950px) and (min-width: 768px) {
    width: 210px;
  }
`;
export const DoctorHeadSlide = styled.h1`
  width: 100%;
  margin-top: 1%;
  font-size: 32px;
  line-height: 50px;
  color: #1f274a;
  font-weight: 600;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 25px;
    margin-top: 5%;
  }
`;

export const DoctorParaInline = styled.div`
  width: 100%;
  margin: 0px auto;
  position: relative;
  text-align: center;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const DocCard = styled.div`
  width: 258px;
  height: 316px;
  @media (max-width: 768px) {
    width: 136px;
    height: 210px;
    .doctorCardTitle {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
export const XCard = styled(Card)`
  box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
  @media (max-width: 768px) {
    width: 125px;
    height: 200px;
    margin-left: 5%;
  }
`;
export const IconDiv = styled.div`
    width: 100%;
    margin:0px auto;
    position: relative;
    text-align: center;  
    ul .list-mob{
        display:none;
    }
    ul{
        margin:0px;
        padding:0px;
    }
    ul li{
        display: flow-root;
        float:left;
        font-size:20px;
        list-style-type:  none;
        padding: 0px 5px;
        color: #1F274A;
    }
   
    @media (max-width: 768px) {
        ul .list{
            display: none;
        }
        ul .list-mob{
            display: block;
        }
        ul .list-mob .doctorIcons svg{ 
            color
        }
        ul li:last-child{
            margin-left: 20px;
        }
        display: flow-root;
        list-style-type: none;
        ul{
            padding-left:2rem;
        }
        ul li{
            font-size: 14px;
        }
        ul{
            padding:0;
        }
    }  
`;

export const XButton1 = styled(Button)`
  @media (max-width: 768px) {
    width: 100px;
    .span {
      font-size: 14px;
    }
  }
  @media (max-width: 950px) and (min-width: 768px) {
    width: 70px;
  }
`;
