import styled from "styled-components";
import Carousel from 'react-bootstrap/Carousel';
import { Button, Card } from "antd";
import { Link } from 'react-router-dom';

export const XText  =  styled.span` 
    @media (max-width: 768px) { 
        font-size: 12px;
        margin-left: -55px; 
    }
    @media (max-width: 950px) and (min-width:768px) {
        font-size: 12px;
        margin-left: -50px;
    }
` 

export const DoctorHeadParent  = styled.h1`
    width: 100%;
    margin-top: 150px;
    font-size: 45px;
    line-height: 40px;
    color: #1F274A;
    font-weight: 600;
    @media (max-width: 768px) {
        text-align: left;
        margin-top:0px;
        font-size: 30px;
        font-weight: 600;
    }
`
export const DoctorHeadChild  = styled.h2`
    width: 100%;
    margin-top:10px;
    font-size: 45px;
    line-height: 60px;
    color: #1F274A;
    font-weight: 610;
    @media (max-width: 768px) {
        text-align: left;   
        font-weight: 610;
        font-size: 35px;
    }
`

export const DoctorContainer  = styled.div`
    width: 100%;
    display: grid;
    @media (max-width: 768px) {
        width: 100%;
        text-decoration: none;
    }
`

export const NoApptDiv  = styled.div`
    // align-items: center; 
    @media (max-width: 768px) {
        width: 100%;
        text-decoration: none;
    }
`

export const DocImg  = styled.img`
    width: 258px;
    height: 292px;
    padding:10% 0% 3% 5%;
    border-radius:50px !important; 
    @media (max-width: 768px) {
        width: 160px;
        height: 240px;
        padding:10% 0% 5% 0%; 
        border-radius:20px !important;
    }  
    @media (max-width: 950px) and (min-width:768px) {
        width: 210px;
    }
`
export const DoctorHeadSlide  = styled.h1`
    width: 100%;
    margin-top:1%;
    font-size: 32px;
    line-height:50px;
    color: #1F274A;
    font-weight: 600;
    text-align: center; 
    @media (max-width: 768px) {
        font-size: 18px;
        line-height:25px;
        margin-top:5%;
    }  
`

export const DoctorParaInline = styled.div`
    width: 100%;
    margin:0px auto;
    position: relative;
    text-align: center;
    font-size: 18px; 
    @media (max-width: 768px) {
        font-size: 12px; 
    }  
`

export const DocCard = styled.div`
    width: 258px;
    height: 316px; 
    @media (max-width: 768px) {
        width: 136px;
        height: 210px; 
        .doctorCardTitle{
            font-weight: 400;
            font-size: 14px;
        }
    }  
`
export const XCard = styled(Card)`
    box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
    @media (max-width: 768px) {
        width: 125px;
        height: 200px;
        margin-left: 5%;
    }  
`
export const IconDiv = styled.div`
    width: 100%;
    margin:0px auto;
    position: relative;
    text-align: center;  
    ul .list-mob{
        display:none;
    }
    ul{
        margin:0px;
        padding:0px;
    }
    ul li{
        display: flow-root;
        float:left;
        font-size:20px;
        list-style-type:  none;
        padding: 0px 5px;
        color: #1F274A;
    }
   
    @media (max-width: 768px) {
        ul .list{
            display: none;
        }
        ul .list-mob{
            display: block;
        }
        ul .list-mob .doctorIcons svg{ 
            color
        }
        ul li:last-child{
            margin-left: 20px;
        }
        display: flow-root;
        list-style-type: none;
        ul{
            padding-left:2rem;
        }
        ul li{
            font-size: 14px;
        }
        ul{
            padding:0;
        }
    }  
`

export const XButton = styled(Button)` 
    @media (max-width: 768px) {
        width: 100px;
        .span{
            font-size: 14px; 
        }
    }  
    @media (max-width: 950px) and (min-width:768px) {
        width: 70px; 
    }
`

export const TopContainer  = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto;
        width: 35%;
        margin-top: 15px;
    } 
`