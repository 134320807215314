import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
const firebaseConfig = {
    apiKey: "AIzaSyBHB2wXDcayVxrKicQzXU3HUUpRIaa7nk0",
    authDomain: "lilac-patient-app-bbdc5.firebaseapp.com",
    projectId: "lilac-patient-app-bbdc5",
    storageBucket: "lilac-patient-app-bbdc5.appspot.com",
    messagingSenderId: "383237443660",
    appId: "1:383237443660:web:dbca02321cc0b2a89664e9",
    measurementId: "G-H8K8F1DE99"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()
const facebookProvider = new firebase.auth.FacebookAuthProvider()

export const googleSignIn = async () => {
    const res = await auth.signInWithPopup(googleProvider)
    if (!res) {
        return false
    }
    const data = {
        "email": res.additionalUserInfo.profile.email,
        "id": res.user._delegate.auth.currentUser.uid,
        "provider": "google",
        socialLogin: true,
        socialType: "social"
    }
    console.log("res", res)
    return data
}

export const googleSignUp = async () => {
    const res = await auth.signInWithPopup(googleProvider)
    if (!res) {
        return false
    }
    const data = {
        "email": res.additionalUserInfo.profile.email,
        "id": res.user._delegate.auth.currentUser.uid,
        "provider": "google",
        socialSignin: true,
        socialType: "social",
        "name": res.additionalUserInfo.profile.name
    }
    return data;
    console.log("res", res)
    console.log("data comming", data)

}

export const facebookSignIn = async () => {
    const res = await auth.signInWithPopup(facebookProvider)
    if (!res) {
        return false
    }
    const data = {
        "email": res.additionalUserInfo.profile.email,
        "id": res.user._delegate.auth.currentUser.uid,
        "provider": "facebook",
        socialLogin: true,
        socialType: "social"
    }
    console.log("res", res)
    return data
}

export const facebookSignUp = async () => {
    const res = await auth.signInWithPopup(facebookProvider)
    if (!res) {
        return false
    }
    const data = {
        "email": res.additionalUserInfo.profile.email,
        "id": res.user._delegate.auth.currentUser.uid,
        "provider": "facebook",
        socialSignin: true,
        socialType: "social",
        "name": res.additionalUserInfo.profile.name
    }
    return data;
    console.log("res", res)
    console.log("data comming", data)

}