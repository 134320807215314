export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT"
export const BOOK_APPOINTMENT_FAIL = "BOOK_APPOINTMENT_FAIL"
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS"
export const BOOK_APPOINTMENT_RESET = "BOOK_APPOINTMENT_RESET"

export const bookAppointment = (params) => {
    return { type: BOOK_APPOINTMENT, params }
}
export const bookAppointmentFail = (response) => {
    return { type: BOOK_APPOINTMENT_FAIL, response }
}
export const bookAppointmentSuccess = (response) => {
    return { type: BOOK_APPOINTMENT_SUCCESS, response }
}
export const bookAppointmentReset = () => {
    return { type: BOOK_APPOINTMENT_RESET }
}