import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getTribeArticleList = async (params) => { 
  const response = await axios({
    url: `${config.api.base_url}article/app-list${params.type? "/"+params.type:""}?support_tribe=${params.tribes}`,
    method: "get", 
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });   
  return response; 
};

export default getTribeArticleList; 