import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_MOOD_TRACK,
    saveMoodTrackFail,
    saveMoodTrackSuccess
} from "../action/saveMoodTrackAction"

// api
import saveMoodTrackApi from "../api/saveMoodTrackApi"

export function* saveMoodTrackSaga(action) {
    try{
        const response = yield call(() => saveMoodTrackApi(action.params))
        if(response.data.status===1){
            yield put(saveMoodTrackSuccess(response, action))
        } else {
            yield put(saveMoodTrackFail(response, action))
        }
    }
    catch(e)
    {
        yield put(saveMoodTrackFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(SAVE_MOOD_TRACK, saveMoodTrackSaga)
}