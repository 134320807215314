import { React, useEffect, useState } from "react";
import {
  TopContainer,
  DocImg,
  DoctorName,
  IconDiv,
  XButton,
  XText,
  NoApptDiv,
} from "./AppointmentsStyle";
import { Tabs, Spin } from "antd";
import { connect } from "react-redux";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { DaySlotFormat, timeSlotFormat } from "../../utils";
import getAppointmentApi from "../../api/getAppointmentApi";
import { Link } from "react-router-dom";
import { getPatientToken } from "../../utils";
import config from "../../config";
import {
  getUpcomingAppointment,
  getUpcomingAppointmentReset,
} from "../../action/getUpcomingAppointmentAction";

import "./Appointments.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

const Appointments = (props) => {
  const {
    getUpcomingAppointment,
    getUpcomingAppointmentState,
    getUpcomingAppointmentReset,
  } = props;
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [pastAppointmentsData, setPastAppointmentsData] = useState([]);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [loadingPastAppointments, setLoadingPastAppointments] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const { TabPane } = Tabs;
  function getTabKey(key) {}

  // useEffect(() => {
  //   if (getUpcomingAppointmentState.apiState === "success") {
  //     const appointmentss = [];
  //     const x = getUpcomingAppointmentState.data.data.map((T) => {
  //       appointmentss.push(T);
  //     });
  //     setAppointmentsData(appointmentss);
  //     // console.log("appoint", appointmentsData);
  //   }
  // }, [getUpcomingAppointmentState.apiState, getUpcomingAppointmentState.data.data]);
//console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",id);
  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchPastAppointments() {
      try {
        const page = 1; // Page number
        const records = 1000; // Number of records per page
        setLoadingPastAppointments(true);
        const response = await fetch(
          `${config.api.base_url}patient/past-appointments?page=${page}&records=${records}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getPatientToken()}`,
            },
          }
        );
        const result = await response.json();
        setPastAppointmentsData(result.data);
      } catch (error) {
        console.error("Error fetching past appointments:", error);
      } finally {
        setLoadingPastAppointments(false);
      }
    }
    fetchPastAppointments();
  }, []);

  useEffect(() => {
    async function fetchUpcomingAppointments() {
      try {
        setLoadingAppointments(true);
        
        const response = await fetch(
          `${config.api.base_url}patient/upcoming-appointments`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getPatientToken()}`,
            },
          }
        );
        const result = await response.json();
        setAppointmentsData(result.data);
      } catch (error) {
        console.log("Error fetching upcoming appointments:", error);
      } finally {
        setLoadingAppointments(false);
      }
    }
    fetchUpcomingAppointments();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  function AppointmentsList(props) {
    function getSpeciality(spec) {
      let QuaValues = [];
      for (let i = 0; i < spec.length; i++) {
        QuaValues[i] = spec[i].value;
      }
      return QuaValues.join(" | ");
    }
    console.log(props.appointments);
    return (
      <div className="pageWrap2 pt-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="row">
                {loadingAppointments ? (
                  <div className="text-center loader-container">
                    <Spin
                      spinning={loadingAppointments}
                      tip="Loading Upcoming Appointments..."
                    />
                  </div>
                ) : props.appointments.length > 0 ? (
                  <>
                    {props.appointments.map((item) => (
                      <>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
                          <div className="appointmentBox">
                            <div className="row  itemscard">
                              <div className="col-12 col-sm-12 mb-2 text-center cardSection1">
                                <div className="appmntImg2">
                                  <img
                                    className="img-fluid"
                                    key={item._id}
                                    src={
                                      item.doctor_id.profile_picture
                                        ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                        : item.doctor_id.gender.toLowerCase() ===
                                          "male"
                                        ? `/images/avatar_male.svg`
                                        : item.doctor_id.gender.toLowerCase() ===
                                          "female"
                                        ? `/images/avatar_female.svg`
                                        : `/images/avatar_transgender.svg`
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      switch (
                                        item.doctor_id.gender.toLowerCase()
                                      ) {
                                        case "male":
                                          currentTarget.src =
                                            "/images/avatar_male.svg";
                                          break;
                                        case "female":
                                          currentTarget.src =
                                            "/images/avatar_female.svg";
                                          break;
                                        default:
                                          currentTarget.src =
                                            "/images/avatar_transgender.svg";
                                      }
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 text-center cardSection2">
                                <div className="docInfo mt-sm-5 mt-md-3 mt-lg-2 mt-xl-1">
                                  <h1
                                    className="appointmentsHeading"
                                    key={item._id}
                                    level={4}
                                  >
                                    {item.doctor_id.salutation.value}{" "}
                                    {item.doctor_id.fname}{" "}
                                    {item.doctor_id.lname}
                                  </h1>
                                  <div className="docText">
                                    <div className="row">
                                      <p className="appointmentsPara">
                                        {getSpeciality(
                                          item.doctor_id.speciality
                                        )}
                                      </p>
                                      <div className="col-12 col-sm-12 text-center">
                                        <ul className="appointmentsList1">
                                          <li>
                                            <CalendarOutlined
                                              className="doctorIcons"
                                              twoToneColor="#1F274A"
                                            />{" "}
                                            {DaySlotFormat(item.date)}
                                          </li>
                                          <li>
                                            <ClockCircleOutlined className="doctorIcons" />{" "}
                                            {timeSlotFormat(
                                              item.time_slot_start
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 text-center submitRspn mt-md-0 mb-2 cardSection3">
                                <Link
                                  to={{
                                    pathname: "/appointment-details",
                                    state: {
                                      Details: { item },
                                      isBooking: true,
                                      doctor: `${item.doctor_id._id}`,
                                      app: "upcom",
                                    },
                                  }}
                                  className="appointmentsBtn"
                                >
                                  View Appointment
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="row d-flex justify-content-center">
                      <div className="offset-col-4 col-4 empmsg loader-container">
                        <p>Please Book an Appointment</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function PastAppointmentsList(props) {
    function getSpeciality(spec) {
      let QuaValues = [];
      for (let i = 0; i < spec.length; i++) {
        QuaValues[i] = spec[i].value;
      }
      return QuaValues.join(" | ");
    }
    console.log(props.appointments, "past");
    return (
      <div className="pageWrap2 pt-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="row">
                {loadingPastAppointments ? (
                  <div className="text-center loader-container">
                    <Spin
                      spinning={loadingPastAppointments}
                      tip="Loading Past Appointments..."
                    />
                  </div>
                ) : props.appointments.result.length > 0 ? (
                  <>
                    {props.appointments.result.map((item) => (
                      <>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5">
                          <div className="appointmentBox">
                            <div className="row  itemscard">
                              <div className="col-12 col-sm-12 mb-2 text-center cardSection1">
                                <div className="appmntImg2">
                                  <img
                                    className="img-fluid"
                                    key={item._id}
                                    src={
                                      item.doctor_id.profile_picture
                                        ? `${config.api.s3_url}doctor/${item.doctor_id.profile_picture}`
                                        : item.doctor_id.gender.toLowerCase() ==
                                          "male"
                                        ? `/images/avatar_male.svg`
                                        : item.doctor_id.gender.toLowerCase() ==
                                          "female"
                                        ? `/images/avatar_female.svg`
                                        : `/images/avatar_transgender.svg`
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 text-center cardSection2">
                                <div className="docInfo mt-sm-5 mt-md-3 mt-lg-2 mt-xl-1">
                                  <h1
                                    className="appointmentsHeading"
                                    key={item._id}
                                    level={4}
                                  >
                                    {item.doctor_id.salutation.value}{" "}
                                    {item.doctor_id.fname}{" "}
                                    {item.doctor_id.lname}
                                  </h1>
                                  <div className="docText">
                                    <div className="row">
                                      <p
                                        className="appointmentsPara"
                                        style={{ height: "100%", top: "100%" }}
                                      >
                                        {getSpeciality(
                                          item.doctor_id.speciality
                                        )}
                                      </p>
                                      <div className="col-12 col-sm-12">
                                        <ul className="appointmentsList1">
                                          <li>
                                            <CalendarOutlined
                                              className="doctorIcons"
                                              twoToneColor="#1F274A"
                                            />{" "}
                                            {DaySlotFormat(item.date)}
                                          </li>
                                          <li>
                                            <ClockCircleOutlined className="doctorIcons" />{" "}
                                            {timeSlotFormat(
                                              item.time_slot_start
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 text-center submitRspn mt-md-0 mb-2 cardSection3">
                                <Link
                                  to={{
                                    pathname: "/appointment-details",
                                    state: {
                                      Details: { item },
                                      isBooking: true,
                                      doctor: `${item.doctor_id._id}`,
                                      app: "past",
                                    },
                                  }}
                                  className="appointmentsBtn"
                                >
                                  View Appointment
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="row d-flex justify-content-center">
                      <div className="offset-col-4 col-4 empmsg loader-container">
                        <p>No Past Appointments</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // useEffect(() => {
  //   getUpcomingAppointment()
  //   return() => {
  //     getUpcomingAppointmentReset()
  //   }
  // })
  // console.log(appointmentsData);
  return (
    <div className="container" style={{ marginTop: "10%" }}>
      <div className="row">
        <div className="col-sm-12 col-12 text-center mb-5">
          <Tabs
            defaultActiveKey="1"
            onChange={getTabKey}
            className="reportTabWrap"
          >
            <TabPane tab="Upcoming Appointments" key="1">
              <AppointmentsList appointments={appointmentsData} />
            </TabPane>
            <TabPane tab="Past Appointments" key="2">
              <PastAppointmentsList appointments={pastAppointmentsData} />
            </TabPane>
          </Tabs>
        </div>
      </div>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getUpcomingAppointmentState: state.getUpcomingAppointment,
});

const mapDispatchToProps = (dispatch) => ({
  getUpcomingAppointment: (params) => dispatch(getUpcomingAppointment(params)),
  getUpcomingAppointmentReset: () => dispatch(getUpcomingAppointmentReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
