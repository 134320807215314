import { call, put, takeLatest } from 'redux-saga/effects'

import {
    REGISTER,
    registerSuccess,
    registerFail
} from '../action/registerAction'

import registerApi from '../api/registerApi'

export function* registerSaga(action) {
    try {
        const response = yield call(() => registerApi(action.params))
        if (response) {
            yield put(registerSuccess(response, action))
        }
    }
    catch (e) {
        yield put(registerFail(e.response, action))
    }
}

export default function* MySaga() {
    yield takeLatest(REGISTER, registerSaga);
}