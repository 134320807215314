export const GET_APPOINTMENT_HISTORY = "GET_APPOINTMENT_HISTORY"
export const GET_APPOINTMENT_HISTORY_FAIL = "GET_APPOINTMENT_HISTORY_FAIL"
export const GET_APPOINTMENT_HISTORY_SUCCESS = "GET_APPOINTMENT_HISTORY_SUCCESS"
export const GET_APPOINTMENT_HISTORY_RESET = "GET_APPOINTMENT_HISTORY_RESET"

export const getAppointmentHistory = (params) => {
    return { type: GET_APPOINTMENT_HISTORY, params }
}
export const getAppointmentHistoryFail = (response) => {
    return { type: GET_APPOINTMENT_HISTORY_FAIL, response }
}
export const getAppointmentHistorySuccess = (response) => {
    return { type: GET_APPOINTMENT_HISTORY_SUCCESS, response }
}
export const getAppointmentHistoryReset = () => {
    return { type: GET_APPOINTMENT_HISTORY_RESET }
}