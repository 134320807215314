export const GET_DOCTOR_SEARCH = "GET_DOCTOR_SEARCH"
export const GET_DOCTOR_SEARCH_FAIL = "GET_DOCTOR_SEARCH_FAIL"
export const GET_DOCTOR_SEARCH_SUCCESS = "GET_DOCTOR_SEARCH_SUCCESS"
export const GET_DOCTOR_SEARCH_RESET = "GET_DOCTOR_SEARCH_RESET"

export const getDoctorSearch = (params) => {
    return { type: GET_DOCTOR_SEARCH, params }
}
export const getDoctorSearchFail = (response) => {
    return { type: GET_DOCTOR_SEARCH_FAIL, response }
}
export const getDoctorSearchSuccess = (response) => {
    return { type: GET_DOCTOR_SEARCH_SUCCESS, response }
}
export const getDoctorSearchReset = () => {
    return { type: GET_DOCTOR_SEARCH_RESET }
}
