import { Result } from "antd";
import {
  AppleStoreImg,
  FootContainer,
  GoogleStoreImg,
  Image,
  SocialButton,
  XCard,
  XFoot,
} from "../PatientAppointmentPayment/PatientAppointmentPaymentStyle";
import { Link } from "react-router-dom";
import CheckSvg from "./CheckSvg";
const PatientAppointmentPaymentSuccess = () => {
  return (
    <>
      <XCard
        style={{ padding: "2%" }}
        title={
          <>
            <div className="d-lg-block d-md-flex d-sm-flex justify-content-center">
              <div>
                <img
                  src="/images/lilac-insights-logo.svg"
                  alt="no"
                  class="sc-jSgvzq"
                />
              </div>
            </div>
          </>
        }
      >
        <>
          <Result
            icon={<CheckSvg/>}
            status="success"
            title="Thank You for completing your Payment"
          />
        </>
      </XCard>
      <FootContainer className="footerWrap">
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-6 col-12">
              <div>
                <Image
                  src={
                    process.env.PUBLIC_URL +
                    "/images/lilac-insights-logo (1) 1.svg"
                  }
                  alt="no"
                />
              </div>
              <h1 level={3} className="compy">
                Lilac Insights Pvt. Ltd.
              </h1>
              <p className="footerPara1">
                301-302 Rupa Solitaire Premises Co-Op Society Ltd., Sector-1,
                Building A -1, Millennium Business Park, MIDC. Navi Mumbai
                400710, Maharashtra, India
              </p>
            </div>
            <div className="col-sm-6 col-12 d-sm-flex justify-content-end">
              <SocialButton className="socialButton">
                <Link
                  to={{
                    pathname:
                      "https://play.google.com/store/apps/details?id=com.lilacinsights.healthcare",
                  }}
                  target="_blank"
                >
                  <GoogleStoreImg
                    className="img-fluid svapp"
                    src="../images/GoogleStore.svg"
                    alt="no"
                  />
                </Link>
                <Link
                  to={{ pathname: "https://apps.apple.com/in/app/" }}
                  target="_blank"
                >
                  <AppleStoreImg
                    className="img-fluid svapp"
                    src="../images/AppleStore.svg"
                    alt="no"
                  />
                </Link>
              </SocialButton>
            </div>
          </div>
          <hr className="divderHr" />
          <XFoot className="mt-3">
            Copyright Lilac Insights, All Rights Reserved.
          </XFoot>
        </div>
        <br />
      </FootContainer>
    </>
  );
};

export default PatientAppointmentPaymentSuccess;
