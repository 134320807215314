import React from "react"

/* CUSTOM COMPONENTS */
import { Container } from "./PageContainerStyle"


const PageContainer = props => {
    return (
        <Container>{props.children}</Container>
    )
}

export default PageContainer