import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavBlock = styled.div``;
export const LeftBlock = styled.div``;

export const RightBlock = styled.div``;

export const Image = styled.img``;

export const NavLink = styled(Link)``;

export const NavLinkL = styled(Link)`
  @media (max-width: 768px) {
    img {
      width: 50%;
    }
  }
  
`;
