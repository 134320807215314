import styled from "styled-components";

export const Styled = styled.div`
  width: 100%;
  position: relative;
  label.ant-checkbox-wrapper {
    display: block;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 0px 15px;
    .ant-checkbox {
      display: none;
    }
    ${
      "" /* > span:first-child {
      height: max-content;
      border-radius: 40px;
      box-shadow: 1px 6px 22px #ede8ed;
      width: 100%;
      display: none;
    }
    > span:last-child {
      width: auto;
      height: max-content;
      border-radius: 40px;
      box-shadow: 1px 6px 22px #ede8ed;
      border: none;
    } */
    }
  }

  label.ant-checkbox-wrapper-checked {
    border: 1px solid #cfb8d3;
    border-radius: 10px;
    background: #cfb8d3;
  }
`;
export const SuccessModal = styled.div`
  h6 {
    margin-bottom: 30px;
  }
  p {
    display: flex;
  }
  p span {
    margin-left: auto;
  }
`;
export const BookingHeader = styled.div`
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
  border-radius: 20px;
  padding: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  /* identical to box height */

  color: #1f274a;
  @media (max-width: 768px) {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
    border-radius: 20px;
    padding: 30px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height */

    color: #1f274a;
  }
`;
export const ContainsBooking = styled.div`
  box-sizing: border-box;
  left: 140px;
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
  border-radius: 20px;
`;
