import axios from 'axios'
import config from '../config'
import {getPatientToken} from '../utils'

const saveArticleBookmarkApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}article/bookmark`,
        method: "post",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`
        }
    })

    return response

}

export default saveArticleBookmarkApi