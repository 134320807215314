export const GET_APPOINTMENT_DETAILS = "GET_APPOINTMENT_DETAILS"
export const GET_APPOINTMENT_DETAILS_FAIL = "GET_APPOINTMENT_DETAILS_FAIL"
export const GET_APPOINTMENT_DETAILS_SUCCESS = "GET_APPOINTMENT_DETAILS_SUCCESS"
export const GET_APPOINTMENT_DETAILS_RESET = "GET_APPOINTMENT_DETAILS_RESET"

export const getAppointmentDetails = (params) => {
    return { type: GET_APPOINTMENT_DETAILS, params }
}
export const getAppointmentDetailsFail = (response) => {
    return { type: GET_APPOINTMENT_DETAILS_FAIL, response }
}
export const getAppointmentDetailsSuccess = (response) => {
    return { type: GET_APPOINTMENT_DETAILS_SUCCESS, response }
}
export const getAppointmentDetailsReset = () => {
    return { type: GET_APPOINTMENT_DETAILS_RESET }
}