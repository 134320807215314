import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_PATIENT_INTAKE_FORM,
    savePatientIntakeFormFail,
    savePatientIntakeFormSuccess
} from '../action/savePatientIntakeFormAction'

// api 
import savePatientIntakeFormApi from '../api/savePatientIntakeFormApi'

export function* savePatientIntakeFormSaga(action) {
    try {
        const response = yield call(() => savePatientIntakeFormApi(action.params))
        if (response.data.status === 1) {
            yield put(savePatientIntakeFormSuccess(response, action))
        } else {
            yield put(savePatientIntakeFormFail(response, action))
        }
    }
    catch (e) {
        yield put(savePatientIntakeFormFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_PATIENT_INTAKE_FORM, savePatientIntakeFormSaga)
}