export const VALIDATE_OTP = 'VALIDATE_OTP'
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS'
export const VALIDATE_OTP_FAIL = 'VALIDATE_OTP_FAIL'

export const validateOtp = (params) => {
    return { type: VALIDATE_OTP, params }
}

export const validateOtpSuccess = (response) => {
    return { type: VALIDATE_OTP_SUCCESS, response }
}

export const validateOtpFail = (response) => {
    return { type: VALIDATE_OTP_FAIL, response }
}