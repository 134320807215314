export const GET_PATIENT_DETAIL_FOR_INTAKE_FORM = "GET_PATIENT_DETAIL_FOR_INTAKE_FORM"
export const GET_PATIENT_DETAIL_FOR_INTAKE_FORM_FAIL = "GET_PATIENT_DETAIL_FOR_INTAKE_FORM_FAIL"
export const GET_PATIENT_DETAIL_FOR_INTAKE_FORM_SUCCESS = "GET_PATIENT_DETAIL_FOR_INTAKE_FORM_SUCCESS"
export const GET_PATIENT_DETAIL_FOR_INTAKE_FORM_RESET = "GET_PATIENT_DETAIL_FOR_INTAKE_FORM_RESET"

export const getPatientDetailForIntakeForm = (params) => {
    return { type: GET_PATIENT_DETAIL_FOR_INTAKE_FORM, params }
}

export const getPatientDetailForIntakeFormSuccess = (response) => {
    return { type: GET_PATIENT_DETAIL_FOR_INTAKE_FORM_SUCCESS, response }
}

export const getPatientDetailForIntakeFormFail = (response) => {
    return { type: GET_PATIENT_DETAIL_FOR_INTAKE_FORM_FAIL, response }
}

export const getPatientDetailForIntakeFormReset = () => {
    return { type: GET_PATIENT_DETAIL_FOR_INTAKE_FORM_RESET }
}