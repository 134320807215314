// action
import {
    GET_UPCOMING_APPOINTMENT,
    GET_UPCOMING_APPOINTMENT_FAIL,
    GET_UPCOMING_APPOINTMENT_SUCCESS,
    GET_UPCOMING_APPOINTMENT_RESET
} from '../action/getUpcomingAppointmentAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_UPCOMING_APPOINTMENT:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_UPCOMING_APPOINTMENT_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_UPCOMING_APPOINTMENT_SUCCESS: 
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
            }
        case GET_UPCOMING_APPOINTMENT_RESET:
            return initialState
        default:
            return {
                ...state
            }
    }
}