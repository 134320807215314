import axios from 'axios'
import config from '../config'
import { getPatientToken } from '../utils'

const bookedAppointmentActivityApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}book-appointment/appointment-call-activity`,
        method: "post",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getPatientToken()}`

        }
    })
    return response
}

export default bookedAppointmentActivityApi