import React, { useEffect, useState } from "react";
import { Typography, Button, Space, Spin } from "antd";
import { connect } from "react-redux";
import SupportBlock from "../../component/SupportBlock/SupportBlock";
import { TribeModal } from "../../component/TribeModal/TribeModal";
import {
  getTribeArticleList,
  getTribeArticleListReset,
} from "../../action/getTribeArticleListAction";

// CSS
import {
  HomeTopBannerMobile,
  PageWrap,
  SupportTribeSelect,
  SupportTribeTitle,
  XButton,
  TribeImg,
} from "./SupportTribeStyled";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./SupportTribe.css";
const SupportTribe = (props) => {
  //CONSTANTS
  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  let week = JSON.parse(localStorage.getItem("week"));
  const showModal = () => {
    setIsModalVisible(true);
  };
  let page_week = localStorage.getItem("week");
  const {
    getTribeArticleList,
    getTribeArticleListReset,
    getTribeArticleState,
  } = props;
  let tribe = JSON.parse(localStorage.getItem("tribe"));
  const paramsAL = {
    tribes: tribe,
  };
  // console.log(props)
  const [isAvailable, setisAvailable] = useState({
    audio: 0,
    video: 0,
    text: 0,
  });
  const [loading, setLoading] = useState(false);
  //CALLBACKS
  useEffect(() => {
    getTribeArticleList(paramsAL);
    return () => {
      getTribeArticleListReset();
    };
  }, []);

  useEffect(() => {
    if (getTribeArticleState.apiState === "loading") {
      setLoading(true);
    } else if (getTribeArticleState.apiState === "success") {
      if (getTribeArticleState.data.video.length > 0) {
        setisAvailable({ video: getTribeArticleState.data.video.length });
      }
      if (getTribeArticleState.data.text.length > 0) {
        setisAvailable({ text: getTribeArticleState.data.text.length });
      }
      if (getTribeArticleState.data.audio.length > 0) {
        setisAvailable({ audio: getTribeArticleState.data.audio.length });
      }
      setLoading(false);
    }
  }, [getTribeArticleState]);

  let img = [];
  for (let i = 0; i < tribe.length; i++) {
    if (tribe[i] === "Depression Support") {
      img.push("DCat");
    } else if (tribe[i] === "Anxiety Support") {
      img.push("anxiety");
    } else if (tribe[i] === "Grief Support") {
      img.push("grief");
    } else if (tribe[i] === "Infertility Support") {
      img.push("infer");
    } else if (tribe[i] === "Perinatal Loss Support") {
      img.push("perin");
    } else if (tribe[i] === "Postpartum Support") {
      img.push("post");
    } else if (tribe[i] === "Queer Support") {
      img.push("queer");
    }
  }

  // console.log(getTribeArticleState.data.text, "text data");
  return (
    <PageWrap>
      <div className="container">
        <HomeTopBannerMobile
          alt="right-bg"
          $mode="doctorRightBg1"
          src="./images/svg/doctorbgRight.svg"
        />
        <HomeTopBannerMobile
          alt="right-bg"
          $mode="doctorLeftBg"
          src="./images/svg/doctorbgLeft.svg"
        />
        <SupportTribeTitle className="mb-2 text-center">
          Support Tribe
        </SupportTribeTitle>
        <div className="row mb-5">
          <div className="col-12 col-sm-12 supportTribeTextAlign">
            <Space>
              {tribe.length ? (
                <>
                  {img.map((item) => (
                    <TribeImg
                      className="mb-sm-0"
                      src={process.env.PUBLIC_URL + `/images/${item}.svg`}
                      alt="icon-img"
                    />
                  ))}
                </>
              ) : (
                <></>
              )}
            </Space>
            <Space className="ml-3">
              <button className="Supportbtn mt-3 mb-sm-0" onClick={showModal}>
                <img className="mr-2" src="./images/Marker.svg" />
                Select Tribe
              </button>
              <TribeModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
              />
            </Space>
          </div>
        </div>
        <SupportTribeTitle className="mt-1">Curated For You</SupportTribeTitle>
        <div className="row">
          {loading ? (
            <Spin spinning={loading} tip="Loading Blogs..." />
          ) : getTribeArticleState.data.text ? (
            <>
              {getTribeArticleState.data.text.length > 0 ? (
                <div className="row">
                  {getTribeArticleState.data.text.map((item, i) => (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-1" key={i}>
                      <SupportBlock block_index={i} article={item} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center">
                  Sorry No Articles available for these settings
                </p>
              )}
              {getTribeArticleState.data.text.length > 0 && (
                <div className="col-12 mt-1 ml-auto mr-auto">
                  <Link
                    to={{
                      pathname: "/tribe-articles",
                      state: { type: "text" },
                    }}
                  >
                    <XButton type="primary" size="small">
                      View More
                    </XButton>
                  </Link>
                </div>
              )}
            </>
          ) : (
            <p className="text-center">
              Sorry No Articles available for these settings
            </p>
          )}
        </div>{" "}
        <br />
        <br />
        <SupportTribeTitle className="mt-1">
          Videos For The Week
        </SupportTribeTitle>
        <div className="row">
        {loading ? (
            <Spin spinning={loading} tip="Loading Blogs..." />
          ) : getTribeArticleState.data.video ? (
            <>
              {getTribeArticleState.data.video.length > 0 ? (
                <div className="row">
                  {getTribeArticleState.data.video.map((item, i) => (
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-1" key={i}>
                      <SupportBlock block_index={i} article={item} />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-center">
                  Sorry No Articles available for these settings
                </p>
              )}
              {getTribeArticleState.data.video.length > 0 && (
                <div className="col-12 mt-1 ml-auto mr-auto">
                  <Link
                    to={{
                      pathname: "/tribe-articles",
                      state: { type: "text" },
                    }}
                  >
                    <XButton type="primary" size="small">
                      View More
                    </XButton>
                  </Link>
                </div>
              )}
            </>
          ) : (
            <p className="text-center">
              Sorry No Articles available for these settings
            </p>
          )}
        </div>
        <br />
        <br />
        {/* <div className="row">
          {loading ? (
            <Spin spinning={loading} tip="Loading Audios..." />
          ) : getTribeArticleState.data.audio ? (
            <>
              {getTribeArticleState.data.audio.length > 0 ? (
                <>
                  <h1 className="heading-home mb-1">Audios for the Week</h1>
                  {getTribeArticleState.data.audio.length > 1 ? (
                    <>
                      <div className="col-sm-6" style={{ marginTop: "1.5rem" }}>
                        <SupportBlock
                          block_index={0}
                          article={getTribeArticleState.data.audio[0]}
                        />
                      </div>
                      <div className="col-sm-6" style={{ marginTop: "1.5rem" }}>
                        <SupportBlock
                          block_index={1}
                          article={getTribeArticleState.data.audio[1]}
                        />
                      </div>
                      <Link
                        to={{
                          pathname: "/tribe-articles",
                          state: { type: "audio" },
                        }}
                      >
                        <XButton type="primary" size="small">
                          Read More
                        </XButton>
                      </Link>
                    </>
                  ) : (
                    <div className="col-sm-6" style={{ marginTop: "1.5rem" }}>
                      <SupportBlock
                        block_index={0}
                        article={getTribeArticleState.data.audio[0]}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </>
          ) : (
            <p className="text-center">
              Sorry No Articles available for these settings
            </p>
          )}
        </div>
        <br />
        <br /> */}
        {/* </> : null} */}
      </div>
    </PageWrap>
  );
};

const mapStateToProps = (state) => ({
  getTribeArticleState: state.getTribeArticleList,
});

const mapDispatchToProps = (dispatch) => ({
  getTribeArticleList: (params) => dispatch(getTribeArticleList(params)),
  getTribeArticleListReset: () => dispatch(getTribeArticleListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportTribe);
