/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Typography,
  Button,
  Alert,
  Modal,
  notification,
} from "antd";
import { FacebookFilled, GooglePlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Redirect, Navigate } from "react-router-dom";

// import OtpInput from "react-otp-input";
/* CUSTOM COMPONENT */
import {
  Container,
  Content,
  Left,
  Right,
  LoginInput,
  Xlink,
  OTPInput,
} from "./AboutUsStyle";

/* ACTIONS */
import "./AboutUs.css";
const AboutUs = (props) => {
  const { Title } = Typography;

  return (
    <div>
      <div
        className="container-fluid"
        style={{
          paddingleft: "1em !important",
          paddingRight: "1em !important",
        }}
      >
        <div className="row pl-lg-5 mt-3">
          <h2 className="main-heading">What We Do ?</h2>
          <p className="text extra-margin">
            The field of Genetics is poised to become the cornerstone of
            medicine as we know it. In the decade to come we will witness a
            startling transformation in the way we manage diseases and their
            therapies. Much of this transformation will account due to ground
            breaking advances in clinical and human genetics happening today.
            This has opened the doors to new business opportunities that lie
            completely untapped and unseen. One such opportunity is Genetic
            Diagnosis.
          </p>
          <br />
          <h4 className="sub-heading">
            Lilac Insights, Leading Genetic testing company in India in the
            field of
          </h4>
          <ul className="text list extra-margin">
            <li>Reproductive Genetics</li>
            <li>Cancer Genetics</li>
            <li>Genetic Consultation & Counseling</li>
            <li>Metabolic Genetics</li>
            <li>Fetal Autopsy</li>
          </ul>
          <h6 className="small-heading">
            Lilac Insights is widely regarded as the pioneer in the field of
            Reproductive Genetics & Cancer Genetics in India. From introducing
            Prenatal genetic screening and diagnosis to cutting edge cancer
            genetics program, Lilac Insights has always been ahead of the curve.
          </h6>
          <h6 className="small-heading extra-margin">
            We were among the 1st few companies to follow the guidelines and
            protocols set by internationally renowned organization like Fetal
            Medicine Foundation (UK) & National Health Service (UK) in India and
            successfully shape the genetic screening and diagnostics industry of
            the country.
          </h6>
          <p className="text">
            In the field of Reproductive Genetics, Lilac Insights deals with
            genetic disorders in a threefold process:
          </p>
          <ul
            className="text list extra-margin"
          >
            <li>
              We conduct various specialized genetic screenings to assess the
              risk of a fetus, newborn baby or an individual (mostly couples)
              suffering from genetic abnormalities, disorders & diseases.
            </li>
            <li>
              Genetic screening, testing & diagnoses differs from ordinary
              pathology in not just the process, expertise & technology
              required, but the resultant reports are not simple computer
              outputs, hence require expert intervention & interpretation, which
              Lilac Insights is a market leader at.
            </li>
            <li>
              We counsel doctors, clinicians & families to further their
              understanding of complex disorders to help them make confident
              decisions.
            </li>
          </ul>
          <p>
            Lilac Insights has developed high-end Cancer Genetic testing panels
            in the disease areas of
          </p>
          <ul
            className="text list extra-margin"
          >
            <li>
              Solid Tumors (i.e., Breast Cancer, Lung Cancers, Colorectal
              Cancers, Gastrointestinal Stromal tumors, etc.)
            </li>
            <li>
              Hematological Malignancies (Blood Cancers i.e., Acute
              Lymphoblastic Leukemia, Acute Myeloid Leukemia, B & T – Cell
              Lymphoma, Chronic Eosinophilic Leukemia, Chronic Myeloid Leukemia,
              Chronic Lymphocytic Leukemia, Hairy Cell Leukemia, etc.)
            </li>
            <li>
              Sarcomas
            </li>
          </ul>
          <p className="text extra-margin">
            In the year 2019, we started focussing more on Clinical Genetics and
            its impact on human health. The same year we started the first of
            its kind clinical and metabolic genetics facility-The Sujanan Clinic
            at Pune with an aim to help in achieving Good Birth, Good Life. The
            program is headed by Dr. Chaitanya Datar, who is a Clinical and
            Metabolic Geneticist. He is ably supported by a team of Clinical
            Geneticist, Metabolic Dietitian and BGCI certified Genetic
            Counsellors backed by a state-of-the-art diagnostic facility. It is
            India’s only private company run program to have such a
            comprehensive team helping clinicians and patients to get complete
            care from diagnosis to management. The program deals with Metabolic
            Genetics, Newborn Screening (NBS), Fetal Autopsy, and Genetic
            Counseling & Consultation.
          </p>
          <p className="text extra-margin">
            Our genetic test reports help for the appropriate prognostication of
            the disease thereby, help to choose the appropriate treatment
            confidently on standard chemotherapy, targeted therapy,
            immunotherapy or palliative care.
          </p>
          <p className="text">
            At Lilac Insights, we are also looking to expand our services and
            provide the benefits of growing Genomic tests to the clinicians and
            patients. We will be soon introducing new genomic tests in different
            therapeutic segments to provide advanced and accurate diagnostic
            solutions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
