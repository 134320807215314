import moment from "moment"
import config from "./config"

export const getPatientToken = () => {
    return localStorage.getItem('LilacPatientToken') || null
}

export const getPatient = () => {
    return JSON.parse(localStorage.getItem('LilacPatient')) || null
}

export const isLogin = () => {
    const LilacPatientToken = localStorage.getItem('LilacPatientToken')
    if (LilacPatientToken) return true
    else return false
}

export const timeSlotFormat = (time) => {
    return moment(time, "HH:mm:ss").format("hh:mm A")
}

export const DaySlotFormat = (time) => {
    // return moment(new Date(time).toUTCString()).format("DD MMM YYYY")
    return moment.utc(time).format("DD MMM YYYY");
}

export const dateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY")
}

export const validateImagePdfFile = (file) => {
    let ext
    if (file.type === "image/jpeg") ext = "jpeg"
    if (file.type === "image/png") ext = "png"
    if (file.type === "application/pdf") ext = "pdf"

    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}

export const validateImageFile = (file) => {
    let ext
    if (file.type === "image/jpeg") ext = "jpeg"
    if (file.type === "image/png") ext = "png"

    if (ext) {
        return { status: true, ext: ext }
    } else {
        return { status: false }
    }
}

export const getS3SingedUrl = async (filename, ext, file, foldername) => {
    return new Promise((resolve, reject) => {
        fetch(`${config.api.base_url}s3/get-singed-url?filename=${filename}&ext=${ext}&folder_name=${foldername}`, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                // "authorization": `bearer ${getUserToken()}`
            }
        }).then(res => {
            const result = res.json();
            return Promise.all([result]);
        }).then((result) => {
            resolve({ ['url']: result[0].url, ['filename']: `${filename}.${ext}` })
        }).catch(console.log)
    })
}
export const s3_cdn = () => {
    return `https://lilac-dtrf-attactments.s3.ap-south-1.amazonaws.com/`
}

export const convertTZ = (date, tzString) => {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

export const dateTimeUTCFormat = (date) => {
    return moment(date).utc().format("DD/MM/YYYY hh:mm A")
}

export const weekDays = () => {
    return ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
}

export const nameValidation = (value) => {
    var regex = /^[a-zA-Z ]{2,30}$/;
    return regex.test(value);
}

export const phoneValidation = (value) => {
    var regex = /^[6-9]\d{9}$/;
    return regex.test(value)
}