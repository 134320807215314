import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_WATCH,
    getWatchFail,
    getWatchSuccess
} from '../action/getWatchAction'

// api 
import getWatchApi from '../api/getWatchApi'

export function* getWatchSaga(action){

    try{
        const response = yield call(()=> getWatchApi(action.params))
        if(response.data.status ===1){
            yield put(getWatchSuccess(response, action))
        }else
        {
            yield put(getWatchFail(response, action))
        }
    }
    catch(e){
        yield put(getWatchFail(e.response, action))
    }
}

export default function* mySaga(){
    yield takeLatest(GET_WATCH, getWatchSaga)
}