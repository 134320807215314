import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const HomeCuratedPara = styled.p`
    font-family : Poppins;
    font-size: 18px;
    line-height: 24px;
    color: #1F274A;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        text-align: center;
    } 
`
export const CuratedTitle = styled.h1`
    width: 100%;
    position: relative;
    font-family : Poppins;
    font-size: 34px;
    line-height: 32px;
    color: #1F274A;
    font-weight: 600;
    margin-top:2%;
    margin-bottom: 48px;
    @media (max-width: 768px) {
        font-size: 24px;
    } 
`
export const ArticleTitle = styled.h1`
    width: 100%;
    position: relative;
    font-family : Poppins;
    font-size: 24px;
    line-height: 32px;
    color: #1F274A;
    font-weight: 600;
    // margin-top:2%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
        font-size: 24px;
    } 
`
export const XLink = styled(Link)`
    height: 40px;
    line-height:20px;
    padding: 6px 36px 10px 36px;
    font-size: 16px;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background: linear-gradient(94.68deg, #d487d4 0%, #a54ea5 100%);;
    color:#FFFFFF;
    border: none;
    text-decoration:none;
    :hover{
        background: #A64FA6;
        color: #FFFFFF;
    }
    @media (max-width: 768px) {
        margin-left: 65px;
    } 
`
export const CuratedImg = styled.img`
    width: 90%;
    border-radius: 30px;
    // padding: 3%;
	// max-height: 250px;
    object-fit:cover;
    object-position: center;
    @media (max-width: 768px) {
        width: 212px;
        height: 160px;
    } 
`
