export const GET_UPCOMING_APPOINTMENT = "GET_UPCOMING_APPOINTMENT"
export const GET_UPCOMING_APPOINTMENT_FAIL = "GET_UPCOMING_APPOINTMENT_FAIL"
export const GET_UPCOMING_APPOINTMENT_SUCCESS = "GET_UPCOMING_APPOINTMENT_SUCCESS"
export const GET_UPCOMING_APPOINTMENT_RESET = "GET_UPCOMING_APPOINTMENT_RESET"

export const getUpcomingAppointment = (params) => {
    return { type: GET_UPCOMING_APPOINTMENT, params }
}
export const getUpcomingAppointmentFail = (response) => {
    return { type: GET_UPCOMING_APPOINTMENT_FAIL, response }
}
export const getUpcomingAppointmentSuccess = (response) => {
    return { type: GET_UPCOMING_APPOINTMENT_SUCCESS, response }
}
export const getUpcomingAppointmentReset = () => {
    return { type: GET_UPCOMING_APPOINTMENT_RESET }
}