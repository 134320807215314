import styled from "styled-components";
import { Modal, Radio, Button } from "antd";
import { Typography, Form, DatePicker } from "antd";

export const XModal = styled(Modal)` 
    .ant-modal-content {
        border-radius: 22px;
    }
`

export const CouponCard = styled.div`
// boxShadow: "0px 8px 18px rgba(202, 212, 226, 0.3)";
padding: 10px;
// background-color: blue;
border-radius: 8px;
// height: 200px;
margin:10px;

`
export const GiftHeader = styled.div`
box-sizing: border-box;

height: 100px;
left: 140px;
top: 150px;

background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
`
export const GiftTitle = styled.div`

padding: 30px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 45px;
/* identical to box height */


color: #1F274A;

@media (max-width: 768px) {

padding: 0px;
padding-left: 20px;
padding-top: 10px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
/* identical to box height */


color: #1F274A;
}
`

export const Giftleft = styled.div`
box-sizing: border-box;

// height:60%;
margin-top:40px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;

@media (max-width: 991px) {
box-sizing: border-box;
// height:93%;
margin-top:40px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
}
`
export const GiftRight = styled.div`
box-sizing: border-box;


height:60%;

margin-top:40px;
margin-bottom:40px;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 30px rgba(202, 212, 226, 0.3);
border-radius: 20px;
    overflow: scroll;
`