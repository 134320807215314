import React, { useState, useEffect } from 'react'
import { Skeleton } from 'antd';
import { connect } from 'react-redux'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { getArticleBySlug, getArticleBySlugReset } from '../../action/getArticleBySlugAction'
import { saveArticleLike, saveArticleLikeReset } from '../../action/saveArticleLikeAction'
import { saveArticleBookmark, saveArticleBookmarkReset } from '../../action/saveArticleBookmarkAction' 
import moment from 'moment';
//COMPONENTS
import Header from '../../component/Header/Header'
import { HeartTwoTone, EyeOutlined, HeartFilled } from '@ant-design/icons';
import {
   Title, Title2, BoxWrap, Title3, HeadRate, Para, AidoPlayerWrap, Ximg
} from "./AudioArticleStyle"
import config from '../../config';

//STYLE

export const AudioArticle = (props) => {

    const { getArticleBySlug, getArticleBySlugReset, getArticleBySlugState, saveArticleLike, saveArticleLikeReset, saveArticleBookmark, saveArticleBookmarkReset } = props
    const slug = props.match.params.slug
    const [articleParams, getArticleParams] = useState({
        "slug": slug
    })
    const [click, setclick] = useState({
        liked: false,
        bookmark: false
    })
    const [ArticleData, setArticleData] = useState({
        "id": '',
        "title": '',
        "Description": '',
        "img": '',
        "views": '',
        "likes": '',
    })

    //FUNCTIONS
    const toggle = (value) => {
        console.log(value,"i was clicked")
        if (value === 'liked' && localStorage.getItem("LilacPatientToken")) {
            let clicked = click.liked;
            const data = {
                "article_id" : ArticleData.id
            }
            clicked = !clicked;
            if (clicked === true) {
                ArticleData.likes = ArticleData.likes + 1
                saveArticleLike(data)
            }
            else {
                ArticleData.likes = ArticleData.likes - 1
                saveArticleLike(data) 
            }
            setclick({...click, ['liked']: clicked }); 
        }
        else if (value === 'bookmark' && localStorage.getItem("LilacPatientToken")) {
            let clicked1 = click.bookmark;
            clicked1 = !clicked1;
            const data1 = {
                "article_id" : ArticleData.id
            }
            saveArticleBookmark(data1)
            setclick({...click, ['bookmark']: clicked1 }); 
        } 
    };

    // console.log(click)
    //CALLBACKS
    useEffect(() => {
        window.scrollTo(0,0)
        getArticleBySlug(articleParams)
        return () => {
            getArticleBySlugReset()
        }
    }, []);

    useEffect(() => {
        if (getArticleBySlugState.apiState === "success") {
            // console.log(getArticleBySlugState)
            setArticleData({
                ['id']: getArticleBySlugState.data._id,
                ['title']: getArticleBySlugState.data.title,
                ['Description']: getArticleBySlugState.data.description,
                ['img']: getArticleBySlugState.data.image_path,
                ['views']: getArticleBySlugState.data.views,
                ['likes']: getArticleBySlugState.data.likes,
            })
            setclick({
                ['liked']: false,
                ['bookmark']: props.location.state.is_bookmarked
            })
        }
    }, [getArticleBySlugState])

    // console.log(getArticleBySlugState)

    // console.log(props)
    return (
        <div className='pageWrap' style={{background:"#fafaff"}}>
            <div className='container'>
                <div class="row">
                    <div className='col-sm-4'>
                        <div style={{ boxShadow: "0px 8px 18px rgba(202, 212, 226, 0.3)", padding: "10px"}}>
                        <Ximg width='100%' className="img-fluid" src={`${config.api.s3_url}article/`+getArticleBySlugState.data.image_path} alt="icon-img" />
                        <AidoPlayerWrap>
                            <div class="row">
                                <div className='col-12'>
                                    <AudioPlayer style={{background:"none", boxShadow:"none"}}
                                        autoPlay={false}
                                        src={`${config.api.s3_url}article/`+getArticleBySlugState.data.audio_path}
                                        // onPlay={e => console.log("onPlay")}
                                    />
                                </div>
                            </div>                            
                        </AidoPlayerWrap>
                        </div>
                    </div>   
                    <div className='col-sm-8'>
                        <div class="row">
                            <div className='col-sm-12'>
                                <Title className="mb-2">{getArticleBySlugState.data.title}</Title>
                            </div> 
                        </div>
                        <div class="row mt-3 mb-3">
                            <div className='col-sm-6 col-6'>
                                <HeadRate>
                                <span className='mr-2'>{moment(getArticleBySlugState.data.createdAt).format("MMM Do YY")}</span> 
                                <span style={{fontSize:'0.5rem', marginTop:'-5px'}}><i class="fa fa-circle mr-2" aria-hidden="true"></i></span>
                                <EyeOutlined style={{ fontSize: "20px" }} /> <span> {getArticleBySlugState.data.views} views</span>
                                </HeadRate>
                            </div>   
                            <div className='col-sm-6 col-6 text-right' style={{verticalAlign: "0"}}>
                                {click.liked
                                    ?
                                    <HeartFilled onClick={() => toggle('liked')} style={{ color: '#eb2f96', fontSize: '1.5rem', verticalAlign: "0" }} />
                                    :
                                    <HeartTwoTone onClick={() => toggle('liked')} style={{fontSize: '1.5rem', verticalAlign: "0" }} twoToneColor="#eb2f96" />
                                }  
                                <i style={{fontSize:'1.5rem', color:'#1F274A'}} class="fa fa-share listIcons" aria-hidden="true"></i>
                                {click.bookmark
                                    ?
                                    <><i class="fa fa-bookmark listIcons" onClick={() => toggle('bookmark')}></i></>
                                    :
                                    <><i class="fa fa-bookmark-o listIcons" onClick={() => toggle('bookmark')}></i></>
                                }
                            </div> 
                        </div>
                        <div class="row">
                            <div className='col-sm-12'>
                                <Title2 className='mt-3 mb-4'>{getArticleBySlugState.data.short_description}</Title2>
                                <Para>
                                <p dangerouslySetInnerHTML={{ __html: decodeURIComponent(getArticleBySlugState.data.description) }} />
                                </Para>
                            </div> 
                        </div>                         
                    </div> 
                </div>
                <div class="row mt-5">
                    <div className='col-sm-4 mb-5'>
                        <BoxWrap>
                            <img className="img-fluid" width="100%" src={process.env.PUBLIC_URL + "/images/audio-1.png"} alt="icon-img" />
                            <Title3 className='mt-3 mb-2 pl-3 pr-3'>The Illustrated guide shows you how to take care of your Baby</Title3>
                            <span>30 mins</span> 
                            <span style={{fontSize:'0.5rem', marginTop:'-5px'}}><i class="fa fa-circle" aria-hidden="true"></i></span>
                            <span><i class="fa fa-eye" aria-hidden="true"></i> 28 views</span> 
                        </BoxWrap>
                    </div>
                    <div className='col-sm-4 mb-5'>
                        <BoxWrap>
                            <img className="img-fluid" width="100%" src={process.env.PUBLIC_URL + "/images/audio-2.png"} alt="icon-img" />
                            <Title3 className='mt-3 mb-2 pl-3 pr-3'>The Illustrated guide shows you how to take care of your Baby</Title3>
                            <span>30 mins</span> 
                            <span style={{fontSize:'0.5rem', marginTop:'-5px'}}><i class="fa fa-circle" aria-hidden="true"></i></span>
                            <span><i class="fa fa-eye" aria-hidden="true"></i> 28 views</span> 
                        </BoxWrap>
                    </div>
                    <div className='col-sm-4 mb-5'>
                        <BoxWrap>
                            <img className="img-fluid" width="100%" src={process.env.PUBLIC_URL + "/images/audio-2.png"} alt="icon-img" />
                            <Title3 className='mt-3 mb-2 pl-3 pr-3'>The Illustrated guide shows you how to take care of your Baby</Title3>
                            <span>30 mins</span> 
                            <span style={{fontSize:'0.5rem', marginTop:'-5px'}}><i class="fa fa-circle" aria-hidden="true"></i></span>
                            <span><i class="fa fa-eye" aria-hidden="true"></i> 28 views</span> 
                        </BoxWrap>
                    </div>
                </div>            
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    getArticleBySlugState: state.getArticleBySlug,
    saveArticleLikeState: state.saveArticleLike,
    saveArticleBookmarkState: state.saveArticleBookmark,
});

const mapDispatchToProps = (dispatch) => ({
    getArticleBySlug: (params) => dispatch(getArticleBySlug(params)),
    getArticleBySlugReset: () => dispatch(getArticleBySlugReset()),
    saveArticleLike: (params) => dispatch(saveArticleLike(params)),
    saveArticleLikeReset: () => dispatch(saveArticleLikeReset()),
    saveArticleBookmark: (params) => dispatch(saveArticleBookmark(params)),
    saveArticleBookmarkReset: () => dispatch(saveArticleBookmarkReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioArticle )
