export const GET_ARTICLE_BY_SLUG = "GET_ARTICLE_BY_SLUG";
export const GET_ARTICLE_BY_SLUG_FAIL = "GET_ARTICLE_BY_SLUG_FAIL";
export const GET_ARTICLE_BY_SLUG_SUCCESS = "GET_ARTICLE_BY_SLUG_SUCCESS";
export const GET_ARTICLE_BY_SLUG_RESET = "GET_ARTICLE_BY_SLUG_RESET";

export const getArticleBySlug = (params) => {
  console.log(
    "getArticleBySlug------------------------------------------------------------------------------------------"
  );
  return { type: GET_ARTICLE_BY_SLUG, params };
};
export const getArticleBySlugFail = (response) => {
  return { type: GET_ARTICLE_BY_SLUG_FAIL, response };
};
export const getArticleBySlugSuccess = (response) => {
  return { type: GET_ARTICLE_BY_SLUG_SUCCESS, response };
};
export const getArticleBySlugReset = () => {
  return { type: GET_ARTICLE_BY_SLUG_RESET };
};
