// action
import {
    GET_SCHEDULE_TIMING_LIST,
    GET_SCHEDULE_TIMING_LIST_FAIL,
    GET_SCHEDULE_TIMING_LIST_SUCCESS,
    GET_SCHEDULE_TIMING_LIST_RESET,
  } from "../action/getScheduleTimeListAction";
  
  import { api_err_msg } from "../strings.json";
  
  // initialState
  const initialState = {
    apiState: "",
    list: [],
    message: "",
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_SCHEDULE_TIMING_LIST:
        return {
          ...state,
          apiState: "loading",
        };
      case GET_SCHEDULE_TIMING_LIST_FAIL:
        return {
          ...state,
          apiState: "error",
          message:
            (action.response &&  action.response.data && action.response.data.message) || api_err_msg,
        };
      case GET_SCHEDULE_TIMING_LIST_SUCCESS:
        return {
          ...state,
          apiState: "success",
          list: action.response.data.data.result,
          message:(action.response && action.response.data && action.response.data.message) || api_err_msg,
        };
      case GET_SCHEDULE_TIMING_LIST_RESET:
        return initialState;
        default:
        return state;
    }
  }
  