import { call, put, takeLatest } from 'redux-saga/effects'

import {
    LOGIN,
    loginSuccess,
    loginFail
} from '../action/loginAction'

import loginApi from '../api/loginApi'

export function* loginSaga(action) {
    // console.log(action)
    // alert("sdsads")
    try {
        const response = yield call(() => loginApi(action.params))
        if (response) {
            yield put(loginSuccess(response, action))
        }
    }
    catch (e) {
        yield put(loginFail(e.response, action))
    }
}

export default function* MySaga() {
    yield takeLatest(LOGIN, loginSaga);
}