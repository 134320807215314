import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";

//COMPONENTS
import { getListen, getListenReset } from "../../action/getListenAction";
import { TopContainer } from "../DoctorAppoint/DoctorAppointStyle";
import config from "../../config";

const Listen = (props) => {
  //CONSTANTS
  const { getListen, getListenReset, getListenState } = props;
  const location = useLocation();
  let page_week = JSON.parse(localStorage.getItem("week"));

  useEffect(() => {
    window.scrollTo(0, 0);
    getListen({
      cat: location.state.category,
      week: location.state.week,
    });
  }, []);

  useEffect(() => {
    if (getListenState.apiState === "success") {
      window.scrollTo(0, 0);
      console.log("real", getListenState);
    }
  }, [getListenState]);

  console.log(getListenState);
  return (
    <>
      <TopContainer>
        <img
          className="align-center mobile-height"
          src="./images/lilac-insights-logo.svg"
          alt="exp"
        />
      </TopContainer>
      <div className="container">
        <img
          alt="right-bg"
          className="doctorRightBg1"
          src="./images/svg/doctorbgRight.svg"
        />
        <img
          alt="right-bg"
          className="doctorLeftBg"
          src="./images/svg/doctorbgLeft.svg"
        />
        <div className="pageWrap">
          {getListenState.data.length > 0 && (
            <div className="row">
              <div className="offset-sm-1 col-sm-10 col-12">
                <div className="row">
                  {getListenState.data.map((ListenData) => {
                    return (
                      <div className="col-sm-6 col-12 mb-3">
                        <div className="drReadBox">
                          <Link
                            to={{
                              pathname: "/audio/" + ListenData.slug,
                              state: {
                                is_bookmarked: ListenData.is_bookmarked,
                              },
                            }}
                            style={{ textDecoration: "none", color: "#1F274A" }}
                          >
                            <div className="row">
                              <div className="col-sm-12 col-3">
                                <img
                                  className="img-fluid drReadImgRound"
                                  src={`${config.api.s3_url}article/${ListenData.image_path}`}
                                  alt="Card image cap"
                                />
                              </div>
                              <div className="col-sm-12 col-9">
                                <div className="row">
                                  <div className="col-sm-12 col-12 mb-2">
                                    <h4 className="drReadHeading">
                                      {ListenData.title}
                                    </h4>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-sm-12 col-12">
                                    <div className="drReadComment">
                                      <img
                                        className="img-fluid"
                                        src="./images/eye.svg"
                                        alt="no"
                                      ></img>{" "}
                                      {ListenData.views} Views &nbsp; &nbsp;
                                      &nbsp;
                                      <img
                                        className="img-fluid"
                                        src="./images/heart.svg"
                                        alt="no"
                                      ></img>{" "}
                                      {ListenData.likes} likes
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getListenState: state.getListen,
});

const mapDispatchToProps = (dispatch) => ({
  getListen: (params) => dispatch(getListen(params)),
  getListenReset: () => dispatch(getListenReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listen);
