import styled from "styled-components";

export const AudioPlayerWrap = styled.div`
    // width:80%;
    height:12%;
    margin: 2px;
    margin-top:20px;
    // margin-left: 25px;
    // background-color: pink;
    boxShadow: "0px 8px 18px rgba(202, 212, 226, 0.3)"
    `
