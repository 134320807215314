export const GET_WATCH = "GET_WATCH"
export const GET_WATCH_FAIL = "GET_WATCH_FAIL"
export const GET_WATCH_SUCCESS = "GET_WATCH_SUCCESS"
export const GET_WATCH_RESET = "GET_WATCH_RESET"

export const getWatch = (params) => {
    return { type: GET_WATCH, params }
}
export const getWatchFail = (response) => {
    return { type: GET_WATCH_FAIL, response }
}
export const getWatchSuccess = (response) => {
    return { type: GET_WATCH_SUCCESS, response }
}
export const getWatchReset = () => {
    return { type: GET_WATCH_RESET }
}