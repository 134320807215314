export const VALIDATE_COUPON = 'VALIDATE_COUPON'
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS'
export const VALIDATE_COUPON_FAIL = 'VALIDATE_COUPON_FAIL'

export const validateCoupon = (params) => {
    return { type: VALIDATE_COUPON, params }
}

export const validateCouponSuccess = (response) => {
    return { type: VALIDATE_COUPON_SUCCESS, response }
}

export const validateCouponFail = (response) => {
    return { type: VALIDATE_COUPON_FAIL, response }
}