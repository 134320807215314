import React, {useState, useEffect,  createContext} from "react";
import firebase from 'firebase/compat/app'


export const UserContext = createContext({user: null})
export const auth = firebase.auth();
export default (props) => {
  const [user, setuser] = useState()
  useEffect(() => {
auth.onAuthStateChanged(async (user) => {
  if(user!=null){
    const { displayName, email }  = user;
    setuser({
      displayName,
      email
    })
  } 
  // else{
  //   // Redirect only if not already on the login page
  //   window.location.replace("/login");
  // }
  
})
  },[])
  // console.log("setuser", user)
  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  )
}