import styled from "styled-components";
import { Link } from "react-router-dom";

export const PageContainer = styled.div`
  margin-top: 120px;
  @media only screen and (max-width: 800px) {
    margin-top: 60px;
  }
`;

export const ArticleTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  color: #1F274A;
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 20px;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
  margin: 1%;
`;

export const ArticleViews = styled.div`
  gap: 8px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 1%;
  margin-right: 1%;
`;

export const LeftBlock = styled.div`
  display: flex;
  margin-left: -3%;
  text-align: left;
`;

export const ViewBlock = styled.div`
  margin-left: 10px;
  cursor: pointer;
  padding-right: 5px;
`;

export const LikeBlock = styled.div`
  cursor: pointer;
`;

export const BookmarkBlock = styled.div``;

export const RightBlock = styled.div`
  // font-size: 18px;
  display: inline-flex;
  cursor: pointer;
  text-align: right;
  right: 50px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Description = styled.div`
  text-align: justify !important;
  margin-bottom: 10px;
  word-break: break-word !important;
  padding: 1%;
`;

export const ShareBlock = styled.div`
  text-align: right;
  padding-right: 7px;
`;

export const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;
export const ContentBlock = styled.div`
  background-color: #fff;
  padding: 150px 30px 50px 30px;
  //padding: 0px 10px 50px 10px;
  margin-bottom: 50px;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export const ContentAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;
export const ShareLink = styled(Link)`
  margin: 0px 10px;
`;
export const ListHeading = styled.h1`
  position: relative;
  width: 100%;
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 35px;
  }
`;

export const TopContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin: auto;
    width: 35%;
    margin-top: 15px;
  }
`;
export const ArticleImage = styled.img`
  border-radius: 15px;
  width: 100%;
  height: 500px;
  margin-top: 15px;
`;
