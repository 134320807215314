// action
import {
    GET_PREG_HIGHLIGHT_APP_LIST,
    GET_PREG_HIGHLIGHT_APP_LIST_FAIL,
    GET_PREG_HIGHLIGHT_APP_LIST_SUCCESS,
    GET_PREG_HIGHLIGHT_APP_LIST_RESET
} from '../action/getPregHighlightAppListAction'
import { api_err_msg } from "../strings.json"

// initialState
const initialState = {
    apiState: "",
    data: {},
    message: ""
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PREG_HIGHLIGHT_APP_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_PREG_HIGHLIGHT_APP_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }
        case GET_PREG_HIGHLIGHT_APP_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data.result,
            }
        case GET_PREG_HIGHLIGHT_APP_LIST_RESET:
            return initialState
        default:
            return{
                ...state
            }
    }
}