import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getArticleAppList,
  getArticleAppListReset,
} from "../../action/getArticleAppListAction";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
//style
import {
  HomeCuratedPara,
  CuratedTitle,
  ArticleTitle,
  XLink,
  CuratedImg,
} from "./ArticleBlockStyle";
import config from "../../config";
import { ArticleCard } from "../../page/ArticleList/ArticleListStyle";
import moment from "moment";
import { DotIcon } from "../../component/CustomIcons";
import {
  EyeOutlined,
  HeartOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

function ArticleBlock(props) {
  const { getArticleAppList, getArticleAppListReset, getArticleAppListState } =
    props;
  const [articleData, setArticleData] = useState({
    data: [],
    available: false,
  });
  let interest = JSON.parse(localStorage.getItem("interest")) || [];

  let paramsAL = {
    week: props.week,
    type: props.type,
    category: encodeURIComponent(
      interest.length > 0 && interest[0] != "null" ? interest : null
    ),
  };

  useEffect(() => {
    setArticleData({
      data: props.data,
      available: true,
    });
  }, [props.data]);

  console.log("articleData====>", articleData);
  console.log("paramsAL article block", paramsAL);
  // articleData.data.map((item,i) => console.log(item, "item"));
  return (
    <div>
      {props.align === "right" && articleData.data.length > 0 ? (
        <>
          {articleData.data.length > 0 && (
            <div className="curatedWrap pt-5">
              <div className="container">
                <CuratedTitle className="text-center">
                  {props.title}
                </CuratedTitle>
                <div className="row">
                  <div className="col-sm-6 mt-tab-blog">
                    <div className="row align-items-center">
                      <div className="col-sm-12">
                        <Link
                          to={{
                            pathname: "/article/" + articleData.data[0].slug,
                            state: {
                              type: props.apiType,
                              category: props.interest,
                              week: paramsAL.week,
                            },
                          }}
                        >
                          <CuratedImg
                            className="img-fluid curated-img"
                            src={
                              articleData.available
                                ? `${config.api.s3_url}article/${articleData.data[0].image_path}`
                                : `./images/mom1.svg`
                            }
                            alt="no"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5 mt-3">
                    <p className="blog-create-date">
                      {moment(articleData.data[0].createdAt).format(
                        "Do MMM YYYY"
                      )}
                    </p>
                    <Link
                      to={{
                        pathname: "/article/" + articleData.data[0].slug,
                        state: {
                          type: props.apiType,
                          category: props.interest,
                          week: paramsAL.week,
                        },
                      }}
                      className="remove-underline"
                    >
                      <ArticleTitle>{articleData.data[0].title}</ArticleTitle>
                      <HomeCuratedPara>
                        {" "}
                        {articleData.data[0].short_description.slice(0, 150) +
                          "..."}
                        {/* <Link
                      style={{ textDecoration: "none" }}
                      className="ReadMore"
                      to={{
                        pathname: "/article/" + articleData.data[0].slug,
                        state: {
                          type: props.apiType,
                          category: props.interest,
                          week: paramsAL.week,
                        },
                      }}
                    >
                      read more
                    </Link> */}
                        {/* Get support from AtEase experts for mental health issues
                    related to reproductive health like irregular menstruation,
                    PMS (premenstrual syndrome), PMDD (premenstrual depressive
                    disorder) , infertility, PCOS (polycystic ovary syndrome),
                    menopause. */}
                      </HomeCuratedPara>
                    </Link>
                    <XLink
                      to={{
                        pathname: `/ArticleList`,
                        state: {
                          type: props.apiType,
                          category: props.interest,
                          week: paramsAL.week,
                        },
                      }}
                      className="d-flex align-items-center gap-3 view-btn"
                    >
                      <p className="m-0 mt-1">View All</p>
                      <ArrowRightOutlined className="mt-1" />
                    </XLink>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {articleData.data.length > 0 && (
            <div className="homeVides">
              <div className="container">
                <CuratedTitle className="text-center">
                  {props.title}
                </CuratedTitle>
              </div>
              {/* <div className="row"> */}
              {/* <div className="offset-sm-2 col-sm-4 order-2 order-sm-1"> */}

              {/* <HomeCuratedPara className="mb-5">
                    {articleData.data[0].short_description.slice(0, 200) +
                      "..."}
                    <Link
                      style={{ textDecoration: "none" }}
                      className="ReadMore1"
                      to={{
                        pathname: "/article/" + articleData.data[0].slug,
                        state: {
                          type: props.apiType,
                          category: props.interest,
                          week: paramsAL.week,
                        },
                      }}
                    >
                      view
                    </Link>
                  </HomeCuratedPara> */}

              {/* </div> */}
              {/* <div className="col-sm-6 order-1 order-1 order-sm-2 mt-tab-video"> */}
              <div className="container">
                {/* <div className="col-sm-12 col-xl-4 col-md-6 col-lg-4"> */}
                {/* <CuratedImg
                        className="img-fluid curated-img"
                        src={`${config.api.s3_url}article/${articleData.data[0].image_path}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "/images/NoProfile.svg";
                        }}
                        alt=""
                      /> */}
                <Row
                  gutter={[20, 20]}
                  style={{
                    marginLeft: "0.5%",
                    marginRight: "0.5%",
                    paddingBottom: "6%",
                    justifyContent: "center"
                  }}
                >
                  {articleData.data.map(
                    (item, index) =>
                      index <= 2 && (
                        <Col
                          className="coltest"
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                          key={item._id}
                        >
                          <Link
                            to={{
                              pathname: "/article/" + item.slug,
                            }}
                            className="remove-underline"
                          >
                            {
                              <ArticleCard
                                className="card-tablets"
                                cover={
                                  <img
                                    alt="example"
                                    className="msgv"
                                    src={`${config.api.s3_url}article/${item.image_path}`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src =
                                        "/images/ArticlePlaceholder.svg";
                                    }}
                                  />
                                }
                              >
                                <div className="videoPlayBtn">
                                  <Link
                                    to={{
                                      pathname: "/article/" + item.slug,
                                      state: {
                                        type: props.apiType,
                                        category: props.interest,
                                        week: paramsAL.week,
                                      },
                                    }}
                                  >
                                    <img src="./images/svg/play-button.svg" />
                                  </Link>
                                </div>
                                <div className="description">
                                  <p>{item.title}</p>

                                  <div className="views">
                                    <span>
                                      {moment(item.createdAt).format(
                                        "Do MMM YYYY"
                                      )}
                                    </span>
                                    <span style={{ margin: "0px 2px" }}>
                                      {/* <DotIcon size="6" height="6" /> */}
                                    </span>
                                    <EyeOutlined />
                                    <span>{item.views} views</span>
                                    <span style={{ margin: "0 2px" }}>
                                      {/* <DotIcon size="6" height="6" /> */}
                                    </span>
                                    <HeartOutlined />
                                    <span>{item.likes} Likes</span>
                                  </div>
                                </div>
                              </ArticleCard>
                            }
                          </Link>
                        </Col>
                      )
                  )}
                </Row>
                {/* </div> */}
              </div>
              <div className="container text-center row">
                <XLink
                  to={{
                    pathname: `/ArticleList`,
                    state: {
                      type: props.apiType,
                      category: props.interest,
                      week: paramsAL.week,
                    },
                  }}
                  className="d-flex align-items-center gap-3 ml-auto mr-auto"
                  style={{ width: "fit-content", zIndex: "100" }}
                >
                  <p className="m-0 mt-1">View All</p>
                  <ArrowRightOutlined className="mt-1" />
                </XLink>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  getArticleAppListState: state.getArticleAppList,
});

const mapDispatchToProps = (dispatch) => ({
  getArticleAppList: (params) => dispatch(getArticleAppList(params)),
  getArticleAppListReset: () => dispatch(getArticleAppListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleBlock);
