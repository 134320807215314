import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_ARTICLE_LIKE,
    saveArticleLikeFail,
    saveArticleLikeSuccess
} from '../action/saveArticleLikeAction'

// api 
import saveArticleLikeApi from '../api/saveArticleLikeApi'

export function* saveArticleLikeSaga(action) {
    try {
        const response = yield call(() => saveArticleLikeApi(action.params))
        if (response.data.status === 1) {
            yield put(saveArticleLikeSuccess(response, action))
        } else {
            yield put(saveArticleLikeFail(response, action))
        }
    }
    catch (e) {
        yield put(saveArticleLikeFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ARTICLE_LIKE, saveArticleLikeSaga)
}