import styled, { css } from "styled-components";


export const ListExpertBox  = styled.div`
    padding: 15px;
    width: 100%; 
    background-color: #FFFFFF;
    border-radius: 10px; 
    box-shadow: 0px 8px 18px rgba(202, 212, 226, 0.3);
    margin-bottom: 30px;
    height:90%;
`

export const XImg = styled.img`
    //width: 226px;
    width: 170px;
    height: 100%;
    // width: 100%;
    border-radius: 10px;
    object-fit: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
        width: 132.24px;
        height: 100%;
    }
`

export const ListBoxPara = styled.p`
    position: relative;
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A3A9BF;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 28px; 
        margin-bottom: 0px;
    }
`

export const TopContainer  = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
        margin: auto;
        width: 35%;
        margin-top: 15px;
    } 
`