import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const getArticleBySlugApi = async (params) => {
  const response = await axios({
    url: `${config.api.base_url}article/get-article-by-slug`,
    method: "get",  
    params: params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });

  return response;
};

export default getArticleBySlugApi;
