export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const GET_SERVICES_LIST_FAIL = "GET_SERVICES_LIST_FAIL";
export const GET_SERVICES_LIST_SUCCESS = "GET_SERVICES_LIST_SUCCESS";
export const GET_SERVICES_LIST_RESET = "GET_SERVICES_LIST_RESET";

export const getServicesList = (params) => {
  console.log("params from actions", params);
  return { type: GET_SERVICES_LIST, params };
};
export const getServicesListFail = (response) => {
  return { type: GET_SERVICES_LIST_FAIL, response };
};
export const getServicesListSuccess = (response) => {
  return { type: GET_SERVICES_LIST_SUCCESS, response };
};
export const getServicesListReset = () => {
  return { type: GET_SERVICES_LIST_RESET };
};
