import styled, { css } from "styled-components";

// export const ArticleImage = styled.img`
//    border-radius: 10px;
//    width: 100%;
//    height: 400px;
// `
export const ArticleImage = styled.img`
   ${(props) => {
      switch (props.mode) {
         case "text":
            return css`
            border-radius: 10px;
            object-fit: cover;
            width: 60%;
            height: auto;
             margin: auto;
            margin: "7px";
            box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
            border-radius: 15px;
            @media (max-width: 576px) {
               height: 100% !important;
               width: 100% !important;
            }
            `;
         case "audio":
            return css` 
            border-radius: 10px;
            width: 100%;
            height: auto;
            margin: auto;
            // margin: "7px"
            margin:auto;
            display:flex;
            box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
           border-radius: 15px;
             `;
         default:
            return css`
            border-radius: 30px;
            width: 100%;
               height: auto;
            margin: auto;
            marginLeft: 5px;
            background-color: red;
            margin: 7px;
           box-shadow: rgb(174 127 184 / 20%) 0px 0px 40px;
           border-radius: 15px;
     `;
      }
   }}
`