import React from "react";

/* CUSTOM COMPONENTS */
import { Container } from "./PageBodyStyle";

const PageBody = (props) => {
  return <Container>{props.children}</Container>;
};

export default PageBody;
