import config from '../config'
import axios from 'axios'

export const registerApi = async (params) => {
    let url = `${config.api.base_url}patient/auth/register`
    let data = {}
    console.log("Before register data", params)
    // alert("RegisterAPI")
    if (params.socialSignin) {

        url = url + "?type=" + params.provider
        data["provider"] = {
            "type": params.provider,
            "id": params.id
        }
        data["name"] = params.name
        data["email"] = params.email
        data["gender"] = params.gender
        data["dob"] = params.dob
        data["interest"] = params.interest
        data["lmp"] = params.lmp
        data["lmp_as_of"] = params.lmp_as_of
        data["phone"] = params.phone
        data["conceive_status"] = params.conceive_status
        data["patient_id"] = params.patient_id

    } else {
        data = {
            "name": params.name,
            "email": params.email,
            "gender": params.gender,
            "dob": params.dob,
            "interest": params.interest,
            "password": params.password,
            "lmp": params.lmp,
            "lmp_as_of": params.lmp_as_of,
            "phone": params.phone,
            "conceive_status": params.conceive_status,
            "patient_id": params.patient_id
        }
    }

    const response = await axios({
        method: 'post',
        url,
        data,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        }
    })
    return response
}

export default registerApi