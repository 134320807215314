import React, { useState } from "react";
import { ArticleImage } from "./ImagesArticleStyle";
import { s3_cdn } from "../../utils";
import { connect } from "react-redux";
import { getArticle, getArticleReset } from "../../action/getArticleAction";
// import {
//   getArticleBySlug,
//   getArticleBySlugReset,
//   getArticleBySlugState,
// } from "../../action/getArticleBySlugAction";
import config from "../../config";

const ImagesArticle = (props) => {
  console.log("Image Article", props);
  const { getArticle, getArticleState } = props;

  // const [imgSrc, setImgSrc] = useState(`${s3_cdn()}article/${getArticleState.data.image_path}`);
  const [imgSrc, setImgSrc] = useState(
    `${config.api.s3_url}article/` + getArticleState.data.image_path
  );
  // const [imgSrc, setImgSrc] = useState("https://picsum.photos/200");
  console.log("images", imgSrc);

  return (
    <ArticleImage mode={props.type} className="audio" src={imgSrc}>
      {/* // src="https://picsum.photos/200"> */}
    </ArticleImage>
  );
};

const mapStateToProps = (state) => ({
  getArticleState: state.getArticle,
});

const mapDispatchToProps = (dispatch) => ({
  getArticle: (params) => dispatch(getArticle(params)),
  getArticleReset: () => dispatch(getArticleReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesArticle);
