import React, { useEffect, useState } from "react";
import { Typography, Modal, Input, Button, Row, Col, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import ShowMoreText from "react-show-more-text";
import config from "../../config";
import { getPatientToken } from "../../utils";
import { getBookTest, getBookTestReset } from "../../action/getBookTestAction";
import getBookTestApi from "../../api/getBookTestApi";
import "./BookTest.css";
import { TopContainer } from "./BookTestStyle";
import { Xpagination } from "../../component/Xcomponent";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

const BookTest = (props) => {
  const { getBookTest, getBookTestReset, getBookTestState } = props;
  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }
  const [loading, setLoading] = useState(false);
  const [List, setList] = useState([]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [testFilter, setTestFilter] = useState({
    test_name: "",
    test_category: "",
  });
  const [scrollTopBtn, setScrollTopBtn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBookTest(testFilter);
    // getBookTestApi();
    // fetch(`${config.api.base_url}test`, {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${getPatientToken()}`,
    //   },
    // }).then((result) => {
    //   result.json().then((resp) => {
    //     setData(resp.data);
    //     console.log(resp);
    //   });
    // });
  }, []);

  useEffect(() => {
    getBookTest(testFilter);
  }, [testFilter]);

  useEffect(() => {
    if (getBookTestState.apiState === "loading") {
      setLoading(true);
    } else if (getBookTestState.apiState === "success") {
      setData(getBookTestState.data);
      setLoading(false);
    }
  }, [getBookTestState.apiState, getBookTestState.data]);

  useEffect(() => {
    setIsCategoryModalVisible(true);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  const showCategoryModal = () => {
    setIsCategoryModalVisible(true);
  };

  // useEffect(() => {
  //   fetchData("", selectedCategory);
  // }, [selectedCategory]);

  const fetchData = async (searchValue, category) => {
    try {
      // getBookTestApi(searchValue, category);
      // const response = await axios.get(
      //   `${config.api.base_url}test?search=${searchValue}&category=${category}`,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${getPatientToken()}`,
      //     },
      //   }
      // );
      // setData(response.data.data);
      // console.log("res:", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilterButtonClick = async (category) => {
    setSelectedCategory(category);
    setIsCategoryModalVisible(false);

    setTestFilter({
      ...testFilter,
      test_category: category,
    });
    // fetchData("", category);
    // setData(response.data.data);
  };

  const handleCategoryCancel = () => {
    setIsCategoryModalVisible(false);
  };

  const filters = (e) => {
    let testValue = e.target.value;

    setTestFilter({
      ...testFilter,
      test_name: testValue,
    });

    // if (e.target.value !== "") {
    //   if (Array.isArray(data.tests)) {
    //   let filteredData = data.tests.filter((test) =>
    //     test.display_test_name.toLowerCase().includes(testValue.toLowerCase())
    //   );
    //   setData({...data,tests:filteredData});
    //   }
    // } else {
    //   setData(data);
    // }
    // fetchData(testValue, selectedCategory);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function getBookListId(item, e) {
    //console.log(e.target.getAttribute('cardId'));
    Modal.confirm({
      title: "Confirm",
      content: (
        <div className="mt-4">
          <p>
            <b>Test Name :</b>
            &nbsp;{item.display_test_name}
          </p>
          <p>
            <b>MRP :</b>
            &nbsp;<span style={{fontFamily:"initial"}}>₹&nbsp;</span>{item.mrp}
          </p>
          <p>
            <b>Description :</b>
            &nbsp;{item.description}
          </p>
        </div>
      ),
      style: {},
      okText: "Confirm",
      onOk() {
        const bookingParams = {
          test_id: e,
        };
        axios({
          url: `${config.api.base_url}test/test-book`,
          method: "post",
          data: bookingParams,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getPatientToken()}`,
          },
        }).then((result) => {
          console.log(result.data.status);

          if (result.data.message === "There was an error saving this data") {
            //setisDisabledLoad(false);
            alert("There was an error saving this data");
          } else {
            showModal();
          }
        });
      },
    });
  }

  return (
    <div className="mb-5 " style={{ position: "relative" }}>
      <div className="expertDetail">
        <TopContainer>
          <img
            className="align-center mobile-height"
            src="./images/lilac-insights-logo.svg"
            alt="exp"
          />
        </TopContainer>
        <img className="drListLeftBg" src="./images/svg/doctorbgLeft.svg" />
        <img
          alt="right-bg"
          className="drListRightBg"
          src="./images/svg/doctorbgRight.svg"
        />
        <div className="container">
          <div className="expertWrap">
            <div className="row">
              <div className="col-12 col-sm-5">
                <h1 className="expertHeading">Tests</h1>
              </div>
              <div className="col-12 col-sm-7">
                <div className="expertAlign">
                  <Input
                    className="consultSearch inputBox"
                    onChange={(e) => filters(e)}
                    prefix={<SearchOutlined style={{ color: "gray" }} />}
                    placeholder="Search"
                  />
                  <img
                    className="img-fluid expertFilter"
                    onClick={showCategoryModal}
                    src="/images/svg/Filter.svg"
                    alt="logo"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {loading ? (
                <div className="text-center loader-container">
                  <Spin spinning={loading} tip="Loading Tests..." />
                </div>
              ) : Array.isArray(data.tests) ? (
                <>
                  {data.tests.map((item) => (
                    <div
                      className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-3"
                      key={item._id}
                    >
                      <div className="bookTestBox text-center">
                        <h2 className="boolTestHead text-left">
                          Test Name : {item.display_test_name}
                        </h2>
                        <h4 className="boolTestHeade2 text-left">
                          Description{" "}
                        </h4>
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          className="text-left"
                          anchorClass="bookReadMore"
                          onClick={executeOnClick}
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <p>{item.description}</p>
                        </ShowMoreText>
                        <p className="mt-2">
                          <strong>MRP : {item.mrp}</strong>
                        </p>
                        <button
                          className="themeBtn"
                          cardId={item._id}
                          onClick={() => getBookListId(item, item._id)}
                        >
                          Book
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="loader-container">
                  <p>No Tests Found</p>
                </div>
                
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={isCategoryModalVisible}
        onCancel={handleCategoryCancel}
        footer={null}
      >
        <h5>Select Category</h5>{" "}
        <div className="btn-container">
          {" "}
          <Button
            className={`category-btn mt-3 ${
              selectedCategory === "adults" ? "selected" : ""
            }`}
            onClick={() => handleFilterButtonClick("adults")}
          >
            Adults{" "}
          </Button>{" "}
          <Button
            className={`category-btn ${
              selectedCategory === "duringPregnancy" ? "selected" : ""
            }`}
            onClick={() => handleFilterButtonClick("duringPregnancy")}
          >
            During Pregnancy{" "}
          </Button>{" "}
          <Button
            className={`category-btn ${
              selectedCategory === "forBabiesChildren" ? "selected" : ""
            }`}
            onClick={() => handleFilterButtonClick("forBabiesChildren")}
          >
            For Babies and Children{" "}
          </Button>{" "}
          <Button
            className={`category-btn ${
              selectedCategory === "postPregnancy" ? "selected" : ""
            }`}
            onClick={() => handleFilterButtonClick("postPregnancy")}
          >
            Post-pregnancy{" "}
          </Button>{" "}
          <Button
            className={`category-btn ${
              selectedCategory === "prePregnancy" ? "selected" : ""
            }`}
            onClick={() => handleFilterButtonClick("prePregnancy")}
          >
            Pre-pregnancy{" "}
          </Button>{" "}
        </div>{" "}
      </Modal>
      {scrollTopBtn && <ScrollToTop />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  getBookTestState: state.getBookTest,
});

const mapDispatchToProps = (dispatch) => ({
  getBookTest: (params) => dispatch(getBookTest(params)),
  getBookTestRest: () => dispatch(getBookTestReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookTest);
