import { call, put, takeLatest } from "redux-saga/effects";

// action
import {
  GET_SERVICES_LIST,
  getServicesListFail,
  getServicesListSuccess,
} from "../action/getServicesListAction";

// api
import getServicesListApi from "../api/getServicesListApi";

export function* getArticleAppListSaga(action) {
  try {
    const response = yield call(() => getServicesListApi(action.params));
    if (response.data.status === 1) {
      yield put(getServicesListSuccess(response, action));
    } else {
      yield put(getServicesListFail(response, action));
    }
  } catch (e) {
    yield put(getServicesListFail(e.response, action));
  }
}

export default function* mySaga() {
  yield takeLatest(GET_SERVICES_LIST, getArticleAppListSaga);
}
