import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const XImg = styled.img`
@media (max-width: 768px) {
    width: 140px;
    height: 105px; 
`

export const XDiv = styled.div`
@media (max-width: 768px) { 
    height: 100px; 
    margin-left: -30px;
`