import { call, put, takeLatest } from "redux-saga/effects"

// action
import { 
    saveArticleBookmarkFail,
    saveArticleBookmarkSuccess,
    SAVE_ARTICLE_BOOKMARK
} from '../action/saveArticleBookmarkAction'

// api 
import saveArticleBookmarkApi from '../api/saveArticleBookmarkApi'

export function* saveArticleBookmarkSaga(action) {
    try {
        const response = yield call(() => saveArticleBookmarkApi(action.params))
        if (response.data.status === 1) {
            yield put(saveArticleBookmarkSuccess(response, action))
        } else {
            yield put(saveArticleBookmarkFail(response, action))
        }
    }
    catch (e) {
        yield put(saveArticleBookmarkFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ARTICLE_BOOKMARK, saveArticleBookmarkSaga)
}