import {
    SEND_OTP,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAIL,
} from '../action/sendOtpAction'
import { api_err_msg } from "../strings.json"

const initialState = {
    apiState: "", // loading, success, error
    message: "",
    data: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND_OTP:
            return {
                ...state,
                apiState: "loading",
            }

        case SEND_OTP_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.data,
                message: action.response && action.response.data && action.response.data.message || api_err_msg,
            }

        case SEND_OTP_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.data.message
            }

        default:
            return state
    }
}
