import styled from "styled-components";
import { Button, Form, Radio, Typography } from "antd";
import { Link } from 'react-router-dom'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`
export const Content = styled.div`
    display: flex;
    max-width: 100%;
    align-self: center;
    width: 1200px;
    min-height: 800px;
`
export const Left = styled.div` 
    width: 50%; 
    margin-left: -15%;
`
export const Right = styled.div` 
    width: 65%; 
    top: 0;
    margin-left: 25%;
    padding-top: 4%;
`

export const Ximg = styled.img` 
margin-bottom: -50px;
`

export const XTitle = styled(Typography.Title)`
    margin-left: -5%;
    @media (min-width: 300px) and  (max-width: 580px){
        margin-top : 280px;
        margin-left: -45%;
        width: 150%; 
    }
`

export const XDTitle = styled(Typography.Title)`
    margin-left: -5%;  
    @media (min-width: 300px) and  (max-width: 580px){ 
        margin-left: -30%;  
    }
`

export const Xh6 = styled.h6 `  
    @media (min-width: 300px) and  (max-width: 580px){
        margin-left: -25%; 
    }
`

export const CardFoot1 = styled.div` 
    display: flex;  
    font-weight: 500;  
    @media (min-width: 300px) and  (max-width: 580px){
        // display: inline; 
        position:absolute;
        margin-top: -65%;
        margin-left: -25% !important; 
        align-content: space-around; 
    }
`

export const CardFoot2 = styled.div` 
    display: flex;  
    font-weight: 500;  
    @media (min-width: 300px) and  (max-width: 580px){
        // display: inline; 
        position:absolute;
        margin-top: -65%;
        margin-left: -12% !important; 
        align-content: space-around; 
    }
`

export const CardFoot3 = styled.div` 
    display: flex;  
    font-weight: 500;  
    @media (min-width: 300px) and  (max-width: 580px){
        // display: inline; 
        position:absolute;
        margin-top: -65%;
        margin-left: 1% !important; 
        align-content: space-around; 
    }
`

export const CardFootD1 = styled.div` 
   
`

export const CardFootD2 = styled.div` 
    
`

export const FormItem = styled(Form.Item)` 

`
export const FormItem2 = styled(Form.Item)`
`
export const RadioButton = styled(Radio.Button)` 
    
`

export const RadioButtonD = styled(Radio.Button)` 
    
`

export const XButton = styled(Button)` 
    width: 200px;
    background-color: #C473C4;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media (min-width: 300px) and  (max-width: 580px){
        height: 40px !important;
        width: 280px;
        margin-Left: -30%;
        margin-top: 50%;
        margin-bottom: 5%;
    } 
`