import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Spin } from "antd";
import { EyeOutlined, HeartOutlined } from "@ant-design/icons";

import { PageContainer } from "../../component/Xcomponent";
import { ArticleCard, CuratedTitle } from "./ArticleListStyle";
import { s3_cdn } from "../../utils";
import moment from "moment";
import { DotIcon } from "../../component/CustomIcons";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { getPatientToken } from "../../utils";

import "./ArticleList.css";
import ScrollToTop from "../../component/ScrollToTop/ScrollToTop";

const ArticleList = (props) => {
  console.log("aricle list read");
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrollTopBtn, setScrollTopBtn] = useState(false);
  const location = useLocation();
  const type = location.state?.type;
  const week = location.state?.week;
  const category = encodeURIComponent(location.state?.category);
  const url =
    week !== ``
      ? `${config.api.base_url}article/app-list/${type}?week=${week}&category=${category}`
      : `${config.api.base_url}article/app-list?category=${category}`;

  async function getArticleList(type) {
    try {
      setLoading(true);
      const response = await axios({
        url: url,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getPatientToken()}`,
        },
      });
      const res = await response.data.data.result;
      console.log(res, type);
      if (type === "text") {
        setResponse(res.text);
      } else if (type === "video") {
        setResponse(res.video);
      } else if (type === "audio") {
        setResponse(res.audio);
      } else {
        setResponse([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    let article_type = "";
    switch (type) {
      case "text":
        article_type = "text";
        break;
      case "video":
        article_type = "video";
        break;
      case "audio":
        article_type = "audio";
        break;
      default:
        article_type = "";
        break;
    }
    getArticleList(article_type);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setScrollTopBtn(true);
      } else {
        setScrollTopBtn(false);
      }
    });
  }, []);

  return (
    <PageContainer style={{ textDecoration: "none" }}>
      <>
        <div className="container roe">
          <CuratedTitle>
            {type === "text"
              ? "Blogs"
              : type === "video"
              ? "Videos"
              : "Coming Soon!"}
          </CuratedTitle>
        </div>
        <div className="container">
          {loading ? (
            <div className="text-center">
              <Spin spinning={loading} tip="Loading..." />
            </div>
          ) : response.length > 0 ? (
            <Row
              gutter={[20, 20]}
              style={{
                marginLeft: "0.5%",
                marginRight: "0.5%",
                paddingBottom: "6%",
              }}
            >
              {response.map((data) => (
                <Col
                  className="coltest"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  key={data.slug}
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{ pathname: "/article/" + data.slug }}
                  >
                    {type !== "audio" && (
                      <ArticleCard
                        className="card-tablets"
                        cover={
                          <img
                            alt="example"
                            className="msgv"
                            src={`${config.api.s3_url}article/${data.image_path}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src =
                                "/images/ArticlePlaceholder.svg";
                            }}
                          />
                        }
                      >
                        <div className="description">
                          <p>{data.title}</p>

                          <div className="views">
                            <span>
                              {moment(data.createdAt).format("Do MMM YYYY")}
                            </span>
                            <span style={{ margin: "0px 2px" }}>
                              {/* <DotIcon size="6" height="6" /> */}
                            </span>
                            <EyeOutlined />
                            <span>{data.views} views</span>
                            <span style={{ margin: "0 2px" }}>
                              {/* <DotIcon size="6" height="6" /> */}
                            </span>
                            <HeartOutlined />
                            <span>{data.likes} Likes</span>
                          </div>
                        </div>
                      </ArticleCard>
                    )}
                  </Link>
                </Col>
              ))}
            </Row>
          ) : (
            <p className="text-center">
              {type === "text"
                ? "No Blogs Found."
                : type === "video"
                ? "No videos Found."
                : "Coming Soon!"}
            </p>
          )}
        </div>
      </>
      {scrollTopBtn && <ScrollToTop />}
    </PageContainer>
  );
};

export default ArticleList;
