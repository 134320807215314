import axios from "axios";
import config from "../config"
import {getPatientToken} from '../utils'

const savePatientProfileApi = async (params) => { 
  const response = await axios({
    url: `${config.api.base_url}patient/profile/save`,
    method: "get",  
    data: params,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getPatientToken()}`
    },
  });  
  console.log(response)
  return response;
};

export default savePatientProfileApi;
