import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_PATIENT_PROFILE,
    savePatientProfileFail,
    savePatientProfileSuccess
} from '../action/savePatientProfileAction'

// api 
import savePatientProfileApi from '../api/savePatientProfileApi'

export function* savePatientProfileSaga(action) {
    try {
        const response = yield call(() => savePatientProfileApi(action.params))
        if (response.data.status === 1) {
            yield put(savePatientProfileSuccess(response, action))
        } else {
            yield put(savePatientProfileFail(response, action))
        }
    }
    catch (e) {
        yield put(savePatientProfileFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_PATIENT_PROFILE, savePatientProfileSaga)
}