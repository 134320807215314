import config from '../config'
import axios from 'axios'
// import {getPatientToken} from '../utils'

export const ValidateOtpApi = async (params) => {
    
    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}patient/validate-otp`,
        data: {
            "phone": params.phone,
            "otp": params.otp
        },
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "token": params.token,
            "Accept": "application/json",
        }
    })
    
    return response
}

export default ValidateOtpApi